export default {
    locale: state => state.locale,
    availableLocales: state => state.availableLocales,
    company: state => state.company,
    requisites: state => state.requisites,
    translations: state => state.translations,
    translationKey: state => state.translationKey,
    allmenu: state => state.allmenu,
    menuDataLoaded: state => state.allmenu.dataLoaded,
    menuCompany: state => state.allmenu.menuCompany,
    menuCompanyCategoriesAndProducts: state => state.allmenu.menuCompanyCategoriesAndProducts,
    menuCompanyRequisites: state => state.allmenu.menuCompanyRequisites,
    menuCompanyHallsTablesOrders: state => state.allmenu.menuCompanyHallsTablesOrders,
    menuCompanySettings: state => state.allmenu.menuCompanySettings,
    basket: state => state.allmenu.basket,
    deleteFromBasket: state => state.allmenu.deleteFromBasket,
    isMobileDevice: state => state.allmenu.isMobileDevice,
    menuSelectedProduct: state => state.allmenu.selectedProduct,
    tableReservationStep: state => state.allmenu.tableReservationStep,
    reservationData: state => state.allmenu.reservationData,
    exchangeRates: state => state.exchangeRates,

    menuCompanyDefaultLocale: state => {
        if (!state.allmenu || !state.allmenu.menuCompanySettings || !state.allmenu.menuCompanySettings.length) {
            return null
        }

        let defaultLocaleData = state.allmenu.menuCompanySettings.find(setting => setting.key === 'LOCALE')

        if (!defaultLocaleData) {
            return null
        }

        return defaultLocaleData.value
    },

    menuCompanyCurrency: state => {
        if (!state.allmenu || !state.allmenu.menuCompanySettings || !state.allmenu.menuCompanySettings.length) {
            return null
        }

        let currencyData = state.allmenu.menuCompanySettings.find(setting => setting.key === 'CURRENCY')

        if (!currencyData) {
            return null
        }

        return currencyData.value
    },

    shoppingCart: state => state.shoppingCart,
    favorites: state => state.favorites,

    carParts: state => state.carParts,
    carPartsCategories: state => state.carParts.categories,
    carPartsGeneratedCategories: state => state.carParts.generatedCategories,
    carPartsMarks: state => state.carParts.marks,
    carPartsPromotionCarParts: state => state.carParts.promotionCarParts,
    carPartsDiscountedCarParts: state => state.carParts.discountedCarParts,
    carPartsPopularMarks: state => state.carParts.popularMarks,
    carPartsPromotionCarPartsLoaded: state => state.carParts.promotionCarPartsLoaded,
    carPartsPopularMarksLoaded: state => state.carParts.popularMarksLoaded,
    carPartLoaded: state => state.carParts.carPartLoaded,
    carPartsMarkModelsLoaded: state => state.carParts.markModelsLoaded,
    carPartsModelCarPartsLoaded: state => state.carParts.modelCarPartsLoaded,
    carPartsProductSearch: state => state.carParts.productSearch
}
