<template>
    <div class="container-fluid position-relative px-0">
        <div class="container pt-lg-5 pt-3">
            <div class="d-lg-flex justify-content-center">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="d-lg-flex justify-content-lg-center">
                <div class="text-uppercase text-white fs-50 fs-sm-25 mt-5">
                    <div class="d-flex flex-md-row flex-column align-items-lg-center">
                        <p class="mb-0 min-w-250">
                            address :
                        </p>
                        <p class="mb-0">
                            <a :href="requisite('LOC_GOOGLE_MAP_LINK')"
                               class="text-white text-decoration-none contactsRequisites" target="_blank">
                                7 chovelidze str. tbilisi
                            </a>
                        </p>
                    </div>

                    <div v-if="requisite('PHONE_1')"
                         class="d-flex flex-md-row flex-column align-items-lg-center mt-md-0 mt-4">
                        <p class="mb-0 min-w-250">
                            tel :
                        </p>
                        <p class="mb-0">
                            <a :href="'tel:' + requisite('PHONE_1')"
                               class="text-white text-decoration-none contactsRequisites">{{ requisite('PHONE_1') }}</a>
                        </p>
                    </div>

                    <div v-if="requisite('PHONE_2')"
                         class="d-flex flex-md-row flex-column align-items-lg-center mt-md-0 mt-4">
                        <p class="mb-0 min-w-250">
                            mob :
                        </p>
                        <div class="d-flex align-items-center">
                            <a :href="'tel:' + requisite('PHONE_2')"
                               class="text-white text-decoration-none contactsRequisites">{{ requisite('PHONE_2') }}</a>
                            <div class="d-flex align-items-center ms-5">
                                <a :href="'https://api.whatsapp.com/send/?phone=+995' + requisite('PHONE_2') + '&text=Hi&app_absent=0'">
                                    <img :src="whatsapp" alt="whatsapp" class="appIcon">
                                </a>
                                <a :href="'viber://chat/?number=+995'+ requisite('PHONE_2')">
                                    <img :src="viber" alt="viber" class="appIcon ms-5">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-center mt-md-0 mt-4">
                        <p class="mb-0 min-w-250">
                            soc. media :
                        </p>
                        <div class="d-flex align-items-center ms-lg-0 ms-5">
                            <a href="https://www.instagram.com/blueberrynightshotel/" target="_blank" title="Instagram">
                                <img :src="instagram" alt="instagtam" class="socIcon" height="35">
                            </a>

                            <a href="https://www.facebook.com/blueberrynightshotel" target="_blank" title="Facebook">
                                <img :src="facebook" alt="facebook" class="socIcon ms-5" height="35">
                            </a>
                        </div>
                    </div>

                    <div v-if="requisite('EMAIL_LOG')" class="mt-md-0 mt-4">
                        <p class="mb-0 text-lowercase">
                            <a :href="'mailto:' + requisite('EMAIL_LOG')"
                               class="text-white text-decoration-none contactsRequisites">{{
                                    requisite('EMAIL_LOG')
                                }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {requisite} from "@/core/helpers"

export default {
    name: "HotelContacts",

    computed: {
        ...mapGetters(['requisites'])
    },

    methods: {
        requisite(key) {
            return requisite(key, this.requisites)
        }
    },

    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
            whatsapp: require("@/assets/websites/blueberrynightshotel/img/app_whatsapp.png"),
            viber: require("@/assets/websites/blueberrynightshotel/img/app_viber.png"),
            facebook: require("@/assets/websites/blueberrynightshotel/img/fb-icon.png"),
            instagram: require("@/assets/websites/blueberrynightshotel/img/instagram-icon.png"),
        }
    }
}
</script>

<style scoped>
@media screen and (min-width: 992px) {
    .min-w-250 {
        min-width: 250px;
    }
}

@media screen and (max-width: 992px) {
    .min-w-250 {
        min-width: 130px;
    }

    .appIcon {
        height: 30px !important;
    }

    .socIcon {
        height: 25px !important;
    }
}
</style>