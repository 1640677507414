<template>
    <div class="category-products">
        <div id="removeProduct" aria-hidden="true" aria-labelledby="removeProductLabel" class="modal fade"
             tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-body fs-6 fw-normal text-center p-2">
                        {{ trans('menu_remove_want') }}
                    </div>
                    <div class="modal-footer pt-0 p-2 border-0">
                        <button class="btn btn-sm btn-danger" data-bs-dismiss="modal" type="button">
                            {{ trans('menu_cancel') }}
                        </button>
                        <button class="btn btn-sm btn-success" data-bs-dismiss="modal" type="button"
                                @click="removeProductFromBasket">{{ trans('menu_confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="(category, key) in categories"
             :key="key" :childId="childrenCategory ? category.id : null"
             :class="childrenCategory ? 'mb-lg-4 un-children-category-block' : 'mb-lg-4 un-category-block'"
             :parentId="childrenCategory ? category.parent_id : category.id">
            <p :id="childrenCategory ? 'children-category-'+category.id : 'category-'+category.id"
               :class="childrenCategory ? 'mb-0 fs-4 fw-bold ps-lg-2 ps-3 pt-lg-3 pb-lg-0 pt-3 pb-1 text-truncate d-flex align-items-center gap-2 categoryName' : 'mb-0 fs-3 fw-bold ps-lg-2 ps-3 py-lg-0 pt-4 pb-1 text-truncate d-flex align-items-center gap-2 categoryName sticky-lg-top'"
               style="top: 21px; z-index: 1040;">
                <img v-if="category.icon_url" :src="category.path" height="22" loading="lazy" width="22" alt="">
                <span class="d-block lh-75">{{ categoryName(category) }}</span>
            </p>

            <p class="mb-lg-2 mb-0 fs-5 ps-lg-2 lh-1 category-child-soft-wrapper">
                <category-products
                    :categories="category.children" :childrenCategory="true"/>
            </p>

            <div
                v-for="product in category.products" :key="product.id"
                :id="'product-card-'+product.id"
                :class="setCardClasses(product)"
                itemprop="itemListElement"
                itemtype="http://schema.org/Product"
                @click="zoomCard(product)">
                <div class="row product-row m-0">
                    <div :id="'product-wrapper-image'+product.id"
                         :class="getProductImage(product) ? 'col-lg-3 col-4 p-0 product-image' : 'd-none'">
                        <div v-if="getProductImage(product)"
                             class="position-relative productImageContainer rounded overflow-hidden">
                            <!--                            <span class="badge text-bg-danger position-absolute bottom-0 start-0 fs-10 badge-new rounded lh-1"-->
                            <!--                                  v-if="dg(product, 'meta_data.new_product')" :title="trans('menu_new')">NEW</span>-->

                            <img :id="'product-image-'+product.id" :alt="getProductName(product)"
                                 :src="getProductImage(product)" class="w-100 rounded productImage"
                                 itemprop="image" loading="lazy"/>
                            <p :content="product.price_discount || product.price"
                               :title="product.price_discount || product.price + ' ' + menuCompanyCurrency"
                               class="m-2 position-absolute top-0 badge rounded fs-14 shadow d-none productPrice"
                               itemprop="price">
                                {{ product.price_discount || product.price }}
                                <span class="ms-1"
                                      content="GEL"
                                      itemprop="priceCurrency">{{ menuCompanyCurrency }}</span>
                            </p>
                            <div
                                class="mb-0 position-absolute bottom-0 text-white py-lg-3 py-2 px-lg-3 px-2 fs-5 fw-semibold w-100 d-none productTitle">
                                <p class="mb-0 lh-1">
                                <span @click="viewProduct($event, product, category)">{{
                                        getProductName(product)
                                    }}</span>
                                </p>
                            </div>
                            <div class="position-absolute opacity-0 lh-1 loop">
                                <i class="fa-solid fa-magnifying-glass-plus text-white fs-6"></i>
                            </div>

                            <div :id="'card-close'+product.id"
                                 :title="trans('menu_close')"
                                 class="position-absolute top-0 end-0 px-0 d-flex cardClose d-none m-2 rounded-circle"
                                 @click="zoomOutCard(product, $event)">
                                <span class="fs-14 text-white">&#10761;</span>
                                <!--                                <i class="fa-solid fa-xmark fs-14 text-white"></i>-->
                            </div>

                            <div
                                class="position-absolute bottom-0 end-0 d-flex cardShare me-2 d-none rounded-circle">
                                <div class="dropdown d-flex align-items-center">
                                    <button aria-expanded="false"
                                            class="btn p-0 dropdown-toggle share-btn d-flex align-items-center"
                                            data-bs-toggle="dropdown" type="button"
                                            @click="share($event)">
                                        <i class="fa-solid fa-share fs-12 text-white"></i>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li @click="stopPropagation($event)">
                                            <a :href="shareProduct(product, category, 1)"
                                               class="dropdown-item d-flex align-items-center gap-2">
                                                <img :src="Facebook" alt="Facebook" title="Facebook" width="20">
                                                <span>Facebook</span>
                                            </a>
                                        </li>

                                        <li class="mt-2">
                                            <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                                <img :src="Instagram" alt="Instagram" title="Instagram" width="20">
                                                <span>Instagram</span>
                                            </a>
                                        </li>

                                        <li class="mt-2">
                                            <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                                <img :src="Twiter" alt="Twiter" title="Twiter" width="20">
                                                <span>Twitter</span>
                                            </a>
                                        </li>

                                        <li class="mt-2" @click="stopPropagation($event)">
                                            <a :href="shareProduct(product, category, 4)"
                                               class="dropdown-item d-flex align-items-center gap-2"
                                               target="_blank">
                                                <img :src="Telegram" alt="Telegram" title="Telegram" width="20">
                                                <span>Telegram</span>
                                            </a>
                                        </li>

                                        <li class="mt-2" @click="stopPropagation($event)">
                                            <a :href="shareProduct(product, category, 5)"
                                               class="dropdown-item d-flex align-items-center gap-2"
                                               target="_blank">
                                                <img :src="Whatsapp" alt="Whatsapp" title="Whatsapp" width="20">
                                                <span>WhatsApp</span>
                                            </a>
                                        </li>

                                        <li class="mt-2" @click="stopPropagation($event)">
                                            <a :href="shareProduct(product, category, 6)"
                                               class="dropdown-item d-flex align-items-center gap-2"
                                               target="_blank">
                                                <img :src="Viber" alt="Viber" title="Viber" width="20">
                                                <span>Viber</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div :id="'product-wrapper-'+product.id"
                        :class="getProductImage(product) ? 'col-lg-9 col-8 pe-0 py-0 d-flex flex-column justify-content-between product-info' : 'col-12 d-flex flex-column justify-content-between card-min-height p-0'">

                        <p class="mb-0 fs-5 fs-sm-18 fw-bold product-name lh-1 cardName" itemprop="name">
                            {{ getProductName(product) }}
                        </p>

                        <p v-if="getProductComment(product)" :id="'product-description-'+product.id"
                           class="my-2 card-text fs-12 cardDescription"
                           itemprop="description" v-html="getProductComment(product)"></p>

                        <div class="d-flex flex-column gap-2 align-items-start product-card-badges">
                            <div v-for="modification in product.modifications" :key="modification.id">
                                <div :class="dg(modification, 'meta_data.popular_product')
                                        && dg(modification, 'meta_data.vegetarian_product')
                                        && dg(modification, 'meta_data.lenten_product') ?
                                            'd-flex align-items-center flex-wrap gap-2 mt-lg-0 mt-2'
                                            : 'd-flex align-items-center flex-wrap gap-2'">
                                    <div class="d-flex align-items-center" @click="share($event)">
                                        <button v-if="modification.counter >= 2"
                                                :title="trans('menu_subtract')"
                                                class="alert alert-warning rounded fs-18 mb-0 p-0 add-btn-plus"
                                                @click="basketAction(modification, false)">
                                                <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">
                                                    &minus;
                                                </span>
                                        </button>

                                        <button v-if="modification.counter === 1"
                                                :title="trans('menu_remove')"
                                                class="alert alert-danger rounded fs-18 mb-0 p-0 add-btn-plus"
                                                data-bs-target="#removeProduct" data-bs-toggle="modal"
                                                @click="removeProductFromBasketRequest(modification)">
                                            <i class="fa-solid fa-xmark text-danger"></i>
                                        </button>

                                        <span :class="modification.counter === 0 ? 'lh-1 fs-5 text-danger mx-1' : 'lh-1 fs-5 text-warning mx-2'">
                                            {{ modification.counter }}
                                        </span>

                                        <button
                                            :class="modification.counter < 1  ? 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus ms-n' : 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus'"
                                            :title="trans('menu_add')"
                                            @click="basketAction(modification, true)">
                                            <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">
                                                <i class="fa-solid fa-plus"></i>
                                            </span>
                                        </button>
                                    </div>

                                    <div :content="modification.price_discount || modification.price"
                                         class="product-price fs-14"
                                         itemprop="price">
                                        {{ modification.price_discount || modification.price }}
                                        <span class="ms-1" content="GEL" itemprop="priceCurrency">
                                            {{ menuCompanyCurrency }}
                                        </span>
                                    </div>

                                    <div :content="modification.name"
                                         class="badge rounded text-bg-light fs-10"
                                         itemprop="name">
                                        {{ modification.name }}
                                    </div>

                                    <div v-if="dg(modification, 'meta_data.sale_quantity') || dg(product, 'unit')"
                                         class="badge rounded text-bg-light fs-10 d-flex align-items-center gap-1">
                                        <div v-if="modification.bottle_type">
                                            <img v-if="modification.bottle_type === 2" :src="BottleBeer"
                                                 alt="" class="d-flex align-items-center" loading="lazy">

                                            <img v-if="modification.bottle_type === 1" :src="Cup" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="modification.bottle_type === 7" :src="Cup2" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="modification.bottle_type === 3" :src="Shot" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="modification.bottle_type === 4" :src="DraftBeer" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="modification.bottle_type === 6" :src="BottleWine" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="modification.bottle_type === 5" :src="WineGlass" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">
                                        </div>

                                        <span>
                                          {{ modification.sale_quantity }} {{ getProductUnitShortName(product) }}
                                        </span>
                                    </div>

                                    <span v-if="dg(modification, 'meta_data.popular_product')"
                                          :title="trans('menu_popular')"
                                          class="badge rounded text-bg-light fs-10">
                                        <i class="fa-solid fa-star"></i>
                                    </span>

                                    <span v-if="dg(modification, 'meta_data.vegetarian_product')"
                                          :title="trans('menu_vegetarian')"
                                          class="badge rounded text-bg-light vegetarian-badge fs-10">
                                        <i class="fa-solid fa-leaf me-1"></i>
                                    </span>

                                    <span v-if="dg(modification, 'meta_data.lenten_product')"
                                          class="badge rounded text-bg-light fs-10">
                                        {{ trans('menu_lenten') }}
                                    </span>

                                    <span
                                        v-if="dg(modification, 'meta_data.new_product') && !getProductImage(modification)"
                                        :title="trans('menu_new')"
                                        class="badge rounded text-bg-danger fs-10 badge-new-nopic">
                                        NEW
                                    </span>

                                    <span
                                        v-if="dg(modification, 'meta_data.new_product') && getProductImage(modification)"
                                        :title="trans('menu_new')"
                                        class="badge rounded text-bg-danger fs-10 badge-new-open-pic d-none">
                                        NEW
                                    </span>

                                    <span v-if="dg(modification, 'meta_data.halal')"
                                          class="badge rounded text-bg-light halal-badge fs-10">
                                        <img :src="HalalIcon" alt="" class="me-1" width="20">
                                        {{ trans('menu_halal') }}
                                    </span>
                                </div>
                            </div>

                            <div :class="dg(product, 'meta_data.popular_product') && dg(product, 'meta_data.vegetarian_product') && dg(product, 'meta_data.lenten_product') ? 'd-flex align-items-center flex-wrap gap-2 mt-lg-0 mt-2' : 'd-flex align-items-center flex-wrap gap-2'">
                                <div class="d-flex align-items-center d-none" @click="share($event)">

                                    <button v-if="product.counter >= 2"
                                            :title="trans('menu_subtract')"
                                            class="alert alert-warning rounded fs-18 mb-0 p-0 add-btn-plus"
                                            @click="basketAction(product, false)">
                                        <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">
                                            &minus;
                                        </span>
                                    </button>

                                    <button v-if="product.counter === 1"
                                            :title="trans('menu_remove')"
                                            class="alert alert-danger rounded fs-18 mb-0 p-0 add-btn-plus"
                                            data-bs-target="#removeProduct" data-bs-toggle="modal"
                                            @click="removeProductFromBasketRequest(product)">
                                        <i class="fa-solid fa-xmark text-danger"></i>
                                    </button>

                                    <span :class="product.counter === 0 ? 'lh-1 fs-5 text-danger mx-1' : 'lh-1 fs-5 text-warning mx-2'">
                                        {{ product.counter }}
                                    </span>

                                    <button :class="product.counter < 1  ? 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus ms-n'
                                            : 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus'"
                                            :title="trans('menu_add')"
                                            @click="basketAction(product, true)"
                                    >
                                        <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">
                                            <i class="fa-solid fa-plus"></i>
                                        </span>
                                    </button>
                                </div>

                                <div :content="product.price_discount || product.price" class="product-price fs-14"
                                     itemprop="price">
                                    {{ product.price_discount || product.price }}
                                    <span class="ms-1" content="GEL" itemprop="priceCurrency">
                                        {{ menuCompanyCurrency }}
                                    </span>
                                </div>

                                <span v-if="dg(product, 'meta_data.new_product')"
                                      :title="trans('menu_new')"
                                      class="badge rounded text-bg-danger fs-10 badge-new-open-pic">
                                    NEW
                                </span>

                                <div v-if="dg(product, 'meta_data.sale_quantity') || dg(product, 'unit')"
                                     class="badge rounded text-bg-light fs-10 d-flex align-items-center gap-1">
                                    <div v-if="product.bottle_type">
                                        <img v-if="product.bottle_type === 2" :src="BottleBeer" alt=""
                                             class="d-flex align-items-center" loading="lazy" width="6">

                                        <img v-if="product.bottle_type === 1" :src="Cup" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">

                                        <img v-if="product.bottle_type === 7" :src="Cup2" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">

                                        <img v-if="product.bottle_type === 3" :src="Shot" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">

                                        <img v-if="product.bottle_type === 4" :src="DraftBeer" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">

                                        <img v-if="product.bottle_type === 6" :src="BottleWine" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">

                                        <img v-if="product.bottle_type === 5" :src="WineGlass" alt=""
                                             class="d-flex align-items-center" height="16" loading="lazy">
                                    </div>
                                        <span>
                                            {{ product.sale_quantity }} {{ getProductUnitShortName(product) }}
                                        </span>
                                </div>

                                <span v-if="dg(product, 'meta_data.popular_product')"
                                      :title="trans('menu_popular')" class="badge rounded text-bg-light fs-10">
                                     <i class="fa-solid fa-star"></i>

                                     <span v-if="zoomedProductCard" class="ms-1">{{ trans('menu_popular') }}</span>
                                </span>

                                <span v-if="dg(product, 'meta_data.recommend')"
                                      :title="trans('recommended')" class="badge rounded text-bg-light fs-10">
                                     {{ trans('recommended') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.vegetarian_product')"
                                      :title="trans('menu_vegetarian')"
                                      class="badge rounded text-bg-light vegetarian-badge fs-10">
                                    <i class="fa-solid fa-leaf"></i>

                                   <span v-if="zoomedProductCard">{{ trans('menu_vegetarian') }}</span>
                                </span>

                                <span v-if="dg(product, 'meta_data.vegan')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('vegan') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.spicy')"
                                      :title="trans('spicy')"
                                      class="badge rounded text-bg-light vegetarian-badge fs-10">
                                    <i class="fa-solid fa-pepper-hot"></i>
                                </span>

                                <span v-if="dg(product, 'meta_data.with_meat')"
                                      :title="trans('with_meat')"
                                      class="badge rounded text-bg-light vegetarian-badge fs-10">
                                    <img :src="SteakIcon" alt="steack icon" width="16">

                                    <span v-if="zoomedProductCard">{{ trans('with_meat') }}</span>
                                </span>

                                <span v-if="dg(product, 'meta_data.lenten_product')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('menu_lenten') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.dietary')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('dietary') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.alcoholic')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('alcoholic') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.nonAlcoholic')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('non_alcoholic') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.hangover')"
                                      class="badge rounded text-bg-light fs-10">
                                    {{ trans('hangover') }}
                                </span>

                                <!--                                <span class="badge rounded text-bg-danger fs-10 badge-new-nopic"-->
                                <!--                                      v-if="dg(product, 'meta_data.new_product') && !getProductImage(product)"-->
                                <!--                                      :title="trans('menu_new')">-->
                                <!--                                    NEW-->
                                <!--                                </span>-->

                                <!--                                <span class="badge rounded text-bg-danger fs-10 badge-new-open-pic d-none"-->
                                <!--                                      v-if="dg(product, 'meta_data.new_product') && getProductImage(product)"-->
                                <!--                                      :title="trans('menu_new')">-->
                                <!--                                    NEW-->
                                <!--                                </span>-->

                                <span v-if="dg(product, 'meta_data.halal')"
                                      class="badge rounded text-bg-light halal-badge fs-10">
                                    <img :src="HalalIcon" alt="" class="me-1" width="20">
                                    {{ trans('menu_halal') }}
                                </span>

                                <span v-if="dg(product, 'meta_data.bio')"
                                      class="badge rounded text-bg-light halal-badge fs-10">
                                   {{ trans('menu_bio') }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <link href="http://schema.org/InStock" itemprop="availability">
            </div>
        </div>
    </div>
</template>

<script>
import {t} from '@/lang/core/helpers'
import _ from "lodash"
import {mapGetters} from "vuex"
import CategoryProducts from "@/components/websites/allmenu/parts/CategoryProducts"
import BottleBeer from "@/assets/websites/allmenu/img/bottle_beer.svg"
import BottleWine from "@/assets/websites/allmenu/img/bottle_wine.svg"
import Cup from "@/assets/websites/allmenu/img/cup.svg"
import Cup2 from "@/assets/websites/allmenu/img/cup_2.svg"
import DraftBeer from "@/assets/websites/allmenu/img/draft_beer.svg"
import Shot from "@/assets/websites/allmenu/img/shot.svg"
import WineGlass from "@/assets/websites/allmenu/img/wineglass.svg"
import {data_get} from "@/core/helpers"
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Twiter from "@/assets/websites/allmenu/img/twiter-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import HalalIcon from "@/assets/websites/allmenu/img/halal-icon.png"
import SteakIcon from "@/assets/websites/allmenu/img/food-steak.svg"

export default {
    name: "CategoryProducts",
    components: {CategoryProducts},

    data() {
        return {
            SteakIcon,
            HalalIcon,
            BottleBeer,
            BottleWine,
            Cup2,
            Cup,
            Shot,
            DraftBeer,
            WineGlass,
            Facebook,
            Instagram,
            Twiter,
            Telegram,
            Whatsapp,
            Viber,
            Messenger,
            basketCondition: true,
            currentDate: new Date().toISOString().slice(0, 10),
            zoomedProductCard: false
        }
    },

    props: {
        categories: {
            required: true
        },
        childrenCategory: {
            type: Boolean
        }
    },

    computed: {
        ...mapGetters([
            'locale',
            'menuCompanyCurrency',
            'deleteFromBasket',
            'isMobileDevice'
        ]),

        products(category) {
            return category.products;
        }
    },

    created() {
        let cardCloseButtons = document.getElementsByClassName('cardClose')

        this.escapeCardClose(cardCloseButtons)
    },

    methods: {
        trans(key) {
            return t(key)
        },

        getProductUnitShortName(product) {
            if (!product.unit) {
                return null
            }

            let translation = _.filter(product.unit.translations, {locale: this.locale})
            return translation.length ? translation[0].short_name : null
        },

        getImageBySize(images, size) {
            let filterImage = _.filter(images, {image_type: size})
            return filterImage.length ? filterImage[0].url : null
        },

        getProductImage(product) {
            if (!product.images.length) {
                return null
            }

            return product.images.length > 0 && product.zoomed ? this.getImageBySize(product.images, 'MEDIUM') : this.getImageBySize(product.images, 'SMALL')
        },

        getProductName(product) {
            let locale = this.locale
            let translation = _.filter(product.translations, {locale: locale})

            if (locale === 'ru' && translation.length && !translation[0].name) {
                translation = _.filter(product.translations, {locale: 'en'})

                if (translation.length && !translation[0].name) {
                    translation = _.filter(product.translations, {locale: 'ka'})
                }
            }

            if (locale === 'en' && translation.length && !translation[0].name) {
                translation = _.filter(product.translations, {locale: 'ka'})

                if (translation.length && !translation[0].name) {
                    translation = _.filter(product.translations, {locale: 'ru'})
                }
            }

            return translation.length ? translation[0].name : product.name
        },

        getProductComment(product) {
            let translation = _.filter(product.translations, {locale: this.locale})

            return translation.length ? translation[0].comment : product.comment
        },

        categoryName(category) {
            let translation = _.filter(category.translations, {locale: this.locale})

            return translation.length ? translation[0].name : category.name
        },

        zoomOutCard(product, e) {
            e.stopPropagation()

            let productId = product.id
            let productCard = document.getElementById('product-card-' + productId)

            productCard.removeAttribute('opened')

            productCard.classList.remove('product-card')

            this.zoomedProductCard = false

        },

        zoomCard(product) {
            let productId = product.id

            product.zoomed = product.zoomed ? false : true

            this.getProductImage(product)


            this.$forceUpdate()

            let productImages = document.getElementsByClassName('card')

            for (let i = 0; productImages.length > i; i++) {
                if (productImages[i].classList.contains('product-card')) {
                    productImages[i].classList.remove('product-card')
                }
            }

            let productCard = document.getElementById('product-card-' + productId)

            if (productCard.hasAttribute('opened')) {
                productCard.removeAttribute('opened')

                productCard.classList.remove('product-card')

                this.zoomedProductCard = false

            } else {
                productCard.setAttribute('opened', true)
                productCard.classList.add('product-card')

                this.zoomedProductCard = true
            }

            let scrollSize = productCard.offsetTop - ((document.documentElement.clientHeight / 2) - productCard.clientHeight)

            window.scrollTo({top: scrollSize, behavior: 'smooth'});

            // productCard.classList.remove("product-card")

            // let addBtn = document.getElementById('add-btn-' + productId)
            // addBtn.classList.toggle("show-btn");

        },
        viewProduct(event, product, category) {
            event.stopPropagation()

            let productName = this.getProductName(product)
            productName = productName ? productName.replaceAll(' ', '-') : ''

            let categoryName = this.categoryName(category)
            categoryName = categoryName ? categoryName.replaceAll(' ', '-') : ''

            this.$router.push({
                name: 'product',
                params: {
                    locale: window.localStorage.getItem('locale') || this.menuCompanyDefaultLocale || 'ka',
                    companyKey: this.$route.params.companyKey,
                    categoryId: category.id + '-' + categoryName,
                    productId: product.id + '-' + productName
                }
            })
        },

        dg(key, value) {
            return data_get(key, value)
        },

        share(event) {
            event.stopPropagation()
        },

        escapeCardClose(cardCloseButtons) {
            document.onkeydown = function (evt) {
                evt = evt || window.event;
                let isEscape = false;

                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }

                if (isEscape) {
                    for (let i = 0; cardCloseButtons.length > i; i++) {
                        cardCloseButtons[i].click()
                    }
                }
            }
        },

        basketAction(product, condition) {
            if (condition) {
                product.counter++
            } else {
                product.counter--
            }

            this.$store.commit('setProductInBasket', product)

            if (this.$store.getters.allmenu.basket.length === 0) {
                this.basketCondition = true
            }

            if (this.$store.getters.allmenu.basket.length === 1 && this.basketCondition) {
                this.basketCondition = false

                setTimeout(function () {
                    document.getElementById('basketButton').click()
                }, 100)

                setTimeout(function () {
                    document.getElementById('basketButton').click()
                }, 1000)
            }
        },

        removeProductFromBasketRequest(product) {
            this.$store.commit('setDeleteFromBasket', product.id)
        },

        async removeProductFromBasket() {
            let productId = this.deleteFromBasket

            this.$store.commit('removeProductInBasket', productId)

            this.$store.commit('resetProductCounter', productId)
        },

        stopPropagation(e) {
            e.stopPropagation()
        },

        getSocialNetwork(id) {
            let socialNetwork = {
                1: 'FACEBOOK',
                2: 'INSTAGRAM',
                3: 'TWITTER',
                4: 'TELEGRAM',
                5: 'WHATSAPP',
                6: 'VIBER'
            }

            return socialNetwork[id]
        },

        setCardClasses(product) {
            let result = ''

            result = this.getProductImage(product) ? 'card p-md-3 p-2 border-bottom-0 rounded-0 cursor-pointer product-card-hover card-padding-0' : 'card p-md-3 p-2 border-bottom-0 rounded-0 cursor-pointer product-card-hover'

            result = product.counter >= 1 ? result + ' border-active' : result

            return result
        },

        shareProduct(product, category, id) {
            if (!product.id) {
                return null
            }

            let locale = this.$route.params.locale
            let companyKey = this.$route.params.companyKey

            let categoryName = this.categoryName(category)
            categoryName = categoryName ? categoryName.replaceAll(' ', '-') : ''

            let productName = this.getProductName(product)
            productName = productName ? productName.replaceAll(' ', '-') : ''

            let selfUrl = 'https://' + window.location.hostname + '/' + locale + '/' + companyKey + '/' + category.id + '-' + categoryName + '/' + product.id + '-' + productName

            let url = this.getSocialNetwork(id) === 'WHATSAPP' ?
                'https://web.whatsapp.com/send?text=' + selfUrl :
                (this.getSocialNetwork(id) === 'VIBER' ?
                    'viber://forward?text=' + selfUrl :
                    (this.getSocialNetwork(id) === 'TELEGRAM' ?
                        'https://t.me/share/url?url=' + selfUrl
                        : (
                            this.getSocialNetwork(id) === 'FACEBOOK' ?
                                'https://www.facebook.com/sharer/sharer.php?u=' + selfUrl
                                : '#'
                        )))

            if (this.isMobileDevice) {
                url = this.getSocialNetwork(id) === 'WHATSAPP' ?
                    'whatsapp://send?text=' + selfUrl :
                    (this.getSocialNetwork(id) === 'VIBER' ?
                        'viber://forward?text=' + selfUrl :
                        (this.getSocialNetwork(id) === 'TELEGRAM' ?
                            'https://t.me/share/url?url=' + selfUrl
                            : (
                                this.getSocialNetwork(id) === 'FACEBOOK' ?
                                    'https://www.facebook.com/sharer/sharer.php?u=' + selfUrl
                                    : '#'
                            )))
            }

            return url
        }
    }
}
</script>

<style lang="scss">
.card {
    display: flex;
    scroll-snap-align: start;
}

.add-btn-plus {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trush-btn, .trush-btn:hover, .trush-btn:focus {
    border: unset;
}

.trush-btn {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-new {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: rgba(221, 25, 25, 0.6) !important;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge-new-nopic, .badge-new-open-pic {
    background-color: #dd1919 !important;
}

//.cardDescription, .cardName {
//    display: -webkit-box;
//    -webkit-line-clamp: 2;
//    -webkit-box-orient: vertical;
//    overflow: hidden;
//}
//
//.cardDescription {
//    line-height: 1.3 !important;
//}

.vegetarian-badge {
    background-color: #C1E4CB !important;
}

.halal-badge {
    background-color: #ebf6f0 !important;
    color: #44985c !important;
}

.share-btn::after {
    display: none;
}

.share-btn:hover, .share-btn:focus-within {
    border: unset !important;
}

#removeProduct .modal-dialog {
    max-width: max-content !important;
    margin: auto;
}

@media screen and (min-width: 992px) {
    .card-min-height {
        min-height: 95px;
    }
}

@media screen and (max-width: 992px) {
    .card-min-height {
        min-height: 75px;
    }

    .categoryName {
        padding-top: 30px !important;
    }
}
</style>
