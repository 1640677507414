<template>
    <div id="app" class="h-100">
        <AppLayout>
            <router-view/>
        </AppLayout>
    </div>
</template>

<script>
import AppLayout from "@/components/layouts/AppLayout"
import allmenuTranslations from "@/lang/core/allmenu-translations"
import blueberrynightshotelTranslations from "@/lang/core/blueberrynightshotel-translations"
import getPartsTranslations from "@/lang/core/get-parts-translations";
import {localStorageGet} from "@/core/localStorage"
import './assets/js/menuScroll'

export default {
    name: 'App',

    computed: {
        routeMeta() {
            if (!this.route) {
                return null
            }

            return this.$route.meta
        }  
    },

    watch: {
        '$route'(to) {
            if (to.meta && to.meta.title && !to.meta.title.includes('##')) {
                document.title = to.meta.title || 'Other Title'
            }

            document.querySelector("link[rel*='icon']").href = to.meta.favicon || ''

            if (to.meta && to.meta.translationKey) {
                this.$store.commit('setTranslations', this.translationsData[to.meta.translationKey])
            }
        }
    },

    data() {
        return {
            companyKey: null,
            translationsData: {
                allmenu: allmenuTranslations,
                blueberrynightshotel: blueberrynightshotelTranslations,
                'get-parts': getPartsTranslations,
            }
        }
    },

    created() {
        if (!this.routeMeta || !Object.keys(this.routeMeta).length ||!this.$route.meta.title.includes('##COMPANY_NAME##')) {
            document.title = this.$route.meta.title || 'Other Title'
        }

        document.querySelector("link[rel*='icon']").href = this.$route.meta.favicon || ''

        if (this.$route.meta && this.$route.meta.translationKey) {
            this.$store.commit('setTranslations', this.translationsData[this.$route.meta.translationKey])
        }

        this.companyKey = localStorageGet('companyKey');
    },

    components: {
        AppLayout
    }
}
</script>

<style>
@import "bootstrap";
</style>
