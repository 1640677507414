<template>
    <div id="carouselViewProduct" class="carousel slide carousel-dark carousel-fade product__image_slide row m-0 flex-lg-row flex-column viewProductPic scroll-styles">
        <div class="col-lg-2 px-0 border order-lg-1 order-2">
            <div class="d-flex flex-lg-column flex-row carousel-indicators mb-0 slide-small-pics position-static">
                <button aria-current="true" aria-label="Slide 1"
                        class="border-0 p-0 border-bottom slide__btn active" data-bs-slide-to="0"
                        data-bs-target="#carouselViewProduct"
                        type="button">
                    <img
                        alt="product name"
                        src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                        title="product name" width="100">
                </button>

                <button aria-label="Slide 2" class="border-0 p-0 border-bottom slide__btn"
                        data-bs-slide-to="1" data-bs-target="#carouselViewProduct"
                        type="button">
                    <img
                        alt="product name"
                        src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                        title="product name" width="100">
                </button>

                <button aria-label="Slide 3" class="border-0 p-0 border-bottom slide__btn"
                        data-bs-slide-to="2" data-bs-target="#carouselViewProduct"
                        type="button">
                    <img
                        alt="product name"
                        src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                        title="product name" width="100">
                </button>

                <button aria-label="Slide 4" class="border-0 p-0 border-bottom slide__btn"
                        data-bs-slide-to="3" data-bs-target="#carouselViewProduct"
                        type="button">
                    <img
                        alt="product name"
                        src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                        title="product name" width="100">
                </button>
            </div>
        </div>

        <div class="col-lg-10 flex-grow-1 order-lg-2 order-1 position-relative"
             data-bs-ride="true">
            <div class="carousel-inner" style="overflow: visible !important;">
                <div class="carousel-item active">
                    <div :class="$route.name === 'view-product' ? 'd-block d-lg-none' : ''">
                        <img
                            alt="product name"
                            class="w-100 img-fluid"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                    </div>

                    <div>
                        <image-magnifier
                            class="d-none d-lg-block w-100 rounded"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            zoom-height="500"
                            zoom-src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            zoom-width="500"/>
                    </div>
                </div>

                <div class="carousel-item">
                    <div :class="$route.name === 'view-product' ? 'd-block d-lg-none' : ''">
                        <img
                            alt="product name"
                            class="w-100 img-fluid"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">
                    </div>

                    <div>
                        <image-magnifier
                            class="d-none d-lg-block w-100 rounded"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            zoom-height="500"
                            zoom-src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            zoom-width="500"/>
                    </div>
                </div>

                <div class="carousel-item">
                    <div :class="$route.name === 'view-product' ? 'd-block d-lg-none' : ''">
                        <img
                            alt="product name"
                            class="w-100 img-fluid"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                    </div>

                    <div>
                        <image-magnifier
                            class="d-none d-lg-block w-100 rounded"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            zoom-height="500"
                            zoom-src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            zoom-width="500"/>
                    </div>
                </div>

                <div class="carousel-item">
                    <div :class="$route.name === 'view-product' ? 'd-block d-lg-none' : ''">
                        <img
                            alt="product name"
                            class="w-100 img-fluid"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">
                    </div>

                    <div>
                        <image-magnifier
                            class="d-none d-lg-block w-100 rounded"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            zoom-height="500"
                            zoom-src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            zoom-width="500"/>
                    </div>
                </div>
            </div>

            <button class="carousel-control-prev" data-bs-slide="prev"
                    data-bs-target="#carouselViewProduct" type="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" data-bs-slide="next"
                    data-bs-target="#carouselViewProduct" type="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </button>

            <div :class="$route.name === 'view-product' ? 'position-absolute top-0 end-0 m-1' : 'd-none'"
                 style="z-index: 1;">
                <button class="btn border pic-action" data-bs-target="#zoomImage"
                        data-bs-toggle="modal">
                    <img :src="ZoomPic" alt="zoom">
                </button>
            </div>

            <div :class="$route.name === 'view-product' ? 'position-absolute bottom-0 start-0' : 'd-none'"
                 style="z-index: 1;">
                <button aria-controls="shopSimilarCanvas"
                        class="btn btn-sm border-0 text-white shadow rounded shop-similar-btn"
                        data-bs-target="#shopSimilarCanvas" data-bs-toggle="offcanvas"
                        type="button">
                    <i class="fa-solid fa-camera"></i>
                    Shop similar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPic1 from "@/assets/websites/moda/img/view-product.png"
import ProductPic2 from "@/assets/websites/moda/img/view-product2.png"
import ProductPic3 from "@/assets/websites/moda/img/view-product3.png"
import ZoomPic from "@/assets/websites/moda/img/zoom.svg"
import {ImageMagnifier} from "vue-image-magnifier"

export default {
    name: "ViewProductImagesSlide",

    components: {
        ImageMagnifier
    },

    data() {
        return {
            ProductPic1,
            ProductPic2,
            ProductPic3,
            ZoomPic
        }
    }
}
</script>

<style>
.pic-action {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-similar-btn {
    background-color: rgba(34, 34, 34, 0.5);
    transition: .3s;
}

.shop-similar-btn:hover {
    background-color: rgba(34, 34, 34, 0.9);
}
.product__image_slide .carousel-indicators.slide-small-pics .slide__btn {
    box-sizing: unset !important;
    flex: unset !important;
    width: 100% !important;
    height: 100% !important;
    text-indent: unset !important;
    background-color: unset !important;
    margin-inline: unset !important;
}
.product__image_slide .carousel-indicators.slide-small-pics .slide__btn:last-child {
    border-bottom: unset !important;
}
.carousel-indicators {
    height: unset !important;
}
@media screen and (min-width: 1401px) {
    .product__image_slide .carousel-item img {
        height: 413px;
    }

    .product__image_slide .carousel-indicators.slide-small-pics {
        height: 413px !important;
    }

    .product__image_slide .image-magnifier .image-magnifier__img {
        width: 532px !important;
    }
}

@media screen and (max-width: 1400px) {
    .product__image_slide .carousel-item img,
    .product__image_slide .carousel-indicators.slide-small-pics {
        height: 355.5px !important;
    }

    .product__image_slide .image-magnifier .image-magnifier__img {
        width: 457px !important;
    }
}

@media screen and (max-width: 1200px) {
    .product__image_slide .carousel-item img,
    .product__image_slide .carousel-indicators.slide-small-pics {
        height: 297px !important;
    }

    .product__image_slide .image-magnifier .image-magnifier__img {
        width: 382px !important;
    }
}

@media screen and (min-width: 992px) {
    .slide-small-pics {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .product__image_slide {
        z-index: 1002;
    }

    .product__image_slide .image-magnifier__mask {
        background-color: rgb(33, 37, 41) !important;
    }

    .product__image_slide .carousel-inner {
        overflow: visible !important;
    }

    .product__image_slide .carousel-control-prev,
    .product__image_slide .carousel-control-next {
        height: max-content;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 991px) {
    .viewProductPic {
        position: relative;
        width: calc(100% + 24px) !important;
        left: -12px;
    }

    .product__image_slide .carousel-indicators.slide-small-pics {
        overflow-x: auto;
        border-top: 1px solid #dee2e6 !important;
        height: auto !important;
    }

    .product__image_slide .carousel-item img {
        height: auto !important;
    }

    .product__image_slide .carousel-indicators.slide-small-pics button {
        border-bottom: unset !important;
        border-right: 1px solid #dee2e6 !important;
    }
}
</style>
