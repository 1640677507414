<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            {{ trans('auto_marks') }}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-0">
                <h1 class="fs-2 fw-bold mb-0 text-center">
                    {{ trans('auto_marks') }}
                </h1>
            </div>
        </div>

        <CategoriesGP/>
    </div>
</template>

<script>
import CategoriesGP from "@/components/websites/get-parts/parts/Categories.vue";
import {t} from "@/lang/core/helpers";

export default {
    name: 'AutoPartsGP',

    components: {CategoriesGP},

    methods: {
        trans(key) {
            return t(key)
        }
    },

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>