<template>
    <div>
        <div class="d-flex flex-column gap-3 mt-3 d-none">
            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">
                Continue With Google

                <img :src="GoogleLogo" alt="Google" class="position-absolute start-0 top-50 translate-middle-y ms-3"
                     width="20">
            </button>

            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">
                Continue With Apple

                <img :src="AppleLogo" alt="Google" class="position-absolute start-0 top-50 translate-middle-y ms-3"
                     width="20">
            </button>

            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">
                Continue With Facebook

                <img :src="FacebookLogo" alt="Google" class="position-absolute start-0 top-50 translate-middle-y ms-3"
                     width="20">
            </button>
        </div>

        <div class="d-flex gap-3">
            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">


                <img :src="GoogleLogo" alt="Google" width="20">
            </button>

            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">

                <img :src="AppleLogo" alt="Google" width="20">
            </button>

            <button class="btn border-dark fs-15 fw-bold position-relative" type="submit">


                <img :src="FacebookLogo" alt="Google" width="20">
            </button>
        </div>


    </div>

</template>

<script>
import GoogleLogo from "@/assets/websites/moda/img/google-logo.png"
import AppleLogo from "@/assets/websites/moda/img/apple-logo.png"
import FacebookLogo from "@/assets/websites/moda/img/fb-logo.png"

export default {
    name: "ModaContinueWith",

    data() {
        return {
            FacebookLogo,
            GoogleLogo,
            AppleLogo,
        }
    }
}
</script>

<style scoped>

</style>