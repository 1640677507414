<template>
    <div class="row row-cols-md-3 row-cols-1 mt-3 m-0">
        <div v-if="requisites.PHONE_1" class="col px-2">
            <p class="d-flex flex-column align-items-center gap-3 mb-0">
                <i class="fa-solid fa-phone fs-2"></i>
                <span class="d-block text-center fs-4 lh-1">{{ trans('phone') }}</span>
                <a :href="`tel:+${requisites.PHONE_1}`"
                   class="d-block fs-14 text-dark link">
                    +{{ formatPhone(requisites.PHONE_1) }}
                </a>
            </p>
        </div>

        <div class="col px-2 mt-md-0 mt-3">
            <p class="d-flex flex-column align-items-center gap-3 mb-0">
                <i class="fa-solid fa-clock fs-2"></i>
                <span class="d-block text-center fs-4 lh-1">{{ trans('working_schedule') }}</span>
                <span class="d-block fs-14">
                    {{ trans('monday') }} - {{ trans('sunday') }} 09:00 - 20:00
                </span>
            </p>
        </div>

        <div v-if="requisites.LOC_GOOGLE_MAP_LINK" class="col px-2 mt-md-0 mt-3">
            <p class="d-flex flex-column align-items-center gap-3 mb-0">
                <i class="fa-solid fa-location-dot fs-2"></i>
                <span class="d-block text-center fs-4 lh-1">{{ trans('address') }}</span>
                <a :href="requisites.LOC_GOOGLE_MAP_LINK"
                   class="d-block fs-14 text-dark link"
                   target="_blank">
                    {{ requisites.LOC_CITY && locale ? requisites.LOC_CITY[locale] : '' }},
                    {{ requisites.LOC_ADDRESS && locale ? requisites.LOC_ADDRESS[locale] : '' }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {t} from "@/lang/core/helpers";

export default {
    name: 'ContactInfoBlocks',

    methods: {
        trans(key) {
            return t(key)
        },

        formatPhone(phone) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
        }
    },

    computed: {
        ...mapGetters(['locale', 'requisites']),
    },

}
</script>

<style scoped>

</style>