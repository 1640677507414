<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home-locale', params: {locale: currentLocale}}">{{ trans('home') }}</router-link>
                        </li>

                        <li class="breadcrumb-item">
                            <router-link :to="{name:'parts', params: {locale: currentLocale}}">{{ trans('auto_marks') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">{{ currentMark }}</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-0">
                <div v-if="!carPartsMarkModelsLoaded">
                    <LoaderMarkAndParts/>
                </div>

                <div v-else class="row row-cols-lg-5 row-cols-md-3 row-cols-2 m-0">
                    <div v-for="model in models" :key="model.id" class="col px-2 mb-3 text-dark cursor-pointer model"
                         @click="gotoModelParts(model)">
                        <div class="shadow-sm border bg-white rounded p-3">
                            <div>
                                <img :src="ModelPic" alt="" class="w-100 img-fluid model__pic">
                            </div>

                            <p class="text-center mt-lg-5 mt-3 mb-0 model__name">
                                {{ currentMark }} {{ model.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModelPic from "@/assets/websites/get-parts/img/car-pic.png"
import ApiRequest from "@/http/ApiRequest";
import {mapGetters} from "vuex";
import {t} from "@/lang/core/helpers";
import LoaderMarkAndParts from "@/components/websites/get-parts/parts/loaders/LoaderMarkAndParts.vue";

export default {
    name: 'ModelsGP',
    components: {LoaderMarkAndParts},

    computed: {
        ...mapGetters([
            'carPartsPopularMarks',
            'carPartsMarkModelsLoaded',
            'menuCompanyDefaultLocale'
        ]),

        currentMark() {
            if (!this.$route.params || !this.$route.params.mark) return null

            return this.$route.params.mark
        },

        currentLocale() {
            return window.localStorage.getItem('locale') 
                || this.menuCompanyDefaultLocale 
                || 'ka'
        }
    },

    data() {
        return {
            ModelPic,
            models: []
        }
    },

    watch: {
        $route: {
            async handler(route) {
                if (route.params && route.params.mark) {
                    this.getAvailableMarkModels(route.params.mark)
                }
            },

            immediate: true
        },
    },

    beforeRouteLeave(to, from, next) {
        this.models = []
        this.$store.commit('setCarPartsMarkModelsLoaded', false)
        next()
    },

    methods: {
        trans(key) {
            return t(key)
        },

        getAvailableMarkModels(mark) {
            new ApiRequest('car-parts/models-by-mark', {
                key: 'get-parts',
                markCode: mark
            }, response => {
                if (!response.data) {
                    alert(response.message || 'Not found')
                    return
                }

                this.models = response.data
                this.$store.commit('setCarPartsMarkModelsLoaded', true)
            })
        },

        gotoModelParts(model) {
            this.$router.push({
                name: 'products-by-model',
                params: {
                    locale: this.$route.params.locale,
                    mark: this.currentMark,
                    model: model.id,
                }
            })
        }
    }
}
</script>

<style scoped>
.model:hover .model__name {
    text-decoration: underline;
}

@media screen and (min-width: 1400px) {
    .model__pic {
        height: 88px;
    }
}

@media screen and (max-width: 1399px) {
    .model__pic {
        height: 74px;
    }
}

@media screen and (max-width: 1200px) {
    .model__pic {
        height: 58px;
    }
}

@media screen and (max-width: 991px) {
    .model__pic {
        height: 78px;
    }
}


@media screen and (max-width: 760px) {
    .model__pic {
        height: 57px;
    }
}
</style>