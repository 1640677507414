<template>
    <div id="zoomImg" aria-hidden="true" class="modal fade" style="z-index: 1310;" tabindex="-1">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-lg-down modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header border-0 p-3">
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body p-lg-3 pt-lg-0 p-0">
                    <div class="row m-0">
                        <div class="col-lg-8 px-0">
                            <img :src="image" alt="" class="w-100 zoom_popup__img rounded rounded-sm-0">
                        </div>

                        <div class="col-lg-4 pe-lg-0 ps-lg-5 px-2 mt-lg-4 mt-3">
                            <p class="fs-15 mb-0">
                                <span class="fw-600">{{ getTranslatedPropertyByLocale(carPart, 'name', locale) }} - </span>
                                <span>{{ getSupportedCars(carPart) }}</span>
                            </p>

                            <p class="fs-14 text-secondary fw-light mb-0">
                                {{ trans('articule_') }}: {{ carPart.id }}
                            </p>

                            <p v-if="carPart.quantity >= 1" class="fs-14 text-success fw-light mb-0">
                                {{ trans('in_stock') }}: {{ carPart.quantity }}
                            </p>

                            <p v-if="getTranslatedPropertyByLocale(carPart, 'comment', locale) && getTranslatedPropertyByLocale(carPart, 'comment', locale).length"
                               class="fs-14 fw-light mb-0 mt-3 border-top pt-3">
                                {{ getTranslatedPropertyByLocale(carPart, 'comment', locale) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import {getTranslatedPropertyByLocale, t} from "@/lang/core/helpers";
import {data_get} from "@/core/helpers";
import {mapGetters} from "vuex";

export default {
    name: 'ZoomImagePopup',

    methods: {
        data_get,

        getTranslatedPropertyByLocale,

        trans(key) {
            return t(key)
        },

        getSupportedCars(part) {
            if (!part.supported_cars || !part.supported_cars.length) {
                return ''
            }

            let supportedCars = ''

            part.supported_cars.forEach((car, index) => {
                supportedCars += `${car.short_supported_car}`

                if (index !== part.supported_cars.length - 1) {
                    supportedCars += ', '
                }
            })

            return supportedCars;
        }
    },

    props: {
        carPart: {
            required: true,
        }
    },

    computed: {
        ...mapGetters([
            'locale'
        ]),

        images() {
            return this.carPart.images || []
        },

        largeImages() {
            return _.filter(this.images, {image_type: 'LARGE'}) || []
        },

        image() {
            if (!this.largeImages.length) {
                return null
            }

            return this.largeImages[0].url
        }
    }
}
</script>

<style lang="scss" scoped>
#zoomImg.modal.fade .modal-dialog {
    transition: unset;
    transform: unset;
}

@media screen and (min-width: 992px) {
    #zoomImg .modal-dialog {
        max-width: unset;
        width: 85%;
    }

    #zoomImg .modal-body {
        margin-top: -32px;
    }

    #zoomImg .modal-header {
        position: relative;
        z-index: 1;
    }
}


/*.zoom_popup__img {*/
/*    object-fit: cover;*/
/*}*/

/*@media screen and (min-width: 1400px) {*/
/*    .zoom_popup__img {*/
/*        height: 740px !important;*/
/*    }*/
/*}*/

/*@media screen and  (max-width: 1399px) {*/
/*    .zoom_popup__img {*/
/*        height: 645px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 760px) {*/
/*    .zoom_popup__img {*/
/*        height: 357px;*/
/*    }*/
//}
</style>