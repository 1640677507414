<template>
    <div>
        <div class="border-bottom pb-3 mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <p class="fs-15 fw-bold mb-0 lh-1">
                    Alex
                </p>

                <div class="d-flex align-items-center gap-2">
                    <div class="d-flex gap-1 fs-14">
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                    </div>

                    <span class="lh-1">
                        01.01.2022
                    </span>
                </div>
            </div>

            <p class="fs-15 mb-0 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa dolore error eveniet,
                excepturi hic illo inventore numquam odit omnis quos ratione repellat sed tempora temporibus
                voluptate voluptatibus. Aut expedita neque nostrum quae quidem quo quod ut voluptas voluptatem
                voluptates!
            </p>

            <div class="d-flex align-items-center gap-2 flex-wrap cursor-pointer mt-2"
                 data-bs-target="#reviewsPicsModal"
                 data-bs-toggle="modal">
                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                         src="https://image.geeko.ltd/comment/medium/116Y4H3a2a7A5F8c5e2i2c0l6S"
                         alt=""
                         width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/1y6h4A3l2D7B5Z8j5x3Q3e6C81"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/1F6B4R3T2N7N588y5A5X7U8H9n"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="border-bottom pb-3 mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <p class="fs-15 fw-bold mb-0 lh-1">
                    Nick
                </p>

                <div class="d-flex align-items-center gap-2">
                    <div class="d-flex gap-1 fs-14">
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                    </div>

                    <span class="lh-1">
                        02.12.2021
                    </span>
                </div>
            </div>

            <p class="fs-15 mb-0 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae dolorum ducimus eius fuga
                magnam, magni minus sed tempore vel.
            </p>
        </div>

        <div class="border-bottom pb-3 mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <p class="fs-15 fw-bold mb-0 lh-1">
                    Natalie
                </p>

                <div class="d-flex align-items-center gap-2">
                    <div class="d-flex gap-1 fs-14">
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                    </div>

                    <span class="lh-1">
                        01.01.2022
                    </span>
                </div>
            </div>

            <p class="fs-15 mb-0 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium culpa dolorem doloremque
                eveniet fugit illum in iusto, mollitia nam necessitatibus nesciunt nulla officiis provident quidem
                saepe, veniam vero voluptatibus.
            </p>

            <div class="d-flex align-items-center gap-2 flex-wrap cursor-pointer mt-2"
                 data-bs-target="#reviewsPicsModal"
                 data-bs-toggle="modal">

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/146K4m3i2s7C5V849C4E8p2L7H"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/1J664n3M2z7U5R99365G0w8522"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="border-bottom pb-3 mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <p class="fs-15 fw-bold mb-0 lh-1">
                    Alex
                </p>

                <div class="d-flex align-items-center gap-2">
                    <div class="d-flex gap-1 fs-14">
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                    </div>

                    <span class="lh-1">
                        01.01.2022
                    </span>
                </div>
            </div>

            <p class="fs-15 mb-0 mt-2">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem culpa dolore error eveniet,
                excepturi hic illo inventore numquam odit omnis quos ratione repellat sed tempora temporibus
                voluptate voluptatibus. Aut expedita neque nostrum quae quidem quo quod ut voluptas voluptatem
                voluptates!
            </p>

            <div class="d-flex align-items-center gap-2 flex-wrap cursor-pointer mt-2"
                 data-bs-target="#reviewsPicsModal"
                 data-bs-toggle="modal">
                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/116Y4H3a2a7A5F8c5e2i2c0l6S"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/1y6h4A3l2D7B5Z8j5x3Q3e6C81"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>

                <div class="position-relative reviews_pic__container">
                    <img  class="reviews_pic"
                          src="https://image.geeko.ltd/comment/medium/1F6B4R3T2N7N588y5A5X7U8H9n"
                          alt=""
                          width="72">

                    <div class="position-absolute top-50 start-50 translate-middle opacity-0 reviews_pic__zoom">
                        <img :src="ZoomPic" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ZoomPic from "@/assets/websites/moda/img/zoom.svg"
export default {
    name: "ModaReviewsContent",

    data() {
        return {
            ZoomPic
        }
    }
}
</script>

<style scoped>
.reviews_pic__container .reviews_pic__zoom img{
    filter: invert(100%);
}

.reviews_pic__container .reviews_pic__zoom,
.reviews_pic__container .reviews_pic {
    transition: .3s;
}
.reviews_pic__container:hover .reviews_pic__zoom{
    opacity: 1 !important;
}
.reviews_pic__container:hover .reviews_pic {
    filter: brightness(.6);
}
</style>