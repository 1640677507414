<template>
    <div class="row m-0 row-cols-lg-2 row-cols-1 mt-lg-0 mt-3 order">
        <div class="col px-0">
            <p class="d-flex align-items-center gap-2 fs-5 fs-sm-16 fw-bold text-uppercase mb-0">
                <i class="fa-solid fa-truck"></i>

                Methods of transportation
            </p>

            <div class="mt-3">
                <input id="center_delivery" autocomplete="off"
                       checked class="btn-check form-check-input position-static inputRadioMethod"
                       name="delivery_options" type="radio">
                <label
                    class="btn btn-outline-dark border-0 w-100 text-start d-flex flex-column radioLabel-transportation"
                    for="center_delivery">
                                    <span class="fs-14">
                                        Your-Fashion.ge - withdrawal from the service center
                                    </span>

                    <span class="fs-12 opacity-75 mt-1">
                                        Withdrawal from Tbilisi service center | Mon - Sun, 10:00-22:00 | Wait for the operator to contact you before coming.
                                    </span>
                </label>
            </div>

            <div class="mt-3">
                <input id="standart_delivery" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="delivery_options" type="radio">
                <label
                    class="btn btn-outline-dark border-0 w-100 text-start d-flex flex-column radioLabel-transportation"
                    for="standart_delivery">
                                    <span class="fs-14">
                                        Standard delivery service
                                    </span>

                    <span class="fs-12 opacity-75 mt-1">
                                        Estimated delivery time: in Tbilisi, | 1-2 working days 1 - 3 working days in regions.
                                    </span>
                </label>
            </div>

        </div>

        <div class="col ps-lg-4 pe-lg-0 px-0 mt-lg-0 mt-3">
            <p class="d-flex align-items-center gap-2 fs-5 fs-sm-16 fw-bold text-uppercase mb-lg-0 mb-4">
                <svg class="bi bi-credit-card-fill" fill="currentColor" height="21" viewBox="0 0 16 16"
                     width="25" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"/>
                </svg>

                Payment methods
            </p>

            <div class="mt-3">
                <input id="visa_mastercard" autocomplete="off"
                       checked class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="visa_mastercard">
                    Payment by VISA or MasterCard plastic card of any bank
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="tbc_loyalty_bonus" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="tbc_loyalty_bonus">
                    Payment with TBC Loyalty Points
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="bog_plus_points" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="bog_plus_points">
                    Bank of Georgia - payment with plus points
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="bog_cards" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="bog_cards">
                    Cards of the Bank of Georgia - or payment with a VISA or MasterCard plastic card of
                    any other bank
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="space_bank" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="space_bank">
                    SPACE Bank - online installments
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="bog_online_installments" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="bog_online_installments">
                    Bank of Georgia online installments
                </label>
            </div>

            <div class="mt-lg-1 mt-3">
                <input id="bog_installments" autocomplete="off"
                       class="btn-check form-check-input position-static inputRadioMethod"
                       name="payment_options" type="radio">
                <label class="btn btn-outline-dark border-0 w-100 text-start fs-14 radioLabel-payment"
                       for="bog_installments">
                    Bank of Georgia payment in installments.
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaOrderMethods"
}
</script>


<style lang="scss" scoped>
.order .btn-check:checked + .btn,
.order .btn-check:active + .btn,
.order .btn:active,
.order .btn.active,
.order .btn.show {
    background-color: rgb(0 0 0 / 30%) !important;
    border-color: unset !important;
    box-shadow: unset !important;
    color: #000000;
}


.order .btn:hover {
    background-color: unset !important;
    color: unset !important;
}

//.order .btn {
//    border-color: rgb(0 0 0 / 60%) !important;
//}

.radioLabel-transportation {
    margin-top: -29px;
    padding-left: 32px;
}

.radioLabel-payment {
    margin-top: -50px;
    padding-left: 32px;
}

.inputRadioMethod {
    margin-left: 8px;
}
</style>