<template>
    <div>
        <div class="row row-cols-xxl-5 row-cols-lg-4 row-cols-2 mt-2 filtredProductsRow">
            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO BAG</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <span class="fs-14 fw-bold">
                            10 511 $
                        </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

            <div class="col mb-4">
                <div>
                    <router-link :to="{ name:'view-product'}"
                                 class="product-img-container position-relative d-block text-decoration-none">
                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                            title="product name">

                        <img
                            alt="product name"
                            class="filtred-product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                            title="product name">

                        <div
                            class="position-absolute bottom-0 start-50 translate-middle-x text-nowrap mb-2 shadow opacity-0 col-10 mx-auto saleables-add-basket">
                            <button class="btn btn-light btn-sm border-dark w-100">ADD TO CART</button>
                        </div>

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </router-link>

                    <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="fs-14 fw-bold">
                                10 511 $
                            </span>

                            <div class="d-flex flex-wrap align-items-center gap-1">
                                    <span class="rounded-circle bg-danger"
                                          style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-dark"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-success"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-warning"
                                      style="width: 14px; height: 14px;"></span>
                                <span class="rounded-circle bg-primary"
                                      style="width: 14px; height: 14px;"></span>
                            </div>
                        </div>

                        <router-link
                            :to="{ name:'view-product'}"
                            class="d-block fs-14 text-secondary text-decoration-none hover-text-decoration mt-1">
                            Texture
                            quilted gilet
                        </router-link>

                    </div>
                </div>
            </div>

        </div>

        <div class="mb-4 text-center">
            <router-link :to="{ name:'filtred' }" class="btn btn-outline-dark text-uppercase">
                Show more
            </router-link>
        </div>
    </div>
</template>

<script>
import Product4 from "@/assets/websites/moda/img/product4.png"
import FavouriteIcon from "@/assets/websites/moda/img/favourite-icon.svg"

export default {
    name: "ModaFiltredProductsSaleable",

    data() {
        return {
            Product4,
            FavouriteIcon
        }
    }
}
</script>

<style scoped>
.product-added-basket:hover .addedBasket {
    display: none;
}

.product-added-basket:hover .removeBasket {
    display: block !important;
}

.product-img-container .saleables-add-basket {
    transition: .3s;
}

.product-img-container:hover .saleables-add-basket {
    opacity: 1 !important;
}

@media screen and (min-width: 1401px) {
    .filtred-product-pic {
        height: 184px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .filtred-product-pic {
        height: 200px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .filtred-product-pic {
        height: 166px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .filtred-product-pic {
        height: 252px;
    }
}

@media screen and (max-width: 766px) {
    .filtred-product-pic {
        height: 127px;
    }
}

@media screen and (min-width: 992px) {
    /*.filtredProductsRow .col:nth-child(5n+1) {*/
    /*    padding-left: 0;*/
    /*}*/
    .filtredProductsRow .col-xl-6:nth-child(even) {
        padding-right: 0;
    }
}

@media screen and (max-width: 575px) {
    .filtredProductsRow .col:nth-child(odd) {
        padding-right: 6px;
    }

    .filtredProductsRow .col:nth-child(even) {
        padding-left: 6px;
    }

}

</style>