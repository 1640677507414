<template>
    <div class="d-flex justify-content-center">
        <div class="activate">
            <div class="login">
                <div class="form">
                    <div class="login-form">
                        <span class="material-icons">Auth</span>
                        <input v-model="phone" placeholder="number" type="text"/>
                        <input v-model="password" placeholder="password" type="password"/>
                        <button @click="login">login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {checkToken} from "@/core/helpers"

export default {
    name: "allmenu-activate",

    data() {
        return {
            phone: null,
            password: null
        }
    },

    created() {
        if (checkToken()) {
            this.$router.push({
                name: 'menu-index-locale',
                params: {locale: this.$route.params.locale, companyKey: this.$route.params.companyKey}
            })
        }
    },

    methods: {
        login() {
            try {
                fetch(`https://auto-click.pro/api/pos/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        phone: this.phone,
                        password: this.password,
                    }),
                }).then(response => response.json().then(data => {
                    if (!data.success) {
                        alert('Wrong number or password')

                        return
                    }

                    let token = data.token
                    let user = data.user
                    let company = data.company

                    window.localStorage.setItem('token', token)
                    window.localStorage.setItem('user', JSON.stringify(user))
                    window.localStorage.setItem('userCompany', JSON.stringify(company))

                    window.location.reload()
                }))
            } catch (e) {
                console.log(e)
            }
        }
    },
}
</script>

<style scoped>
.activate {
    width: 500px;
    clear: both;
    margin-top: 50px;
}

.login {
    width: 360px;
    padding: 8% 0 0;
    margin: auto;
}

.form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
}

.form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.form input:focus {
    background: #dbdbdb;
}

.form button {
    text-transform: uppercase;
    outline: 0;
    background: #4b6cb7;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
}

.form button:active {
    background: #395591;
}

.form span {
    font-size: 75px;
    color: #4b6cb7;
}
</style>
