<template>
    <div class="col-lg-3 px-0">
        <ul v-if="$route.name !== 'product'" class="d-lg-none ps-3">
            <template v-for="category in menuCompanyCategoriesAndProducts">
                <li
                    v-if="(category.products && category.products.length) || (category.children && category.children.length)"
                    :key="category.id"
                    class="fs-5 lh-1 mt-3"
                    style="list-style-type: none;">
                    <span
                        @click="pushRoute(category)">
                        <img v-if="category.icon_url" :src="category.path" height="22" width="22" :alt="getCategoryName(category) + 'icon'">
                        {{ getCategoryName(category) }}
                    </span>

                    <ul
                        v-if="category.children && category.children.length"
                        style="list-style-type: none;">

                        <li
                            v-for="child in category.children"
                            :key="child.id"
                            class="mt-2"
                            @click="pushRoute(child)">
                            <img v-if="child.icon_url" :src="child.path" height="22"
                                 width="22" :alt="getCategoryName(child) + 'icon'">
                            {{ getCategoryName(child) }}
                        </li>

                    </ul>
                </li>
            </template>
        </ul>

        <div class="sticky-lg-top" style="top: 70px">
            <div id="sticky-mobile"
                 :class="$route.name !== 'product' ? 'stickyMobile bg-white' : 'stickyMobile bg-white d-lg-block d-none'">
                <div class="categoryCol">
                    <h2 class="fs-18 mb-3 fw-bold text-secondary text-center d-none d-lg-block">
                        {{ trans('menu_qr_menu') }}
                    </h2>
                    <template v-for="category in menuCompanyCategoriesAndProducts">
                        <div
                            v-if="(category.products && category.products.length) || (category.children && category.children.length)"
                            :id="'x-parent-'+category.id"
                            :key="category.id"
                            class="mb-lg-2 py-lg-0 category-sector d-none d-lg-block">
                            <button
                                :id="'parent-x-category-'+category.id"
                                class="btn text-nowrap border-0 p-0 fs-5 fw-bold d-none d-lg-flex align-items-center gap-2 categoryBtn"
                                @click="pushRoute(category)">
                                <img v-if="category.icon_url" :src="category.path" height="22" width="22">
                                {{ getCategoryName(category) }}
                            </button>

                            <div
                                v-if="category.children && category.children.length"
                                class="d-flex flex-lg-column ps-lg-3 pe-lg-0 pe-3 pt-lg-0 pt-2 overflow-auto hide-scroll children-categories-box">
                                <div
                                    v-for="child in category.children"
                                    :id="'category-wrapper-'+child.id"
                                    :key="child.id" class="px-2 d-none d-lg-block">
                                    <button
                                        :id="'parent-'+child.id"
                                        class="btn text-nowrap mt-lg-1 px-2 py-lg-1 position-relative categoryBtn category-checker-duck d-lg-flex align-items-center gap-2"
                                        @click="pushRoute(child)">
                                        <img v-if="child.icon_url" :src="child.path" class="d-none d-lg-block"
                                             height="22"
                                             width="22"
                                             alt=""
                                        >
                                        {{ getCategoryName(child) }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {t} from "@/lang/core/helpers"
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
    name: "left-side",
    computed: {
        ...mapGetters([
            'locale',
            'menuCompanyCategoriesAndProducts',
            'menuCompany',
        ]),
    },

    methods: {
        trans(key) {
            return t(key)
        },
        getCategoryName(category) {
            let translation = _.filter(category.translations, {locale: this.locale})

            return translation[0].name
        },
        pushRoute(category) {
            this.$router.push({
                name: 'menu-category',
                params: {
                    locale: this.locale,
                    companyKey: this.menuCompany.key,
                    slug: category.slug
                }
            })

            let categoryContent = document.getElementById('category-' + category.id) || document.getElementById('children-category-' + category.id)

            window.scrollTo({
                top: categoryContent.offsetTop,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style scoped>

</style>
