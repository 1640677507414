<template>
    <div id="shopSimilarCanvas" aria-labelledby="shopSimilarCanvasLabel"
         class="offcanvas offcanvas-end border-start-0 shop-similar-canvas"
         tabindex="-1">
        <div class="offcanvas-header">
            <h5 id="shopSimilarCanvasLabel" class="offcanvas-title">
                Shop Similar
            </h5>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
        </div>
        <div class="offcanvas-body pt-0">
            <div class="row">
                <div class="col-lg-3">
                    <div class="sticky-top">
                        <div class="d-none d-lg-block">
                            <img
                                alt=""
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="Product name">
                        </div>

                        <div class="mt-lg-2">
                            <p class="mb-0 d-none d-lg-block">
                                Category
                            </p>

                            <ul class="d-flex flex-lg-column flex-row gap-lg-2 gap-3 overflow-auto p-0 mt-lg-2 text-nowrap list-style-none mb-0">
                                <li class="form-check">
                                    <input id="all" checked class="form-check-input" type="radio" name="shop-similar">
                                    <label class="form-check-label fs-14" for="all">
                                        All
                                    </label>
                                </li>

                                <li class="form-check">
                                    <input id="tops" class="form-check-input" type="radio" name="shop-similar">
                                    <label class="form-check-label fs-14" for="tops">
                                        Tops
                                    </label>
                                </li>

                                <li class="form-check">
                                    <input id="dresses" class="form-check-input" type="radio" name="shop-similar">
                                    <label class="form-check-label fs-14" for="dresses">
                                        Dresses
                                    </label>
                                </li>

                                <li class="form-check">
                                    <input id="women_suits" class="form-check-input" type="radio" name="shop-similar">
                                    <label class="form-check-label fs-14" for="women_suits">
                                        Women suits
                                    </label>
                                </li>

                                <li class="form-check">
                                    <input id="men_suits" class="form-check-input" type="radio" name="shop-similar">
                                    <label class="form-check-label fs-14" for="men_suits">
                                        Men suits
                                    </label>
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>

                <div class="col-lg-9 mt-lg-0 mt-3">
                    <div
                        class="d-flex align-items-center justify-content-between border-top border-bottom py-2 sticky-top bg-white">
                        <span class="fs-18">
                            99 items
                        </span>

                        <div class="d-flex align-items-center gap-2">
                            <span class="fs-18">
                                Sort
                            </span>

                            <select class="form-select form-select-sm">
                                <option selected>Recommend</option>
                                <option value="1">New Arrivals</option>
                                <option value="2">Price low to high</option>
                                <option value="3">Price high to low</option>
                            </select>
                        </div>
                    </div>

                    <div class="row row-cols-lg-3 row-cols-2 similar-products-row mt-3">
                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <div class="position-relative">
                                <img
                                    alt=""
                                    class="w-100 img-fluid"
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg">


                                <div class="position-absolute top-0 start-0 badge rounded-0 bg-dark" title="Sale">
                                    -30%
                                </div>
                            </div>

                            <div class="mt-1">
                                <p class="fs-14 fw-light mb-0">
                                    Lantern Sleeve Ruched Wrap Top
                                </p>

                                <div class="d-flex align-items-center gap-2 mt-1">
                                    <span>283 $</span>
                                    <span class="del-price fs-14">360 $</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaShopSimilar"
}
</script>

<style scoped>
.del-price {
    position: relative;
    width: max-content;
}

.del-price::after {
    content: "";
    width: 100%;
    background: #E86363;
    top: 48%;
    transform: translateY(-50%) rotateZ(6deg);
    height: 2px;
    position: absolute;
    left: 0;
}

@media screen and (min-width: 1000px) {
    .shop-similar-canvas {
        width: 1000px;
        transition: .1s !important;
    }
}

@media screen and (max-width: 575px) {

    .similar-products-row .col:nth-child(odd) {
        padding-right: 8px;
    }

    .similar-products-row .col:nth-child(even) {
        padding-left: 8px;
    }
}

</style>