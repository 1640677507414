<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-lg-0">
            <div class="d-flex flex-lg-row flex-column">
                <div class="mb-lg-0 mb-3 order-left-side me-lg-5">
                    <ModaSignInPopupBody/>
                </div>

                <div class="flex-grow-1">
                    <p class="fs-5 fw-bold text-uppercase mb-0">
                        shopping bag
                    </p>

                    <div class="row m-0 mt-3 pb-3 border-bottom">
                        <div class="col-lg-6 col-12 px-0">
                            <div class="d-flex gap-2">
                                <img
                                    alt=""
                                    src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                    width="100">

                                <div>
                                    <p class="fs-12 mb-0 text-uppercase">
                                        SELECTED HOMME - LEATHER CHELSEA BOOTS
                                    </p>

                                    <p class="fs-12 mb-0">
                                        Model : 16081455 Black
                                    </p>

                                    <p class="fs-12 mb-0">
                                        <strong>Size</strong> : 42
                                    </p>

                                    <p class="badge text-bg-success rounded-0 shadow mb-0">In stock</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 px-0 text-nowrap d-flex align-items-end mt-lg-0 mt-2">
                            <div class="d-flex align-items-end justify-content-between fs-12 w-100">
                                <p class="mb-0">Price 479,00 $</p>

                                <div class="d-flex gap-3">
                                    <div class="d-flex align-items-center border">
                                        <button class="btn btn-sm border-0 px-1 py-0" type="button">
                                            <i class="fa-solid fa-minus fs-12"></i>
                                        </button>

                                        <input class="form-control border-start border-end border-0 rounded-0 fs-12"
                                               style="width: 32px; height: 20px;"
                                               type="number">

                                        <button class="btn btn-sm border-0 px-1 py-0" type="button">
                                            <i class="fa-solid fa-plus fs-12"></i>
                                        </button>
                                    </div>

                                    <button class="btn btn-sm p-0 border-0" type="button">
                                        <svg class="bi bi-trash-fill" fill="#DD1919" height="16" viewBox="0 0 16 16"
                                             width="16" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                        </svg>
                                    </button>
                                </div>


                                <div class="d-flex flex-column text-end">
                                    <span class="fs-12">Total</span>
                                    <span class="fs-12">479,00 $</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row m-0 mt-3 pb-3 border-bottom">
                        <div class="col-lg-6 col-12 px-0">
                            <div class="d-flex gap-2">
                                <img
                                    alt=""
                                    src="https://img.mysilver.ge/cafedaphna/product/large/VkxvIgaR69UmICE8YTlEiUmMVAIo8ZNhMIhUwMIw.jpg"
                                    width="100">

                                <div>
                                    <p class="fs-12 mb-0 text-uppercase">
                                        SELECTED HOMME - LEATHER CHELSEA BOOTS
                                    </p>

                                    <p class="fs-12 mb-0">
                                        Model : 16081455 Black
                                    </p>

                                    <p class="fs-12 mb-0">
                                        <strong>Size</strong> : 42
                                    </p>

                                    <p class="badge text-bg-success rounded-0 shadow mb-0">In stock</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 px-0 text-nowrap d-flex align-items-end mt-lg-0 mt-2">
                            <div class="d-flex align-items-end justify-content-between fs-12 w-100">
                                <p class="mb-0">Price 479,00 $</p>

                                <div class="d-flex gap-3">
                                    <div class="d-flex align-items-center border">
                                        <button class="btn btn-sm border-0 px-1 py-0" type="button">
                                            <i class="fa-solid fa-minus fs-12"></i>
                                        </button>

                                        <input class="form-control border-start border-end border-0 rounded-0 fs-12"
                                               style="width: 32px; height: 20px;"
                                               type="number">

                                        <button class="btn btn-sm border-0 px-1 py-0" type="button">
                                            <i class="fa-solid fa-plus fs-12"></i>
                                        </button>
                                    </div>

                                    <button class="btn btn-sm p-0 border-0" type="button">
                                        <svg class="bi bi-trash-fill" fill="#DD1919" height="16" viewBox="0 0 16 16"
                                             width="16" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                        </svg>
                                    </button>
                                </div>


                                <div class="d-flex flex-column text-end">
                                    <span class="fs-12">Total</span>
                                    <span class="fs-12">479,00 $</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="fs-14 text-end mt-3 pb-3 border-bottom" style="border-bottom-style: dashed !important;">
                        <p class="mb-0">
                            Total: 1200 $
                        </p>

                        <p class="mb-0">
                            Delivery: Free shiping
                        </p>
                    </div>

                    <div class="mt-3 d-flex flex-column align-items-lg-end">
                        <div class="col-lg-4 d-flex flex-row align-items-center gap-2 rounded-0">
                            <input class="form-control form-control-sm" placeholder="Coupon code" type="text">

                            <button class="btn btn-dark btn-sm text-uppercase">
                                Use
                            </button>
                        </div>

                        <p class="fw-bold mt-2 mb-0">
                            Total: 1200 $
                        </p>
                    </div>

                    <ModaOrderMethods/>

                    <div class="mt-3">
                        <p class="fw-semibold mb-0">
                            Add comment
                        </p>
                        <textarea class="form-control mt-2" rows="3"></textarea>

                        <router-link :to="{ name: 'order-final' }"
                                     class="btn btn-dark btn-sm text-uppercase mt-2 col-lg-2 col-12"
                        >
                            Buy
                        </router-link>

<!--                        <button class="btn btn-dark btn-sm text-uppercase mt-2 col-lg-2 col-12" type="submit">-->
<!--                            Buy-->
<!--                        </button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModaSignInPopupBody from "@/components/websites/moda/parts/dialogs-and-offcanvas/SignInPopupBody.vue";
import ModaOrderMethods from "@/components/websites/moda/parts/OrderMethods.vue"

export default {
    name: "ModaOrder",

    components: {
        ModaSignInPopupBody,
        ModaOrderMethods
    }
}
</script>

<style scoped>

</style>