<template>
    <div>
        <div class="p-lg-4 p-3 pb-lg-0 pb-0">
            <p class="fs-5 mb-0">
                Персональный калькулятор
            </p>

            <p class="mt-3 mb-0">
                Калькулятор личного кредита использует калькулятор для расчета приблизительной суммы по
                той же формуле, что и стандартная для финансовых учреждений (без учета комиссий или
                других дополнительных расходов для конкретного финансового учреждения).
            </p>
        </div>

        <div class="row m-0">
            <div class="col-lg-6 px-lg-4 px-3 mt-3">
                <div>
                    <label class="form-label"
                           for="price">
                        Продажная цена (₽)
                    </label>

                    <input
                        id="price"
                        class="form-control"
                        required
                        type="number"
                    />
                </div>

                <div class="mt-3">
                    <label class="form-label"
                           for="first_payment">
                        Первый взнос (₽)
                    </label>

                    <input id="first_payment"
                           class="form-control"
                           required
                           type="number"
                    />
                </div>

                <div class="d-flex flex-lg-row flex-column align-items-lg-center mt-3">
                    <div>
                        <label class="form-label"
                               for="credit_term">
                            Срок кредита
                        </label>

                        <input id="credit_term"
                               class="form-control"
                               required
                               type="number"
                        />
                    </div>

                    <div class="mt-lg-0 mt-3 ms-lg-3">
                        <label class="form-label"
                               for="annual_percentage"
                        >
                            Годовой процент (%)
                        </label>

                        <input id="annual_percentage"
                               class="form-control"
                               required
                               type="number"
                        />
                    </div>
                </div>

                <div class="my-lg-4 my-3">
                    <button class="btn btn-outline-warning w-100" type="button">
                        Рассчитать
                    </button>
                </div>
            </div>

            <div class="col-lg-6 px-0 bg-warning bg-gradient">
                <div class="d-flex flex-column justify-content-between pt-lg-5 py-3 pb-lg-4 px-lg-4 px-3 h-100">

                    <div class="d-flex align-items-center">
                        <div>
                            <p class="d-block opacity-50 mb-0">Первый взнос:</p>
                            <p class="d-block opacity-50 mt-lg-4 mt-3 mb-0">Размер кредита:</p>
                            <p class="d-block opacity-50 mt-lg-4 mt-3 mb-0">Месячный взнос:</p>
                        </div>

                        <div class="ms-lg-5 ms-3">
                            <span id="calc_vznos" class="d-block">12323</span>
                            <span id="calc_credit" class="d-block mt-lg-4 mt-3">123123</span>
                            <span id="calc_mes" class="d-block mt-lg-4 mt-3">12312312</span>
                        </div>
                    </div>

                    <div class="mt-lg-0 mt-4">
                        <div class="mt-lg-4 mt-3">
                            <router-link :to="{name: 'credit'}"
                                         class="btn btn-light w-100 text-uppercase py-lg-3">
                                подать заявку сейчас
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoCalculator"
}
</script>

<style scoped>

</style>