<template>
    <div>
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img
                        alt="Фото"
                        class="w-100 img-fluid" loading="lazy"
                        src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg"
                        title="Фото">
                </div>

                <div class="carousel-item">
                    <img
                        alt="Фото"
                        class="w-100 img-fluid" loading="lazy"
                        src="https://img.mysilver.ge/autopark77/car/medium/WeTlFxdJjajlWPeYyN7XtN6AyHc9sD6iW5pq6NpD.jpg"
                        title="Фото">
                </div>

                <div class="carousel-item">
                    <img
                        alt="Фото"
                        class="w-100 img-fluid" loading="lazy"
                        src="https://img.mysilver.ge/autopark77/car/medium/8V74gvPq6EllKZki6xmZCMOGluo96lnjYpqJpZzc.jpg"
                        title="Фото">
                </div>
            </div>

            <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <div class="d-flex align-items-center gap-2 mt-2 overflow-auto">

            <button aria-current="true" aria-label="Slide 1" class="active" data-bs-slide-to="0"
                    data-bs-target="#carouselExampleFade" type="button">
                <img alt=""
                     class="d-block"
                     src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg"
                     width="90">
            </button>
            <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselExampleFade" type="button">
                <img alt=""
                     class="d-block"
                     src="https://img.mysilver.ge/autopark77/car/medium/WeTlFxdJjajlWPeYyN7XtN6AyHc9sD6iW5pq6NpD.jpg"
                     width="90">
            </button>
            <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselExampleFade" type="button">
                <img alt=""
                     class="d-block"
                     src="https://img.mysilver.ge/autopark77/car/medium/8V74gvPq6EllKZki6xmZCMOGluo96lnjYpqJpZzc.jpg"
                     width="90">
            </button>

        </div>
    </div>
</template>

<script>
export default {
    name: 'CarPicSlide',

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>