<template>
	<div>
		<div class="container-fluid px-0">
			<img :src="CoverPic" alt="" class="w-100 img-fluid cover__pic" />
		</div>

		<div class="container-fluid px-0 mt-lg-5 mt-3">
			<div class="container px-2">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb fs-14 mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'home' }">{{
								trans("home")
							}}</router-link>
						</li>

						<li aria-current="page" class="breadcrumb-item active">
							{{ trans("contacts") }}
						</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="container-fluid px-0 mt-lg-5 mt-3">
			<div class="container px-0 mt-3">
				<ContactInfoBlocks />
			</div>
		</div>

		<div class="container-fluid px-0 my-lg-5 my-3">
			<div class="container px-0">
				<div class="row m-0">
					<div class="col-lg-6 px-lg-2 px-0">
						<div v-html="requisites.LOC_GOOGLE_MAP_EMBED"></div>
					</div>

					<div class="col-lg-6 px-2 my-lg-0 my-3">
						<div class="bg-white rounded shadow p-3">
							<h1 class="fs-3 text-center mb-0">
								{{ trans("feedback_form") }}
							</h1>

							<div class="form-floating mt-3">
								<input
									id="contacts_name"
									class="form-control"
									placeholder="სახელი"
									type="text"
									v-model="requestCall.name"
								/>
								<label class="text-secondary" for="contacts_name">{{
									trans("first_name")
								}}</label>
							</div>

							<div class="form-floating mt-3">
								<input
									id="contacts_tel"
									class="form-control"
									placeholder="ტელეფონი"
									type="number"
									v-model="requestCall.phone"
								/>
								<label class="text-secondary" for="contacts_tel">{{
									trans("phone")
								}}</label>
							</div>

							<div class="form-floating mt-3">
								<textarea
									id="contacts_comment"
									:placeholder="trans('message')"
									class="form-control"
									v-model="requestCall.message"
								></textarea>
								<label class="text-secondary" for="contacts_comment">{{
									trans("message")
								}}</label>
							</div>

							<div class="mt-3">
								<button
									class="btn btn-lg btn-warning col-lg-auto col-12"
									type="button"
									@click="sendRequestCall()"
								>
									{{ trans("send") }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CoverPic from "@/assets/websites/get-parts/img/cover.png";
import ContactInfoBlocks from "@/components/websites/get-parts/parts/ContactInfoBlocks.vue";
import { mapGetters } from "vuex";
import { t } from "@/lang/core/helpers";
import ApiRequest from "@/http/ApiRequest";

export default {
	name: "ContactsGP",

	components: { ContactInfoBlocks },

	computed: {
		...mapGetters(["requisites"]),
	},

	methods: {
		trans(key) {
			return t(key);
		},

		sendRequestCall() {
			if (
				!this.requestCall.name ||
				!this.requestCall.phone ||
				!this.requestCall.message
			) {
				return;
			}

			new ApiRequest(
				"car-parts/request-call",
				{
					key: "get-parts",
					requestCall: this.requestCall,
				},
				(data) => {
					if (!data.success) {
						alert(data.message || "Server Error");
						return;
					}

					this.requestCall.name = null;
					this.requestCall.phone = null;
					this.requestCall.message = null;

					alert(data.message || "მოთხოვნა მიღებულია");
				}
			);
		},
	},

	data() {
		return {
			CoverPic,
			requestCall: {
				name: null,
				phone: null,
				message: null,
			},
		};
	},
};
</script>

<style scoped>
textarea {
	min-height: 110px !important;
}

@media screen and (max-width: 760px) {
	.cover__pic {
		height: 150px;
		object-fit: cover;
	}
}
</style>
