<template>
    <div id="reservationModal" aria-hidden="true" aria-labelledby="reservationModalLabel" class="modal fade"
         tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header border-0 px-lg-4">
                    <h5 id="reservationModalLabel" aria-label="Close"
                        class="modal-title text-warning cursor-pointer"
                        data-bs-dismiss="modal">
                        {{ companyName }}
                    </h5>
                    <button aria-label="Close" class="btn border-0 fs-15 text-warning" data-bs-dismiss="modal"
                            type="button">
                        {{ trans('menu_menu') }}
                        <i class="fa-solid fa-utensils ms-1"></i>
                    </button>
                </div>

                <p class="mb-0 fw-bold text-center">
                    <i class="fa-solid fa-calendar-days me-1"></i>
                    {{ trans('menu_reservation_table') }}
                </p>

                <div class="modal-body px-lg-4 px-0">
                    <div :style="'background-image: url('+popupBg.url+')'"
                         class="popupBg d-flex align-items-center">
                        <div
                            class="col-xxl-3 col-lg-5 mx-auto shadow bg-white rounded rounded-0-sm webStyles mobStyles">
                            <nav class="border-bottom py-3">
                                <div id="nav-tab" class="nav nav-tabs justify-content-center border-0 fs-15"
                                     role="tablist">
                                    <button id="nav-home-time" :class="{'active': reservationStep === 1}"
                                            aria-controls="nav-time" aria-selected="true"
                                            class="nav-link border-0 text-secondary pb-1" data-bs-target="#nav-time"
                                            data-bs-toggle="tab"
                                            role="tab" type="button"
                                            @click="reservationStep = 1">
                                        <i class="fa-regular fa-clock me-1"></i>
                                        {{ trans('menu_time') }}
                                    </button>
                                    <div>
                                        <i class="fa-solid fa-hyphen fs-15 text-secondary"></i><i
                                        class="fa-solid fa-hyphen"></i>
                                    </div>
                                    <button id="nav-profile-contacts" :class="{'active': reservationStep === 2}"
                                            aria-controls="nav-contacts" aria-selected="false"
                                            class="nav-link border-0 text-secondary pb-1" data-bs-target="#nav-contacts"
                                            data-bs-toggle="tab" role="tab" type="button"
                                            @click="reservationStep = 2">
                                        <i class="fa-solid fa-mobile-screen-button me-1"></i>
                                        {{ trans('menu_contacts') }}
                                    </button>
                                </div>
                            </nav>

                            <div id="nav-tabContent" class="tab-content p-3">
                                <div id="nav-time" :class="{'show active': reservationStep === 1}"
                                     aria-labelledby="nav-home-time"
                                     class="tab-pane fade"
                                     role="tabpanel" tabindex="0">
                                    <table-reservation-time/>
                                </div>

                                <div id="nav-contacts" :class="{'show active': reservationStep === 2}"
                                     aria-labelledby="nav-contacts-tab"
                                     class="tab-pane fade"
                                     role="tabpanel" tabindex="0">
                                    <table-reservation-contacts/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import {t} from "@/lang/core/helpers";
import TableReservationTime from "@/components/websites/allmenu/parts/TableReservationTime";
import TableReservationContacts from "@/components/websites/allmenu/parts/TableReservationContacts";

export default {
    name: "TableReservation",
    components: {TableReservationContacts, TableReservationTime},
    computed: {
        ...mapGetters([
            'menuCompany',
            'locale',
            'availableLocales',
            'menuCompanyDefaultLocale',
        ]),

        // reservationStep() {
        //     return this.$store.getters.tableReservationStep;
        // },

        reservationStep: {
            get() {
                return this.$store.getters.tableReservationStep;
            },
            set(value) {
                this.$store.commit('setTableReservationStep', value);
            }
        },

        reservationData: {
            get() {
                return this.$store.getters.reservationData;
            },
            set(value) {
                this.$store.commit('setTableReservationDate', value);
            }
        },

        companyName() {
            if (!this.menuCompany) {
                return null
            }

            let translation = _.filter(this.menuCompany.translations, {locale: this.locale})
            let translatedName = null

            if (!translation || !translation.length) {
                let locales = (this.availableLocales || []).filter(locale => locale !== this.locale)

                if (locales && locales.length) {
                    locales.forEach(l => {
                        translation = _.filter(this.menuCompany.translations, {locale: l})

                        if (translation && translation.length) {
                            translatedName = translation[0].name
                            return
                        }
                    })
                }

                return translatedName
            }

            return translation[0].name
        },

        popupBg() {
            if (!this.menuCompany) {
                return null
            }

            let assets = _.filter(this.menuCompany.assets, {image_type: 'COVER'})

            return {
                url: assets.length ? assets[0].url + '?id=' + Math.floor(Math.random() * (100000 - 1 + 1) + 1) : null,
                meta: assets.length ? assets[0].meta : {'x': null, 'y': null, 'zoom': 1},
            }
        },
    },

    data() {
        return {}
    },

    beforeUnmount() {
        this.$store.commit('setTableReservationStep', 1)
    },

    methods: {
        trans(key) {
            return t(key)
        },

        generateToday() {
            let now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            this.reservationData.reservationDate = now.toISOString().slice(0, 16);
        },
    }
}
</script>

<style scoped>
@media screen and (min-width: 992px) {
    .popupBg {
        background-size: cover;
        background-position: 50%;
        height: 100%;
        border-radius: 4px;
    }

    .popupBg .webStyles {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 992px) {
    .popupBg {
        background-image: none;
    }

    .popupBg .mobStyles {
        border-radius: 0 !important;
        box-shadow: unset !important;
    }
}
</style>
