export default {
    setLocale(state, locale) {
        state.locale = locale
    },

    setAvailableLocales(state, availableLocales) {
        state.availableLocales = availableLocales
    },

    setCompany(state, company) {
        state.company = company
    },

    setShoppingCart(state, shoppingCart) {
        state.shoppingCart = shoppingCart
    },

    setFavorites(state, favorites) {
        state.favorites = favorites
    },

    setRequisites(state, requisites) {
        state.requisites = requisites
    },

    setTranslations(state, translations) {
        state.translations = translations
    },

    setTranslationKey(state, translationKey) {
        state.translationKey = translationKey
    },

    setExchangeRates(state, exchangeRates) {
        state.exchangeRates = exchangeRates
    },

    setAllmenu(state, allmenu) {
        state.allmenu = allmenu
    },

    setMenuDataLoaded(state, dataLoaded) {
        state.allmenu.dataLoaded = dataLoaded
    },

    setMenuCompany(state, menuCompany) {
        state.allmenu.menuCompany = menuCompany
    },

    setMenuCompanyCategoriesAndProducts(state, menuCompanyCategoriesAndProducts) {
        state.allmenu.menuCompanyCategoriesAndProducts = menuCompanyCategoriesAndProducts
    },

    setMenuCompanyRequisites(state, menuCompanyRequisites) {
        state.allmenu.menuCompanyRequisites = menuCompanyRequisites
    },

    setMenuCompanyHallsTablesOrders(state, menuCompanyHallsTablesOrders) {
        state.allmenu.menuCompanyHallsTablesOrders = menuCompanyHallsTablesOrders
    },

    setMenuCompanySettings(state, menuCompanySettings) {
        state.allmenu.menuCompanySettings = menuCompanySettings
    },

    setMenuSelectedProduct(state, selectedProduct) {
        state.allmenu.selectedProduct = selectedProduct
    },

    setTableReservationStep(state, tableReservationStep) {
        state.allmenu.tableReservationStep = tableReservationStep
    },

    setTableReservationData(state, reservationData) {
        state.allmenu.reservationData = reservationData
    },

    setProductInBasket(state, product) {
        let checkProductExists = state.allmenu.basket.findIndex(h => h.id === product.id)

        if (checkProductExists >= 0) {
            state.allmenu.basket[checkProductExists] = product

            return
        }

        state.allmenu.basket.push(product)
    },

    removeProductInBasket(state, productId) {
        state.allmenu.basket.splice(state.allmenu.basket.findIndex(h => h.id === productId), 1)
    },

    setDeleteFromBasket(state, productId) {
        state.allmenu.deleteFromBasket = productId
    },

    resetProductCounter(state, productId) {
        state.allmenu.menuCompanyCategoriesAndProducts.filter(category => {
            category.children.filter(category => {
                category.products.filter(product => {
                    if (product.id === productId) {
                        product.counter = ''
                    }
                })
            })
            category.products.filter(product => {

                if (product.id === productId) {
                    product.counter = ''
                }
            })
        })

        // currentProduct.counter = ''
    },

    setIsMobileDevice(state) {
        state.allmenu.isMobileDevice = true
    },

    setCarParts(state, carParts) {
        state.carParts = carParts
    },

    setCarPartsCategories(state, categories) {
        state.carParts.categories = categories
    },

    setCarPartsGeneratedCategories(state, generatedCategories) {
        state.carParts.generatedCategories = generatedCategories
    },

    setCarPartsMarks(state, marks) {
        state.carParts.marks = marks
    },

    setCarPartsPromotionCarParts(state, promotionCarParts) {
        state.carParts.promotionCarParts = promotionCarParts
    },

    setCarPartsDiscountedCarParts(state, discountedCarParts) {
        state.carParts.discountedCarParts = discountedCarParts
    },

    setCarPartPopularMarks(state, popularMarks) {
        state.carParts.popularMarks = popularMarks
    },

    setCarPartLoaded(state, carPartLoaded) {
        state.carParts.carPartLoaded = carPartLoaded
    },

    setCarPartPromotionCarPartsLoaded(state, promotionCarPartsLoaded) {
        state.carParts.promotionCarPartsLoaded = promotionCarPartsLoaded
    },

    setCarPartPopularMarksLoaded(state, popularMarksLoaded) {
        state.carParts.popularMarksLoaded = popularMarksLoaded
    },

    setCarPartsMarkModelsLoaded(state, markModelsLoaded) {
        state.carParts.markModelsLoaded = markModelsLoaded
    },

    setCarPartsModelCarPartsLoaded(state, modelCarPartsLoaded) {
        state.carParts.modelCarPartsLoaded = modelCarPartsLoaded
    },

    setCarPartsProductSearch(state, productSearch) {
        state.carParts.productSearch = productSearch
    }
}
