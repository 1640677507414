<template>
    <nav class="d-flex justify-content-center">
        <ul class="pagination mb-0">
            <li class="page-item">
                <a aria-label="Previous" class="page-link" href="#">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li class="page-item active"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
                <a aria-label="Next" class="page-link" href="#">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "ModaPagination"
}
</script>

<style scoped>
.page-item.active .page-link {
    background-color: #000 !important;
    border-color: #000 !important;
    color: #fff !important;
}

.page-link {
    color: #000 !important;
    border-color: #000;
}

.page-link:focus, .page-link:hover {
    color: #000 !important;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    border-radius: 0 !important;
}
</style>