<template>
    <div class="container-fluid my-lg-5 my-3 px-0">
        <div class="container p-2">
            <p class="text-danger fs-5 fw-normal text-uppercase fs-sm-16 mb-2">Наши условия и гарантия</p>

            <p class="text-secondary fs-sm-12">
                Выбирая подержанные авто, вы получите машину сразу же после оформления кредита. Для этого не нужно
                длительное время откладывать деньги, затем ждать подходящей возможности выплатить всю стоимость
                автомобиля или срочно занимать сумму, чтобы выгодно приобрести новую автомашину в салоне.
            </p>
            <p class="text-secondary fs-sm-12">
                Наша компания — это команда профессионалов, предлагающая автокредиты на привлекательных для клиентов
                условиях. Чтобы купить у нас подержанные авто в кредит, достаточно обратиться к нашим консультантам,
                которые помогут подобрать машину, максимально соответствующую вашим требованиям, стилю езды и условиям
                эксплуатации. Из представленного у нас широкого ассортиментаподержанных авто вы можете позволить себе
                транспортное средство высокого класса, даже если прежде у вас не было достаточно средств для его
                приобретения в автосалоне, ведь стоимость авто с пробегом существенно ниже, чем новых. А значит, покупка
                подержанной машины — это наиболее демократичный и доступный вариант приобретения хорошего автомобиля,
                который долгое время будет безотказно служить своему владельцу.
            </p>
            <p class="text-secondary fs-sm-12">
                Если вы решились купить авто с пробегом в кредит, не нужно ходить по банкам, разбираться в особенностях
                различных программ автокредитования и схем выплаты процентов за машину. Вы можете обратиться к нам, и
                наши консультанты помогут выбрать наиболее подходящий по уровню и стоимости автомобиль и
                сориентироваться в многочисленных банковских предложениях, чтобы найти оптимальный вариант погашения
                кредита.
            </p>
            <p class="text-secondary fs-sm-12">
                Наша компания успешно сотрудничает более чем с 15 банками, среди которых — Райффайзен, Альфа Банк,
                Сбербанк, Юниаструм, Ренессанс и т.д., поэтому у нас есть возможность предложить вам на выбор более 30
                различных программ автокредитования. Подобрать оптимально подходящую схему кредитования вы можете
                непосредственно на нашем сайте, заполнив заявку онлайн. В течение часа с вами свяжется наш специалист.
            </p>
            <p class="text-secondary fs-sm-12">
                Наша база подержанных автомобилей представлена моделями азиатского и европейского производства разных
                классов и годов выпуска, при необходимости наши менеджеры помогут подобрать для вас наиболее подходящее
                авто. Машиной вы сможете пользоваться сразу же после оформления кредита, а расплачиваться — небольшими
                суммами на протяжении продолжительного срока. Мы самостоятельно выполним расчеты с продавцом автомобиля,
                а также оформим для вас страховой полис на лучших условиях.
            </p>
            <p class="text-secondary fs-sm-12">
                Все предложенные нами подержанные авто проходят полный технический осмотр и тщательную юридическую
                проверку. Это гарантия того, что вы получите в собственность транспортное средство без каких-либо
                дефектов или технических неисправностей. Если вы твердо решили, что авто с пробегом в кредит — это
                оптимальный вариант, обращайтесь к нам, и мы поможем вам приобрести машину уже сегодня и на очень
                выгодных условиях.
            </p>
            <p class="text-secondary fs-sm-12">
                Покупка подержанного авто у нас — это гарантия того, что вы получите автомобиль без каких-либо
                технических неисправностей, с комплектом необходимых документов и исчерпывающей информацией о его
                предыдущих владельцах.
            </p>
            <p class="text-secondary fs-sm-12 mb-0">
                Прежде чем совершить сделку, вы всегда можете проверить работоспособность всех систем транспортного
                средства в специализированном сервисе, а также лично испытать машину на тест-драйве. Все предлагаемые
                нами автомобили с пробегом проходят тщательный технический осмотр и полную юридическую проверку.
            </p>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ConditionsAndGuarantees',

    data() {
        return {}
    }

}
</script>

<style scoped>

</style>