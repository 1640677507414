<template>
    <div>
        <carousel :autoplay="true"
                  :autoplayTimeout="3000"
                  :loop="true"
                  :responsive="{250:{items:2, margin:16, autoplay: false}, 670:{items:3}, 992:{items:4}}">

            <div class="partner-item d-flex align-items-center justify-content-center rounded-5 my-3 p-3">
                <img :src="Partner1" alt="">
            </div>

            <div class="partner-item d-flex align-items-center justify-content-center rounded-5 my-3 p-3">
                <img :src="Partner5" alt="">
            </div>

            <div class="partner-item d-flex align-items-center justify-content-center rounded-5 my-3 p-3">
                <img :src="Partner2" alt="">
            </div>

            <div class="partner-item d-flex align-items-center justify-content-center rounded-5 my-3 p-3">
                <img :src="Partner3" alt="">
            </div>

            <div class="partner-item d-flex align-items-center justify-content-center rounded-5 my-3 p-3">
                <img :src="Partner4" alt="">
            </div>

        </carousel>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import Partner1 from "@/assets/websites/moda/img/partners1.png"
import Partner2 from "@/assets/websites/moda/img/partners2.png"
import Partner3 from "@/assets/websites/moda/img/partners3.jpg"
import Partner4 from "@/assets/websites/moda/img/partners4.jpg"
import Partner5 from "@/assets/websites/moda/img/partners5.png"

export default {
    name: "ModaPartners",

    data() {
        return {
            Partner1,
            Partner2,
            Partner3,
            Partner4,
            Partner5,
        }
    },

    components: {
        carousel
    },
}
</script>

<style scoped>
.partner-item {
    width: 240px;
    box-shadow: rgb(61 0 227 / 8%) 10px 10px 20px;
}

.partner-item img {
    width: 180px;
    height: 140px;
    object-fit: cover;
}

.owl-theme .owl-nav {
    margin-top: 0 !important;
}

.owl-prev, .owl-next {
    position: relative;
    background: unset !important;
}

.owl-prev::after, .owl-next::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D6D6D6;
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-weight: 900;
    border-radius: 50%;
    transition: .3s;
}

.owl-prev::after {
    content: '\f053';
}

.owl-next::after {
    content: '\f054';
}

.owl-prev:hover::after, .owl-next:hover::after {
    background: #869791 !important;
}

.owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    opacity: 0;
    transition: .3s;

}

.owl-carousel:hover .owl-nav {
    opacity: 1;
}

@media screen and (max-width: 992px) {
    .owl-prev::after, .owl-next::after {
        width: 40px;
        height: 40px;
    }

    .owl-nav {
        opacity: 1;
    }

    .partner-item {
        width: unset;
    }

    .partner-item img {
        width: 100px;
        height: 80px;
        object-fit: cover;
    }
}
</style>