<template>
    <div class="row m-0 p-3">
        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>

        <div class="col-lg-2 col-md-3 col-6 mb-lg-4 mb-3">
            <a class="d-flex align-items-center justify-content-between text-dark"
               href="#">
                <div class="text-truncate">
                    <img :src="CategoryIcon" alt="" height="20" loading="lazy" width="27">
                    <span class="lh-1">Acura</span>
                </div>
                <span>1</span>
            </a>
        </div>
    </div>
</template>

<script>
import CategoryIcon from "@/assets/websites/auto/img/marc-icon.png"

export default {
    name: "AutoCategories",

    data() {
        return {
            CategoryIcon
        }
    }
}
</script>

<style scoped>

</style>