<template>
    <div class="container-fluid px-0">
        <div>
            <img :src="CoverPic" alt="" class="w-100 img-fluid cover__pic">
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            {{ trans('about_us') }}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-0 mt-3">
                <ContactInfoBlocks/>
            </div>
        </div>


        <div class="container-fluid px-0 mt-lg-5 mt-3">
            <div class="container px-2">
                <p class="fs-5 fs-sm-16 text-center">
                    {{ trans('about_first_text') }}
                </p>
                <p class="fs-5 fs-sm-16 text-center mb-0">
                    {{ trans('about_second_text') }}
                </p>
            </div>
        </div>

        <div class="container-fluid px-0 my-lg-5 my-3">
            <div class="container px-2">
                <div
                    class="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-center gap-3 text-center">
                    <h1 class="fs-2 fs-sm-22 text-warning mb-0"> {{ trans('find_us_on_soc_media') }}:</h1>

                    <div class="d-flex align-items-center justify-content-center gap-2">
                        <a v-if="requisites.SOC_FACEBOOK_LINK"
                           :href="requisites.SOC_FACEBOOK_LINK"
                           class="d-block"
                           target="_blank">
                            <img :src="FbIcon" alt="Facebook icon" title="Facebook" width="25">
                        </a>

                        <a v-if="requisites.SOC_INSTAGRAM_LINK"
                           :href="requisites.SOC_INSTAGRAM_LINK"
                           class="d-block"
                           target="_blank">
                            <img :src="InstagramIcon" alt="Instagram icon" title="Instagram" width="25">
                        </a>

                        <a class="d-block"
                           href="https://www.tiktok.com/@getpartsgeorgia"
                           target="_blank">
                            <img :src="TiktokIcon" alt="Tiktok icon" title="Tiktok" width="25">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import CoverPic from "@/assets/websites/get-parts/img/cover.png"
import ContactInfoBlocks from "@/components/websites/get-parts/parts/ContactInfoBlocks.vue";
import FbIcon from "@/assets/websites/get-parts/img/fb-icon.png"
import InstagramIcon from "@/assets/websites/get-parts/img/instagram-icon.png"
import TiktokIcon from "@/assets/websites/get-parts/img/tiktok-icon.png"
import {mapGetters} from "vuex";
import {t} from "@/lang/core/helpers";

export default {
    name: 'AboutUsGP',
    components: {
        ContactInfoBlocks
    },

    computed: {
        ...mapGetters(['requisites']),
    },

    methods: {
        trans(key) {
            return t(key)
        }
    },

    data() {
        return {
            CoverPic,
            FbIcon,
            InstagramIcon,
            TiktokIcon
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 760px) {
    .cover__pic {
        height: 150px;
        object-fit: cover;
    }
}
</style>