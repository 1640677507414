<template>
    <div class="checkStyle p-lg-4 p-3 shadow position-relative">
        <h2 class="fs-3 text-center mb-0">
            Your order
        </h2>

        <div class="bg-white mt-3 p-3">
            <div class="d-flex align-items-center justify-content-between border-bottom pb-3">
                                <span class="fs-15 fw-semibold">
                                    Product
                                </span>

                <span class="fs-15 fw-semibold">
                                    Total
                                </span>
            </div>

            <div class="d-flex align-items-center justify-content-between border-bottom py-3">
                                <span class="fs-14 text-secondary fw-light">
                                    PREMIATA GOLDEN GOOSE - BLACK, 40  × 1
                                </span>

                <span class="fs-14 text-secondary fw-light">$365.00</span>
            </div>

            <div class="d-flex align-items-center justify-content-between border-bottom py-3">
                                <span class="fs-14 text-secondary fw-light">
                                    BCL22208 - WHITE, 40  × 2
                                </span>

                <span class="fs-14 text-secondary fw-light">$400.00</span>
            </div>

            <div class="d-flex align-items-center justify-content-between border-bottom py-3">
                                <span class="fs-14 fw-semibold">
                                    Total
                                </span>

                <span class="fs-14 fw-semibold">
                                    $765.00
                                </span>
            </div>

            <div class="d-flex align-items-center justify-content-between border-bottom py-3">
                                <span class="fs-14 fw-semibold">
                                    Delivery
                                </span>

                <span class="fs-14">
                                    Free shipping
                                </span>
            </div>

            <div class="d-flex align-items-center justify-content-between pt-3">
                                <span class="fw-semibold">
                                    The overall result
                                </span>

                <span class="fw-semibold">
                                   $765.00
                                </span>
            </div>
        </div>

        <div class="mt-3 border-bottom pb-3">
            <div class="form-check">
                <input id="ipay" class="form-check-input" name="delivery_radio" type="radio">
                <label class="form-check-label fs-14" for="ipay">
                    iPay
                </label>
            </div>

            <div class="form-check mt-2">
                <input id="bog" class="form-check-input" name="delivery_radio" type="radio">
                <label class="form-check-label fs-14" for="bog">
                    Bank of Georgia installment plan
                </label>
            </div>
        </div>

        <div class="form-check m-0 pt-3">
            <input id="site_rules" class="form-check-input" type="checkbox">
            <label class="form-check-label fs-14" for="site_rules">
                I have read and agree to the site rules, terms and conditions.
            </label>
        </div>

        <div class="mt-4">
            <button class="btn btn-dark w-100 text-uppercase" type="submit">
                make an order
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: "ModaOrderCheck"
}
</script>

<style scoped>
.checkStyle {
    background-color: #f7f7f7;
}

.checkStyle:before, .checkStyle:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: transparent;
    background-image: radial-gradient(farthest-side, transparent 6px, #f7f7f7 0);
    background-size: 15px 15px;

}

.checkStyle:before {
    top: -10px;
    background-position: -3px -5px, 0 0;
}

.checkStyle:after {
    bottom: -10px;
    background-position: -3px 2px, 0 0;
}

@media screen and (max-width: 991px) {
    .checkStyle {
        position: relative;
        width: calc(100% + 24px) !important;
        left: -12px;
    }
}
</style>