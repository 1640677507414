<template>
    <div class="shadow-sm bg-white">
        <div class="py-3">
            <p class="fs-5 fs-md-16 fs-sm-20 text-center text-uppercase">Подбор автомобиля</p>
            <div class="row m-0">

                <div class="mt-1">
                    <select class="form-select form-select-md border-0 border-bottom border-secondary px-0 fs-sm-15">
                        <option value="">Выберите марку</option>

                        <option value="1">Mercedes-Benz</option>
                    </select>
                </div>

                <div class="mt-3">
                    <select class="form-select form-select-md border-0 border-bottom border-secondary px-0 fs-sm-15">
                        <option value="">Выберите модель</option>

                        <option value="1">C-250</option>

                    </select>
                </div>

                <div class="d-flex mt-3">
                    <select class="form-select form-select-md border-0 border-bottom border-secondary px-0 fs-sm-15">
                        <option value="">Год от</option>

                        <option value="1">2000г</option>

                    </select>

                    <select
                        class="form-select form-select-md ms-2 border-0 border-bottom border-secondary px-0 fs-sm-15">
                        <option>Год до</option>

                        <option value="1">2022</option>
                    </select>
                </div>

                <div class="d-flex mt-3">
                    <div class="input-group">
                        <input id="min_price"
                               class="form-control border-0 border-bottom border-secondary px-0 fs-sm-15"
                               name="from_price"
                               placeholder="Цена от"
                               type="number">
                    </div>

                    <div class="ms-2 input-group">
                        <input id="max_price"
                               class="form-control border-0 border-bottom border-secondary px-0 fs-sm-15"
                               name="to_price"
                               placeholder="Цена до"
                               type="number">
                    </div>
                </div>

                <div class="text-center">
                    <div id="detailFilter" class="collapse detail__filter show">
                        <div class="mt-3">
                            <div id="panelsFilter6" class="accordion-header">
                                <div
                                    aria-controls="panelsItem6"
                                    aria-expanded="true"
                                    class="accordion-button collapsed form-select bg-transparent fs-6 border-0 border-bottom border-secondary py-2 ps-0 pe-2 fs-sm-15"
                                    data-bs-target="#panelsItem6" data-bs-toggle="collapse">
                                    Тип КПП
                                </div>
                                <div id="panelsItem6" aria-labelledby="panelsStay6" class="accordion-collapse collapse">
                                    <div class="accordion-body px-0 pt-0 fs-sm-15">
                                        <select class="form-select border-top-0 border-secondary w-100" multiple>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="mt-3">
                            <div id="panelsFilter7" class="accordion-header">
                                <div
                                    aria-controls="panelsItem7"
                                    aria-expanded="true"
                                    class="accordion-button collapsed form-select bg-transparent fs-6 border-0 border-bottom border-secondary py-2 ps-0 pe-2 fs-sm-15"
                                    data-bs-target="#panelsItem7" data-bs-toggle="collapse">
                                    Тип кузова
                                </div>
                            </div>
                            <div id="panelsItem7" aria-labelledby="panelsStay7" class="accordion-collapse collapse">
                                <div class="accordion-body px-0 pt-0">
                                    <select class="form-select border-top-0 border-secondary w-100" multiple>
                                        <option value="1">1</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div id="panelsFilter8" class="accordion-header">
                                <div
                                    aria-controls="panelsItem8"
                                    aria-expanded="true"
                                    class="accordion-button collapsed form-select bg-transparent fs-6 border-0 border-bottom border-secondary py-2 ps-0 pe-2 fs-sm-15"
                                    data-bs-target="#panelsItem8" data-bs-toggle="collapse">
                                    Тип двигателя
                                </div>
                            </div>
                            <div id="panelsItem8" aria-labelledby="panelsStay8" class="accordion-collapse collapse">
                                <div class="accordion-body px-0 pt-0 fs-sm-15">
                                    <select class="form-select border-top-0 border-secondary w-100" multiple>
                                        <option value="1">1</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div id="panelsFilter9" class="accordion-header">
                                <div
                                    aria-controls="panelsItem9"
                                    aria-expanded="true"
                                    class="accordion-button collapsed form-select bg-transparent fs-6 border-0 border-bottom border-secondary py-2 ps-0 pe-2 fs-sm-15"
                                    data-bs-target="#panelsItem9" data-bs-toggle="collapse">
                                    Привод
                                </div>
                            </div>
                            <div id="panelsItem9" aria-labelledby="panelsStay9" class="accordion-collapse collapse">
                                <div class="accordion-body px-0 pt-0 fs-sm-15">
                                    <select class="form-select  border-top-0 border-secondary w-100" multiple>
                                        <option value="1">1</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button aria-controls="detailFilter"
                            aria-expanded="false"
                            class="btn text-dark text-decoration-underline mt-2 d-lg-none detail__filter_button"
                            data-bs-target="#detailFilter" data-bs-toggle="collapse"
                            type="button">
                        <span class="show_parameters">Дополнительные параметры</span>
                        <span class="hide_parameters d-none">Скрыть дополнительные параметры</span>
                    </button>
                </div>

            </div>

            <div class="px-3 mt-3">
                <input id="filter_search_button" class="flex-grow-1 btn btn-dark fs-14 w-100 text-uppercase"
                       type="button" value="Подбор автомобиля">
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'FilterRbAuto',

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>