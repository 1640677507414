<template>
    <div class="border rounded">
        <div class="p-lg-4 p-3 pb-lg-0 pb-0">
            <p class="fs-5 text-uppercase mb-0">
                авто кредитование
            </p>
        </div>

        <div class="row m-0 p-lg-4 p-3">
            <div class="col-lg-4 ps-0 pe-lg-2">
                <label
                    class="form-label"
                    for="credit-request-line-name">
                    Ваше имя
                </label>

                <input
                    id="credit-request-line-name"
                    class="form-control"
                    type="text">
            </div>

            <div class="col-lg-4 px-lg-3 px-0 mt-lg-0 mt-3">
                <label
                    class="form-label"
                    for="credit-request-line-number">
                    Ваш номер
                </label>

                <input
                    id="credit-request-line-number"
                    class="form-control credit-phone-number-mask"
                    type="number">
            </div>

            <div class="col-lg-4 ps-lg-2 px-0 mt-lg-0 mt-3">
                <label class="form-label fs-14 d-none d-lg-block">&nbsp;</label>
                <button class="btn btn-warning w-100 text-uppercase" type="button">Подать заявку</button>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoCreditRequestBlock"
}
</script>

<style scoped>

</style>