<template>
    <div>
        <div class="infoCol d-flex flex-column hide-scroll">
            <TableReservation/>

            <div class="position-relative input-group">
                <input :placeholder="trans('menu_search')" class="form-control form-control-sm border-end-0 pe-0"
                       type="text">
                <span id="basic-addon1" class="input-group-text bg-white px-2">
                <i class="fa-solid fa-magnifying-glass text-secondary fs-14"></i>
            </span>
                <!--            <i class="fa-solid fa-magnifying-glass text-secondary fs-14 position-absolute top-50 end-0 translate-middle-y me-2"></i>-->
            </div>

            <div class="row m-0 mt-3 fs-14" itemscope="" itemtype="http://schema.org/Restaurant">
                <div class="col-12 px-0">
                    <div class="d-flex align-items-center justify-content-between">
                        <div v-if="menuCompanyRequisites['PHONE_1']"
                             class="d-flex align-items-center fw-bold">
                            <i class="fa-solid fa-phone-volume fs-14"></i>

                            <a :href="'tel:' + menuCompanyRequisites['PHONE_1']"
                               class="text-decoration-none fw-bold d-block ms-2 fs-6"
                               itemprop="telephone">
                                {{ formatPhone(menuCompanyRequisites['PHONE_1']) }}
                            </a>
                        </div>

                        <div class="d-flex align-items-center gap-4">
                            <div v-if="menuCompanyRequisites['PHONE_VIBER']"
                                 class="d-flex align-items-start fw-bold">
                                <a :href="'viber://chat/?number='+ menuCompanyRequisites['PHONE_VIBER']"
                                   target="_blank">
                                    <img :src="Viber" alt="Viber" title="Viber" width="20">
                                </a>
                            </div>

                            <div v-if="menuCompanyRequisites['PHONE_WHATSAPP']"
                                 class="d-flex align-items-start fw-bold">
                                <a :href="'https://api.whatsapp.com/send/?phone=' + menuCompanyRequisites['PHONE_WHATSAPP'] + '&text=Hi&app_absent=0'"
                                   target="_blank">
                                    <img :src="Whatsapp" alt="WhatsApp" title="WhatsApp" width="23">
                                </a>
                            </div>

                            <div v-if="menuCompanyRequisites['PHONE_TELEGRAM']"
                                 class="d-flex align-items-start fw-bold">
                                <a :href="'https://t.me/' + menuCompanyRequisites['PHONE_TELEGRAM']" target="_blank">
                                    <img :src="Telegram" alt="Telegram" title="Telegram" width="20">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-4 ps-0">
                    <div v-if="menuCompanyRequisites['SOC_FACEBOOK_LINK']" class="mt-3">
                        <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15 "
                                @click="openBlankUrl(menuCompanyRequisites['SOC_FACEBOOK_LINK'])">
                            <i class="fa-brands fa-facebook-square"></i>
                            <span>Facebook</span>
                        </button>
                    </div>

                    <div v-if="menuCompanyRequisites['SOC_INSTAGRAM_LINK']" class="mt-3">
                        <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15"
                                @click="openBlankUrl(menuCompanyRequisites['SOC_INSTAGRAM_LINK'])">
                            <i class="fa-brands fa-instagram"></i>
                            <span>Instagram</span>
                        </button>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-8 pe-0 ps-lg-4">
                    <div v-if="menuCompanyRequisites['WIFI_NAME']"
                         class="d-flex align-items-start fw-bold mt-3">
                        <i class="fa-solid fa-wifi pt-1"></i>
                        <div class="mb-0 ms-2 fw-bold">
                            <div class="d-flex align-items-center">
                                <small class="text-secondary d-block me-1 " style="width: 35px;">name:</small>
                                <span>{{ menuCompanyRequisites['WIFI_NAME'] }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <small class="text-secondary d-block me-1 " style="width: 35px;">pass:</small>
                                <span>{{ menuCompanyRequisites['WIFI_PASSWORD'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--            <div class="mt-4 pb-lg-0 pb-5">-->
            <div class="pb-lg-0 pb-5">
                <!--                <button class="btn btn-lg mb-0 fs-15 fw-bold w-100 rounded lh-1 reservationModal" :style="'background-color: '+mainColor"-->
                <!--                        data-bs-toggle="modal"-->
                <!--                        data-bs-target="#reservationModal">-->
                <!--                <span class="btn-shadow">-->
                <!--                    <i class="fa-solid fa-calendar-days me-1"></i>-->
                <!--                    {{ trans('menu_reservation_table') }}-->
                <!--                </span>-->

                <!--                </button>-->
                <p v-if="menuCompanyRequisites['VAT'] && menuCompanyRequisites['SERVICE_NAME']"
                   class="my-4 fs-14 text-secondary fw-light text-center lh-1">
                    {{ trans(menuCompanyRequisites['SERVICE_NAME']) }} +{{
                        menuCompanyRequisites['VAT']
                    }}%
                </p>

                <div
                    id="accordionExample"
                    :class="menuCompanyRequisites['VAT'] && menuCompanyRequisites['SERVICE_NAME']? 'accordion accordion-flush': 'accordion accordion-flush mt-3'">
                    <div class="accordion-item border-0">
                        <h2 id="headingOne" class="accordion-header">
                            <button
                                :class="menuCompanyRequisites['VAT'] && menuCompanyRequisites['SERVICE_NAME']? 'accordion-button collapsed px-0 pt-0': 'accordion-button collapsed px-0'"
                                aria-controls="collapseOne"
                                aria-expanded="false" data-bs-target="#collapseOne"
                                data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-route me-2"></i>{{ trans('menu_navigation') }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseOne" aria-labelledby="headingOne"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <a :href="appleMapLink" target="_blank">
                                        <img :src="AppleMap" alt="Apple Map" title="Apple Map"
                                             width="25">
                                    </a>

                                    <a href="https://www.google.com/maps/dir//41.744866,44.7537733/"
                                       target="_blank">
                                        <img :src="GoogleMap" alt="Google Map" title="Google Map"
                                             width="25">
                                    </a>

                                    <a href="https://yandex.ru/maps/?rtext=~55.659173,37.762848"
                                       target="_blank">
                                        <img :src="YandexMap" alt="Yandex Map" title="Yandex Map"
                                             width="25">
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0">
                        <h2 id="headingTwo" class="accordion-header">
                            <button aria-controls="collapseTwo" aria-expanded="false"
                                    class="accordion-button collapsed px-0" data-bs-target="#collapseTwo"
                                    data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-taxi me-2"></i>{{ trans('menu_order_taxi') }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseTwo" aria-labelledby="headingTwo"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <a href="https://bolt.eu/ka/" target="_blank">
                                        <img :src="BoltTaxi" alt="Bolt Taxi" title="Bolt Taxi"
                                             width="25">
                                    </a>

                                    <a href="https://taxi.yandex.com.ge/" target="_blank">
                                        <img :src="YandexTaxi" alt="Yandex Taxi" title="Yandex Taxi"
                                             width="25">
                                    </a>

                                    <a href="https://taximaxim.ge/ka/" target="_blank">
                                        <img :src="MaximTaxi" alt="Maxim Taxi" title="Maxim Taxi"
                                             width="25">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="menuCompanyRequisites['GLOVO_DELIVERY_URL'] || menuCompanyRequisites['WOLT_DELIVERY_URL'] || menuCompanyRequisites['ELVIS_DELIVERY_URL'] || menuCompanyRequisites['BOLT_DELIVERY_URL']"
                        class="accordion-item border-0">
                        <h2 id="headingThree" class="accordion-header">
                            <button aria-controls="collapseThree" aria-expanded="false"
                                    class="accordion-button collapsed px-0" data-bs-target="#collapseThree"
                                    data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-truck me-2"></i>{{ trans('menu_delivery') }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseThree" aria-labelledby="headingThree"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <a v-if="menuCompanyRequisites['GLOVO_DELIVERY_URL']"
                                       :href="menuCompanyRequisites['GLOVO_DELIVERY_URL']"
                                       target="_blank">
                                        <img :src="Glovo" alt="Glovo" title="Glovo" width="25">
                                    </a>

                                    <a v-if="menuCompanyRequisites['WOLT_DELIVERY_URL']"
                                       :href="menuCompanyRequisites['WOLT_DELIVERY_URL']"
                                       target="_blank">
                                        <img :src="Wolt" alt="Wolt" title="Wolt" width="25">
                                    </a>

                                    <a v-if="menuCompanyRequisites['ELVIS_DELIVERY_URL']"
                                       :href="menuCompanyRequisites['ELVIS_DELIVERY_URL']"
                                       target="_blank">
                                        <img :src="Elvis" alt="Elvis" title="Elvis" width="25">
                                    </a>

                                    <a v-if="menuCompanyRequisites['BOLT_DELIVERY_URL']"
                                       :href="menuCompanyRequisites['BOLT_DELIVERY_URL']"
                                       target="_blank">
                                        <img :src="BoltFood" alt="Bolt Food" title="Bolt Food" width="25">
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0">
                        <h2 id="headingFour" class="accordion-header">
                            <button aria-controls="collapseFour" aria-expanded="false"
                                    class="accordion-button collapsed px-0" data-bs-target="#collapseFour"
                                    data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-map-location-dot me-2"></i>{{
                                        trans('menu_look_map')
                                    }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseFour" aria-labelledby="headingFour"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <a :href="appleMapLink" target="_blank">
                                        <img :src="AppleMap" alt="Apple Map" title="Apple Map"
                                             width="25">
                                    </a>

                                    <a v-if="google" :href="google" class="cursor-pointer" target="_blank">
                                        <img :src="GoogleMap" alt="Google Map" title="Google Map"
                                             width="25">
                                    </a>

                                    <a v-if="!google && googleLink" :href="googleLink" class="cursor-pointer"
                                       target="_blank">
                                        <img :src="GoogleMap" alt="Google Map" title="Google Map"
                                             width="25">
                                    </a>

                                    <a v-if="yandex" :href="yandex" target="_blank">
                                        <img :src="YandexMap" alt="Yandex Map" title="Yandex Map"
                                             width="25">
                                    </a>

                                    <a v-if="!yandex && yandexLink" :href="yandexLink" target="_blank">
                                        <img :src="YandexMap" alt="Yandex Map" title="Yandex Map"
                                             width="25">
                                    </a>

                                    <a v-if="twogis"
                                       :href="twogis"
                                       target="_blank">
                                        <img :src="TwoGis" alt="2 Gis" title="2 Gis" width="25">
                                    </a>

                                    <a v-if="openStreet"
                                       :href="openStreet"
                                       target="_blank">
                                        <img :src="OpenStreet" alt="Open Street" title="Open Street"
                                             width="25">
                                    </a>

                                    <a v-if="wikimapia"
                                       :href="wikimapia"
                                       target="_blank">
                                        <img :src="WikiMapia" alt="WikiMapia" title="WikiMapia"
                                             width="25">
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0">
                        <h2 id="headingFive" class="accordion-header">
                            <button aria-controls="collapseFive" aria-expanded="false"
                                    class="accordion-button collapsed px-0" data-bs-target="#collapseFive"
                                    data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-share me-2"></i>{{ trans('menu_share') }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseFive" aria-labelledby="headingFive"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <a :href="shareProduct(1)">
                                        <img :src="Facebook" alt="Facebook" loading="lazy" title="Facebook" width="25">
                                    </a>
                                    <img :src="Instagram" alt="Instagram" loading="lazy" title="Instagram" width="25">
                                    <img :src="Twiter" alt="Twitter" loading="lazy" title="Twitter" width="25">
                                    <a :href="shareProduct(4)">
                                        <img :src="Telegram" alt="Telegram" loading="lazy" title="Telegram" width="25">
                                    </a>
                                    <a :href="shareProduct(5)">
                                        <img :src="Whatsapp" alt="WhatsApp" loading="lazy" title="WhatsApp" width="30">
                                    </a>
                                    <a :href="shareProduct(6)">
                                        <img :src="Viber" alt="Viber" loading="lazy" title="Viber" width="25">
                                    </a>
                                    <img :src="Messenger" alt="Messenger" loading="lazy" title="Messenger" width="25">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0">
                        <h2 id="headingSix" class="accordion-header">
                            <button aria-controls="collapseSix" aria-expanded="false"
                                    class="accordion-button collapsed px-0" data-bs-target="#collapseSix"
                                    data-bs-toggle="collapse" type="button">
                                <p class="mb-0 cursor-pointer fs-14">
                                    <i class="fa-solid fa-comment me-2"></i>{{ trans('menu_reviews') }}
                                </p>
                            </button>
                        </h2>
                        <div id="collapseSix" aria-labelledby="headingSix"
                             class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                    <span>booking.com</span>
                                    <span>google maps</span>
                                    <span>yandex maps</span>
                                    <span>facebook</span>
                                    <span>tripadwaiser</span>
                                    <span>2 gis</span>
                                    <span>allmenu</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!--                <div class="d-flex align-items-start fw-bold my-3 fs-14 cursor-pointer"-->
                <!--                     data-bs-target="#menuControl" data-bs-toggle="modal">-->
                <!--                    <i class="fa-solid fa-bars pt-1"></i>-->

                <!--                    <span class="ms-2">{{ trans('menu_menu_control') }}</span>-->
                <!--                </div>-->

                <div v-if="getCompanyAddress()" class="d-flex align-items-start fw-bold my-2 fs-14"
                     style="cursor:pointer;" @click="openMap()">
                    <i class="fa-solid fa-location-dot pt-1"></i>

                    <div class="d-flex flex-column ms-2">
                        <span>{{ trans('menu_address') }}</span>
                        <address class="mb-0 text-secondary" itemprop="streetAddress">
                            {{ getCompanyAddress() }}
                        </address>
                    </div>

                </div>

                <div v-if="menuCompanyRequisites['LOC_GOOGLE_MAP_EMBED']" class="map overflow-hidden"
                     v-html="localizedMap">
                </div>

            </div>

            <div class="mt-auto text-center">

                <button
                    class="btn p-0 text-center border-0 home-link text-decoration-none fs-14 text-secondary fw-light"
                    data-bs-target="#menuControl" data-bs-toggle="modal">
                    {{ trans('menu_menu_control') }}
                </button>

                <a class="d-block home-link text-body text-decoration-none pb-lg-0 pb-3" href="https://allmenu.ge/"
                   target="_blank">
                    &copy; AllMenu.Ge
                </a>
            </div>
        </div>
    </div>

</template>

<script>
import {t} from '@/lang/core/helpers'
import {mapGetters} from "vuex"
import _ from "lodash"
import TableReservation from "@/components/websites/allmenu/parts/TableReservation"
import AppleMap from "@/assets/websites/allmenu/img/apple-map.png"
import GoogleMap from "@/assets/websites/allmenu/img/google-map.png"
import YandexMap from "@/assets/websites/allmenu/img/yandex-map.png"
import TwoGis from "@/assets/websites/allmenu/img/2gis-map.png"
import WikiMapia from "@/assets/websites/allmenu/img/wikimapia.png"
import OpenStreet from "@/assets/websites/allmenu/img/openstreet-map.png"
import BoltTaxi from "@/assets/websites/allmenu/img/bolt-taxi.png"
import YandexTaxi from "@/assets/websites/allmenu/img/yandex-taxi.png"
import MaximTaxi from "@/assets/websites/allmenu/img/maxim-taxi.png"
import Glovo from "@/assets/websites/allmenu/img/glovo-icon.png"
import Wolt from "@/assets/websites/allmenu/img/wolt-icon.png"
import Elvis from "@/assets/websites/allmenu/img/elvis-icon.png"
import BoltFood from "@/assets/websites/allmenu/img/bolt-food-icon.png"
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Twiter from "@/assets/websites/allmenu/img/twiter-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import {data_get} from "@/core/helpers"


export default {
    name: "menu-information",
    components: {TableReservation},
    computed: {
        ...mapGetters([
            'menuCompany',
            'locale',
            'menuCompanyCategoriesAndProducts',
            'menuCompanyRequisites',
            'availableLocales',
            'menuCompanyDefaultLocale',
            'menuCompanySettings',
            'isMobileDevice'
        ]),

        mainColor() {
            let mainColor = this.menuCompanySettings.find(setting => setting.key === 'SITE_MAIN_COLOR')
            mainColor = mainColor ? data_get(mainColor, 'value') : '#ffa336'

            return mainColor
        },

        companyId() {
            if (!this.menuCompany) {
                return null
            }

            return this.menuCompany.id
        },

        companyAvailableLocales() {
            return this.availableLocales.map(availableLocale => {
                return availableLocale.toLowerCase()
            })
        },


        localizedMap() {
            if (!this.menuCompanyRequisites['LOC_GOOGLE_MAP_EMBED']) {
                return null
            }

            let map = this.menuCompanyRequisites['LOC_GOOGLE_MAP_EMBED']
            let mapLocale = "1s" + this.locale.toLowerCase()

            switch (this.locale.toLowerCase()) {
                case 'ka':
                    map = map.replace('1sru', mapLocale)
                    map = map.replace('1sen', mapLocale)
                    break
                case 'ru':
                    map = map.replace('1sen', mapLocale)
                    map = map.replace('1ska', mapLocale)
                    break
                case 'en':
                    map = map.replace('1ska', mapLocale)
                    map = map.replace('1sru', mapLocale)
                    break
                default:
                    break
            }

            return map
        },

        wikimapia() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://wikimapia.org/#lang=en&lat=" + this.menuCompanyRequisites['COORDINATE_YY'] + "&lon=" + this.menuCompanyRequisites['COORDINATE_XX'] + "&z=17&m=w"
        },

        openStreet() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://www.openstreetmap.org/search?whereami=1&query=" + this.menuCompanyRequisites['COORDINATE_YY'] + "%2C" + this.menuCompanyRequisites['COORDINATE_XX'] + "#map=19/" + this.menuCompanyRequisites['COORDINATE_XX'] + "/" + this.menuCompanyRequisites['COORDINATE_YY']
        },

        twogis() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://2gis.ru/?m=" + this.menuCompanyRequisites['COORDINATE_XX'] + "%2C" + this.menuCompanyRequisites['COORDINATE_YY'] + "%2F14.52"
        },

        yandex() {
            if (!this.menuCompanyRequisites['LOC_YANDEX_MAP_LINK']) {
                return null
            }

            return this.menuCompanyRequisites['LOC_YANDEX_MAP_LINK']
        },

        yandexLink() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }
            return "https://yandex.ru/maps/?ll=" + this.menuCompanyRequisites['COORDINATE_XX'] + "%2C" + this.menuCompanyRequisites['COORDINATE_YY'] + "&mode=whatshere&whatshere%5Bpoint%5D=44.802176%2C41.699935&whatshere%5Bzoom%5D=19&z=19"
        },

        google() {
            if (!this.menuCompanyRequisites['LOC_GOOGLE_MAP_LINK']) {
                return null
            }

            return this.menuCompanyRequisites['LOC_GOOGLE_MAP_LINK']
        },

        googleLink() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://www.google.com/maps/@" + this.menuCompanyRequisites['COORDINATE_YY'] + "," + this.menuCompanyRequisites['COORDINATE_XX'] + ",17z"
        },

        appleMapLink() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://maps.apple.com/place?ll=" + this.menuCompanyRequisites['COORDINATE_YY'] + "%2F14.52," + this.menuCompanyRequisites['COORDINATE_XX'] + "&q=" + this.menuCompany.name
        },
    },

    data() {
        return {
            BoltTaxi,
            YandexTaxi,
            MaximTaxi,
            AppleMap,
            GoogleMap,
            YandexMap,
            TwoGis,
            WikiMapia,
            OpenStreet,
            Glovo,
            Wolt,
            Elvis,
            BoltFood,
            Facebook,
            Instagram,
            Telegram,
            Twiter,
            Whatsapp,
            Viber,
            Messenger,
        }
    },

    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        trans(key) {
            return t(key)
        },

        getCategoryName(category) {
            let translation = _.filter(category.translations, {locale: this.locale})

            return translation[0].name
        },

        pushRoute(category) {
            this.$router.push({
                name: 'menu-category',
                params: {
                    locale: this.locale,
                    companyKey: this.menuCompany.key,
                    slug: category.slug
                }
            })
        },

        openBlankUrl(url) {
            window.open(url, '_blank')
        },

        changeLocale(locale) {
            if (!this.companyAvailableLocales.includes(locale)) {
                return
            }

            let oldLocale = window.localStorage.getItem('locale') || 'ka'
            let path = this.$route.fullPath.replace('/' + oldLocale, '/' + locale)

            window.localStorage.setItem('locale', locale)
            this.$store.commit('setLocale', locale)

            this.$router.push(path)

            if (this.$route.meta.title.includes('##COMPANY_NAME##')) {
                let localeCompany = _.filter(this.menuCompany.translations, {locale: locale})

                if (!localeCompany.length) {
                    document.title = this.$route.meta.title.replace('##COMPANY_NAME##', this.menuCompany.translations[0].name)
                    return
                }

                let companyName = _.filter(this.menuCompany.translations, {locale: locale})[0].name

                document.title = this.$route.meta.title.replace('##COMPANY_NAME##', companyName)
            }
        },

        getCompanyAddress() {
            let locale = this.locale || 'ka'
            let locAddress = this.menuCompanyRequisites['LOC_ADDRESS'] || []
            let locCity = this.menuCompanyRequisites['LOC_CITY'] || []
            let locRegion = this.menuCompanyRequisites['LOC_REGION'] || []

            return [
                locRegion[locale] || null,
                locCity[locale] || null,
                locAddress[locale] || null
            ].filter(Boolean).join(', ')
        },

        basketButton() {
            let basketButton = document.getElementById('basketButton')
            let basketButtonicon = document.getElementById('basketButtonIcon')

            if (basketButton.getAttribute('opened') === 'true') {
                basketButton.setAttribute('opened', false)
                basketButtonicon.classList.add('fa-chevron-right')
                basketButtonicon.classList.remove('fa-xmark')
            } else {
                basketButton.setAttribute('opened', true)
                basketButtonicon.classList.remove('fa-chevron-right')
                basketButtonicon.classList.add('fa-xmark')
            }
        },

        formatPhone(phone) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
        },

        getSocialNetwork(id) {
            let socialNetwork = {
                1: 'FACEBOOK',
                2: 'INSTAGRAM',
                3: 'TWITTER',
                4: 'TELEGRAM',
                5: 'WHATSAPP',
                6: 'VIBER'
            }

            return socialNetwork[id]
        },

        shareProduct(id) {
            let locale = this.$route.params.locale
            let companyKey = this.$route.params.companyKey

            let selfUrl = 'https://' + window.location.hostname + '/' + locale + '/' + companyKey

            let url = this.getSocialNetwork(id) === 'WHATSAPP' ?
                'https://web.whatsapp.com/send?text=' + selfUrl :
                (this.getSocialNetwork(id) === 'VIBER' ?
                    'viber://forward?text=' + selfUrl :
                    (this.getSocialNetwork(id) === 'TELEGRAM' ?
                        'https://t.me/share/url?url=' + selfUrl
                        : (
                            this.getSocialNetwork(id) === 'FACEBOOK' ?
                                'https://www.facebook.com/sharer/sharer.php?u=' + selfUrl
                                : '#'
                        )))

            if (this.isMobileDevice) {
                url = this.getSocialNetwork(id) === 'WHATSAPP' ?
                    'whatsapp://send?text=' + selfUrl :
                    (this.getSocialNetwork(id) === 'VIBER' ?
                        'viber://forward?text=' + selfUrl :
                        (this.getSocialNetwork(id) === 'TELEGRAM' ?
                            'https://t.me/share/url?url=' + selfUrl
                            : (
                                this.getSocialNetwork(id) === 'FACEBOOK' ?
                                    'https://www.facebook.com/sharer/sharer.php?u=' + selfUrl
                                    : '#'
                            )))
            }

            return url
        }
    }
}
</script>

<style scoped>
.reservationModal:hover {
    border-color: transparent;
}

.btn-shadow {
    text-shadow: 0 3px 3px rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 992px) {
    .map {
        position: relative;
        left: -12px;
        width: calc(100% + 24px);
    }
}
</style>
