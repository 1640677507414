<template>
    <div>
        <div id="carouselExampleFade" class="carousel slide carousel-fade">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img :src="CarPic1" alt="" class="d-block w-100">
                </div>
                <div class="carousel-item">
                    <img :src="CarPic2" alt="" class="d-block w-100">
                </div>
                <div class="carousel-item">
                    <img :src="CarPic3" alt="" class="d-block w-100">
                </div>
            </div>
            <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </button>

            <div class="carousel-indicators gap-3 m-0 position-static">
                <button aria-current="true" aria-label="Slide 1" class="active" data-bs-slide-to="0"
                        data-bs-target="#carouselExampleFade" type="button">
                    <img :src="CarPic1" alt="" class="d-block" width="90">
                </button>
                <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselExampleFade" type="button">
                    <img :src="CarPic2" alt="" class="d-block" width="90">
                </button>
                <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselExampleFade" type="button">
                    <img :src="CarPic3" alt="" class="d-block" width="90">
                </button>
            </div>
        </div>


    </div>
</template>

<script>
import CarPic1 from "@/assets/websites/auto/img/car-pic1.jpg"
import CarPic2 from "@/assets/websites/auto/img/car-pic2.jpg"
import CarPic3 from "@/assets/websites/auto/img/car-pic3.jpg"

export default {
    name: "AutoCarousel",

    data() {
        return {
            CarPic1,
            CarPic2,
            CarPic3
        }
    }
}
</script>

<style>

.carousel-indicators {
    width: auto !important;
    height: auto !important;
    margin-right: unset !important;
    margin-left: unset !important;
    text-indent: unset !important;
}
</style>