<template>
    <div id="tradeinmodal" aria-hidden="true" aria-labelledby="tradeinmodal" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-white">
                <div class="modal-header border-bottom-0 shadow-sm" style="background: #f1f1f1;">
                    <h5 id="exampleModalLabel" class="modal-title fs-14 text-uppercase">Автомобиль на обмен</h5>
                    <button aria-label="Close" class="btn-close pt-0" data-bs-dismiss="modal" type="button">&#10006;
                    </button>
                </div>
                <div class="modal-body p-3">
                    <div class="row w-100 pb-3 p-0 m-0 border-bottom">
                        <div class="col-lg-4 col-12 p-0">

                            <img alt=""
                                 class="w-100 h-100 img-fluid" loading="lazy"
                                 src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg">

                        </div>
                        <div class="col-lg-8 mt-lg-0 mt-3 px-lg-3 px-0">
                            <div class="d-flex flex-column justify-content-between h-100 text-center">
                                <h1 class="fs-5 fw-bold text-danger">Лучшее предложение на</h1>
                                <h1 class="fs-5 text-center">
                                    Ford
                                    <span class="text-secondary">
                                        Focus
                                    </span>
                                </h1>

                                <div>
                                    <span class="fs-5 fw-bold">С выгодой до 100 000 &#8381;</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row px-0 p-0 m-0 pt-3 bp-3">
                        <div class="row m-0 px-0">
                            <div class="col-lg-6 ps-lg-0 pe-lg-2 px-0">
                                <select class="form-select">
                                    <option selected value="1">Выберите марку</option>

                                    <option value="2">1</option>
                                </select>
                            </div>
                            <div class="col-lg-6 pe-lg-0 ps-lg-2 px-0 mt-lg-0 mt-3">
                                <select class="form-select">
                                    <option selected value="">Выберите модель</option>
                                </select>
                            </div>
                            <div class="col-lg-6 ps-lg-0 pe-lg-2 px-0 mt-3">
                                <select class="form-select">
                                    <option selected value="">Год выпуска автомобиля</option>
                                </select>
                            </div>
                            <div class="col-lg-6 pe-lg-0 ps-lg-2 px-0 mt-3">
                                <div class="input-group">
                                    <input class="form-control" placeholder="Укажите пробег" type="number">
                                </div>
                            </div>
                        </div>
                        <div class="input-form position-relative p-0 mt-3">
                            <label class="input-title nameGet">ФИО</label>
                            <input class="form-control position-relative" type="text">
                        </div>
                        <div class="input-form position-relative p-0 mt-3">
                            <label class="input-title number-error-place">Телефон</label>
                            <input class="form-control position-relative"
                                   placeholder="+7 (___) ___-__-__"
                                   type="text">
                        </div>
                        <div class="form-check mt-3">
                            <input id="flexCheckChecked" checked class="form-check-input" type="checkbox" value="">
                            <label class="form-check-label fs-14 fs-sm-12" for="flexCheckChecked">
                                Даю согласие на обработку персональных данных
                            </label>
                        </div>
                        <div class="btn btn-danger w-100 text-uppercase mt-lg-3 mt-2">
                            Отправить
                            заявку
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TradeInPopup'
}
</script>

<style scoped>

</style>