<template>
    <div class="row row-cols-lg-5 row-cols-md-4 row-cols-3 m-0">
        <div class="col mb-lg-3 mb-2 px-2 category__loader">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 category__loader">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 category__loader">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 category__loader d-none d-md-block">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 category__loader d-none d-lg-block">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderCategories'
}
</script>

<style scoped>

@media screen and (min-width: 1400px) {
    .category__loader {
        height: 244.8px;
    }
}

@media screen and (max-width: 1399px) {
    .category__loader {
        height: 208.8px;
    }
}

@media screen and (max-width: 1200px) {
    .category__loader {
        height: 172.8px;
    }
}

@media screen and (max-width: 991px) {
    .category__loader {
        height: 164px;
    }
}


@media screen and (max-width: 760px) {
    .category__loader {
        height: 109px;
    }

    .category__loader:nth-child(3n+1) {
        padding-right: 4px !important;
    }

    .category__loader:nth-child(3n+3) {
        padding-left: 4px !important;
    }

    .category__loader:is(:nth-child(2), :nth-child(5)) {
        padding-inline: 4px !important;
    }
}
</style>