<template>
    <div>
        <div class="container-fluid mt-lg-3 mt-2">
            <div class="container p-2 d-flex justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent d-flex align-items-center mb-0 px-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }" class="text-dark fs-sm-14">
                                Главная
                            </router-link>
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14">
                            <a class="text-dark fs-sm-14" href="/cars">Автомобили</a>
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14">
                            Ford
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14 active">
                            Focus
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container p-0">
                <div class="row m-0 flex-wrap-reverse">

                    <div class="col-lg-7 mt-lg-0 mt-3 px-lg-2 px-0">
                        <CarPicSlide/>
                    </div>

                    <div class="col-lg-5 col-12 px-lg-2 px-0">
                        <div class="sticky-lg-top" style="top: 30px">
                            <CarInfo/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container p-0">
                <div class="row m-0 mt-3">
                    <div class="col-lg-9 p-0">
                        <div class="row m-0">
                            <div class="col-lg-6 px-2">
                                <table class="table table-striped mb-0 shadow-sm">
                                    <tbody>
                                    <tr class="p-1 fs-sm-14">
                                        <td>Год выпуска:</td>
                                        <td>2008 г</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Пробег:</td>
                                        <td>180 462 км</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Коробка:</td>
                                        <td>Механическая</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Привод:</td>
                                        <td>Передний</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Объем двигателя:</td>
                                        <td>1.6 см³ 115 л.с. Дизель</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-lg-6 px-2">
                                <table class="table table-striped mb-0 shadow-sm">
                                    <tbody>
                                    <tr class="d-lg-none">
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Тип кузова:</td>
                                        <td>Седан</td>
                                    </tr>

                                    <tr class="p-1 tr-none fs-sm-14">
                                        <td>ПТС:</td>
                                        <td>Оригинал</td>
                                    </tr>

                                    <tr class="p-1 tr-none fs-sm-14">
                                        <td>Руль:</td>
                                        <td>Левый</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Цвет:</td>
                                        <td>Черный</td>
                                    </tr>

                                    <tr class="p-1 fs-sm-14">
                                        <td>Обмен:</td>
                                        <td>Возможен</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="px-2">
                            <CarCharacteristics/>
                        </div>

                        <div class="row m-0 mt-3 px-2">
                            <div class="col-lg-7 p-lg-3 p-2 shadow-sm bg-white">
                                <h5 class="fs-sm-16">ЮРИДИЧЕСКАЯ ЧИСТОТА</h5>

                                <div class="row m-0 mt-3 fs-sm-14">
                                    <p class="col-lg-6 px-0">
                                        <i class="fa fa-check-circle fs-12 me-2 text-danger"></i>
                                        Не находится в залоге
                                    </p>

                                    <p class="col-lg-6 px-0">
                                        <i class="fa fa-check-circle fs-12 me-2 text-danger"></i>
                                        Не находится в розыске
                                    </p>

                                    <p class="col-lg-6 px-0">
                                        <i class="fa fa-check-circle fs-12 me-2 text-danger"></i>
                                        Не учавствует в спорах
                                    </p>

                                    <p class="col-lg-6 px-0 mb-0">
                                        <i class="fa fa-check-circle fs-12 me-2 text-danger"></i>
                                        Отсутствуют ограничения на регистрационные действия
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-5 p-0 mt-lg-0 mt-3">
                                <div
                                    class="d-flex align-items-center justify-content-between bg-white shadow-sm h-100 ms-lg-3 p-lg-3 p-2">
                                    <img :src="Guarantee" alt="" height="80" loading="lazy" width="80">
                                    <div class="ms-3">
                                        <p class="fs-4 fs-sm-16 p-0 m-0 text-secondary fw-bold">1 год гарантии</p>
                                        <p class="fs-sm-12 mb-0">
                                            Предоставляется гарантия на КПП и двигатель
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 px-2 mt-lg-0 mt-3">
                        <ExpressCreditRbAuto/>

                        <TradeInRbAuto/>
                    </div>
                </div>
            </div>
        </div>


        <TradeInPopup/>

        <CallRequestLine/>

        <InfoLinks/>

        <ConditionsAndGuarantees/>
    </div>
</template>

<script>
import CarPicSlide from "@/components/websites/rb-auto/parts/view-car-blocks/CarPicSlide.vue";
import CarInfo from "@/components/websites/rb-auto/parts/view-car-blocks/CarInfo.vue";
import ExpressCreditRbAuto from "@/components/websites/rb-auto/parts/view-car-blocks/ExpressCreditBlock.vue";
import TradeInRbAuto from "@/components/websites/rb-auto/parts/TradeInBanner.vue";
import CarCharacteristics from "@/components/websites/rb-auto/parts/view-car-blocks/CarCharacteristics.vue";
import Guarantee from "@/assets/websites/rb-auto/img/guarantee.png"
import CallRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CallRequestLine.vue";
import InfoLinks from "@/components/websites/rb-auto/parts/InfoLinks.vue";
import ConditionsAndGuarantees from "@/components/websites/rb-auto/parts/ConditionsAndGuarantees.vue";
import TradeInPopup from "@/components/websites/rb-auto/parts/popups-and-blocks/TradeInPopup.vue";

export default {
    name: 'ViewCarRbAuto',
    components: {
        TradeInPopup,
        ConditionsAndGuarantees,
        InfoLinks,
        CallRequestLine,
        CarCharacteristics,
        TradeInRbAuto,
        ExpressCreditRbAuto,
        CarInfo,
        CarPicSlide
    },

    data() {
        return {
            Guarantee
        }
    }
}
</script>

<style scoped>

</style>