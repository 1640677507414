<template>
	<div>
		<div class="container-fluid px-0 mt-3">
			<div class="container px-2">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb fs-14 mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'home' }">{{
								trans("home")
							}}</router-link>
						</li>

						<li aria-current="page" class="breadcrumb-item active">
							{{ trans("sale") }}
						</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="container-fluid px-0 mt-3">
			<div class="container px-0">
				<div class="row row-cols-lg-5 row-cols-md-3 row-cols-2 m-0">
					<router-link
						v-for="part in carPartsDiscountedCarParts"
						:key="part.id"
						:to="{
							name: 'view-product',
							params: { id: part.id },
						}"
						class="col text-dark px-2 mb-3 part"
					>
						<div class="shadow-sm border bg-white rounded p-3">
							<div>
								<img
									v-if="part.images && part.images.length"
									:src="getProductImage(part)"
									alt=""
									class="w-100 img-fluid rounded-top part__pic"
								/>
								<img
									v-else
									:src="NoImage"
									alt=""
									class="w-100 img-fluid part__pic"
								/>
							</div>

							<p class="text-center mt-3 mb-0 part__name">
								{{ getTranslatedPropertyByLocale(part, "name", locale) }}
							</p>

							<p class="text-center mt-2 mb-0">
								{{ part.price_discount || part.price }} ₾
							</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CarPart from "@//assets/websites/get-parts/img/part.png";
import { t, getTranslatedPropertyByLocale } from "@/lang/core/helpers";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
	name: "SaleGP",

	computed: {
		...mapGetters(["carPartsDiscountedCarParts"]),
	},

	methods: {
		getTranslatedPropertyByLocale,

		trans(key) {
			return t(key);
		},

		getImageBySize(images, size) {
			let filterImage = _.filter(images, { image_type: size });
			return filterImage.length ? filterImage[0].url : null;
		},

		getProductImage(part) {
			if (!part.images.length) {
				return null;
			}

			return this.getImageBySize(part.images, "MEDIUM");
		},
	},

	data() {
		return {
			CarPart,
		};
	},
};
</script>

<style scoped>
@media screen and (min-width: 1400px) {
	.sale__pic {
		height: 88px;
	}
}

@media screen and (max-width: 1399px) {
	.sale__pic {
		height: 74px;
	}
}

@media screen and (max-width: 1200px) {
	.sale__pic {
		height: 58px;
	}
}

@media screen and (max-width: 991px) {
	.sale__pic {
		height: 78px;
	}
}

@media screen and (max-width: 760px) {
	.sale__pic {
		height: 57px;
	}
}
</style>
