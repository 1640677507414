<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item">
                            <router-link :to="{name:'news'}">{{ trans('news') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            სტატიის სახელი
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 my-3">
            <div class="container px-0">
                <div class="row m-0">
                    <div class="col-lg-9 col-md-7 px-2">
                        <div class="col-lg-7 col-12 mx-auto">
                            <img :src="ArticlePic" alt="" class="w-100 rounded">
                        </div>

                        <h1 class="fs-2 mt-3 mb-0">
                            10%-იან ფასდაკლება Chevrolet Cruze- ს ავტონაწილებზე.
                        </h1>

                        <p class="fw-light fs-15 mt-3 mb-0">
                            აუცილებელი პირობები :
                            შეუკვეთე შენით
                            დაამატე კალათაში სასურველი ავტონაწილები
                            გადახდამდე გამოიყენე ვაუჩერი - CRUZE
                            მიუთითეთ სად გნებავთ ავტონაწილების მიტანა
                            გადაიხადეთ სასურველი გადახდის მეთოდით
                            საჩუქრად მიიღეთ სწრაფი ადგილზე მიტანის სერვისი თბილისსა და რუსთავის მაშტაბით.
                            ისარგებლეთ ავტონაწილების რეგიონებში გაგზავნის სერვისით (გადაზიდვის ფასი და პირობები
                            შეატანხმეთ ოპერატორთან)
                        </p>
                    </div>

                    <div class="col-lg-3 col-md-5 px-2 mt-md-0 mt-3">
                        <h2 class="fs-4 text-center mt-3 mb-0">
                            {{ trans('last_added') }}
                        </h2>

                        <div v-for="n in 8" :key="n" class="mt-3">
                            <router-link :to="{name: 'article'}" class="d-block shadow-sm rounded overflow-hidden">
                                <div>
                                    <img :src="ArticlePic" alt="" class="w-100">
                                </div>

                                <p class="fw-600 text-center text-dark bg-white border-top fs-sm-14 p-2 mb-0">
                                    10%-იან ფასდაკლება Chevrolet Cruze- ს ავტონაწილებზე.
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArticlePic from "@/assets/websites/get-parts/img/article-pic.png"
import {t} from "@/lang/core/helpers";

export default {
    name: 'ArticleGP',

    methods: {
        trans(key) {
            return t(key)
        }
    },


    data() {
        return {
            ArticlePic
        }
    }
}
</script>

<style scoped>

</style>