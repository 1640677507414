<template>
    <footer class="sticky-bottom bg-light">
        <!--        <div class="d-flex align-items-center justify-content-between px-3">-->
        <!--            <div>-->
        <!--                <span v-if="menuCompanyRequisites['VAT']">VAT +{{ menuCompanyRequisites['VAT'] }}%</span>-->
        <!--            </div>-->

        <!--            <div>-->
        <!--                <div class="btn-group dropup">-->
        <!--                    <button type="button" class="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">-->
        <!--                        <i class="fa-solid fa-ellipsis-vertical"></i>-->
        <!--                    </button>-->
        <!--                    <ul class="dropdown-menu border-0 shadow">-->
        <!--                        <li>-->
        <!--                            <button class="dropdown-item" v-if="menuCompanyRequisites['SOC_INSTAGRAM_LINK']"-->
        <!--                                    @click="openBlankUrl(menuCompanyRequisites['SOC_INSTAGRAM_LINK'])">Instagram-->
        <!--                            </button>-->
        <!--                        </li>-->

        <!--                        <li>-->
        <!--                            <button class="dropdown-item" v-if="menuCompanyRequisites['SOC_FACEBOOK_LINK']"-->
        <!--                                    @click="openBlankUrl(menuCompanyRequisites['SOC_FACEBOOK_LINK'])">Facebook-->
        <!--                            </button>-->
        <!--                        </li>-->
        <!--                        <li>-->
        <!--                            <button class="dropdown-item" v-if="menuCompanyRequisites['LOC_GOOGLE_MAP_LINK']"-->
        <!--                                    @click="openBlankUrl(menuCompanyRequisites['LOC_GOOGLE_MAP_LINK'])">MAP-->
        <!--                            </button>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->

        <!--                <div class="btn-group dropup">-->
        <!--                    <button type="button" class="btn btn-light text-uppercase d-flex align-items-center gap-2"-->
        <!--                            data-bs-toggle="dropdown"-->
        <!--                            aria-expanded="false">-->
        <!--                        <img :src="localeIcons[locale]" alt="language"/>-->
        <!--                        <span>{{ locale }}</span>-->
        <!--                    </button>-->
        <!--                    <ul class="dropdown-menu border-0 shadow" style="min-width: unset;">-->
        <!--                        <li>-->
        <!--                            <button class="dropdown-item text-uppercase d-flex align-items-center gap-2 py-2"-->
        <!--                                    v-for="(l, index) in locales" :key="index"-->
        <!--                                    @click="changeLocale(l)">-->
        <!--                                <img :src="localeIcons[l]" alt="language"/>-->
        <!--                                <span>{{ l }}</span>-->
        <!--                            </button>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->
        <!--            </div>-->

        <!--        </div>-->
    </footer>
</template>

<script>
import {mapGetters} from "vuex"
import langEN from "@/assets/websites/allmenu/img/flags/en.png"
import langRU from "@/assets/websites/allmenu/img/flags/ru.png"
import langKA from "@/assets/websites/allmenu/img/flags/ka.png"

export default {
    name: "RestaurantFooter",

    computed: {
        ...mapGetters([
            'menuCompany',
            'menuCompanyRequisites',
            'locale'
        ])
    },

    data() {
        return {
            locales: [
                'en',
                'ru',
                'ka'
            ],
            localeIcons: {
                en: langEN,
                ru: langRU,
                ka: langKA
            }
        }
    },

    methods: {
        openBlankUrl(url) {
            window.open(url, '_blank')
        },

        changeLocale(locale) {
            if (!this.locales.includes(locale)) {
                return
            }

            let oldLocale = window.localStorage.getItem('locale') || 'ka'
            let path = this.$route.fullPath.replace('/' + oldLocale, '/' + locale)

            window.localStorage.setItem('locale', locale)
            this.$store.commit('setLocale', locale)

            this.$router.push(path)
        }
    }
}
</script>