// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Favicon from "@/assets/websites/get-parts/img/favicon.png"
import Index from "@/components/websites/get-parts/pages/Index.vue"
import Models from "@/components/websites/get-parts/pages/Models.vue"
import ProductByModel from "@/components/websites/get-parts/pages/ProductByModel.vue"
import ViewProduct from "@/components/websites/get-parts/pages/ViewProduct.vue"
import AboutUs from "@/components/websites/get-parts/pages/AboutUs.vue"
import Contacts from "@/components/websites/get-parts/pages/Contacts.vue"
import AutoParts from "@/components/websites/get-parts/pages/AutoParts.vue"
import Cart from "@/components/websites/get-parts/pages/Cart.vue"
import News from "@/components/websites/get-parts/pages/News.vue"
import Article from "@/components/websites/get-parts/pages/Article.vue"
import Favourites from "@/components/websites/get-parts/pages/Favourites.vue"
import Sale from "@/components/websites/get-parts/pages/Sale.vue"
import { createRouter, createWebHistory } from 'vue-router'


// Vue.use(VueRouter)

const favIcon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index,
        meta: {
            title: 'Get Parts',
            favicon: favIcon
        }
    },
    {
        path: '/:locale',
        name: 'home-locale',
        component: Index,
        meta: {
            title: 'Get Parts',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/view-product/:id',
        name: 'view-product',
        component: ViewProduct,
        meta: {
            title: 'Get Parts - Product',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/about-us',
        name: 'about-us',
        component: AboutUs,
        meta: {
            title: 'Get Parts - About us',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            title: 'Get Parts - Contacts',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/parts',
        name: 'parts',
        component: AutoParts,
        meta: {
            title: 'Get Parts - Auto parts',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/cart',
        name: 'cart',
        component: Cart,
        meta: {
            title: 'Get Parts - Cart',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/news',
        name: 'news',
        component: News,
        meta: {
            title: 'Get Parts - News',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/article',
        name: 'article',
        component: Article,
        meta: {
            title: 'Get Parts - Article',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/mark/:mark',
        name: 'mark',
        component: Models,
        meta: {
            title: 'Get Parts - Marks',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/mark/:mark/model/:model',
        name: 'products-by-model',
        component: ProductByModel,
        meta: {
            title: 'Get Parts - Auto parts',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/favourites',
        name: 'favourites',
        component: Favourites,
        meta: {
            title: 'Get Parts - Favourites',
            favicon: favIcon
        }
    },
    {
        path: '/:locale/sale',
        name: 'sale',
        component: Sale,
        meta: {
            title: 'Get Parts - Sale',
            favicon: favIcon
        }
    },
].map(route => ({
    ...route,
    meta: {
        ...route.meta,
        translationKey: 'get-parts'
    }
}))

// const router = new VueRouter({
//     mode: 'history',
//     routes,
//     scrollBehavior
// })

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
})

export default router
