<template>
    <div>
        <header class="container-fluid px-0 sticky-top">
            <HeaderLanding/>
        </header>

        <main class="container-fluid px-0 my-lg-5 my-3 pb-lg-5 pb-3">
            <div class="container px-lg-0">
                <div class="row row-cols-lg-5 row-cols-md-3 row-cols-2 g-3">
                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="StrawberryPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მარწყვის ნაყინი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="PearPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მხლის ტარტი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="StrawberryPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მარწყვის ნაყინი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="PearPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მხლის ტარტი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="StrawberryPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მარწყვის ნაყინი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="PearPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მხლის ტარტი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="StrawberryPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მარწყვის ნაყინი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="col">
                        <router-link :to="{ name: 'view-receipt' }" class="d-block">
                            <div class="card overflow-hidden border-0">
                                <div class="card-body p-0 position-relative">
                                    <img :src="PearPic" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-center">
                                        მხლის ტარტი
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </main>

        <footer class="container-fluid px-0">
            <FooterLanding/>
        </footer>
    </div>
</template>

<script>
import HeaderLanding from "@/components/websites/allmenu/landing/landing-parts/HeaderLanding.vue";
import FooterLanding from "@/components/websites/allmenu/landing/landing-parts/FooterLanding.vue";
import StrawberryPic from "@/assets/websites/allmenu/img/strawberry-pic.jpg"
import PearPic from "@/assets/websites/allmenu/img/pear-pic.jpg"

export default {
    name: "DeseartMenu",

    components: {
        FooterLanding,
        HeaderLanding
    },

    data() {
        return {
            StrawberryPic,
            PearPic
        }
    }
}
</script>

<style scoped>
.card img {
    filter: brightness(.7);
}

.card .card-body {
    transition: .3s;
}

.card:hover .card-body {
    transform: scale(1.15);
}

.card img {
    height: 150px;
    object-fit: cover;
}
@media screen and (max-width: 576px){
    .card img {
        height: 100px;
    }
}
</style>