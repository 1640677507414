// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Index from "@/components/websites/moda/Index"
import FiltredProducts from "@/components/websites/moda/FiltredProducts"
import ViewProduct from "@/components/websites/moda/ViewProduct";
import Favourites from "@/components/websites/moda/Favourites";
import Order from "@/components/websites/moda/Order";
import OrderFinalyStep from "@/components/websites/moda/OrderFinalyStep.vue";
import Privacy from "@/components/websites/moda/Privacy";
import Brand from "@/components/websites/moda/Brand";
import Categories from "@/components/websites/moda/Categories";
import Catalog from "@/components/websites/moda/Catalog";
import Favicon from "@/assets/websites/moda/img/icon.jpg"
import { createRouter, createWebHistory } from 'vue-router'
// Vue.use(VueRouter)

const favIcon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/filtred',
        name: 'filtred',
        component: FiltredProducts,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/product',
        name: 'view-product',
        component: ViewProduct,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/order',
        name: 'order',
        component: Order,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/order-final',
        name: 'order-final',
        component: OrderFinalyStep,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/brand',
        name: 'brand',
        component: Brand,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/categories',
        name: 'categories',
        component: Categories,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

    {
        path: '/catalog',
        name: 'catalog',
        component: Catalog,
        meta: {
            title: 'Your-fashion',
            favicon: favIcon
        }
    },

].map(route => ({...route,
    meta: {
        ...route.meta,
        translationKey: 'moda'
    }
}))

// const router = new VueRouter({
//     mode: 'history',
//     routes
// })

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
