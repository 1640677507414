<template>
    <div class="container-fluid px-0">
        <div class="container px-0">
            <h1 class="text-uppercase mt-lg-5 mt-3 px-lg-0 px-2">
                Your Wish List
            </h1>

            <div class="row row-cols-lg-5 row-cols-sm-3 row-cols-2 favourites-products-row mt-3">
                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <div>
                        <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                                title="product name">

                            <div class="position-absolute top-0 end-0 lh-1">
                                <button class="btn border-0 lh-1 removeFromFav" title="Remove from favourites"
                                        type="button">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </router-link>

                        <div class="mt-2">
                            <p class="fw-bold mb-0">
                                BALMAIN
                            </p>

                            <p class="mb-0 fs-14">
                                SNEAKERS B-RUNNER
                            </p>

                            <p class="mt-2 mb-0 fs-14">
                                155 $
                            </p>

                            <div class="d-flex align-items-center gap-2 mt-2">
                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Select size</option>
                                    <option value="1">33</option>
                                    <option value="2">35</option>
                                    <option value="3">41</option>
                                </select>

                                <select class="form-select form-select-sm border-dark rounded-0">
                                    <option disabled selected>Chose color</option>
                                    <option value="1">Blue</option>
                                    <option value="2">White</option>
                                    <option value="3">Black</option>
                                </select>
                            </div>

                            <div class="mt-2">
                                <button class="btn btn-outline-dark btn-sm w-100">Add to basket</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Product1 from "@/assets/websites/moda/img/offers-product1.png"
import Product2 from "@/assets/websites/moda/img/offers-product2.png"
import Product3 from "@/assets/websites/moda/img/offers-product3.png"

export default {
    name: "ModaFavourites",

    data() {
        return {
            Product1,
            Product2,
            Product3,
        }
    }
}
</script>

<style scoped>
.removeFromFav:hover {
    background: #e3e3e3;
    color: #000;
}

@media screen and (max-width: 575px) {
    .favourites-products-row .col:nth-child(odd) {
        padding-right: 6px;
    }

    .favourites-products-row .col:nth-child(even) {
        padding-left: 6px;
    }
}
</style>