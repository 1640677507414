<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-lg-2 px-0">
            <div v-if="!carPartsPopularMarksLoaded">
                <LoaderCategories/>
            </div>

            <div v-else class="row row-cols-lg-5 row-cols-md-4 row-cols-3 m-0">
                <div v-for="category in carPartsPopularMarks" :key="category.id"
                     class="col category__col mb-lg-3 mb-2 px-2">
                    <router-link :to="{
                        name: 'mark',
                        params: {
                            locale: $route.params.locale,
                            mark: category.code
                        }
                    }"
                                 class="text-dark border bg-white rounded d-block p-3 category overflow-hidden position-relative h-100 d-flex align-items-center justify-content-center">
                        <img :src="getMarkIcon(category)" alt="" class="w-100 d-block mx-auto"
                             style="object-fit: cover;"
                             title="Category name">
                        <div
                            class="position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex justify-content-center align-items-center text-center category__hover fs-sm-18">
                            <span>
                                {{ category.name }}
                            </span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image.png"
import {mapGetters} from "vuex";
import LoaderCategories from "@/components/websites/get-parts/parts/loaders/LoaderCategories.vue";

export default {
    name: 'CategoriesGP',

    components: {
        LoaderCategories
    },

    computed: {
        ...mapGetters([
            'carPartsPopularMarks',
            'carPartsPopularMarksLoaded'
        ])
    },

    data() {
        return {
            NoImage
        }
    },

    methods: {
        getMarkIcon(mark) {
            let code = (mark.code || '').toLowerCase()
                .replace(' ', '-')
                .replace('_', '-');
            try {
                return require(`@/assets/logos/brands/${code}.png`)
            } catch (e) {
                console.log(e)
                return NoImage
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.category img {
    transition: .3s;
}

.category:hover img {
    transform: scale(1.2);
}

.category .category__hover {
    opacity: 0;
    transition: opacity .3s
}

.category:hover .category__hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.72);
    color: #fff;
    font-size: 24px;
    font-weight: 800;
}

@media screen and (min-width: 1400px) {
    .category__col {
        height: 244.8px;
    }
}

@media screen and (max-width: 1399px) {
    .category__col {
        height: 208.8px;
    }
}

@media screen and (max-width: 1200px) {
    .category__col {
        height: 172.8px;
    }
}

@media screen and (min-width: 991px) {
    .category img {
        width: 180px !important;
    }
}

@media screen and (max-width: 991px) {
    .category__col {
        height: 164px;
    }
}


@media screen and (max-width: 760px) {
    .category__col {
        height: 109px;
    }

    .category__col:nth-child(3n+1) {
        padding-right: 4px !important;
    }
    .category__col:nth-child(3n+3) {
        padding-left: 4px !important;
    }
    .category__col:is(:nth-child(2), :nth-child(5)) {
        padding-inline: 4px !important;
    }
}


</style>