<template>
    <div class="container-fluid mt-lg-5 mt-3 px-0">
        <div class="container p-lg-2 p-0">
            <div class="d-flex justify-content-end filter-bg p-lg-3 p-2">
                <div class="col-lg-5 col-12 border border-white p-lg-3 p-2">
                    <label class="form-label text-white text-uppercase fw-light fs-sm-14" for="customRange1">выберите
                        стоимость автомобиля</label>
                    <input id="customRange1" class="form-range d-block w-100 mt-lg-4" type="range">
                    <p class="text-white text-uppercase mt-1 fs-12 fs-sm-10">до - 682 300 &#8381;</p>
                    <button class="btn btn-danger text-uppercase mt-lg-4 fs-sm-14" type="submit">
                        подобрать авто
                        <i class="fas fa-angle-right ms-3"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'PriceRange',

    data() {
        return {}
    }
}
</script>

<style scoped>
.filter-bg {
    background-image: url('@/assets/websites/rb-auto/img/filter-bg1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.filter-bg .col-lg-5 {
    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(100px);
}

input[type='range'] {
    border-radius: 0 !important;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #DD1919;
    border-radius: 0 !important;
    width: 8px !important;
    height: 16px !important;
}

input[type='range']::-webkit-slider-thumb:focus {
    box-shadow: none !important;
}
</style>