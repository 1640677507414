<template>
    <div class="row m-0">
        <div class="col-lg-6">
            <select
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2">
                <option selected value="1">Выберите марку</option>
                <option value="2">1</option>
            </select>
        </div>

        <div class="col-lg-6 mt-lg-0 mt-3">
            <select
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2">
                <option value="1">Выберите модель</option>
                <option value="2">1</option>
            </select>
        </div>

        <div class="col-lg-6 mt-3">
            <input
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                placeholder="Укажите пробег"
                type="number">
        </div>

        <div class="col-lg-6 mt-3">
            <input
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                placeholder="Год выпуска"
                type="number">
        </div>

        <div class="col-lg-6 mt-3">
            <input
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                placeholder="Цена тыс. ₽"
                type="number">
        </div>

    </div>
</template>

<script>
export default {
    name: 'FirstStepTradeIn'
}
</script>


<style scoped>

</style>