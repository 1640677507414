<template>
    <div>
        <div class="row row-cols-lg-4 row-cols-sm-3 row-cols-2 brand-products-row">
            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>

                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0" title="Sale">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0" title="Winter production">
                            <img :src="SnowflakeIcon" alt="" width="20">
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>

                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0" title="Sale">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0" title="Winter production">
                            <img :src="SnowflakeIcon" alt="" width="20">
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>

                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0" title="Sale">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0" title="Winter production">
                            <img :src="SnowflakeIcon" alt="" width="20">
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-danger rounded-0">
                            -12$
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col mb-lg-4 mb-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 badge text-bg-info rounded-0 text-white"
                             title="New">
                            NEW
                        </div>
                    </router-link>

                    <div>
                        <div class="d-flex align-items-center justify-content-between border-bottom border-dark pb-2">
                            <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0">
                                Balmain
                            </p>

                            <span class="fs-18">976 $</span>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <p class="fs-14 fw-light text-uppercase mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                Express view
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <ModaPagination/>
    </div>
</template>

<script>
import SnowflakeIcon from "@/assets/websites/moda/img/snowflake-icon.png"

import ModaPagination from "@/components/websites/moda/parts/Pagination";

export default {
    name: "ModaBrandProduct",
    components: {ModaPagination},

    data() {
        return {
            SnowflakeIcon
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 575px) {
    .favourites-products-row .col:nth-child(odd) {
        padding-right: 6px;
    }

    .favourites-products-row .col:nth-child(even) {
        padding-left: 6px;
    }
}
</style>