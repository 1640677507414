<template>
    <div>
        <div class="p-lg-4 p-3 pb-lg-0 pb-0">
            <p class="fs-5 mb-0">
                Заказать звонок
            </p>
        </div>

        <div class="flex-grow-1">
            <div class="d-flex flex-column justify-content-between h-100 p-lg-4 p-3 h-100">
                <div>
                    <div>
                        <label class="form-label"
                               for="call_request_name">
                            Ваше имя
                        </label>

                        <input id="call_request_name"
                               class="form-control"
                               type="text"
                        />
                    </div>

                    <div class="mt-3">
                        <label class="form-label"
                               for="call_request_number">
                            Ваш номер
                        </label>

                        <input id="call_request_number"
                               class="form-control"
                               type="number"
                        />
                    </div>

                    <div class="form-check mt-3">
                        <input id="flexCheckDefault" checked="" class="form-check-input" type="checkbox" value="">
                        <label class="form-check-label fs-14" for="flexCheckDefault" style="color: #A7A8B0">
                            Даю согласие на обработку персональных данных
                        </label>
                    </div>
                </div>

                <div class="mt-lg-0 mt-3">
                    <div class="btn btn-warning w-100 text-uppercase py-lg-3">подать заявку на звонок
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoCallRequestBlock"
}
</script>

<style scoped>

</style>