<template>
    <div>
        <div class="row m-0">
            <div class="col-12 px-3">
                <label class="form-label fs-15 fw-bold" for="reservation-date">
                    {{ trans('menu_reservation') }}*
                </label>
                <input id="reservation-date" v-model="reservationData.reservationDate"
                       class="form-control"
                       type="datetime-local">
            </div>

            <div class="col-12 px-3 mt-3">
                <p class="mb-2 fs-15 fw-bold">
                    {{ trans('menu_number_people') }}*
                </p>
                <select v-model="reservationData.personsCount" class="form-select">
                    <option v-for="persons in 10" :key="persons" :value="persons">{{ persons }}</option>
                </select>
            </div>
        </div>

        <div class="mt-3 px-3 py-3">
            <button class="btn btn-lg btn-warning fs-15 fw-bold rounded w-100"
                    @click="reservationStep = 2">
                {{ trans('menu_continue') }}
            </button>
        </div>
    </div>
</template>

<script>
import {t} from "@/lang/core/helpers";
import {mapGetters} from "vuex";

export default {
    name: "TableReservationTime",

    computed: {
        ...mapGetters([
            'menuCompany',
        ]),

        reservationStep: {
            get() {
                return this.$store.getters.tableReservationStep;
            },
            set(value) {
                this.$store.commit('setTableReservationStep', value);
            }
        },

        reservationData: {
            get() {
                return this.$store.getters.reservationData;
            },
            set(value) {
                this.$store.commit('setTableReservationDate', value);
            }
        }
    },

    data() {
        return {}
    },

    created() {
        this.generateToday()
    },

    methods: {
        trans(key) {
            return t(key)
        },

        generateToday() {
            let now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            this.reservationData.reservationDate = now.toISOString().slice(0, 16);
        }
    }
}
</script>

<style scoped></style>
