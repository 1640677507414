<template>
    <div class="row m-0">
        <div class="col-lg-6">
            <input
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                placeholder="ФИО"
                type="text">
        </div>

        <div class="col-lg-6 mt-lg-0 mt-3">
            <input
                class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                placeholder="+7(___)___-__-__"
                type="tel">
        </div>

        <div class="col-12 mt-3">
            <input id="flexCheckChecked" checked class="form-check-input me-2" type="checkbox" value="">
            <label class="form-check-label fs-sm-12" for="flexCheckChecked">
                Даю согласие на обработку персональных данных
            </label>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SecondStepTradeIn',
}
</script>

<style scoped>

</style>