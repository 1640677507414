<template>
    <div>
        <div class="container-fluid px-lg-0 filterBlock sticky-top py-3 d-none" style="z-index: 20; top: 13%;">
            <div class="container px-0">
                <div class="d-flex">
                    <button aria-controls="offcanvasScrolling"
                            class="btn btn-light btn-sm border border-dark text-uppercase"
                            data-bs-target="#offcanvasScrolling" data-bs-toggle="offcanvas"
                            type="button">
                        filters
                    </button>

                    <div class="d-flex align-items-center gap-3 fs-14 ms-auto">
                        <a class="text-decoration-none text-dark" href="#">
                            View all
                        </a>

                        <a class="text-decoration-none text-dark" href="#">
                            Top sales
                        </a>

                        <a class="text-decoration-none text-dark" href="#">
                            Plain
                        </a>

                        <a class="text-decoration-none text-dark" href="#">
                            Prints
                        </a>
                    </div>
                </div>

            </div>
        </div>

        <div class="vh-100 position-relative introBlock" style="margin-top: -113px;">
            <img :src="BgPic2" alt="" class="w-100 h-100">

            <div class="container position-absolute start-50 translate-middle-x fw-bold mt-4 preparation-order"
                 style="z-index: 10;">
                <PreparationOrder/>
            </div>

            <div class="position-absolute top-50 start-50 translate-middle fw-bold d-none d-lg-block">
                <div class="row m-0 gap-lg-5">
                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#fav">
                        FAVOURITES
                    </a>

                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        NEW
                    </a>

                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        TOP
                    </a>

                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        LAST VIEWS
                    </a>
                </div>

                <div class="row m-0 gap-lg-5 mt-lg-5">
                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        FOR MEN
                    </a>

                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        FOR WOMAN
                    </a>

                    <a class="col-lg-auto d-block text-decoration-none text-white fs-4" href="#">
                        FOR CHILDREN
                    </a>
                </div>
            </div>

            <div class="container position-absolute top-0 start-50 translate-middle-x fw-bold d-lg-none mt-4"
                 style="z-index: 10;">
                <div class="row row-cols-3">
                    <div class="col pe-0">
                        <button aria-controls="collapseIntroNavigation" aria-expanded="false"
                                class="btn btn-light border-dark w-100 intro-navigation-btn"
                                data-bs-target="#collapseIntroNavigation"
                                data-bs-toggle="collapse" type="button">
                            Men
                        </button>
                    </div>

                    <div class="col">
                        <button aria-controls="collapseIntroNavigation" aria-expanded="false"
                                class="btn btn-light border-dark w-100 intro-navigation-btn"
                                data-bs-target="#collapseIntroNavigation"
                                data-bs-toggle="collapse" type="button">
                            Woman
                        </button>
                    </div>

                    <div class="col ps-0">
                        <button aria-controls="collapseIntroNavigation" aria-expanded="false"
                                class="btn btn-light border-dark w-100 intro-navigation-btn"
                                data-bs-target="#collapseIntroNavigation"
                                data-bs-toggle="collapse" type="button">
                            Child
                        </button>
                    </div>

                </div>

                <div id="collapseIntroNavigation" class="collapse mt-1">
                    <div class="card card-body rounded-0 border-dark">
                        <ul class="navbar-nav flex-wrap">
                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between pt-0"
                                   href="#">
                                    20% Off
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    New in
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Shop by
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Brands
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Clothing
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Shoes
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Bags
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Accessories
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Jewelry
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    Pre-owned
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    20% Off
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between"
                                   href="#">
                                    New in
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link text-dark px-0 mx-lg-2 d-lg-block d-flex justify-content-between pb-0"
                                   href="#">
                                    Shop by
                                    <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="fav" class="container px-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 px-2 fs-2 mb-lg-0 mb-3">Favourites</p>

            <ModaFavouritesSaleables/>
        </div>

        <div class="container-fluid-sm mt-lg-5 mt-3 py-lg-0 py-4">
            <img :src="AdsBaner" alt=""
                 class="w-100 img-fluid" loading="lazy" style="max-height: 450px; object-fit: cover;">
        </div>

        <div class="container px-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 px-2 fs-2 mb-lg-0 mb-3">Top sales</p>

            <ModaTopSales/>
        </div>

        <div class="container-fluid-sm mt-lg-5 mt-3 py-lg-0 py-4">
            <img :src="AdsBaner2" alt=""
                 class="w-100 img-fluid" loading="lazy" style="max-height: 450px; object-fit: cover;">
        </div>

        <div class="container px-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 px-2 fs-2 mb-lg-0 mb-3">New</p>

            <Modanew/>
        </div>

        <div class="container-fluid px-0 mt-lg-5 mt-3">
            <img :src="BgPic3" alt="" class="w-100 img-fluid">
        </div>

        <div class="container px-lg-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 fs-2 mb-lg-0 mb-3">Recently Viewed</p>

            <ModaFiltredProductsSaleable/>
        </div>

        <div class="container px-lg-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 px-2 mb-lg-0 mb-3">Our partners</p>

            <ModaPartners/>
        </div>

        <div class="container px-lg-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 px-2 fs-3 fs-sm-18 text-center letter-spacing-2">
                Subscribe to our newsletter
            </p>

            <div class="col-lg-7 mx-lg-auto">
                <div class="d-flex align-items-center gap-2">
                    <input autocomplete="off" class="form-control form-control-lg rounded-0" placeholder="Email"
                           type="email">

                    <button class="btn btn-dark btn-lg">Subscribe</button>
                </div>

                <p class="fs-14 text-center fw-light mt-2 mb-0">
                    By clicking "Subscribe" you agree to the personal data processing policy.
                </p>

            </div>
        </div>

    </div>
</template>

<script>
import BgPic1 from "@/assets/websites/moda/img/index-bg-1.jpg"
import BgPic2 from "@/assets/websites/moda/img/index-bg-2.jpg"
import BgPic3 from "@/assets/websites/moda/img/banner2.png"
import AdsBaner from "@/assets/websites/moda/img/ads-baner.jpg"
import AdsBaner2 from "@/assets/websites/moda/img/ads-baner2.jpg"
import AdsBanerMob from "@/assets/websites/moda/img/ads-baner-mob.jpg"
import ModaTopSales from "@/components/websites/moda/parts/TopSales";
import ModaFiltredProductsSaleable from "@/components/websites/moda/parts/FiltredProductsSaleable";
import ModaFavouritesSaleables from "@/components/websites/moda/parts/FavouritesSaleables";
import Modanew from "@/components/websites/moda/parts/New";
import ModaPartners from "@/components/websites/moda/parts/Partners";
import PreparationOrder from "@/components/websites/moda/parts/PreparationOrder.vue";

export default {
    name: 'ModaIndex',

    components: {
        PreparationOrder,
        ModaPartners,
        Modanew,
        ModaFavouritesSaleables,
        ModaFiltredProductsSaleable,
        ModaTopSales
    },

    data() {
        return {
            BgPic1,
            BgPic2,
            BgPic3,
            AdsBaner,
            AdsBaner2,
            AdsBanerMob,
        }
    }

}
</script>

<style lang="scss" scoped>
.filterBlock {
    transition: .3s;
}

.filterBlock:hover {
    background-color: rgb(255 255 255 / 70%);
}

.intro-navigation-btn.collapsed {
    background-color: #fff !important;
    color: #2a2a2a !important;
}

.intro-navigation-btn {
    background-color: #2a2a2a !important;
    color: #fff !important;
}

@media screen and (min-width: 992px) {
    .introBlock img {
        filter: brightness(.8);
        object-fit: cover;
    }
}

@media screen and (max-width: 991px) {
    .introBlock {
        height: auto !important;
        margin-top: 70px !important;
        //margin-top: -63px !important;
    }
    .filterBlock {
        top: 51.44px !important;
        background-color: rgb(255 255 255 / 80%);
    }
}

</style>