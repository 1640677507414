<template>
    <div v-if="carPart" class="bg-white rounded shadow-sm sticky-lg-top p-3" style="top: 12px;">
        <div class="d-flex align-items-center justify-content-between gap-3">
            <div class="d-flex align-items-center gap-3">
                <p v-if="selectedCurrency === 'lari_product'" id="lari_price"
                   :class="carPart.price_discount !== null ? 'd-flex flex-column fw-600 mb-0 product__price' :  'fw-600 mb-0 product__price'">
                    <span :class="carPart.price_discount !== null ? 'lh-1' : 'fs-5'">{{ carPart.price_discount || carPart.price }}</span>

                    <del v-if="carPart.price_discount !== null" class="text-danger fs-15 lh-1">
                        {{ carPart.price }}
                    </del>
                </p>

                <p v-if="selectedCurrency === 'dollar_product'" id="dollar_price"
                   :class="carPart.price_discount !== null ? 'd-flex flex-column fw-600 mb-0 product__price' :  'fw-600 mb-0 product__price'">
                    <span :class="carPart.price_discount !== null ? 'lh-1' : 'fs-5'">{{ calculateUsdPrice(carPart.price_discount || carPart.price) }}</span>

                    <del v-if="carPart.price_discount !== null" class="text-danger fs-15 lh-1">
                        {{ calculateUsdPrice(carPart.price) }}
                    </del>
                </p>

                <div class="btn-group">
                    <input id="lari_product"
                           v-model="selectedCurrency"
                           autocomplete="off"
                           class="btn-check"
                           name="currency_product"
                           type="radio"
                           value="lari_product">
                    <label class="btn btn-sm btn-outline-warning"
                           for="lari_product">
                        ₾
                    </label>

                    <input id="dollar_product"
                           v-model="selectedCurrency"
                           autocomplete="off"
                           class="btn-check"
                           name="currency_product"
                           type="radio"
                           value="dollar_product">
                    <label class="btn btn-sm btn-outline-warning"
                           for="dollar_product">
                        $
                    </label>
                </div>
            </div>

            <p class="d-flex flex-column mb-0">
                <span class="d-block fs-12">
                    {{ trans('articule_') }}: {{ carPart.id }}
                </span>

                <span v-if="carPart.quantity >= 1" class="d-block text-success fs-12">
                     <i class="fa-solid fa-circle-check"></i>
                    {{ trans('in_stock') }}: {{ carPart.quantity }}
                </span>
            </p>

        </div>

        <hr>

        <div v-if="requisites.LOC_GOOGLE_MAP_LINK" class="border px-3 py-2 rounded mt-3">
            <a :href="requisites.LOC_GOOGLE_MAP_LINK"
               class="d-block text-secondary link"
               target="_blank">
                <i class="fa-solid fa-location-dot"></i>
                <span class="fw-600 text-dark ms-2 fs-14">
                    {{ requisites.LOC_CITY && locale ? requisites.LOC_CITY[locale] : '' }},
                    {{ requisites.LOC_ADDRESS && locale ? requisites.LOC_ADDRESS[locale] : '' }}
                </span>
            </a>
        </div>

        <div v-if="requisites.PHONE_1" class="border border-warning px-3 py-2 rounded mt-3">
            <a :href="`tel:+${requisites.PHONE_1}`"
               class="d-block">
                <i class="fa-solid fa-mobile-screen-button text-warning"></i>
                <span class="fw-600 text-warning ms-2">
                    +{{ formatPhone(requisites.PHONE_1) }}
                </span>
            </a>
        </div>

        <div v-if="requisites.PHONE_2" class="border border-warning px-3 py-2 rounded mt-3">
            <a :href="`tel:+${requisites.PHONE_2}`"
               class="d-block">
                <i class="fa-solid fa-mobile-screen-button text-warning"></i>
                <span class="fw-600 text-warning ms-2">
                     +{{ formatPhone(requisites.PHONE_2) }}
                </span>
            </a>
        </div>

        <div class="actions__buttons bg-white">
            <div class="d-flex justify-content-between gap-2 rounded mt-md-3">
                <div class="flex-grow-1 d-flex justify-content-start align-items-center">
                    <button v-if="!isInCart(carPart)"
                            class="btn border border-warning text-warning rounded-0 rounded-start w-100"
                            style="height: 38px;"
                            type="button"
                            @click="addToCart(carPart)">
                        <i class="fa-sharp fa-solid fa-cart-shopping"></i>
                        <span class="fw-600 ms-2 fs-14">{{ trans('add') }}</span>
                    </button>

                    <div v-else class="d-flex align-items-center border border-warning bg-white w-100 rounded-start"
                         style="height: 38px;">
                        <div class="text-center" style="flex: 1">
                            <button class="btn btn-sm border-0 w-100" type="button"
                                    @click="decreaseItemCount(carPart)">
                                <i class="fa-solid fa-minus text-warning"></i>
                            </button>
                        </div>

                        <div class="h-100" style="flex: 1;">
                            <input
                                :value="itemQuantity(carPart)"
                                class="form-control text-center p-2 border-start border-end border-warning border-0 rounded-0 text-warning h-100"
                                type="tel">
                        </div>

                        <div class="text-center" style="flex: 1;">
                            <button class="btn btn-sm border-0 w-100" type="button"
                                    @click="increaseItemCount(carPart)">
                                <i class="fa-solid fa-plus text-warning"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <button class="btn border border-warning text-warning rounded-0" style="height: 38px;" type="button"
                        @click="toggleFavorite(carPart)">
                    <i :class="!isInFavorites(carPart) ? 'fa-regular fa-heart' : 'fa-solid fa-heart'"></i>
                </button>

                <div class="dropdown share" style="z-index: 1001;">
                    <button aria-expanded="false"
                            class="btn border border-warning text-warning rounded-0 rounded-end dropdown-toggle share-btn"
                            data-bs-toggle="dropdown"
                            style="height: 38px;"
                            type="button">
                        <i class="fa-solid fa-share fs-12"></i>
                    </button>

                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item d-flex align-items-center gap-2"
                               href="#">
                                <img :src="Facebook" alt="Facebook" title="Facebook" width="20">
                                <span>Facebook</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                <img :src="Instagram" alt="Instagram" title="Instagram" width="20">
                                <span>Instagram</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Telegram" alt="Telegram" title="Telegram" width="20">
                                <span>Telegram</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Whatsapp" alt="Whatsapp" title="Whatsapp" width="20">
                                <span>WhatsApp</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Viber" alt="Viber" title="Viber" width="20">
                                <span>Viber</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <i class="fa fa-link"></i>
                                <span>Copy link</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import {mapGetters} from "vuex";
import {t} from "@/lang/core/helpers";
import {round_number} from "@/core/helpers";

export default {
    name: 'ContactInfoViewProduct',

    props: {
        carPart: {
            required: true,
        }
    },

    computed: {
        ...mapGetters(['locale', 'requisites', 'favorites', 'shoppingCart', 'exchangeRates']),
    },

    methods: {
        trans(key) {
            return t(key)
        },

        toggleFavorite(part) {
            this.$store.dispatch('toggleFavorite', part)
        },

        isInFavorites(part) {
            return this.favorites.find(item => item.id === part.id)
        },

        addToCart(part) {
            this.$store.dispatch('addToCart', part)
        },

        isInCart(part) {
            return this.shoppingCart.find(item => item.id === part.id)
        },

        increaseItemCount(product) {
            this.$store.dispatch('increaseItemCount', product)
        },

        decreaseItemCount(product) {
            this.$store.dispatch('decreaseItemCount', product)
        },

        itemQuantity(product) {
            if (!this.shoppingCart || !this.isInCart(product)) {
                return 0
            }

            return this.shoppingCart.find(item => item.id === product.id).quantity
        },

        calculateUsdPrice(price) {
            if (!this.exchangeRates || !Object.keys(this.exchangeRates).length || !this.exchangeRates['USD']) {
                return price
            }

            return round_number(price * this.exchangeRates['USD'])
        },

        formatPhone(phone) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
        },

    },

    data() {
        return {
            Facebook,
            Instagram,
            Telegram,
            Whatsapp,
            Viber,
            Messenger,
            selectedCurrency: 'lari_product'
        }
    }
}

</script>

<style scoped>
.product__price {
    width: 60px;
}

.share-btn::after {
    display: none !important;
}

@media screen and (max-width: 760px) {
    .actions__buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1010;
        width: 100%;
        padding: 8px;
        background-color: #F5F5F5;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
}
</style>