<template>
	<div>
		<div class="container-fluid px-0 mt-3">
			<div class="container px-2">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb fs-14 mb-0">
						<li class="breadcrumb-item">
							<router-link
								:to="{ name: 'home-locale', params: { locale: currentLocale } }"
								>{{ trans("home") }}</router-link
							>
						</li>

						<li class="breadcrumb-item">
							<router-link
								:to="{ name: 'parts', params: { locale: currentLocale } }"
								>{{ trans("auto_marks") }}</router-link
							>
						</li>

						<li class="breadcrumb-item">
							<router-link
								:to="{
									name: 'mark',
									params: {
										locale: $route.params.locale,
										mark: $route.params.mark,
									},
								}"
							>
								{{ breadCrumbName }}
							</router-link>
						</li>

						<li aria-current="page" class="breadcrumb-item active">
							{{ trans("auto_parts") }}
						</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="container-fluid px-0 mt-3">
			<div class="container px-0">
				<div v-if="!carPartsModelCarPartsLoaded">
					<LoaderMarkAndParts />
				</div>

				<div v-else class="row row-cols-lg-5 row-cols-md-3 row-cols-2 m-0">
					<router-link
						v-for="part in parts"
						:key="part.id"
						:to="{
							name: 'view-product',
							params: { id: part.id },
						}"
						class="col text-dark px-2 mb-3 part"
					>
						<div class="shadow-sm border bg-white rounded p-3">
							<div>
								<img
									v-if="part.images && part.images.length"
									:src="getProductImage(part)"
									alt=""
									class="w-100 img-fluid rounded-top part__pic"
								/>
								<img
									v-else
									:src="NoImage"
									alt=""
									class="w-100 img-fluid part__pic"
								/>
							</div>

							<p class="text-center mt-3 mb-0 part__name">
								{{ getTranslatedPropertyByLocale(part, "name", locale) }}
							</p>

							<p class="text-center mt-2 mb-0">
								{{ part.price_discount || part.price }} ₾
							</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NoImage from "@//assets/websites/get-parts/img/no-image.png";
import ApiRequest from "@/http/ApiRequest";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getTranslatedPropertyByLocale, t } from "@/lang/core/helpers";
import LoaderMarkAndParts from "@/components/websites/get-parts/parts/loaders/LoaderMarkAndParts.vue";

export default {
	name: "ProductByModel",
	components: { LoaderMarkAndParts },

	watch: {
		$route: {
			async handler(route) {
				if (route.params && route.params.mark && route.params.model) {
					this.getCarPartsByMarkAndModel(route.params.mark, route.params.model);
					this.getCurrentModelById(route.params.model);
				}
			},

			immediate: true,
		},
	},

	computed: {
		...mapGetters([
			"carPartsMarks",
			"locale",
			"carPartsModelCarPartsLoaded",
			"menuCompanyDefaultLocale",
		]),

		breadCrumbName() {
			if (
				!this.$route.params ||
				!this.$route.params.mark ||
				!this.$route.params.model ||
				!this.carPartsMarks.length
			) {
				return null;
			}

			let markCode = this.$route.params.mark;
			let mark = this.carPartsMarks.find((mark) => mark.code === markCode);

			if (!mark) {
				return null;
			}

			if (this.currentModel) {
				return `${mark.name} ${this.currentModel.name}`;
			}

			return mark.name;
		},

		currentLocale() {
			return (
				window.localStorage.getItem("locale") ||
				this.menuCompanyDefaultLocale ||
				"ka"
			);
		},
	},

	beforeRouteLeave(to, from, next) {
		this.parts = [];
		this.$store.commit("setCarPartsModelCarPartsLoaded", false);
		next();
	},

	data() {
		return {
			NoImage,
			companyKey: "get-parts",
			parts: [],
			currentModel: null,
		};
	},

	methods: {
		getTranslatedPropertyByLocale,

		trans(key) {
			return t(key);
		},

		getCarPartsByMarkAndModel(mark, model) {
			new ApiRequest(
				"car-parts/parts/mark-model",
				{
					key: "get-parts",
					markCode: mark,
					modelId: model,
				},
				(response) => {
					if (!response.data) {
						alert(response.message || "Not found");
						return;
					}

					this.parts = response.data;
					this.$store.commit("setCarPartsModelCarPartsLoaded", true);
				}
			);
		},

		getCurrentModelById(modelId) {
			new ApiRequest(
				"car-parts/model",
				{
					key: this.companyKey,
					modelId: modelId,
				},
				(response) => {
					if (!response.data) {
						alert(response.message || "Not found");
						return;
					}

					this.currentModel = response.data;
				}
			);
		},

		getImageBySize(images, size) {
			let filterImage = _.filter(images, { image_type: size });
			return filterImage.length ? filterImage[0].url : null;
		},

		getProductImage(part) {
			if (!part.images.length) {
				return null;
			}

			return this.getImageBySize(part.images, "MEDIUM");
		},
	},
};
</script>

<style scoped>
.part:hover .part__name {
	text-decoration: underline;
}

.part__pic {
	object-fit: cover;
}

@media screen and (min-width: 1400px) {
	.part__pic {
		height: 88px;
	}
}

@media screen and (max-width: 1399px) {
	.part__pic {
		height: 74px;
	}
}

@media screen and (max-width: 1200px) {
	.part__pic {
		height: 58px;
	}
}

@media screen and (max-width: 991px) {
	.part__pic {
		height: 78px;
	}
}

@media screen and (max-width: 760px) {
	.part__pic {
		height: 57px;
	}
}
</style>
