<template>
    <div class="d-flex ms-lg-5">
        <div class="d-none d-lg-block">
            <img :src="SuccessCarPic" alt="Success" class="img-fluid" height="352" loading="lazy" width="176">
        </div>
        <div class="d-flex flex-column justify-content-between ms-lg-5 mt-lg-0 mt-3 ">
            <div>
                <h1 class="text-success fs-4 fs-sm-18 text-uppercase text-lg-start text-center">заявка успешно
                    отправлена</h1>
                <p class="text-uppercase mt-4 mb-0 fs-sm-14">наш оператор свяжется с вами течение 30 минут</p>
                <p class="text-uppercase mt-3 mb-0 fs-sm-14">
                    <span class="text-success">95%</span>
                    Заявок одобряют
                </p>
                <p class="text-uppercase mt-3 mb-0 fs-sm-14">
                    <span class="text-success">209</span>
                    Одобренно заявок за прошедший месяц
                </p>
            </div>
            <div class="mt-lg-auto mt-2">
                <router-link :to="{name: 'home'}"
                             class="btn btn-success text-light text-uppercase fs-18 fs-sm-16 py-lg-3 py-2 w-100 send-trade-in">
                    перейти на главную
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessCarPic from "@/assets/websites/rb-auto/img/success-car.png"

export default {
    name: 'ThirdStepTradeIn',

    data() {
        return {
            SuccessCarPic
        }
    }
}
</script>

<style scoped>

</style>