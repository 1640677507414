// import Vue from 'vue'
import { createApp, configureCompat } from 'vue'
import App from './App.vue'
import store from './store/_index'
// import {sync} from 'vuex-router-sync'
import {setCompanyKeyFromDomain, snakeToCamel} from "@/core/helpers"
import blueberrynightshotel from "@/routes/blueberrynightshotel"
import allmenu from "@/routes/allmenu"
import moda from "@/routes/moda"
import auto from "@/routes/auto"
import rbAuto from "@/routes/rb-auto"
import getParts from "@/routes/get-parts"
import "bootstrap/dist/js/bootstrap"
// import VueMeta from 'vue-meta'
import { createMetaManager } from 'vue-meta'
// import state from "@/store/state"
// import getters from '@/store/getters'
// import mutations from '@/store/mutators'
// import actions from '@/store/actions'
// import { createStore } from 'vuex'

// const store = createStore({
//     state,
//     getters,
//     mutations,
//     actions
// })

const app = createApp(App)

configureCompat({
    RENDER_FUNCTION: false
})

// Vue.config.productionTip = false
// Vue.use(VueMeta, {
//     keyName: 'metaInfo',
//     attribute: 'data-vue-meta',
//     ssrAttribute: 'data-vue-meta-server-rendered',
//     tagIDKeyName: 'vmid',
//     refreshOnceOnNavigation: true
// })

app.use(createMetaManager(), {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})
app.use(store)

const routesData = {
    blueberrynightshotel,
    allmenu,
    moda,
    auto,
    rbAuto,
    getParts
}

let key = snakeToCamel(setCompanyKeyFromDomain())

if (key === 'getparts') key = 'getParts'
else if (key === 'get-parts') key = 'getParts'

const router = () => routesData[key]

// sync(store, router())

app.use(router())

// new Vue({
//     router: router(),
//     render: h => h(App),
//     store
// }).$mount('#app')

app.mount('#app')
