<template>
    <div>
        <div class="container-fluid- mt-lg-5 mt-3 d-none d-lg-block">
            <div class="container p-lg-0">
                <div class="d-flex justify-content-between m-0 bg-white rounded shadow-sm px-4 py-3">

                    <div class="d-flex align-items-center">
                        <p class="mb-0 d-flex align-items-center justify-content-center bg-warning rounded px-3 py-2">
                            1
                        </p>
                        <p class="ms-3 mb-0">
                            Выберите автомобиль
                        </p>
                    </div>

                    <div class="d-flex align-items-center">
                        <p class="mb-0 d-flex align-items-center justify-content-center bg-warning rounded px-3 py-2">
                            2
                        </p>
                        <p class="ms-3 mb-0">
                            Заполните анкету на автокредит
                        </p>
                    </div>

                    <div class="d-flex align-items-center">
                        <p class="mb-0 d-flex align-items-center justify-content-center bg-warning rounded px-3 py-2">
                            3
                        </p>
                        <p class="ms-3 mb-0">
                            С вами свяжется менеджер
                        </p>
                    </div>

                    <div class="d-flex align-items-center">
                        <p class="mb-0 d-flex align-items-center justify-content-center bg-warning rounded px-3 py-2">
                            4
                        </p>
                        <p class="ms-3 mb-0">
                            Приезжайте за машиной!
                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div class="container-fluid-sm mt-lg-5 mt-3">
            <div class="container p-lg-0">
                <div class="row justify-content-between">
                    <div class="col-lg-4 pe-lg-0">
                        <div class="container p-0">
                            <h1 class="fs-2 fs-sm-18 mb-0 text-nowrap">
                                Автокредит
                            </h1>

                            <nav aria-label="breadcrumb" class="overflow-auto mt-2">
                                <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                                    <li class="breadcrumb-item d-flex align-items-center">
                                        <router-link :to="{ name:'home' }" class="text-dark text-nowrap">
                                            Главная
                                        </router-link>
                                    </li>
                                    <li class="breadcrumb-item d-flex align-items-center  text-nowrap active">
                                        Автокредит
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div class="rounded-3 bg-white shadow-lg shadow-sm-0 p-lg-4 p-3">
                            <h3 class="fs-18 fs-sm-16">Данные автомобиля</h3>
                            <select class="form-select form-select-md mt-3 py-2">
                                <option value="">Марка</option>
                                <option value="">Audi</option>
                                <option value="">Mercedes</option>
                                <option value="">BMW</option>
                            </select>

                            <select class="form-select form-select-md mt-3 py-2">
                                <option value="other">Выберите модель</option>
                                <option value="">X3</option>
                                <option value="">X5</option>
                                <option value="">X6</option>
                            </select>

                            <select class="form-select form-select-md mt-3 py-2">
                                <option value="">Выберите автомобиль</option>
                            </select>

                            <h3 class="mt-4 mb-3">Персональные данные</h3>

                            <input class="form-control form-control-md mt-3 py-2" placeholder="Имя" type="text">

                            <input class="form-control form-control-md mt-3 py-2" placeholder="+7(___)___-__-__">

                            <input class="form-control form-control-md mt-3 py-2" placeholder="Адрес прописки"
                                   type="text">

                            <h3 class="mt-4 mb-3">Условия кредита</h3>

                            <select class="form-select form-select-md mt-3 py-2">
                                <option value="">Срок кредита (месяцев)</option>
                                <option value="">2</option>
                                <option value="">4</option>
                                <option value="">8</option>
                            </select>

                            <input class="form-control form-control-md mt-3 py-2" placeholder="Первоначальный взнос"
                                   type="number">

                            <div class="form-check mt-3">
                                <input id="flexCheckChecked" checked="" class="form-check-input" type="checkbox"
                                       value="">
                                <label class="form-check-label  fs-sm-12" for="flexCheckChecked">
                                    Даю согласие на обработку персональных данных
                                </label>
                            </div>

                            <button class="btn rounded btn-warning text-uppercase w-100 py-2 mt-3">
                                Отправить
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-7 ps-lg-0 mt-lg-0 mt-3">
                        <div class="row">
                            <div class="container text-lg-start text-center">
                                <h1 class="fs-2 mb-0">
                                    Выберите автомобиль
                                </h1>

                                <nav aria-label="breadcrumb" class="overflow-auto mt-2">
                                    <ol class="breadcrumb d-flex flex-nowrap align-items-center justify-content-lg-start justify-content-center">
                                        <li class="breadcrumb-item d-flex align-items-center">
                                            <a class="text-dark  text-nowrap"
                                               href="#">
                                                Просмотреть каталог
                                            </a>
                                        </li>
                                        <li class="vr align-self-center mx-2" style="height: 7px;"></li>
                                        <li class="breadcrumb-item d-flex align-items-center">
                                            <a class="text-dark  text-nowrap"
                                               data-bs-target="#tradeinmodal" data-bs-toggle="modal"
                                               href="#">
                                                Обмен автомобиля
                                            </a>
                                        </li>
                                        <li class="vr align-self-center mx-2" style="height: 7px;"></li>
                                        <li class="breadcrumb-item d-flex align-items-center">
                                            <a class="text-dark  text-nowrap"
                                               data-bs-target="#buyoutmodal" data-bs-toggle="modal"
                                               href="#">
                                                Выкуп
                                            </a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>

                            <div class="col-lg-6 d-none d-lg-block">
                                <img
                                    alt=""
                                    class="rounded img-fluid"
                                    height="310"
                                    loading="lazy"
                                    src="https://goldstarmotors.ak-top.ru/img/websites/goldstarmotors/nochoose-car.png"
                                    width="440">
                            </div>

                            <div class="col-lg-6">
                                <ul class="ps-3">
                                    <li class="text-uppercase">Автоматизированная система, проверки анкеты</li>
                                    <li class="text-uppercase mt-3">Досрочное погашение кредита <span
                                        class="fw-bold">без штрафа</span></li>
                                    <li class="text-uppercase mt-3">Первоначальный <span class="fw-bold">взнос от 0 рублей</span>
                                    </li>
                                    <li class="text-uppercase mt-3">Срок кредитования от 6 месяцев до 7 лет</li>
                                    <li class="text-uppercase mt-3">Сумма кредитования <span class="fw-bold">до 3 500 000 рублей</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoCredit"
}
</script>

<style scoped>

</style>