<template>
    <div>
        <div class="container-fluid">
            <div class="container p-lg-0 d-flex flex-wrap align-items-end justify-content-between">
                <h1 class="display-5 fw-bolder pb-1">Отзывы</h1>

                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-white">
                        <li class="breadcrumb-item">
                            <a class="text-secondary" href="#">Главная</a>
                        </li>
                        <li aria-current="page" class="breadcrumb-item active">Отзывы</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid-sm">
            <div class="container p-0">
                <div class="row m-0">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 shadow-lg">
                            <div class="row flex-lg-row flex-column w-100 m-0">
                                <div class="col-lg-3 d-flex justify-content-center p-0 mt-lg-3 align-items-start mt-3">
                                    <img
                                        alt=""
                                        class="rounded-pill"
                                        height="100"
                                        src="https://static01.nyt.com/images/2021/10/13/science/13shatner-launch-oldest-person-sub/13shatner-launch-oldest-person-sub-superJumbo.jpg"
                                        width="100">
                                </div>
                                <div class="col-lg-9 d-flex flex-column p-3">
                                    <h3 class="mt-2 mb-0 text-lg-start text-center w-100">Федор Петрович</h3>

                                    <div
                                        class="d-flex flex-lg-row flex-column align-items-center justify-content-between mt-3">
                                        <div>
                                            <p class="text-start p-0 m-0">
                                                <span class="text-muted me-2">5.0</span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                                <span class="fa fa-star text-warning"></span>
                                            </p>
                                        </div>
                                        <p class="text-muted p-0 m-0">12 сент 2021</p>
                                    </div>

                                </div>

                            </div>
                            <div class="row text-start w-100 px-lg-4 p-0 text-lg-start text-center w-100 m-0 mt-3">
                                <p class="d-block content w-100 p-0 m-0 pb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, voluptates!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="row shadow-lg p-3 sticky-lg-top">
                            <h1 class="display-5">Оставить отзыв</h1>

                            <div class="col-12 d-flex flex-column align-content-between p-0 pe-lg-2 pe-0">
                                <input id="name" class="form-control p-2 p-0 mb-2" name="name" placeholder="Ваше имя"
                                       required=""
                                       type="text" value="">
                                <input class="form-control credit-phone-number-mask numberGet-2 input_validate-2 border"
                                       placeholder="+7 (___) ___-__-__"
                                       type="text">

                                <div class="input-group my-2 d-flex justify-content-center">

                                    <div class="rate">
                                        <input id="star5" checked name="star_rating" type="radio" value="5"/>
                                        <label class="mx-1" for="star5" title="text">5 stars</label>
                                        <input id="star4" name="star_rating" type="radio" value="4"/>
                                        <label class="mx-1" for="star4" title="text">4 stars</label>
                                        <input id="star3" name="star_rating" type="radio" value="3"/>
                                        <label class="mx-1" for="star3" title="text">3 stars</label>
                                        <input id="star2" name="star_rating" type="radio" value="2"/>
                                        <label class="mx-1" for="star2" title="text">2 stars</label>
                                        <input id="star1" name="star_rating" type="radio" value="1"/>
                                        <label class="mx-1" for="star1" title="text">1 star</label>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 mt-lg-0 mt-2 p-0">
                            <textarea id="message" class="form-control p-2 h-100" maxlength="1500"
                                      name="message" placeholder="Текст отзыва" required="" rows="5"
                                      spellcheck="false"></textarea>
                            </div>

                            <div class="col-12 p-0">
                                <button class="btn text-uppercase fw-bold btn-warning w-100 p-2 mt-2" type="submit">
                                    Отправить отзыв
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoGuestbook"
}
</script>

<style scoped>
.stars {
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
}

.star {
    color: #91a6ff !important;
}

.rate {
    height: 50px;
    margin-left: -5px;
    padding: 5px;
    font-size: 25px;
    position: relative;
    cursor: pointer;
}

.rate input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    pointer-events: none;
}

.star-over::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 16px;
    content: "\f005";
    display: inline-block;
    color: #d3dcff;
    z-index: 1;
    position: absolute;
    top: 17px;
    left: 10px;
}

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}

.rate:not(:checked) > label:before {
    content: '★ ';
}

.rate > input:checked ~ label {
    color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
</style>