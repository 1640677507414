<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-lg-0">
            <nav aria-label="breadcrumb"
                 class="overflow-auto">
                <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                    <li class="breadcrumb-item d-flex align-items-center">
                        <a class="text-dark fs-14 text-capitalize text-nowrap" href="#">Home</a>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a aria-expanded="false"
                           class="text-dark fs-14 text-capitalize text-nowrap dropdown-toggle" data-bs-toggle="dropdown"
                           href="#">
                            Brands
                        </a>

                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" href="#">20% Off</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">New in</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shop by</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Brands</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Clothing</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shoes</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Bags</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Accessories</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Jewelry</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Pre-owned</a>
                            </li>
                        </ul>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a aria-expanded="false"
                           class="text-dark fs-14 text-capitalize text-nowrap dropdown-toggle" data-bs-toggle="dropdown"
                           href="#">
                            Fila
                        </a>

                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" href="#">20% Off</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">New in</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shop by</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Brands</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Clothing</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shoes</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Bags</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Accessories</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Jewelry</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Pre-owned</a>
                            </li>
                        </ul>
                    </li>

                </ol>
            </nav>

            <h1 class="fw-bold mt-3 fs-3 mb-0">
                Fila
            </h1>

            <div class="d-flex justify-content-between flex-md-row flex-column mt-3">
                <div class="col-md-3 text-secondary fw-light mt-lg-0 mt-3 order-lg-1 order-2">
                    <div class="table-characterostics lh-1 mt-3 fs-15">
                        <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                        <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                    </div>

                    <div class="table-characterostics lh-1 mt-3 fs-15">
                        <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production countries</p>

                        <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                    </div>

                    <div class="table-characterostics lh-1 mt-3 fs-15">
                        <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                        <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                    </div>
                </div>

                <div class="text-lg-start text-center order-lg-2 order-1">
                    <img :src="Fila" alt="" class="opacity-50" width="200">
                </div>
            </div>

            <p class="fs-15 opacity-75 mt-lg-5 mt-3 mb-0">
                Fila is a world famous Italian sportswear and footwear brand with over a century of history. The company
                made history through collaborations with international sports stars such as Bjorn Borg and Reinhold
                Messner. Active advertising in the 90s made Fila branded products a real heritage of street fashion.
                Today, in their collections, designers use iconic models of the brand, created over 100 years.
            </p>
        </div>

        <div class="container px-lg-0 mt-lg-5 mt-3">
            <div class="row m-0">
                <div class="col-lg-2 px-0">
                    <div class="sticky-lg-top" style="top: 130px;">
                        <p class="d-flex align-items-center text-uppercase fw-bold mb-0">
                            Products FILA (104)
                        </p>

                        <ul class="d-flex flex-lg-column flex-row gap-lg-0 gap-3 overflow-auto text-nowrap hide-scroll-mob list-style-none ps-0 fs-15 mb-0">
                            <li class="mt-3">
                                <a class="d-flex align-items-center justify-content-between text-secondary fw-light text-decoration-none"
                                   href="#">
                                    <span>
                                        Men shoes<span class="text-dark fs-12 fw-bold ms-1">(104)</span>
                                    </span>

                                    <i class="fa-solid fa-chevron-right fs-12"></i>
                                </a>
                            </li>

                            <li class="mt-3">
                                <a class="d-flex align-items-center justify-content-between text-secondary fw-light text-decoration-none"
                                   href="#">
                                    <span>
                                        Women shoes<span class="text-dark fs-12 fw-bold ms-1">(104)</span>
                                    </span>

                                    <i class="fa-solid fa-chevron-right fs-12"></i>
                                </a>
                            </li>

                            <li class="mt-3">
                                <a class="d-flex align-items-center justify-content-between text-secondary fw-light text-decoration-none"
                                   href="#">
                                    <span>
                                        Child shoes<span class="text-dark fs-12 fw-bold ms-1">(104)</span>
                                    </span>

                                    <i class="fa-solid fa-chevron-right fs-12"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-10 ps-lg-3 pe-lg-0 px-0 mt-lg-0 mt-3">
                    <ModaBrandProduct/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fila from "@/assets/websites/moda/img/fila.svg"
import ModaBrandProduct from "@/components/websites/moda/parts/BrandProduct";

export default {
    name: "ModaBrand",

    components: {
        ModaBrandProduct,
    },

    data() {
        return {
            Fila
        }
    }
}
</script>

<style scoped>
.dropdown-toggle::after {
    display: none;
}

.table-characterostics::after {
    content: '.............................................................................................................................';
    display: block;
    white-space: nowrap;
    overflow: hidden;
    color: #dee2e6;
}

</style>