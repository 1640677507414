// import Vue from 'vue'
// import VueRouter from 'vue-router'
import LandingLayout from "@/components/websites/allmenu/landing/LandingLayout.vue"
import Menu from "@/components/websites/allmenu/Menu"
import ViewProduct from "@/components/websites/allmenu/product/ViewProduct.vue"
import Deseart from "@/components/websites/allmenu/landing/Deseart.vue";
import ViewReceipt from "@/components/websites/allmenu/landing/ViewReceipt.vue";
import activate from "@/components/websites/allmenu/activate"
import Favicon from "@/assets/websites/allmenu/img/favicon.png"
import { createRouter, createWebHistory } from 'vue-router'
// Vue.use(VueRouter)

const favicon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: LandingLayout,
        meta: {
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/deseart',
        name: 'deseart',
        component: Deseart,
        meta: {
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/view-receipt',
        name: 'view-receipt',
        component: ViewReceipt,
        meta: {
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/:locale/:companyKey/activate',
        name: 'activate',
        component: activate,
        meta: {
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/:locale/:companyKey/:categoryId/:productId',
        name: 'product',
        component: ViewProduct,
        meta: {
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/:companyKey',
        component: Menu,
        name: 'menu-index',
        meta: {
            layout: 'MenuLayout',
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/:locale/:companyKey',
        component: Menu,
        name: 'menu-index-locale',
        meta: {
            layout: 'MenuLayout',
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    },
    {
        path: '/:locale/:companyKey/:slug',
        component: Menu,
        name: 'menu-category',
        meta: {
            layout: 'MenuLayout',
            title: '##COMPANY_NAME## - AllMenu.Ge - Online QR Menu',
            favicon: favicon
        }
    }
].map(route => ({
    ...route,
    meta: {
        ...route.meta,
        translationKey: 'allmenu'
    }
}));

// const router = new VueRouter({
//     mode: 'history',
//     routes
// })

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
