<template>
    <div>
        <div v-if="carPart && carPart.images && carPart.images.length" id="carouselExampleFade"
             class="carousel slide carousel-fade car__part_carousel">
            <div class="carousel-inner shadow-sm rounded">
                <div v-for="(image, index) in getImagesBySize((carPart.images || []), 'LARGE')"
                     :key="image.id" :class="['carousel-item', { 'active': index === activeIndex }]">
                    <!-- Image for md and sm devices -->
                    <div class="d-block d-lg-none" data-bs-target="#zoomImg" data-bs-toggle="modal">
                        <img :src="image.url" alt="" class="d-block w-100 slide__img rounded">
                    </div>

                    <!-- Image for lg devices -->
                    <div class="d-none d-lg-block" data-bs-target="#zoomImg" data-bs-toggle="modal">
                        <image-magnifier :src="image.url"
                                         :zoom-src="image.url"
                                         class="d-block w-100 rounded"
                                         zoom-height="500"
                                         zoom-width="500"/>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleFade"
                    type="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </button>

            <div v-if="carPart.images && carPart.images.length" class="carousel-indicators gap-3 m-0 position-static">
                <button v-for="(image, index) in getImagesBySize((carPart.images || []), 'SMALL')" :key="image.id"
                        :class="{'active': index === activeIndex}" :data-bs-slide-to="index" aria-current="true"
                        aria-label="Slide 1" data-bs-target="#carouselExampleFade" type="button">
                    <img :src="image.url" alt="" class="d-block" width="90">
                </button>
            </div>
        </div>

        <div v-else>
            <img :src="NoImage" alt="" class="w-100">
        </div>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image-1.jpg"
import _ from "lodash";
import {ImageMagnifier} from "vue-image-magnifier"

export default {
    name: 'SlideProductGP',

    components: {
        ImageMagnifier
    },

    props: {
        carPart: {
            required: true,
        }
    },

    methods: {
        getImagesBySize(images, size) {
            return _.filter(images, {image_type: size}) || []
        },
    },

    data() {
        return {
            NoImage,
            activeIndex: 0
        }
    }
}
</script>

<style>
.slide__img,
.car__part_carousel .image-magnifier__img {
    object-fit: cover;
}

.car__part_carousel .image-magnifier__img {
    border-radius: 0.375rem;
}

@media screen and (min-width: 1400px) {
    .car__part_carousel .carousel-item .slide__img {
        height: 532px !important;
    }

    .car__part_carousel .image-magnifier .image-magnifier__img {
        height: 532px !important;
        width: 532px !important;
    }
}

@media screen and  (max-width: 1399px) {
    .carousel-item .slide__img {
        height: 457px;
    }

    .car__part_carousel .image-magnifier .image-magnifier__img {
        height: 457px !important;
        width: 457px !important;
    }
}

@media screen and  (max-width: 1200px) {
    .car__part_carousel .image-magnifier .image-magnifier__img {
        width: 382px !important;
    }
}

@media screen and (min-width: 992px) {
    .car__part_carousel .image-magnifier__mask {
        background-color: #ffc107 !important;
    }

    .car__part_carousel {
        z-index: 1030;
    }

    .car__part_carousel .carousel-inner {
        overflow: visible !important;
    }

    .car__part_carousel .carousel-control-prev,
    .car__part_carousel .carousel-control-next {
        height: max-content;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 760px) {
    .carousel-item .slide__img {
        height: 357px;
    }
}

.car__part_carousel .carousel-indicators button {
    width: auto !important;
    height: auto !important;
    margin-right: unset !important;
    margin-left: unset !important;
    text-indent: unset !important;
}

.car__part_carousel .carousel-indicators .slide__img {
    height: 90px;
}
</style>