<template>
    <div>
        <div class="container-fluid bg-danger d-none d-lg-block px-0">
            <div class="container d-flex align-items-center justify-content-around py-4">
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    До 3 500 000 &#8381;
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                по двум документам
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    до 7 лет!
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                срок кредита
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    0 &#8381;
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                первый взнос
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    Без КАСКО!
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                решаете сами
            </span>
                </p>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container p-0 d-flex justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent d-flex align-items-center mb-0 px-lg-0 px-2">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }" class="text-dark fs-sm-14">Главная</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item fs-sm-14">Услуги</li>

                        <li aria-current="page" class="breadcrumb-item fs-sm-14 active">Обмен</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container px-0">
                <div class="row m-0 align-items-center  bg-white shadow-sm p-lg-4 p-2">
                    <div class="col-lg-3 px-0 pe-lg-5 border-end">

                        <h1 class="fs-5 fs-sm-16 text-uppercase text-lg-end text-center mb-3">trade-in</h1>

                        <div class="row row-cols-lg-1 row-cols-3 mt-lg-5">
                            <div class="col p-lg-0">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-center text-uppercase">
                                    <span class="fs-14 text-end d-none d-lg-block">ваш автомобиль</span>
                                    <span class="stage_badge rounded-circle bg-danger text-white fs-sm-12 ms-2">1</span>
                                </div>

                                <div class="text-end my-lg-4 pe-lg-2 d-none d-lg-block">
                                    <i class="fas fa-circle"
                                       style="font-size: 5px; color: rgba(147, 147, 147, 0.6);"></i>
                                </div>
                            </div>

                            <div class="col p-lg-0 mt-lg-3">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-center text-uppercase">
                                    <span class="fs-14 text-end d-none d-lg-block">Личные данные</span>
                                    <span class="stage_badge rounded-circle border fs-sm-12 ms-2"
                                          style="color: #939393; border-color: #939393 !important;">2</span>
                                </div>
                                <div class="text-end my-lg-4 pe-lg-2 d-none d-lg-block">
                                    <i class="fas fa-circle"
                                       style="font-size: 5px; color: rgba(147, 147, 147, 0.6);"></i>
                                </div>
                            </div>

                            <div class="col p-0 text-uppercase">
                                <div
                                    class="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-center text-uppercase">
                                    <span class="fs-14 text-end d-none d-lg-block">оформлениe кредита</span>
                                    <span class="stage_badge rounded-circle border fs-sm-12 ms-2"
                                          style="color: #939393; border-color: #939393 !important;">3</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-9 px-0 ps-lg-3 mt-lg-0 mt-3">
                        <ul id="pills-tab" class="nav nav-pills mb-3 gap-3" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button id="pills-home-tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        class="nav-link btn btn-danger text-white"
                                        data-bs-target="#pills-home"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        type="button">
                                    First Step
                                </button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button id="pills-profile-tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                        class="nav-link btn btn-danger text-white"
                                        data-bs-target="#pills-profile"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        type="button">
                                    Second Step
                                </button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button id="pills-contact-tab"
                                        aria-controls="pills-contact"
                                        aria-selected="false"
                                        class="nav-link btn btn-danger text-white"
                                        data-bs-target="#pills-contact"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        type="button">
                                    Third Step
                                </button>
                            </li>
                        </ul>

                        <div id="pills-tabContent" class="tab-content">
                            <div id="pills-home" aria-labelledby="pills-home-tab" class="tab-pane fade show active"
                                 role="tabpanel"
                                 tabindex="0">
                                <FirstStepTradeIn/>
                            </div>

                            <div id="pills-profile" aria-labelledby="pills-profile-tab" class="tab-pane fade"
                                 role="tabpanel"
                                 tabindex="0">
                                <SecondStepTradeIn/>
                            </div>

                            <div id="pills-contact" aria-labelledby="pills-contact-tab" class="tab-pane fade"
                                 role="tabpanel"
                                 tabindex="0">
                                <ThirdStepTradeIn/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import FirstStepTradeIn from "@/components/websites/rb-auto/parts/trade-in-steps/FirstStepTradeIn.vue";
import SecondStepTradeIn from "@/components/websites/rb-auto/parts/trade-in-steps/SecondStepTradeIn.vue";
import ThirdStepTradeIn from "@/components/websites/rb-auto/parts/trade-in-steps/ThirdStepTradeIn.vue";

export default {
    components: {ThirdStepTradeIn, SecondStepTradeIn, FirstStepTradeIn}
}
</script>

<style scoped>
.stage_badge {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>