<template>
    <div>
        <div class="container-fluid mt-lg-5 mt-3">
            <div class="container px-lg-0 px-1">
                <h1 class="fs-2 mb-0">
                    Trade-in
                </h1>

                <nav aria-label="breadcrumb" class="overflow-auto mt-2">
                    <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                        <li class="breadcrumb-item d-flex align-items-center">
                            <a class="text-dark text-nowrap"
                               href="#">
                                Главная
                            </a>
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center active">
                            <a class="text-dark text-nowrap"
                               href="#">
                                Услуги
                            </a>
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center text-nowrap active">
                            Trade-in
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid">
            <div class="container px-lg-0 px-1">
                <div class="row m-0 justify-content-between">

                    <div class="col-lg-4 px-0">
                        <div action="#" class="bg-white rounded-3 shadow-lg p-lg-4 p-0">
                            <h3 class="fs-18 fs-sm-16">Ваш автомобиль</h3>

                            <select
                                class="form-select form-select-md bg-light border-0 rounded mt-3 py-2 text-secondary">
                                <option value="">Марка</option>
                            </select>

                            <select
                                class="form-select form-select-md bg-light border-0 rounded mt-3 py-2 text-secondary">
                                <option value="">Модель</option>
                            </select>

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Пробег тыс. км" type="number">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Год выпуска" type="number">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Желаемая цена" type="number">

                            <h3 class="mt-4 mb-3">Персональные данные</h3>

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Имя" type="text">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary client-phone credit-phone-number-mask buy-out-number input_validate"
                                placeholder="Номер телефона" required type="tel">

                            <div class="form-check mt-3">
                                <input id="flexCheckChecked" checked="" class="form-check-input" type="checkbox"
                                       value="">
                                <label class="form-check-label fs-14 fs-sm-12" for="flexCheckChecked">
                                    Даю согласие на обработку персональных данных
                                </label>
                            </div>

                            <button class="btn rounded btn-warning text-uppercase w-100 py-2 mt-3" type="submit">
                                отправить заявку
                            </button>

                        </div>
                    </div>

                    <div class="col-lg-7 px-0 mt-lg-0 mt-3">
                        <div class="mt-lg-4 mt-3 bg-white border rounded p-3">
                            <p class="mb-0 fs-4">
                                Покупать автомобиль в TRADE-IN
                                это выгодно
                            </p>
                            <p class="mb-0 mt-2" style="color: rgba(31, 31, 31, 0.5);">
                                Это бесплатно! Мы отправим Вашу заявку и получим ответ от банков за 30 минут
                            </p>
                            <ul class="ps-3 mt-lg-4 mt-3 fs-sm-15 mb-lg-2 mb-0">
                                <li style="list-style: disc;">Автоматизированная система, проверки анкеты</li>
                                <li class="mt-3" style="list-style: disc;">Досрочное погашение кредита <strong>без
                                    штрафа</strong></li>
                                <li class="mt-3" style="list-style: disc;">Первоначальный <strong>взнос от 0
                                    рублей</strong>
                                </li>
                                <li class="mt-3" style="list-style: disc;">Процент по кредиту <strong>4.9%
                                    годовых</strong>
                                </li>
                                <li class="mt-3" style="list-style: disc;">Срок кредитования от 6 месяцев до 7 лет</li>
                                <li class="mt-3" style="list-style: disc;">Сумма кредитования <strong>до 3 500 000
                                    рублей</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoTradeIn"
}
</script>

<style scoped>

</style>