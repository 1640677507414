<template>
    <div class="row m-0">
        <div class="col-lg-3 col-md-3">
            <select class="form-select form-select-md">
                <option value="">Марка</option>
                <option value="">Acura</option>
                <option value="">Acura</option>
                <option value="">Acura</option>
                <option value="">Acura</option>
                <option value="">Acura</option>
                <option value="">Acura</option>
            </select>
        </div>

        <div class="col-lg-3 col-md-3">
            <select class="form-select form-select-md">
                <option value="">Модель</option>
            </select>
        </div>

        <div class="col-lg-3 col-md-3">
            <div class="d-flex align-items-center gap-3">
                <select class="form-select form-select-md">
                    <option value="">Год от</option>
                </select>

                <select class="form-select form-select-md">
                    <option value="">Год до</option>
                </select>
            </div>

        </div>

        <div class="col-lg-3 col-md-3">
            <select class="form-select form-select-md">
                <option value="">Тип дивгателя</option>
            </select>
        </div>

        <div class="col-lg-3 col-md-3">
            <select class="form-select form-select-md">
                <option value="">Тип кузова</option>
            </select>
        </div>

        <div class="col-lg-3 col-md-3">
            <select class="form-select form-select-md">
                <option value="">Тип КПП</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoFilter"
}
</script>

<style scoped>

</style>