<template>
    <div id="reviewsPicsModal" aria-hidden="true" aria-labelledby="reviewsPicsModalLabel"
         class="modal reviewsPicsModal fade"
         tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content bg-dark">
                <div class="modal-header border-0">
                    <button aria-label="Close" class="btn-close btn-close-white" data-bs-dismiss="modal"
                            type="button"></button>
                </div>

                <div class="modal-body d-flex align-items-center justify-content-center">
                    <div>
                        <button class="carousel-control-prev" data-bs-slide="prev"
                                data-bs-target="#carouselExampleControls"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" data-bs-slide="next"
                                data-bs-target="#carouselExampleControls"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-next-icon"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div id="carouselExampleControls" class="carousel carousel-fade slide" data-bs-ride="carousel">
                        <div class="row m-0">
                            <div class="col-lg-auto">
                                <div class="carousel-inner text-center">
                                    <div class="carousel-item active" data-bs-interval="10000000000000">
                                        <img alt=""
                                             class="reviews-modal-pic img-fluid"
                                             src="https://image.geeko.ltd/comment/medium/116Y4H3a2a7A5F8c5e2i2c0l6S">
                                    </div>
                                    <div class="carousel-item" data-bs-interval="10000000000000">
                                        <img alt=""
                                             class="reviews-modal-pic img-fluid"
                                             src="https://image.geeko.ltd/comment/medium/1y6h4A3l2D7B5Z8j5x3Q3e6C81">
                                    </div>
                                    <div class="carousel-item" data-bs-interval="10000000000000">
                                        <img alt=""
                                             class="reviews-modal-pic img-fluid"
                                             src="https://image.geeko.ltd/comment/medium/1F6B4R3T2N7N588y5A5X7U8H9n">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-auto">
                                <div class="carousel-indicators slide-small-pics position-static flex-column">
                                    <button aria-current="true" aria-label="Slide 1"
                                            class="border-0 p-0 border-bottom slide__btn active" data-bs-slide-to="0"
                                            data-bs-target="#reviewsPicsModal"
                                            type="button">
                                        <img
                                            alt="product name"
                                            src="https://image.geeko.ltd/comment/medium/116Y4H3a2a7A5F8c5e2i2c0l6S"
                                            title="product name" width="100">
                                    </button>

                                    <button aria-label="Slide 2" class="border-0 p-0 border-bottom slide__btn"
                                            data-bs-slide-to="1" data-bs-target="#reviewsPicsModal"
                                            type="button">
                                        <img
                                            alt="product name"
                                            src="https://image.geeko.ltd/comment/medium/1y6h4A3l2D7B5Z8j5x3Q3e6C81"
                                            title="product name" width="100">
                                    </button>

                                    <button aria-label="Slide 3" class="border-0 p-0 border-bottom slide__btn"
                                            data-bs-slide-to="2" data-bs-target="#reviewsPicsModal"
                                            type="button">
                                        <img
                                            alt="product name"
                                            src="https://image.geeko.ltd/comment/medium/1F6B4R3T2N7N588y5A5X7U8H9n"
                                            title="product name" width="100">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaReviewsPicsPopup"
}
</script>

<style>
.reviewsPicsModal.modal.fade .modal-dialog {
    transition: unset !important;
}

.reviewsPicsModal.fade {
    transition: unset !important;
}
.reviewsPicsModal .carousel-indicators.slide-small-pics .slide__btn {
    box-sizing: unset !important;
    flex: unset !important;
    width: 100% !important;
    height: 100% !important;
    text-indent: unset !important;
    background-color: unset !important;
    margin-inline: unset !important;
}
@media screen and (min-width: 992px) {
    .reviews-modal-pic {
        height: 90vh;
    }
}
</style>