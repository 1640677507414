<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">

        <div class="container px-0">
            <AutoCategories/>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <AutoFilter/>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <AutoSaleables/>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <div class="row m-0 border rounded">
                <div class="col-lg-8 px-0 pe-lg-2">
                    <AutoCalculator/>
                </div>

                <div class="col-lg-4 px-0 ps-lg-2 mt-lg-0 mt-3">
                    <AutoCallRequestBlock/>
                </div>
            </div>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <AutoCreditRequestBlock/>
        </div>

    </div>
</template>

<script>


import AutoCategories from "@/components/websites/auto/parts/Categories.vue";
import AutoFilter from "@/components/websites/auto/parts/Filter.vue";
import AutoSaleables from "@/components/websites/auto/parts/Saleables.vue";
import AutoCalculator from "@/components/websites/auto/parts/Calculator.vue";
import AutoCreditRequestBlock from "@/components/websites/auto/parts/CreditRequestBlock.vue";
import AutoCallRequestBlock from "@/components/websites/auto/parts/CallRequestBlock.vue";

export default {
    name: 'AutoIndex',
    components: {
        AutoCallRequestBlock,
        AutoCalculator,
        AutoCreditRequestBlock,
        AutoSaleables,
        AutoFilter,
        AutoCategories
    },

    data() {
        return {}
    }

}
</script>

<style lang="scss" scoped>

</style>