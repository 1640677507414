<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-lg-0">
            <nav aria-label="breadcrumb"
                 class="overflow-auto">
                <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                    <li class="breadcrumb-item d-flex align-items-center">
                        <a class="text-dark fs-14 text-capitalize text-nowrap" href="#">Home</a>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a class="text-dark fs-14 text-capitalize text-nowrap"
                           href="#">
                            Catalog
                        </a>
                    </li>
                </ol>
            </nav>
        </div>

        <div class="container px-lg-0 mt-3">
            <h1 class="fw-bold fs-3 mb-0">
                Brands
            </h1>

            <p class="fs-14 text-secondary fw-light mt-3 m-0">
                The online store features brands from France, Italy, Spain, Germany, the UK and the USA. The collection
                includes shoes, bags and accessories Ash, Calvin Klein, Casadei, Dino Bigioni, Gerard Henon, Gianni
                Chiarini, Giovanni Fabiani, Jil Sander Navy, Katy Perry, Kenzo, Lacoste, Lloyd, Loriblu, Marc Jacobs,
                Michael Kors, MM6 Maison Margiela, Nero Giardini, New Balance, See by Chloé and others. Direct
                interaction with brand owners gives Rendez-Vous a unique opportunity to adapt popular models from the
                collections of leading manufacturers to the climatic conditions of Russia and offer customers
                interesting collaborations.
            </p>

            <h2 class="fs-4 mt-lg-5 mt-3 mb-0">
                Brands Alphabet
            </h2>

            <div
                class="d-flex align-items-center justify-content-between gap-lg-0 gap-4 text-nowrap overflow-auto mt-3">
                <a class="d-block text-dark text-decoration-none" href="#">
                    0-9
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    A-Z
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    A
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    B
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    C
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    D
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    E
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    F
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    G
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    H
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    I
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    J
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    K
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    L
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    M
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    N
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    O
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    P
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    R
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    S
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    T
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    U
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    W
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    W
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    X
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    Y
                </a>

                <a class="d-block text-dark text-decoration-none" href="#">
                    Z
                </a>
            </div>

            <h3 class="fs-4 mt-lg-5 mt-3 mb-0">
                Top Brands
            </h3>

            <div class="row row-cols-lg-6 row-cols-md-4 cols-2 mt-3">
                <div class="col">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Adidas" alt="Adidas" class="brand-img" title="Adidas">
                    </router-link>
                </div>

                <div class="col">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="NewBalance" alt="NewBalance" class="brand-img" title="NewBalance">
                    </router-link>
                </div>

                <div class="col mt-lg-0 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Vagabond" alt="Vagabond" class="brand-img" title="Vagabond">
                    </router-link>
                </div>

                <div class="col mt-lg-0 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Lacoste" alt="Lacoste" class="brand-img" title="Lacoste">
                    </router-link>
                </div>

                <div class="col mt-lg-0 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Reebok" alt="Reebok" class="brand-img" title="Reebok">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Puma" alt="Puma" class="brand-img" title="Puma">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Puma" alt="Puma" class="brand-img" title="Puma">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Reebok" alt="Reebok" class="brand-img" title="Reebok">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Lacoste" alt="Lacoste" class="brand-img" title="Lacoste">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Vagabond" alt="Vagabond" class="brand-img" title="Vagabond">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="NewBalance" alt="NewBalance" class="brand-img" title="NewBalance">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Adidas" alt="Adidas" class="brand-img" title="Adidas">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Adidas" alt="Adidas" class="brand-img" title="Adidas">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="NewBalance" alt="NewBalance" class="brand-img" title="NewBalance">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Vagabond" alt="Vagabond" class="brand-img" title="Vagabond">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Lacoste" alt="Lacoste" class="brand-img" title="Lacoste">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Reebok" alt="Reebok" class="brand-img" title="Reebok">
                    </router-link>
                </div>

                <div class="col mt-lg-4 mt-3">
                    <router-link :to="{ name:'brand'}"
                                 class="d-flex justify-content-center text-decoration-none p-2 brand-img-container">
                        <img :src="Puma" alt="Puma" class="brand-img" title="Puma">
                    </router-link>
                </div>

            </div>

            <h4 class="fs-4 mt-lg-5 mt-3 mb-0">
                All Brands
            </h4>

            <div class="row align-items-start mt-3 border-bottom pb-3">
                <div class="col-md-2 col-2">
                    0-9
                </div>

                <div class="col-md-10 col-10">
                    <div class="row row-cols-lg-4 row-cols-1">
                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                0'105 ZEROCENTCINQ

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                4CCCCEES

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                7AM

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                №21

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row align-items-start mt-3 border-bottom pb-3">
                <div class="col-md-2 col-2">
                    A
                </div>

                <div class="col-md-10 col-10">
                    <div class="row row-cols-lg-4 row-cols-1">
                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                ABRICOT

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ACCESSOIRE DIFFUSION

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ADIDAS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ADIEU PARIS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                AGNELLE

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ALLUSION

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                ANTARCTICA

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ARCHE

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ARCUS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ARGO

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ARNY PRAHT

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ARTIKA SOFT

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                ASH

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ASICS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ASICS TIGER

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                AVELURIS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                AVENUE by GIOTTO

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                AVRIL GAU

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                AZUREE

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row align-items-start mt-3 border-bottom pb-3">
                <div class="col-md-2 col-2">
                    B
                </div>

                <div class="col-md-10 col-10">
                    <div class="row row-cols-lg-4 row-cols-1">
                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                BADURA

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BAGGHY

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BAGLLERINA

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BAITELLI

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                AGNELLE

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                ALLUSION

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col d-flex flex-column gap-3 mb-3">
                            <div class="position-relative brand-name">
                                BIKKEMBERGS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BLIPERS

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BLOCCO 5

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BLUGIRL

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BOCAGE

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>

                            <div class="position-relative brand-name">
                                BOTTERO

                                <div class="position-absolute bg-white shadow fs-14 p-3 brand-info d-none">
                                    <div class="d-flex align-items-center gap-3">
                                        <img :src="Adidas" alt="Adidas" width="50">

                                        <div>
                                            <div class="table-characterostics lh-1">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Country of origin</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">Italy</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Production
                                                    countries</p>

                                                <p class="float-end mb-0 lh-1 ps-2 text-uppercase">China</p>
                                            </div>

                                            <div class="table-characterostics lh-1 mt-3">
                                                <p class="float-start mb-0 lh-1 pe-2 opacity-75">Rating</p>

                                                <p class="float-end mb-0 lh-1 ps-2">5 /2382</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-secondary fs-12 mt-3 mb-0">
                                        German industrial concern producing sportswear, footwear, accessories and
                                        equipment. Adidas is one of the most popular and best selling sportswear brands
                                        in the world. Company founder Adolf Dassler began making shoes in the 1920s.
                                        Together with his brother Rudolf, in 1924 they officially opened the Gebruder
                                        Dassler shoe company.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Adidas from "@/assets/websites/moda/img/adidas-logo.png"
import NewBalance from "@/assets/websites/moda/img/nb-logo.png"
import Vagabond from "@/assets/websites/moda/img/vagabond-logo.png"
import Lacoste from "@/assets/websites/moda/img/lacoste-logo.png"
import Reebok from "@/assets/websites/moda/img/reebok-logo.png"
import Puma from "@/assets/websites/moda/img/puma-logo.png"

export default {
    name: "ModaCatalog",

    data() {
        return {
            Adidas,
            NewBalance,
            Vagabond,
            Lacoste,
            Reebok,
            Puma
        }
    }
}
</script>

<style scoped>
.brand-img-container {
    transition: .3s;
}

.brand-img-container:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.brand-img {
    width: 80px;
    height: 54px;
}

.table-characterostics::after {
    content: '.............................................................................................................................';
    display: block;
    white-space: nowrap;
    overflow: hidden;
    color: #dee2e6;
}

.brand-name {
    width: max-content;
}

.brand-info {
    z-index: 1;
}

.brand-name:hover .brand-info {
    display: block !important;
}
</style>