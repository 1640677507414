<template>
    <div class="container-fluid position-relative contacts px-0">
        <div class="row flex-wrap-reverse m-0">
            <div class="col-12 p-0 mt-lg-0 mt-3 mb-lg-0 mb-3 map text-center" style="height: 80vh">
                <div class="mb-lg-0 mb-3 position-absolute start-50 translate-middle-x mobile_static"
                     style="top: 20px;">
                    <a href="https://yandex.com/maps/org/72783047793" target="_blank">
                        Перейти в Яндекс.Навигатор
                    </a>
                </div>

                <div class="h-100">
                    <iframe
                        allowfullscreen=""
                        height="100%" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d23659.40159911621!2d42.3420053!3d42.1625856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sge!4v1685969911358!5m2!1sru!2sge"
                        style="border:0;"
                        width="100%"></iframe>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-12 position-absolute bg-white shadow px-0"
                 style="top: 100px; left: 165px;">
                <div class="p-3">
                    <h1 class="fs-4">Контакты</h1>
                    <div class="mt-lg-4 mt-3">
                        <span class="fs-12 text-uppercase opacity-80">
                            адресс:
                        </span>
                        <p class="mt-2">
                            метро Юго-Западная, <br>
                            улица Коштоянца, 22
                        </p>
                    </div>

                    <div class="mt-lg-4 mt-3 phone_hover">
                        <div class="d-flex justify-content-between mb-2">
                            <span class="fs-12 text-uppercase opacity-80">
                                телефон:
                            </span>
                        </div>
                        <a class="text-dark text-decoration-none"
                           href="tel:+7 (925) 478-2822">
                            +7 (925) 478-2822
                        </a>
                    </div>

                    <div class="mt-lg-4 mt-3">
                        <span class="fs-12 text-uppercase opacity-80">
                            график работы:
                        </span>
                        <p class="mb-0">
                            Ежедневно с 09:00 до 21:00
                        </p>
                    </div>

                    <button class="btn btn-outline-danger w-100 text-uppercase mt-3" data-bs-target="#callRequestModal"
                            data-bs-toggle="modal">
                        Обратный звонок
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactsRbAuto',

    data() {
        return {}
    }
}
</script>

<style scoped>
.map iframe {
    height: 100% !important;
}

@media screen and (max-width: 991px) {
    .contacts .position-relative {
        position: static !important;
    }

    .contacts .position-absolute {
        position: static !important;
    }

    .mobile_static {
        position: static !important;
        transform: unset !important;
    }
}

</style>