<template>
    <div class="container-fluid px-0 d-none d-lg-block">
        <div>
            <img :src="CoverPic" alt="" class="w-100 img-fluid cover__pic">
        </div>
    </div>
</template>

<script>
import CoverPic from "@/assets/websites/get-parts/img/cover.png"

export default {
    name: 'SliderGP',

    data() {
        return {
            CoverPic
        }
    }
}
</script>


<style scoped>
.carousel-item img {
    max-height: 280px;
    object-fit: cover;
}
</style>