<template>
    <div id="sizesGuideModal" aria-hidden="true" aria-labelledby="sizesGuideModalLabel" class="modal fade"
         tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content scroll-styles">
                <div class="modal-header border-0">
                    <h5 id="sizesGuideModalLabel" class="modal-title text-uppercase fs-6">
                        SIZE GUIDE
                    </h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="d-flex flex-lg-row flex-column">
                        <div>
                            <img
                                alt="product name"
                                class=" img-fluid"
                                src="https://img.mysilver.ge/TEST/product/small/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                                title="product name">
                        </div>


                        <div class="d-flex flex-column justify-content-between h-100 mt-lg-0 mt-2">
                            <div>
                                <p class="fw-bold fs-5 mb-0">
                                    Premiata
                                </p>

                                <p class="mb-0">
                                    Golden Goose
                                </p>
                            </div>

                            <div class="border border-dark bg-light p-2 fs-14">
                                <strong>Fitting Information:</strong> <br>
                                This piece fits true to size. We recommend you get your regular size
                                Cut for a slim fit
                                Made with a mid-weight fabric
                            </div>
                        </div>

                    </div>

                    <div class="mt-3 fs-14">
                        <div class="row m-0">
                            <div class="col-4 d-flex align-items-center fw-bold">Sm</div>

                            <div class="col-4 d-flex align-items-center fw-bold">Size</div>

                            <div class="col-4 ps-lg-2 ps-0 pe-0 fw-bold">
                                <div class="d-flex align-items-center ps-lg-3">
                                    Price
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">15</div>

                            <div class="col-4 d-flex align-items-center">22.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">16</div>

                            <div class="col-4 d-flex align-items-center">24</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">15</div>

                            <div class="col-4 d-flex align-items-center">22.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">17</div>

                            <div class="col-4 d-flex align-items-center">25.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">18</div>

                            <div class="col-4 d-flex align-items-center">27</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">19</div>

                            <div class="col-4 d-flex align-items-center">28.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">20</div>

                            <div class="col-4 d-flex align-items-center">30</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">21</div>

                            <div class="col-4 d-flex align-items-center">31.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">22</div>

                            <div class="col-4 d-flex align-items-center">33</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">23</div>

                            <div class="col-4 d-flex align-items-center">35.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">24</div>

                            <div class="col-4 d-flex align-items-center">36</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">25</div>

                            <div class="col-4 d-flex align-items-center">37.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">26</div>

                            <div class="col-4 d-flex align-items-center">39</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">27</div>

                            <div class="col-4 d-flex align-items-center">40.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">28</div>

                            <div class="col-4 d-flex align-items-center">42</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">29</div>

                            <div class="col-4 d-flex align-items-center">43.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">30</div>

                            <div class="col-4 d-flex align-items-center">45</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">31</div>

                            <div class="col-4 d-flex align-items-center">46.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">32</div>

                            <div class="col-4 d-flex align-items-center">48</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">33</div>

                            <div class="col-4 d-flex align-items-center">49.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">34</div>

                            <div class="col-4 d-flex align-items-center">51</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">35</div>

                            <div class="col-4 d-flex align-items-center">52.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">36</div>

                            <div class="col-4 d-flex align-items-center">54</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">37</div>

                            <div class="col-4 d-flex align-items-center">55.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">38</div>

                            <div class="col-4 d-flex align-items-center">57</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">39</div>

                            <div class="col-4 d-flex align-items-center">58.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">40</div>

                            <div class="col-4 d-flex align-items-center">60</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">41</div>

                            <div class="col-4 d-flex align-items-center">61.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">42</div>

                            <div class="col-4 d-flex align-items-center">63</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">43</div>

                            <div class="col-4 d-flex align-items-center">64.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">44</div>

                            <div class="col-4 d-flex align-items-center">66</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">45</div>

                            <div class="col-4 d-flex align-items-center">67.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">46</div>

                            <div class="col-4 d-flex align-items-center">69</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">47</div>

                            <div class="col-4 d-flex align-items-center">70.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">48</div>

                            <div class="col-4 d-flex align-items-center">72</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-4 d-flex align-items-center">49</div>

                            <div class="col-4 d-flex align-items-center">73.5</div>

                            <div class="col-4 pe-0">
                                <div class="d-flex align-items-center justify-content-end gap-5">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPic from "@/assets/websites/moda/img/view-product.png"

export default {
    name: "ModaSizesGuidePopup",

    data() {
        return {
            ProductPic
        }
    }
}
</script>

<style scoped>

.sizesRow:nth-child(odd) {
    background-color: #f5f5f5;
}

.sizesRow {
    transition: .3s;
}

.sizesRow:hover {
    background-color: #e6e6e6;
}

#sizesGuideModal .modal-lg {
    max-width: 680px !important;
}

</style>
