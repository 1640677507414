<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li v-if="carPart && carPart.mark" class="breadcrumb-item">
                            <router-link :to="{
                                name:'mark',
                                params: {
                                    locale: $route.params.locale,
                                    mark: data_get(carPart, 'mark.code')
                                }
                            }">
                                {{ breadCrumbName }}
                            </router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            {{ data_get(carPart, 'name') }}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <template v-if="!carPartLoaded">
            <LoaderViewProduct/>
        </template>

        <template v-else>
            <ZoomImagePopup v-if="carPart && carPart.images && carPart.images.length" :car-part="carPart"/>

            <div class="container-fluid px-0 mt-3">
                <div class="container px-0">
                    <div class="row justify-content-between m-0">
                        <div class="col-lg-5 col-md-7 px-2 order-1">
                            <SlideProductGP :car-part="carPart"/>
                        </div>

                        <div class="col-lg-4 mt-lg-0 mt-3 px-2 order-lg-2 order-md-3 order-2">
                            <div class="bg-white shadow-sm rounded p-3">
                                <h1 class="fs-5 fw-bold mb-0">
                                    {{ getTranslatedPropertyByLocale(carPart, 'name', locale) }}
                                </h1>

                                <h2 class="fs-6 mt-3 mb-0">
                                    {{ getSupportedCars(carPart) }}
                                </h2>

                                <div class="d-flex gap-2 mt-3">
                                    <div>
                                        <span v-if="carPart && carPart.condition === 'NEW'" class="badge bg-info">
                                            {{ trans('new') }}
                                        </span>

                                        <span v-else class="badge bg-warning">
                                             {{ trans('secondary') }}
                                        </span>
                                    </div>

                                    <div
                                        v-if="carPart && carPart.categories && carPart.categories.length"
                                        class="d-flex align-items-center gap-2"
                                    >
                                        <span v-for="category in carPart.categories" :key="category.id"
                                              class="badge bg-success">
                                            {{ category.name }}
                                        </span>
                                    </div>
                                </div>

                                <p v-if="data_get(carPart, 'comment')"
                                   class="border-top text-secondary fs-15 pt-3 mt-3 mb-0">
                                    {{ getTranslatedPropertyByLocale(carPart, 'comment', locale) }}
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-5 mt-md-0 mt-3 px-2 order-lg-3 order-md-2 order-3">
                            <ContactInfoViewProduct :car-part="carPart"/>
                        </div>

                        <div class="col-lg-9 my-3 px-2 order-4">
                            <ProductDescriptionGP :car-part="carPart"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="data_get(carPart, 'similarCarParts')" class="container-fluid px-0 mt-3">
                <div class="container px-2">
                    <h2 class="fs-5 fw-bold mb-0">
                        {{ trans('same_products') }}
                    </h2>

                    <SameProductGP :car-parts="data_get(carPart, 'similarCarParts') || []"/>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import SlideProductGP from "@/components/websites/get-parts/parts/view-product/SlideViewProduct.vue";
import ContactInfoViewProduct from "@/components/websites/get-parts/parts/view-product/ContactInfoViewProduct.vue";
import ProductDescriptionGP from "@/components/websites/get-parts/parts/view-product/ProductDescription.vue";
import SameProductGP from "@/components/websites/get-parts/parts/view-product/SameProducts.vue";
import ApiRequest from "@/http/ApiRequest";
import {data_get} from "@/core/helpers";
import {mapGetters} from "vuex";
import LoaderViewProduct from "@/components/websites/get-parts/parts/loaders/LoaderViewProduct.vue";
import {getTranslatedPropertyByLocale, t} from "@/lang/core/helpers";
import ZoomImagePopup from "@/components/websites/get-parts/parts/view-product/ZoomImagePopup.vue";

export default {
    name: 'ViewProductGP',

    components: {
        ZoomImagePopup,
        LoaderViewProduct,
        SameProductGP,
        ProductDescriptionGP,
        ContactInfoViewProduct,
        SlideProductGP
    },

    computed: {
        ...mapGetters([
            'carPartsMarks',
            'locale',
            'carPartLoaded'
        ]),

        breadCrumbName() {
            if (!this.carPart) {
                return null
            }

            let mark = this.carPart.mark
            let model = this.carPart.model

            return `${mark.name} ${model.name}`
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$store.commit('setCarPartLoaded', false)
        next()
    },

    data() {
        return {
            companyKey: 'get-parts',
            carPart: null,
        }
    },

    created() {
        this.getCarPart();
    },

    methods: {
        getTranslatedPropertyByLocale,

        trans(key) {
            return t(key)
        },

        data_get,

        getCarPart() {
            new ApiRequest('car-parts/find', {
                key: this.companyKey,
                id: this.$route.params.id
            }, response => {
                if (!response.success) {
                    alert(response.message || 'პროდუქტი ვერ მოიძებნა')
                    return
                }

                this.carPart = response.data
                this.$store.commit('setCarPartLoaded', true)
            })
        },

        getSupportedCars(part) {
            if (!part.supported_cars || !part.supported_cars.length) {
                return ''
            }

            let supportedCars = ''

            part.supported_cars.forEach((car, index) => {
                supportedCars += `${car.short_supported_car}`

                if (index !== part.supported_cars.length - 1) {
                    supportedCars += ', '
                }
            })

            return supportedCars;
        }
    }
}
</script>
