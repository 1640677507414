<template>
    <div>
        <p class="fw-bold fs-4 mb-3" style="color: rgba(0, 0, 0, 0.2);">
            Характеристики
        </p>
        <div class="row m-0 border rounded"
             style="box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.25);">
            <div class="col-lg-6 p-0">
                <table class="table table-hover mb-0">
                    <tbody>

                    <tr>
                        <td>Кузов:</td>
                        <td> Хэтчбек 5 дв.</td>
                    </tr>

                    <tr>
                        <td>Привод:</td>
                        <td>Передний</td>
                    </tr>

                    <tr>
                        <td>Цвет:</td>
                        <td>Бежевый</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-6 p-0 border-start">
                <table class="table table-hover fs-sm-15 mb-0">
                    <tbody>
                    <tr>
                        <td>Двигатель:</td>
                        <td>2.0 см³ 143 л.с. Б/И</td>
                    </tr>

                    <tr>
                        <td>Коробка:</td>
                        <td>Автоматическая</td>
                    </tr>


                    <tr>
                        <td>Руль:</td>
                        <td>Левый</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoCharacteristics"
}
</script>

<style scoped>

</style>