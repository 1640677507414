<template>
    <div class="container-fluid px-0 my-3">
        <div class="container px-0">
            <div class="row justify-content-between m-0">
                <div class="col-lg-5 col-md-7 px-2 order-1">
                    <div aria-hidden="true" class="placeholder-glow rounded shadow overflow-hidden">
                        <div class="loader__img placeholder col-12"></div>
                    </div>
                </div>

                <div class="col-lg-4 mt-lg-0 mt-3 px-2 order-lg-2 order-md-3 order-2 placeholder-glow">
                    <div class="bg-white shadow-sm rounded p-3">
                        <h1 class="col-12 placeholder fs-5 mb-0 rounded"></h1>
                        <h1 class="col-12 placeholder fs-5 mb-0 rounded"></h1>

                        <div class="border-top mt-3 pt-3">
                            <p class="col-4 placeholder mb-0 rounded"></p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-5 mt-md-0 mt-3 px-2 order-lg-3 order-md-2 order-3">
                    <div class="bg-white rounded shadow-sm sticky-lg-top p-3 placeholder-glow"
                         style="top: 12px;">
                        <div class="d-flex align-items-center justify-content-between gap-3">
                            <div class="col-4 placeholder rounded"></div>

                            <div class="col-4 placeholder rounded"></div>
                        </div>

                        <hr>

                        <div class="rounded w-100 placeholder" style="height: 42px;"></div>

                        <div class="rounded w-100 placeholder mt-3" style="height: 42px;"></div>

                        <div class="rounded w-100 placeholder mt-3" style="height: 42px;"></div>


                        <div class="rounded w-100 mt-3 d-flex justify-content-between gap-2 rounded">
                            <div class="col-7 placeholder rounded-start" style="height: 42px;"></div>
                            <div class="col-2 placeholder" style="height: 42px;"></div>
                            <div class="col-2 placeholder rounded-end" style="height: 42px;"></div>
                        </div>

                    </div>
                </div>

                <div class="col-lg-9 mt-3 px-2 order-4">
                    <div class="bg-white shadow rounded p-3 placeholder-glow">
                        <div class="d-flex gap-3">
                            <div class="col-3 placeholder py-3"></div>
                            <div class="col-3 placeholder py-3"></div>
                        </div>

                        <div class="col-6 placeholder mt-3"></div>

                        <div v-for="n in 5" :key="n" class="d-flex justify-content-between mt-3">
                            <div class="col-3 placeholder"></div>
                            <div class="col-2 placeholder me-lg-5 pe-lg-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderViewProduct'
}
</script>


<style scoped>
@media screen and (min-width: 1400px) {
    .loader__img {
        height: 532px !important;
    }
}

@media screen and (max-width: 1399px) {
    .loader__img {
        height: 457px;
    }
}

@media screen and (max-width: 760px) {
    .loader__img {
        height: 357px;
    }
}
</style>