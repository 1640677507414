<template>
    <div class="row m-0 align-items-baseline">
        <div class="col-lg-12 col-4">
            <a class="marks_filter d-block text-center" href="#">
                <img :src="Sedan" alt="Sedan" class="img-fluid" height="43" loading="lazy" title="sedan" width="75">
                <p class="text-dark text-uppercase text-center mt-1 mb-0 fs-sm-10">седан</p>
            </a>
        </div>
        <div class="col-lg-12 col-4 mt-lg-5">
            <a class="marks_filter d-block text-center" href="#">
                <img :src="Hatchback" alt="Sedan" class="img-fluid" height="43" loading="lazy" title="sedan" width="75">
                <p class="text-dark text-uppercase text-center mt-1 mb-0 fs-sm-10">хэтчбек</p>
            </a>
        </div>

        <div class="col-lg-12 col-4 mt-lg-5">
            <a class="marks_filter d-block text-center" href="#">
                <img :src="Vnedorozhnik" alt="Sedan" class="img-fluid" height="50" loading="lazy" title="sedan"
                     width="75">
                <p class="text-dark text-uppercase text-center mt-1 mb-0 fs-sm-10">внедорожник</p>
            </a>
        </div>
    </div>
</template>

<script>
import Sedan from "@/assets/websites/rb-auto/img/sedan.png"
import Hatchback from "@/assets/websites/rb-auto/img/hatchbek.png"
import Vnedorozhnik from "@/assets/websites/rb-auto/img/vnedorozhnik.png"

export default {
    name: 'BodyTypeFilter',

    data() {
        return {
            Sedan,
            Hatchback,
            Vnedorozhnik
        }
    }
}
</script>

<style scoped>
.marks_filter img {
    filter: brightness(0);
    transition: 0.3s;
}

.marks_filter:hover img {
    filter: unset;
}

@media screen and (max-width: 992px) {
    .marks_filter img {
        width: 60px !important;
        height: 22px !important;
    }
}
</style>