export default {
    addToCart({commit}, product) {
        let shoppingCart = this.state.shoppingCart

        if (!shoppingCart || !shoppingCart.length) {
            commit('setShoppingCart', [{
                id: product.id,
                name: product.name,
                price: product.price_discount || product.price,
                image: product.images && product.images.length && product.images.filter(image => image.image_type === 'MEDIUM').length
                    ? product.images.filter(image => image.image_type === 'MEDIUM')[0].url
                    : '',
                quantity: 1,
            }])
            window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
            return
        }

        let checkProductExists = shoppingCart.findIndex(h => h.id === product.id)

        if (checkProductExists >= 0) {
            shoppingCart[checkProductExists].quantity += 1
            shoppingCart[checkProductExists].price = product.price_discount || product.price
            commit('setShoppingCart', shoppingCart)
            window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
            return
        }

        shoppingCart.push({
            id: product.id,
            name: product.name,
            price: product.price_discount || product.price,
            image: product.images && product.images.length && product.images.filter(image => image.image_type === 'MEDIUM').length
                ? product.images.filter(image => image.image_type === 'MEDIUM')[0].url
                : '',
            quantity: 1,
        })

        commit('setShoppingCart', shoppingCart)
        window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
    },

    removeFromCart({commit}, product) {
        let shoppingCart = this.state.shoppingCart

        if (!shoppingCart || !shoppingCart.length) {
            return
        }

        shoppingCart.splice(shoppingCart.findIndex(h => h.id === product.id), 1)
        commit('setShoppingCart', shoppingCart)
        window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
    },

    increaseItemCount({commit}, product) {
        let shoppingCart = this.state.shoppingCart

        if (!shoppingCart || !shoppingCart.length) {
            return
        }

        shoppingCart.map(item => {
            if (item.id === product.id) {
                item.quantity += 1
            }
        })

        commit('setShoppingCart', shoppingCart)
        window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
    },

    decreaseItemCount({commit}, product) {
        let shoppingCart = this.state.shoppingCart

        if (!shoppingCart || !shoppingCart.length) {
            return
        }

        let checkProductExists = shoppingCart.findIndex(h => h.id === product.id)

        if (checkProductExists >= 0) {
            shoppingCart[checkProductExists].quantity -= 1
            if (shoppingCart[checkProductExists].quantity <= 0) {
                shoppingCart.splice(checkProductExists, 1)
            }

            commit('setShoppingCart', shoppingCart)
            window.localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
        }
    },

    transformCarPartsCategories({commit}) {
        const categories = this.state.carParts.categories

        if (!categories || !categories.length) {
            return
        }

        let transformedCategories = []

        categories.forEach(category => {
            let categoryData = {
                id: category.id,
                name: category.name,
                slug: category.slug,
                image: category.image,
                icon: category.icon,
                children: []
            }

            category.children.forEach(child => {
                categoryData.children.push({
                    id: child.id,
                    name: child.name,
                    slug: child.slug,
                    image: child.image,
                    icon: child.icon,
                    children: []
                })
            })

            transformedCategories.push(categoryData)
        })

        commit('setCarPartsGeneratedCategories', transformedCategories)
    },

    toggleFavorite({commit}, product) {
        let favorites = this.state.favorites

        if (!favorites || !favorites.length) {
            let p = {
                id: product.id,
                name: product.name,
                price: product.price_discount || product.price,
                image: product.images && product.images.length && product.images.filter(image => image.image_type === 'MEDIUM').length
                    ? product.images.filter(image => image.image_type === 'MEDIUM')[0].url
                    : '',
            }

            commit('setFavorites', [p])
            window.localStorage.setItem('favorites', JSON.stringify([p]))
            return
        }

        let checkProductExists = favorites.findIndex(h => h.id === product.id)

        if (checkProductExists >= 0) {
            favorites.splice(checkProductExists, 1)
            commit('setFavorites', favorites)
            window.localStorage.setItem('favorites', JSON.stringify(favorites))
            return
        }

        favorites.push({
            id: product.id,
            name: product.name,
            price: product.price_discount || product.price,
            image: product.images && product.images.length && product.images.filter(image => image.image_type === 'MEDIUM').length
                ? product.images.filter(image => image.image_type === 'MEDIUM')[0].url
                : '',
        })
        commit('setFavorites', favorites)
        window.localStorage.setItem('favorites', JSON.stringify(favorites))
    }
}