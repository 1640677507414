<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-0">
            <div class="row row-cols-xl-6 row-cols-lg-5 row-cols-md-3 row-cols-2 m-0 categories-products-row">
                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Shirts
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="ShirtPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Longsliv
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="LongslivPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Topik
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="TopikPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Svitshot
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="SvitshotPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Jumper
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="JumperPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Dress
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="DressPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Bathrobe
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="BathrobePic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Sportwear
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="SportwearPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Outwear
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="OutwearPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Bra
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="BraPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Accessories
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="AccessoriesPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>

                <div class="col mb-lg-4 mb-3">
                    <a class="border rounded bg-light d-block text-dark text-decoration-none categories-products-product"
                       href="#">
                        <p class="fs-14 fw-bold p-3 mb-0">
                            Rolings
                        </p>
                        <div class="d-flex justify-content-end mt-2 categories-img-container">
                            <img :src="RolingsPic" alt="shirt" width="120">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShirtPic from "@/assets/websites/moda/img/shirt.png"
import LongslivPic from "@/assets/websites/moda/img/longsliv.png"
import TopikPic from "@/assets/websites/moda/img/topik.png"
import SvitshotPic from "@/assets/websites/moda/img/svitshot.png"
import JumperPic from "@/assets/websites/moda/img/jumper.png"
import DressPic from "@/assets/websites/moda/img/dress.png"
import BathrobePic from "@/assets/websites/moda/img/bathrobe.png"
import SportwearPic from "@/assets/websites/moda/img/sport-wear.png"
import OutwearPic from "@/assets/websites/moda/img/outerwear.png"
import BraPic from "@/assets/websites/moda/img/bra-pic.png"
import AccessoriesPic from "@/assets/websites/moda/img/scarf-picture.png"
import RolingsPic from "@/assets/websites/moda/img/rolling.png"

export default {
    name: "ModaCategories",

    data() {
        return {
            ShirtPic,
            LongslivPic,
            TopikPic,
            SvitshotPic,
            JumperPic,
            DressPic,
            BathrobePic,
            SportwearPic,
            OutwearPic,
            BraPic,
            AccessoriesPic,
            RolingsPic
        }
    }
}
</script>

<style scoped>
.categories-img-container {
    height: 170px;
}

.categories-img-container img {
    object-fit: cover;
}

.categories-products-viewlink, .categories-products-product, .categories-img-container {
    transition: .3s;
    overflow: hidden;
}

.categories-products-product:hover {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
    background-color: #c7c7c7 !important;
}

.categories-products-product:hover .categories-img-container {
    transform: scale(1.3);
    filter: brightness(.6);
}

.categories-products-product:hover .categories-products-viewlink {
    opacity: 1 !important;
}

@media screen and (max-width: 575px) {
    .categories-products-row .col:nth-child(odd) {
        padding-right: 6px;
    }

    .categories-products-row .col:nth-child(even) {
        padding-left: 6px;
    }
}
</style>