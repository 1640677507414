<template>
    <div>
        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container px-2 d-flex justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent d-flex align-items-center mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'home'}" class="text-dark fs-sm-14">
                                Главная
                            </router-link>
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14 active">О компании</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container p-0">
                <div class="position-relative">
                    <img :src="AboutBg" alt="" class="w-100" loading="lazy">
                    <div class="d-lg-none">
                        <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleDark"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-prev-icon w-50"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleDark"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-next-icon w-50"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div
                    class="mx-auto bg-body rounded-circle d-flex align-items-center justify-content-center logo_about__bg">
                    <div class="rounded-circle d-flex align-items-center justify-content-center logo_about"
                         style="">
                        LOGO
                    </div>
                </div>
            </div>

            <div class="container p-0 slide">
                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item h-100 active" data-bs-interval="100000">
                            <div class="row m-0 carousel_slide__bg">
                                <div class="col-lg-6 d-flex align-items-center">
                                    <div class="col-lg-9 col-12 p-0 fs-sm-14">
                                        <p class="fs-5 fs-sm-16 fw-bold text-lg-start text-center mt-lg-0 mt-5 mb-2">
                                            Автоцентр
                                            «АВТО-ПАРК»</p>
                                        <p class="mt-2 mb-0 opacity-80 fs-14">
                                            Автоцентр "АВТО-ПАРК" - легендарный бренд столичного рынка автомобилей с
                                            пробегом. Уже более 15
                                            лет мы развиваем авторынок Москвы, чтобы каждый житель столицы смог
                                            подобрать оптимальное авто по
                                            лучшей цене. Ждем Вас!
                                        </p>
                                        <p class="mt-2 mb-0 opacity-80 fs-14">
                                            Покупка подержанного авто у нас — это гарантия того, что вы получите
                                            автомобиль без каких-либо
                                            технических неисправностей, с комплектом необходимых документов.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="d-flex justify-content-center">
                                        <img :src="Logos" alt="" class="d-block col-lg-9 col-12 p-0 about_slide_pic"
                                             height="265"
                                             loading="lazy" style="object-fit: contain">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item h-100" data-bs-interval="100000">
                            <div class="row m-0 carousel_slide__bg">
                                <div class="col-lg-6 d-flex align-items-center">
                                    <div class="w-75 fs-sm-14">
                                        <p class="fs-5 fs-sm-16 fw-bold text-lg-start text-center mt-lg-0 mt-5 mb-2">
                                            Автоцентр
                                            «АВТО-ПАРК»</p>
                                        <p class="mt-2 mb-0 opacity-80 fs-14">
                                            На территории автосалона представлен кредитный отдел и отдел страхования. Не
                                            выходя из салона, Вы
                                            можете приобрести страховки КАСКО и ОСАГО, а так же оформить автокредит.
                                        </p>
                                        <p class="mt-2 mb-0 opacity-80 fs-14">
                                            Мы внимательно следим за всеми потребностями и пожеланиями наших клиентов,
                                            постоянно
                                            совершенствуемся и уделяем особое внимание качеству обслуживания и
                                            квалификации наших сотрудников.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 d-flex align-items-center">
                                    <div class="row align-items-center about_slide_pic">
                                        <div class="col-6">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <img :src="LikeIcon" alt="" class="slide_about_icon" loading="lazy">
                                                <div class="ms-2">
                                                    <h1 class="fs-5 fs-sm-16">
                                                        <span class="text-danger fw-bold">20</span> лет <br> <span
                                                        class="fs-6 fs-sm-14">на рынке</span>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="d-flex align-items-center justify-content-start">
                                                <img :src="OfficialDealerIcon" alt="" class="slide_about_icon"
                                                     loading="lazy">
                                                <div class="ms-2">
                                                    <h1 class="fs-5 fs-sm-16">
                                                        <span class="text-danger fw-bold">52</span> бренда
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 mt-3">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <img :src="CarIcon" alt="" class="slide_about_icon" loading="lazy">
                                                <div class="ms-2">
                                                    <h1 class="fs-5 fs-sm-16">
                                                        <span class="text-danger fw-bold">1772</span> авто <br> <span
                                                        class="fs-6 fs-sm-14">в наличии</span>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 mt-3">
                                            <div class="d-flex align-items-center justify-content-start">
                                                <img :src="EmployeeIcon" alt="" class="slide_about_icon" loading="lazy">
                                                <div class="ms-2">
                                                    <h1 class="fs-5 fs-sm-14">
                                                        <span class="text-danger fw-bold">2 000</span> сотрудников <br>
                                                        <span class="fs-6 fs-sm-14">в компании</span>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="position-absolute start-50 bottom-50 translate-middle d-none d-md-block"
                         style="width: 10%;">
                        <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleDark"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleDark"
                                type="button">
                            <span aria-hidden="true" class="carousel-control-next-icon"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <CallRequestLine/>

        <ConditionsAndGuarantees/>

    </div>
</template>

<script>
import AboutBg from "@/assets/websites/rb-auto/img/about-bg.jpg"
import Logos from "@/assets/websites/rb-auto/img/about-logos.png"
import EmployeeIcon from "@/assets/websites/rb-auto/img/employee.png"
import OfficialDealerIcon from "@/assets/websites/rb-auto/img/official_dealer.png"
import LikeIcon from "@/assets/websites/rb-auto/img/like.png"
import CarIcon from "@/assets/websites/rb-auto/img/car123.png"
import CallRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CallRequestLine.vue";
import ConditionsAndGuarantees from "@/components/websites/rb-auto/parts/ConditionsAndGuarantees.vue";

export default {
    name: 'AboutRbAuto',
    components: {ConditionsAndGuarantees, CallRequestLine},

    data() {
        return {
            AboutBg,
            Logos,
            EmployeeIcon,
            OfficialDealerIcon,
            LikeIcon,
            CarIcon
        }
    }
}
</script>

<style scoped>
.carousel_slide__bg {
    background-image: url('@/assets/websites/rb-auto/img/about-slide-bg.png');
    background-size: cover;
    height: 100%;
}

.logo_about__bg {
    width: 210px;
    height: 210px;
    transform: translateY(-50%);
    position: relative;
    z-index: 1;
}

.logo_about {
    width: 181px;
    height: 181px;
    box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.25);
}

.slide {
    margin-top: -195px;
}

@media screen and (min-width: 992px) {
    .carousel-item .carousel_slide__bg .col-lg-6:after {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        background-color: #F5F5F5;
    }

    .carousel-inner {
        height: 265px;
    }
}

@media screen and (max-width: 991px) {
    .slide {
        margin-top: -80px;
    }

    .logo_about__bg {
        width: 90px !important;
        height: 90px !important;
    }

    .logo_about {
        width: 73px !important;
        height: 73px !important;
    }

    .slide_about_icon {
        width: 30px !important;
    }

    .about_slide_pic {
        height: 170px !important;
    }
}
</style>