<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
import {localStorageGet} from "@/core/localStorage"

export default {
    name: 'DefaultLayout',

    watch: {
        $route: {
            immediate: true,
            async handler(route) {
                this.$store.commit('setTranslationKey', route.meta.translationKey)
            }
        }
    },

    created() {
        this.$store.commit('setTranslationKey', this.$route.meta.translationKey)

        let locale = localStorageGet('locale', 'en');

        this.$store.commit('setLocale', locale)
    }
}
</script>