// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Index from "@/components/websites/rb-auto/Index.vue"
import Cars from "@/components/websites/rb-auto/Cars.vue"
import Credit from "@/components/websites/rb-auto/Credit.vue"
import ViewCar from "@/components/websites/rb-auto/ViewCar.vue"
import Contacts from "@/components/websites/rb-auto/Contacts.vue"
import About from "@/components/websites/rb-auto/About.vue"
import TradeIn from "@/components/websites/rb-auto/TradeIn.vue"
import Favourites from "@/components/websites/rb-auto/Favourites.vue"
import Favicon from "@/assets/websites/blueberrynightshotel/img/favicon.png"
import { createRouter, createWebHistory } from 'vue-router'
// Vue.use(VueRouter)

const favIcon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/cars',
        name: 'cars',
        component: Cars,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/credit',
        name: 'credit',
        component: Credit,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/view-car',
        name: 'view-car',
        component: ViewCar,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/trade-in',
        name: 'trade-in',
        component: TradeIn,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },

    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
].map(route => ({
    ...route,
    meta: {
        ...route.meta,
        translationKey: 'rb-auto'
    }
}))

// const router = new VueRouter({
//     mode: 'history',
//     routes
// })

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
