<template>
    <div class="container-fluid px-0 mt-3 filtredProducts">
        <div class="container px-xl-0">
            <div class="d-flex flex-lg-row flex-column justify-content-lg-between gap-lg-0 gap-3 border-bottom">
                <div class="d-flex align-items-center justify-content-between">
                    <h1 class="fs-sm-18 text-secondary text-capitalize fm-mons mb-0">
                        Finded
                        <span class="text-dark">341</span>
                    </h1>

                    <button aria-controls="offcanvasScrolling"
                            class="btn btn-light border border-dark text-uppercase open__filter_btn d-lg-none d-block"
                            data-bs-target="#offcanvasScrolling" data-bs-toggle="offcanvas"
                            type="button">
                        filters
                    </button>
                </div>

                <div class="d-flex justify-content-between">
                    <select class="form-select form-select-sm border-0 p-0 pe-5" style="width: max-content;">
                        <option selected>Increase</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>

                    <div class="d-flex align-items-center gap-1">
                        <button class="btn border-0 p-2 view-table-btn">
                            <svg class="bi bi-grid-3x3-gap-fill" fill="currentColor" height="16" viewBox="0 0 16 16"
                                 width="16" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
                            </svg>
                        </button>

                        <button class="btn border-0 p-2 view-table-btn">
                            <svg class="bi bi-list-ul" fill="currentColor" height="16" viewBox="0 0 16 16"
                                 width="16" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                    fill-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center flex-wrap my-3 filtred-badges">
                <div class="btn btn-sm btn-dark me-2">
                    <span class="text-capitalize me-1 opacity-75">Size :</span>
                    <span class="text-capitalize">36, 37, 38, 42, 46 </span>
                    <i class="fas fa-times text-light fs-12 ms-1" title="Delete"></i>
                </div>

                <div class="btn btn-sm btn-dark me-2">
                    <span class="text-capitalize me-1 opacity-75">sex :</span>
                    <span class="text-capitalize">Female </span>
                    <i class="fas fa-times text-light fs-12 ms-1" title="Delete"></i>
                </div>
            </div>

            <ModaFiltredProductsSaleable/>

            <ModaPagination/>
        </div>

        <div class="container px-lg-0 fm-mont mt-lg-5 mt-3">
            <p class="text-uppercase px-lg-0 fs-2 mb-lg-0 mb-3">Recently Viewed</p>

            <ModaRecentlyViewed/>
        </div>
    </div>
</template>

<script>
import GridIcon from "@/assets/websites/moda/img/grid.svg"
import ListIcon from "@/assets/websites/moda/img/list.svg"
import ModaFiltredProductsSaleable from "@/components/websites/moda/parts/FiltredProductsSaleable";
import ModaPagination from "@/components/websites/moda/parts/Pagination";
import ModaRecentlyViewed from "@/components/websites/moda/parts/RecentlyViewed.vue";

export default {
    name: "FiltredProducts",

    components: {
        ModaRecentlyViewed,
        ModaPagination,
        ModaFiltredProductsSaleable
    },

    data() {
        return {
            GridIcon,
            ListIcon
        }
    }

}
</script>

<style scoped>
@media screen and (min-width: 992px) and (max-width: 1640px) {
    .filtredProducts {
        width: 97% !important;
    }
}

@media screen and (min-width: 992px) {
    .filtredProducts {
        padding-left: 280px !important;
    }

    .view-table-btn:hover {
        background-color: #e4e4e485;
        border-radius: 3px !important;
    }
}

.filtred-badges .fa-times:hover {
    opacity: .75;
}
</style>