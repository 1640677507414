<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            {{ trans('news') }}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-0">
                <h1 class="fs-2 text-center px-2 mb-3">{{ trans('news') }}</h1>

                <div class="row row-cols-lg-4 row-cols-md-3 row-cols-2 m-0">
                    <div v-for="n in 8" :key="n" class="col px-2 mb-3 rounded">
                        <router-link :to="{name: 'article'}" class="d-block shadow-sm rounded overflow-hidden">
                            <div>
                                <img :src="ArticlePic" alt="" class="w-100">
                            </div>

                            <p class="fw-600 text-center text-dark bg-white border-top fs-sm-14 p-2 mb-0">
                                10%-იან ფასდაკლება Chevrolet Cruze- ს ავტონაწილებზე.
                            </p>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ArticlePic from "@/assets/websites/get-parts/img/article-pic.png"
import {t} from "@/lang/core/helpers";

export default {
    name: 'NewsGP',

    methods: {
        trans(key) {
            return t(key)
        }
    },

    data() {
        return {
            ArticlePic
        }
    }
}
</script>

<style scoped>

</style>