<template>
<div>
    <header class="container-fluid px-0 sticky-top">
        <HeaderLanding/>
    </header>

    <main class="container-fluid px-0 my-lg-5 my-3 pb-lg-5 pb-3 receipt__bg">
        <div class="container px-lg-0">
            <h1 class="fs-1 mt-lg-5 mt-3 text-center mb-0">მარწყვის ნაყინი</h1>

            <p class="fs-18 text-center mt-lg-5 mt-3">
                ადვილი მარწყვის გემრიელი და ჯანსაღი ნაყინი სახლის პირობებში – ზაფხულის ცხელი დღეების გრილი გემო. ადვილი მარწყვის გემრიელი და ჯანსაღი ნაყინი სახლის პირობებში – ზაფხულის ცხელი დღეების გრილი გემო. ადვილი მარწყვის გემრიელი და ჯანსაღი ნაყინი სახლის პირობებში – ზაფხულის ცხელი დღეების გრილი გემო.
            </p>

            <div class="col-lg-6 col-12 mx-auto mt-lg-5 mt-3">
                <img :src="StrawberryPic" class="d-block w-100 img-fluid rounded" alt="">
            </div>

            <div class="row justify-content-between mt-lg-5 mt-3">
                <div class="col-lg-7">
                    <h2 class="fs-2 mb-0">ინგრიდიენტები</h2>

                    <div class="mt-lg-5 mt-3">
                        <div class="d-flex justify-content-between">
                            <span class="d-block lh-1 text-secondary">რძე 3.2%</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">2.5 ჭიქა</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">ნაღები 30%</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">2 ჭიქა</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">შაქარი%</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">2 კოვზი</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">მარილი</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">1 კოვზი</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">ნაღების ყველი</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">გემოს მიხედვით</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">მარწყვი</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">100 გრ</span>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <span class="d-block lh-1 text-secondary">ლიმონის წვენი</span>
                            <span class="border-2 border-bottom flex-grow-1 mx-3" style="border-bottom-style: dotted !important;"></span>
                            <span class="d-block lh-1">გემოს მიხედვით</span>
                        </div>
                    </div>

                    <h3 class="fs-2 mt-lg-5 mt-3 mb-0">მომზადება</h3>

                    <p class="fs-4 mt-lg-4 mt-3 mb-0">ნაბიჯი 1</p>

                    <p class="text-secondary mb-0 mt-3">
                        თხლად დაჭერით 5–6 ცალი მარწყვი და შეინახეთ ფორმების მოსართავად.
                    </p>

                    <p class="fs-4 mt-lg-4 mt-3 mb-0">ნაბიჯი 2</p>

                    <p class="text-secondary mb-0 mt-3">
                        დარჩენილი მწიფე, გარეცხილი და გარჩეული ახალი მარწყვი ნახევრებად ან მეოთხედებად დაჭერით და ბლენდერში პიურეს მიღებამდე თქვიფეთ
                    </p>

                    <div class="mt-2">
                        <img :src="SecondStep" class="w-100 img-fluid" alt="">
                    </div>

                    <p class="fs-4 mt-lg-4 mt-3 mb-0">ნაბიჯი 3</p>

                    <p class="text-secondary mb-0 mt-3">
                        მოათავსეთ დაჭრილი მარწყვი ნაყინის ფორმებში, ეცადეთ, რომ ნაჭრები მიეწებოს კიდეებს, რათა ნაყინი სასიამოვნოდ და ხალისიანად გამოიყურებოდეს.
                    </p>

                    <div class="mt-2">
                        <img :src="ThirdStep" class="w-100 img-fluid" alt="">
                    </div>

                    <p class="fs-4 mt-lg-4 mt-3 mb-0">ნაბიჯი 4</p>

                    <p class="text-secondary mb-0 mt-3">
                        ჩაასხით მარწყვის პიურე ნაყინის ფორმებში, დაფარეთ და მოათავსეთ მასში ჯოხები. თუ ფორმად პატარა ჭიქებს იყენებთ, შეგიძლიათ, დააფაროთ მას ფოლგა და ნარევში ჯოხი ფოლგის გავლით მოათავსოთ, რათა ჭიქაში უძრავად იყოს. ან გაყინეთ დაახლოებით ერთი საათით და ჯოხები შემდეგ ჩააწყვეთ.
                    </p>

                    <div class="mt-2">
                        <img :src="FourStep" class="w-100 img-fluid" alt="">
                    </div>

                    <p class="fs-4 mt-lg-4 mt-3 mb-0">ნაბიჯი 5</p>

                    <p class="text-secondary mb-0 mt-3">
                        ნაყინები საყინულეში დაახლოებით 3–4 საათით ან სრულად გაყინვამდე გააჩერეთ. მათ ადვილად ამოსაღებად ფორმის ძირი თბილ წყალში მოათავსეთ და გააჩერეთ დაახლოებით 10–20 წამით.
                    </p>

                    <div class="mt-2">
                        <img :src="FiveStep" class="w-100 img-fluid" alt="">
                    </div>
                </div>

                <div class="col-lg-4 mt-lg-0 mt-3">
                    <div class="sticky-lg-top" style="top: 60px;">
                        <div>
                            <p class="text-secondary mb-0">მომზადების დრო</p>
                            <p class="fs-4 mt-1 mb-0">1 სთ.</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">პორციების რაოდენობა</p>
                            <p class="mt-1 mb-0">6-8</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">მომზადების სირთულე</p>
                            <p class="mt-1 mb-0">მარტივი</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">ტიპი</p>
                            <p class="mt-1 mb-0">
                                <router-link :to="{ name: 'deseart' }" class="text-decoration-none text-dark">
                                    დესერტი
                                </router-link>

                            </p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">ვეგეტარიანული/ვეგანური</p>
                            <p class="mt-1 mb-0">არა</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">კალორიები</p>
                            <p class="mt-1 mb-0">280</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">ცილა</p>
                            <p class="mt-1 mb-0">10 გრ</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">ცხიმი</p>
                            <p class="mt-1 mb-0">8 გრ.</p>
                        </div>

                        <div class="mt-3">
                            <p class="text-secondary mb-0">ნახშირწყლები</p>
                            <p class="mt-1 mb-0">11.7 გრ.</p>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="fs-4 mt-lg-5 mt-3 mb-0">ჩვენ გირჩევთ:</h4>
        </div>
    </main>

    <footer class="container-fluid px-0">
        <FooterLanding/>
    </footer>
</div>
</template>

<script>
import HeaderLanding from "@/components/websites/allmenu/landing/landing-parts/HeaderLanding.vue";
import FooterLanding from "@/components/websites/allmenu/landing/landing-parts/FooterLanding.vue";
import StrawberryPic from "@/assets/websites/allmenu/img/strawberry-pic.jpg";
import SecondStep from "@/assets/websites/allmenu/img/cut-strawberry-pic.jpg";
import ThirdStep from "@/assets/websites/allmenu/img/3-etap-strawberry-pic.jpg";
import FourStep from "@/assets/websites/allmenu/img/4-etap-strawberry-pic.jpg";
import FiveStep from "@/assets/websites/allmenu/img/5-etap-strawberry-pic.jpg"

export default {
    name: "ViewReceipt",

    components: {
        FooterLanding,
        HeaderLanding,
    },

    data() {
        return {
            StrawberryPic,
            SecondStep,
            ThirdStep,
            FourStep,
            FiveStep
        }
    }
}
</script>

<style scoped>

</style>