export const localStorageSet = (key, value) => {
    if (localStorageSupported()) {
        localStorage.setItem(key, value);
    } else {
        window[key] = value;
    }
}

export const localStorageGet = (key, setDefault = null) => {
    if (localStorageSupported()) {
        return localStorage.getItem(key);
    } else {
        let value = window[key]

        if (value === undefined) {
            value = setDefault
            localStorageSet(key, value)
        }

        return value
    }
}

export const localStorageSupported = () => {
    let testKey = 'test'
    let storage = window.localStorage

    try {
        storage.setItem(testKey, '1')
        storage.removeItem(testKey)
        return true
    } catch (error) {
        return false
    }
}