<template>
    <div>
        <div class="container-fluid mt-lg-5 mt-3">
            <div class="container px-lg-0 px-1">
                <h1 class="fs-1 mb-0">
                    Kia Ceed
                </h1>

                <nav aria-label="breadcrumb" class="overflow-auto mt-2">
                    <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                        <li class="breadcrumb-item d-flex align-items-center active">
                            <a class="text-dark fs-14 text-nowrap"
                               href="/cars">
                                Автомобили
                            </a>
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center fs-14 text-nowrap">
                            Kia
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center fs-14 text-nowrap active">
                            Ceed
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid">
            <div class="container p-lg-0">
                <div class="row">
                    <div class="col-lg-9">
                        <AutoCarousel/>
                    </div>

                    <div class="col-lg-3 d-flex mt-lg-0 mt-3 flex-column justify-content-between sticky-lg-top"
                         style="top: 100px;">
                        <div>
                            <div class="d-flex align-items-center border rounded p-3">
                                <span>Год выпуска:</span>
                                <span class="ms-3">2011 г.</span>
                            </div>

                            <div class="d-flex align-items-center border rounded p-3 mt-3">
                                <span>Пробег:</span>
                                <span class="fw-500 ms-3">112 230 км</span>
                            </div>

                            <div class="d-flex align-items-center border rounded p-3 mt-3">
                                <span>Старая цена:</span>
                                <span class="fw-500 ms-3 old_price position-relative">
                                  680 000 GEL
                                </span>
                            </div>

                            <div class="d-flex align-items-center border rounded p-3 mt-3">
                                <span>В кредит от:</span>
                                <span class="fw-500 ms-2">
                                    4504/в мес.
                                </span>
                            </div>

                            <div class="d-flex align-items-center border rounded p-3 mt-3">
                                <a class="text-dark"
                                   href="tel:+7 (495) 513-14-91">
                                    +7 (495) 513-14-91
                                </a>
                            </div>
                        </div>

                        <div class="mt-lg-0 mt-3">
                            <button class="btn text-dark w-100 rounded" data-bs-target="#exampleModal"
                                    data-bs-toggle="modal"
                                    style="background: rgba(0, 0, 0, 0.05);">
                                Получить консультацию
                            </button>

                            <a class="btn btn-warning w-100 rounded mt-3"
                               href="#">
                                Купить в кредит
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-9 mt-lg-5 mt-3">
                        <AutoCharacteristics/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AutoCarousel from "@/components/websites/auto/parts/view-car/Carousel.vue";
import AutoCharacteristics from "@/components/websites/auto/parts/view-car/Characteristics.vue";

export default {
    name: "ViewCar",
    components: {AutoCharacteristics, AutoCarousel},

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>