// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Index from "@/components/websites/auto/Index"
import Credit from "@/components/websites/auto/Credit"
import TradeIn from "@/components/websites/auto/TradeIn"
import BuyOut from "@/components/websites/auto/BuyOut"
import ViewCar from "@/components/websites/auto/ViewCar"
import Contacts from "@/components/websites/auto/Contacts"
import Cars from "@/components/websites/auto/Cars"
import Guestbook from "@/components/websites/auto/Guestbook"
import Favicon from "@/assets/websites/blueberrynightshotel/img/favicon.png"
import { createRouter, createWebHistory } from 'vue-router'
// Vue.use(VueRouter)

const favIcon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/credit',
        name: 'credit',
        component: Credit,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/view-car',
        name: 'view-car',
        component: ViewCar,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/trade-in',
        name: 'trade-in',
        component: TradeIn,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/buy-out',
        name: 'buy-out',
        component: BuyOut,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/cars',
        name: 'cars',
        component: Cars,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },
    {
        path: '/guestbook',
        name: 'guestbook',
        component: Guestbook,
        meta: {
            title: 'Auto',
            favicon: favIcon
        }
    },


].map(route => ({
    ...route,
    meta: {
        ...route.meta,
        translationKey: 'auto'
    }
}))

// const router = new VueRouter({
//     mode: 'history',
//     routes
// })

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
