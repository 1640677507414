<template>
    <component :is="layout">
        <slot/>
    </component>
</template>

<script>
import DefaultLayout from '@/components/layouts/DefaultLayout'
import {localStorageGet} from "@/core/localStorage"
import {markRaw} from "vue"

export default {
    name: "DefaultLayout",

    data() {
        return {
            companyKey: null,
            layout: markRaw(DefaultLayout)
        }
    },

    watch: {
        companyKey: {
            async handler(value) {
                if (!value) {
                    return
                }

                try {
                    const component = await import(`@/components/websites/${value}/layouts/DefaultLayout`)
                    this.layout = markRaw(component?.default)
                } catch (e) {
                    this.layout = markRaw(DefaultLayout)
                }
            },
            immediate: true
        }
    },

    created() {
        // this.companyKey = window.localStorage.getItem('companyKey');
        this.companyKey = localStorageGet('companyKey');
    }
}
</script>