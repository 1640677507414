<template>
    <div id="sizesGuideClothModal" aria-hidden="true" aria-labelledby="sizesGuideClothModalLabel" class="modal fade"
         tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content scroll-styles">
                <div class="modal-header border-0">
                    <h5 id="sizesGuideClothModalLabel" class="modal-title text-uppercase fs-6">
                        SIZE GUIDE
                    </h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="d-flex flex-lg-row flex-column">
                        <img :src="SizeGuideMen" alt="" class="w-50 img-fluid">

                        <img :src="SizeGuideWomen" alt="" class="w-50 img-fluid">
                    </div>

                    <div class="mt-3 fs-14">
                        <div class="row m-0">
                            <div class="col-2 d-flex align-items-center fw-bold">Size (CM)</div>

                            <div class="col-1 d-flex align-items-center fw-bold">XS</div>

                            <div class="col-1 d-flex align-items-center fw-bold">S</div>

                            <div class="col-1 d-flex align-items-center fw-bold">M</div>

                            <div class="col-1 d-flex align-items-center fw-bold">L</div>

                            <div class="col-1 d-flex align-items-center fw-bold">XL</div>

                            <div class="col-1 d-flex align-items-center fw-bold">2XL</div>

                            <div class="col-1 d-flex align-items-center fw-bold">3XL</div>

                            <div class="col-3 d-flex align-items-center justify-content-center fw-bold">Price</div>

                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-2 d-flex align-items-center fw-bold">1. Bust/Chest</div>

                            <div class="col-1 d-flex align-items-center">88</div>

                            <div class="col-1 d-flex align-items-center">94</div>

                            <div class="col-1 d-flex align-items-center">100</div>

                            <div class="col-1 d-flex align-items-center">106</div>

                            <div class="col-1 d-flex align-items-center">112</div>

                            <div class="col-1 d-flex align-items-center">118</div>

                            <div class="col-1 d-flex align-items-center">126</div>

                            <div class="col-3">
                                <div class="d-flex align-items-center justify-content-end gap-3">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-2 d-flex align-items-center fw-bold">2. Waist</div>

                            <div class="col-1 d-flex align-items-center">76</div>

                            <div class="col-1 d-flex align-items-center">82</div>

                            <div class="col-1 d-flex align-items-center">88</div>

                            <div class="col-1 d-flex align-items-center">94</div>

                            <div class="col-1 d-flex align-items-center">100</div>

                            <div class="col-1 d-flex align-items-center">106</div>

                            <div class="col-1 d-flex align-items-center">114</div>

                            <div class="col-3">
                                <div class="d-flex align-items-center justify-content-end gap-3">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-2 d-flex align-items-center fw-bold">3. Seat</div>

                            <div class="col-1 d-flex align-items-center">90</div>

                            <div class="col-1 d-flex align-items-center">96</div>

                            <div class="col-1 d-flex align-items-center">102</div>

                            <div class="col-1 d-flex align-items-center">108</div>

                            <div class="col-1 d-flex align-items-center">114</div>

                            <div class="col-1 d-flex align-items-center">120</div>

                            <div class="col-1 d-flex align-items-center">128</div>

                            <div class="col-3">
                                <div class="d-flex align-items-center justify-content-end gap-3">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-2 d-flex align-items-center fw-bold">4. Inseam</div>

                            <div class="col-1 d-flex align-items-center">81</div>

                            <div class="col-1 d-flex align-items-center">82</div>

                            <div class="col-1 d-flex align-items-center">83</div>

                            <div class="col-1 d-flex align-items-center">84</div>

                            <div class="col-1 d-flex align-items-center">85</div>

                            <div class="col-1 d-flex align-items-center">86</div>

                            <div class="col-1 d-flex align-items-center">87</div>

                            <div class="col-3">
                                <div class="d-flex align-items-center justify-content-end gap-3">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row m-0 sizesRow mt-1 py-1">
                            <div class="col-2 d-flex align-items-center fw-bold">
                                <span class="d-block text-truncate w-100">
                                    5. Sleeve length from neck
                                </span>
                            </div>

                            <div class="col-1 d-flex align-items-center">76</div>

                            <div class="col-1 d-flex align-items-center">78</div>

                            <div class="col-1 d-flex align-items-center">80</div>

                            <div class="col-1 d-flex align-items-center">82</div>

                            <div class="col-1 d-flex align-items-center">84</div>

                            <div class="col-1 d-flex align-items-center">86</div>

                            <div class="col-1 d-flex align-items-center">88</div>

                            <div class="col-3">
                                <div class="d-flex align-items-center justify-content-end gap-3">
                                    <span>$574</span>

                                    <button class="btn btn-dark btn-sm text-uppercase text-nowrap sizedGuidAddBasket">
                                        <span class="d-none d-lg-block">Add to bag</span>
                                        <i class="fa-solid fa-plus fs-12 d-lg-none"></i>
                                    </button>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SizeGuideMen from "@/assets/websites/moda/img/size-guide-pic-men.png"
import SizeGuideWomen from "@/assets/websites/moda/img/size-guide-pic-women.png"

export default {
    name: "ModaSizesGuideClothPopup",

    data() {
        return {
            SizeGuideWomen,
            SizeGuideMen,
        }
    }
}
</script>

<style scoped>

.sizesRow:nth-child(odd) {
    background-color: #f5f5f5;
}

.sizesRow {
    transition: .3s;
}

.sizesRow:hover {
    background-color: #e6e6e6;
}

/*#sizesGuideClothModal .modal-lg {*/
/*    max-width: 680px !important;*/
/*}*/

</style>
