export default {
	locale: null,
	availableLocales: [],
	company: null,
	requisites: [],
	translations: null,
	translationKey: null,
	shoppingCart: [],
	favorites: [],
	exchangeRates: {},
	allmenu: {
		dataLoaded: false,
		menuCompany: null,
		menuCompanyCategoriesAndProducts: [],
		menuCompanyHallsTablesOrders: [],
		menuCompanyRequisites: [],
		menuCompanySettings: [],
		basket: [],
		deleteFromBasket: null,
		isMobileDevice: false,
		selectedProduct: null,
		tableReservationStep: 1,
		reservationData: {
			reservationDate: null,
			personsCount: 1,
			name: null,
			email: null,
			phone: null,
			comment: null,
			agreeWithTerms: false,
		},
	},
	carParts: {
		categories: [],
		generatedCategories: [],
		marks: [],
		promotionCarParts: [],
		discountedCarParts: [],
		popularMarks: [],
		promotionCarPartsLoaded: false,
		popularMarksLoaded: false,
		carPartLoaded: false,
		markModelsLoaded: false,
		modelCarPartsLoaded: false,
		productSearch: "",
		ps: null,
	},
};
