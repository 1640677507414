<template>
    <div class="container-fluid d-flex align-items-center px-0">
        <div class="container pt-lg-5 pt-3">
            <div class="d-lg-flex justify-content-center px-2">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="d-lg-flex justify-content-lg-center d-lg-none">
                <div class="mt-5">
                    <img :src="roomsMin" class="img-fluid">
                </div>
            </div>
            <div class="row m-0 mt-lg-5 mt-3">
                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2" data-bs-target="#roomModal1"
                         data-bs-toggle="modal">
                        <img :src="room1Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal1" aria-hidden="true" aria-labelledby="roomModalLabel1" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 1</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room1" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-0 pt-2" data-bs-target="#roomModal2"
                         data-bs-toggle="modal">
                        <img :src="room2Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal2" aria-hidden="true" aria-labelledby="roomModalLabel2" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 2</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room2" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-0 pt-2" data-bs-target="#roomModal3"
                         data-bs-toggle="modal">
                        <img :src="room3Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal3" aria-hidden="true" aria-labelledby="roomModalLabel3" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 3</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room3" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-0 pt-2" data-bs-target="#roomModal4"
                         data-bs-toggle="modal">
                        <img :src="room4Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal4" aria-hidden="true" aria-labelledby="roomModalLabel4" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 4</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room4" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-3 pt-2" data-bs-target="#roomModal5"
                         data-bs-toggle="modal">
                        <img :src="room5Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal5" aria-hidden="true" aria-labelledby="roomModalLabel5" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 5</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room5" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-3 pt-2" data-bs-target="#roomModal6"
                         data-bs-toggle="modal">
                        <img :src="room6Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal6" aria-hidden="true" aria-labelledby="roomModalLabel6" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 6</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room6" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="col-lg-3 col-sm-6 cursor-pointer px-2 pt-lg-3 pt-2" data-bs-target="#roomModal7"
                         data-bs-toggle="modal">
                        <img :src="room7Min" alt="" class="img-fluid w-100">
                    </div>

                    <div id="roomModal7" aria-hidden="true" aria-labelledby="roomModalLabel7" class="modal fade"
                         tabindex="-1">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header border-0 pb-0">
                                    <h5 id="roomModalLabel" class="modal-title">Room 7</h5>
                                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                                            type="button"></button>
                                </div>
                                <div class="modal-body">
                                    <img :src="room7" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "HotelRooms",

    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
            room1: require("@/assets/websites/blueberrynightshotel/img/room-1.jpg"),
            room2: require("@/assets/websites/blueberrynightshotel/img/room-2.jpg"),
            room3: require("@/assets/websites/blueberrynightshotel/img/room-3.jpg"),
            room4: require("@/assets/websites/blueberrynightshotel/img/room-4.jpg"),
            room5: require("@/assets/websites/blueberrynightshotel/img/room-5.jpg"),
            room6: require("@/assets/websites/blueberrynightshotel/img/room-6.jpg"),
            room7: require("@/assets/websites/blueberrynightshotel/img/room-7.jpg"),
            roomsMin: require("@/assets/websites/blueberrynightshotel/img/rooms-min.jpg"),
            room1Min: require("@/assets/websites/blueberrynightshotel/img/room1-min.png"),
            room2Min: require("@/assets/websites/blueberrynightshotel/img/room2-min.png"),
            room3Min: require("@/assets/websites/blueberrynightshotel/img/room3-min.png"),
            room4Min: require("@/assets/websites/blueberrynightshotel/img/room4-min.png"),
            room5Min: require("@/assets/websites/blueberrynightshotel/img/room5-min.png"),
            room6Min: require("@/assets/websites/blueberrynightshotel/img/room6-min.png"),
            room7Min: require("@/assets/websites/blueberrynightshotel/img/room7-min.png"),
        }
    }
}
</script>
