<template>
    <div class="row mt-3">
        <div class="col-lg-6">
            <label class="form-label" for="name_order">Name</label>
            <input id="name_order" class="form-control" type="text">
        </div>

        <div class="col-lg-6 mt-lg-0 mt-3">
            <label class="form-label" for="surname_order">Surname</label>
            <input id="surname_order" class="form-control" type="text">
        </div>

        <div class="col-12 mt-3">
            <label class="form-label">City</label>
            <select class="form-select">
                <option selected>Tbilisi</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>

        <div class="col-12 mt-3">
            <label class="form-label" for="address_order">Address</label>
            <input id="address_order" class="form-control" type="text">
        </div>

        <div class="col-12 mt-3">
            <label class="form-label" for="id_order">Personal ID</label>
            <input id="id_order" class="form-control" type="text">
        </div>

        <div class="col-lg-6 mt-3">
            <label class="form-label" for="mobnum_order">Mobile number</label>
            <input id="mobnum_order" class="form-control" type="tel">
        </div>

        <div class="col-lg-6 mt-3">
            <label class="form-label" for="index_order">Post index</label>
            <input id="index_order" class="form-control" type="text">
        </div>

        <div class="col-12 mt-3">
            <label class="form-label" for="email_order">Email</label>
            <input id="email_order" class="form-control" type="email">
        </div>

        <div class="col-12 mt-3">
            <div aria-controls="collapseOtherAddress" aria-expanded="false" class="form-check m-0"
                 data-bs-target="#collapseOtherAddress" data-bs-toggle="collapse">
                <input id="delivery_other_address" class="form-check-input" type="checkbox">
                <label class="form-check-label" for="delivery_other_address">
                    Delivery to another address?
                </label>
            </div>
        </div>

        <div id="collapseOtherAddress" class="row m-0 px-0 mt-3 collapse">
            <div class="col-lg-6">
                <label class="form-label" for="name_order">Name</label>
                <input id="name_order_other" class="form-control" type="text">
            </div>

            <div class="col-lg-6 mt-lg-0 mt-3">
                <label class="form-label" for="surname_order">Surname</label>
                <input id="surname_order_other" class="form-control" type="text">
            </div>

            <div class="col-12 mt-3">
                <label class="form-label">City</label>
                <select class="form-select">
                    <option selected>Tbilisi</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>

            <div class="col-12 mt-3">
                <label class="form-label" for="address_order">Address</label>
                <input id="address_order_other" class="form-control" type="text">
            </div>

            <div class="col-12 mt-3">
                <label class="form-label" for="index_order">Post index</label>
                <input id="index_order_other" class="form-control" type="text">
            </div>
        </div>

        <div class="col-12 mt-3">
            <label class="form-label" for="comment">Comment</label>
            <textarea id="comment" class="form-control" rows="5"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaOrderInformation"
}
</script>

<style scoped>

</style>