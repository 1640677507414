<template>
    <div>
        <div class="row row-cols-lg-5 products-row hide-scroll-mob">
            <div class="col-lg col-sm-5 col-9 mt-lg-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 d-flex align-items-center gap-2"
                             title="New">
                            <span class="text-dark">976 $</span>
                            <span class=" badge text-bg-info rounded-0 text-white">NEW</span>
                        </div>

                    </router-link>

                    <div>
                        <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0 border-bottom border-dark pb-2">
                            Balmain
                        </p>

                        <div class="d-flex align-items-center justify-content-between gap-2 mt-2">
                            <p class="fs-14 fw-light text-uppercase text-truncate mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                <span class="me-2">Express view</span>
                                <img :src="ViewExpressIcon" width="20" alt="">
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg col-sm-5 col-9 mt-lg-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 d-flex align-items-center gap-2" title="Sale">
                            <span class="text-dark">976 $</span>

                            <span class=" badge text-bg-danger rounded-0">-12%</span>
                        </div>
                    </router-link>

                    <div>
                        <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0 border-bottom border-dark pb-2">
                            Balmain
                        </p>

                        <div class="d-flex align-items-center justify-content-between gap-2 mt-2">
                            <p class="fs-14 fw-light text-uppercase text-truncate mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                <span class="me-2">Express view</span>
                                <img :src="ViewExpressIcon" width="20" alt="">
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg col-sm-5 col-9 mt-lg-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 d-flex align-items-center gap-2" title="Winter production">
                            <span class="text-dark">976 $</span>

                            <img :src="SnowflakeIcon" alt="" width="20">
                        </div>
                    </router-link>

                    <div>
                        <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0 border-bottom border-dark pb-2">
                            Balmain
                        </p>

                        <div class="d-flex align-items-center justify-content-between gap-2 mt-2">
                            <p class="fs-14 fw-light text-uppercase text-truncate mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                <span class="me-2">Express view</span>
                                <img :src="ViewExpressIcon" width="20" alt="">
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg col-sm-5 col-9 mt-lg-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 d-flex align-items-center gap-2" title="Sale">
                            <span class="text-dark">976 $</span>

                            <span class=" badge text-bg-danger rounded-0">-12%</span>
                        </div>
                    </router-link>

                    <div>
                        <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0 border-bottom border-dark pb-2">
                            Balmain
                        </p>

                        <div class="d-flex align-items-center justify-content-between gap-2 mt-2">
                            <p class="fs-14 fw-light text-uppercase text-truncate mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                <span class="me-2">Express view</span>
                                <img :src="ViewExpressIcon" width="20" alt="">
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg col-sm-5 col-9 mt-lg-3">
                <div class="product">
                    <router-link :to="{ name:'view-product'}" class="d-block position-relative">
                        <img
                            alt="product name"
                            class="product-pic product-pic-1 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg"
                            title="product name">
                        <img
                            alt="product name"
                            class="product-pic product-pic-2 w-100"
                            src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg"
                            title="product name">

                        <div class="position-absolute top-0 end-0 lh-1 mt-1 me-2 addFavouritesBtn">
                            <button class="btn border-0 p-0 lh-1" title="Add to favourites" type="button">
                                <i class="fa-regular fa-heart"></i>
                                <i class="fa-sharp fa-solid fa-heart"></i>
                            </button>
                        </div>

                        <div class="position-absolute top-0 start-0 d-flex align-items-center gap-2"
                             title="New">
                            <span class="text-dark">976 $</span>
                            <span class=" badge text-bg-info rounded-0 text-white">NEW</span>
                        </div>

                    </router-link>

                    <div>
                        <p class="fw-bold fs-18 letter-spacing-2 mt-2 text-uppercase mb-0 border-bottom border-dark pb-2">
                            Balmain
                        </p>

                        <div class="d-flex align-items-center justify-content-between gap-2 mt-2">
                            <p class="fs-14 fw-light text-uppercase text-truncate mb-0">SNEAKERS B-Runner</p>
                            <div class="d-flex align-items-center gap-1">
                                <span class="rounded-circle bg-danger" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-dark" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-success" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-warning" style="width: 12px; height: 12px;"></span>
                                <span class="rounded-circle bg-primary" style="width: 12px; height: 12px;"></span>
                            </div>
                        </div>

                        <div class="opacity-0 product-sizes mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="fs-14 fw-light mb-0">Sizes:</p>
                                <div class="d-flex align-items-center gap-1 fs-14">
                                    <span>39</span>
                                    <span>41</span>
                                    <span>43</span>
                                    <span>45</span>
                                </div>
                            </div>

                            <button class="btn p-0 border-0 mt-1 d-block mx-auto expressViewBtn"
                                    data-bs-target="#expessViewModal"
                                    data-bs-toggle="modal">
                                <span class="me-2">Express view</span>
                                <img :src="ViewExpressIcon" width="20" alt="">
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg col-sm-5 col-9 mt-lg-3 d-lg-none">
                <div class="product h-100">
                    <router-link
                        :to="{ name:'filtred' }"
                        class="btn border-0 text-uppercase d-flex align-items-center justify-content-center h-100">
                        Show more
                    </router-link>
                </div>
            </div>
        </div>

        <div class="mt-lg-5 mt-3 position-relative">
            <hr class="position-absolute translate-middle-y w-100" style="z-index: -1;">
            <div class="text-center bg-white px-3 position-relative start-50 translate-middle-x"
                 style="width: max-content">
                <router-link :to="{ name:'filtred' }" class="btn stretched-link btn-outline-dark text-uppercase">
                    View all
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Product1 from "@/assets/websites/moda/img/product1.png"
import Product2 from "@/assets/websites/moda/img/product2.png"
import Product3 from "@/assets/websites/moda/img/product3.png"
import OffersProduct1 from "@/assets/websites/moda/img/offers-product1.png"
import OffersProduct2 from "@/assets/websites/moda/img/offers-product2.png"
import OffersProduct3 from "@/assets/websites/moda/img/offers-product3.png"
import SnowflakeIcon from "@/assets/websites/moda/img/snowflake-icon.png"
import ViewExpressIcon from "@/assets/websites/moda/img/express-view-icon.png"

export default {
    name: "ModaNew",

    data() {
        return {
            SnowflakeIcon,
            Product1,
            Product2,
            Product3,
            OffersProduct1,
            OffersProduct2,
            OffersProduct3,
            ViewExpressIcon,
        }
    }
}
</script>

<style scoped>


</style>