import {parse} from 'tldts'
import {kebabCase} from './utils'
import {localStorageSet} from "@/core/localStorage"

export const setCompanyKeyFromDomain = () => {
    const domainData = parse(window.location.href)
    const {domain, subdomain, hostname, domainWithoutSuffix} = domainData
    let companyKey = null

    if (domain === null && subdomain === null && hostname === 'localhost') {
        if (process.env.VUE_APP_DOMAIN) {
            const envDomain = parse(process.env.VUE_APP_DOMAIN)
            const {domainWithoutSuffix} = envDomain

            companyKey = domainWithoutSuffix
        }
    } else if (domain === 'ak-top.ru' || domain === 'auto-click.pro') {
        companyKey = subdomain
    } else {
        companyKey = domainWithoutSuffix
    }

    if (!companyKey) {
        window.localStorage.removeItem('companyKey')
        alert('Please set company key in your project config file')
        return
    }

    const keyRewrites = {
        'your-fashion': 'moda'
    }

    companyKey = keyRewrites[companyKey.toLowerCase()] || companyKey.toLowerCase()

    // // TODO: remove this
    // if (companyKey.toLowerCase() === 'your-fashion') {
    //     companyKey = 'allmenu'
    // }

    localStorageSet('companyKey', companyKey.toLowerCase())

    return companyKey.toLowerCase()
}

export const requisite = (key, requisites) => {
    const requisite = requisites.find(requisite => requisite.key === key)

    return requisite ? requisite.value : null
}

export const data_get = (target, path, fallback) => {
    if (!target) {
        return fallback
    }

    let segments = Array.isArray(path) ? path : path.split('.')
    let [segment] = segments

    let find = target

    if (segment !== '*' && segments.length > 0) {
        if (find[segment] === null || typeof find[segment] === 'undefined') {
            find = typeof fallback === 'function' ? fallback() : fallback
        } else {
            find = data_get(find[segment], segments.slice(1), fallback)
        }
    } else if (segment === '*') {
        const partial = segments.slice(path.indexOf('*') + 1, path.length)

        if (typeof find === 'object') {
            find = Object.keys(find).reduce((build, property) => ({
                    ...build,
                    [property]: data_get(find[property], partial, fallback)
                }),
                {})
        } else {
            find = data_get(find, partial, fallback)
        }
    }

    if (typeof find === 'object') {
        if (Object.keys(find).length > 0) {
            const isArrayTransformable = Object.keys(find).every(index => index.match(/^(0|[1-9][0-9]*)$/))

            return isArrayTransformable ? Object.values(find) : find
        }
    } else {
        return find
    }
}

export const data_set = (target, path, value, force = true) => {
    let segments = Array.isArray(path) ? path : path.split('.')
    let [segment] = segments

    if (segments.length === 0) {
        target = value
    } else if (segments.length === 1 && !segments.includes('*')) {
        target[segment] = force ? value : target[segment] || value
    } else if (segment !== '*') {
        if (!target[segment]) {
            target[segment] = {}
            target = data_set(target[segment], segments.slice(1), value, force)
        }

        let inner = data_set(target[segment], segments.slice(1), value, force)

        if (Array.isArray(target[segment])) {
            if (force && target[segment].length) {
                target[segment] = [...target[segment]]
            } else {
                target[segment] = [...inner]
            }
        } else {
            target[segment] = force ? {...target[segment], ...inner} : {...inner, ...target[segment]}
        }
    } else if (segment === '*') {
        const partial = segments.slice(path.indexOf('*') + 1, path.length)

        if (typeof target === 'object') {
            target = Object.keys(target).reduce((build, property) => ({
                    ...build,
                    [property]: data_set(target[property], partial, value, force)
                }),
                {})
        } else {
            target = data_set(target, partial, value, force)
        }
    }

    const arrayable = [
        typeof target === 'object',
        Object.keys(target).length,
        Object.keys(target).every(index => index.match(/^(0|[1-9][0-9]*)$/))
    ]

    if (arrayable.every(requirement => requirement === true)) {
        return Object.values(target)
    }

    return target
}

export const blank = value => {
    if (typeof value === 'undefined') {
        return true
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0
    }

    if (typeof value === 'function') {
        return false
    }

    if (typeof value === 'boolean') {
        return false
    }

    if (value === null || value.trim() === '') {
        return true
    }

    return true
}

export const data_fill = (target, key, value) => {
    return data_set(target, key, value, false)
}

export const camelToSnake = string => {
    let result = string.replace(/([A-Z])/g, '_$1').toLowerCase()

    if (result.charAt(0) === '_') {
        result = result.slice(1)
    }

    return result
}

export const group_by = (list, keyGetter) => {
    const map = new Map()

    list.forEach((item) => {
        const key = keyGetter(item)
        const collection = map.get(key)

        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })

    return map
}

export const array_from_group_by = (list, keyGetter) => {
    return Array.from(group_by(list, keyGetter).values())
}

export const round_number = number => {
    return Math.round(number * 100) / 100
}

export function eq(arg1, arg2) {
    return function () {
        return this[arg1] === (this[arg2] || arg2)
    }
}

export function notEq(arg1, arg2) {
    return function () {
        return this[arg1] !== (this[arg2] || arg2)
    }
}

export function not(...args) {
    return function () {
        return args.every(arg => !this[arg])
    }
}

export function and(...args) {
    return function () {
        return args.every(arg => this[arg])
    }
}

export function or(...args) {
    return function () {
        return args.some(arg => this[arg])
    }
}

export function xor(arg1, arg2) {
    return function () {
        return (this[arg1] && !this[arg2]) || (this[arg2] && !this[arg1])
    }
}

export function gt(arg1, arg2) {
    return function () {
        return this[arg1] > (this[arg2] || arg2)
    }
}

export function gte(arg1, arg2) {
    return function () {
        return this[arg1] >= (this[arg2] || arg2)
    }
}

export function lt(arg1, arg2) {
    return function () {
        return this[arg1] < (this[arg2] || arg2)
    }
}

export function lte(arg1, arg2) {
    return function () {
        return this[arg1] <= (this[arg2] || arg2)
    }
}

export function sum(...args) {
    return function () {
        const firstArg = this[args[0]]

        // First argument is an array
        if (args.length === 1 && Array.isArray(firstArg)) {
            return firstArg.reduce((acc, num) => acc + num, 0)
        }

        // One of passed arguments is not a number
        if (args.some(arg => !Number.isFinite(arg) && !Number.isFinite(this[arg]))) {
            console.assert(true, 'One of passed properties to "sum" helper is not a number')
            return 0
        }

        return args
            .reduce(
                (acc, arg) => acc + (this[arg] || arg),
                0
            )
    }
}

export function alias(arg) {
    return function () {
        return this[arg]
    }
}

export function bool(arg) {
    return function () {
        return Boolean(this[arg])
    }
}

export function empty(arg) {
    return function () {
        return !this[arg] || this[arg].length === 0
    }
}

export function max(arg) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "max" requires property of array type')
        return isArray ? this[arg].sort((a, b) => a < b)[0] : 0
    }
}

export function min(arg) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "min" requires property of array type')
        return isArray ? this[arg].sort((a, b) => a > b)[0] : 0
    }
}

export function filter(arg, fn) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "filter" requires property of array type')
        return isArray ? this[arg].filter(fn) : []
    }
}

export function filterBy(arg, key, value) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "filterBy" requires property of array type')
        if (!isArray) return []
        return this[arg]
            .filter((item) => {
                return typeof item[key] !== undefined && item[key] === value
            })
    }
}

export function find(arg, fn) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "find" requires property of array type')
        return isArray ? this[arg].find(fn) : undefined
    }
}

export function findBy(arg, key, value) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "findBy" requires property of array type')
        if (!isArray) return undefined
        return this[arg]
            .find((item) => {
                return typeof item[key] !== undefined && item[key] === value
            })
    }
}

export function map(arg, fn) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "map" requires property of array type')
        return isArray ? this[arg].map(fn) : []
    }
}

export function mapBy(arg, key) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "map" requires property of array type')
        return isArray ? this[arg].map(item => item[key]) : []
    }
}

export function count(arg) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "count" requires property of array type')
        if (!isArray) return 0
        return this[arg].length
    }
}

export function countBy(arg, key, value) {
    return function () {
        const isArray = Array.isArray(this[arg])
        console.assert(isArray, 'computed helper "countBy" requires property of array type')
        if (!isArray) return 0
        return this[arg].filter(item => item[key] && item[key] === value).length
    }
}

export function classObject(...args) {
    return function () {
        const hasProperArgs = args.every(arg => typeof arg === 'string')
        console.assert(hasProperArgs, 'computed helper "classObject" requires arguments of string type')

        return args.reduce((acc, arg) => {
            let className = kebabCase(arg)
            let prop = arg

            if (arg.indexOf(':') > 0) {
                [className, prop] = arg.split(':')
            }

            acc[className] = Boolean(this[prop]);
            return acc;
        }, {});
    }
}

export const checkToken = () => {
    let result = window.localStorage.getItem('token')

    result = result ? true : false

    return result
}
export const snakeToCamel = str => {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    )
}