<template>
    <div class="container-fluid position-relative px-0">
        <div class="container py-lg-5 py-3">
            <div class="d-lg-flex justify-content-center">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="col-lg-5 px-lg-4 mx-auto mt-lg-5 mt-3">
                <div>
                    <label class="col-form-label-lg text-white pt-0" for="check-in">Check-in</label>
                    <input id="check-in" v-model="checkInDate" class="form-control form-control-lg rounded-0"
                           data-date-format="YYYY MM DD" required type="date">
                </div>

                <div class="mt-3">
                    <label class="col-form-label-lg text-white pt-0" for="check-out">Check-out</label>
                    <input id="check-out" v-model="checkOutDate" class="form-control form-control-lg rounded-0"
                           required type="date">
                </div>

                <div class="mt-4">
                    <button :disabled="searchDisabled"
                            class="btn btn-light text-uppercase fw-bold rounded-0 btn-lg w-100 search-btn"
                            @click="searchForReservation">
                        Search
                    </button>
                </div>

                <div class="mt-4">
                    <h1 class="fs-5 fw-bold bg-white px-3 py-2 mb-0">
                        Reservation
                    </h1>

                    <div class="p-3 border border-white">
                        <table class="table table-sm table-borderless text-white mb-0 border-0">
                            <thead>
                            <tr>
                                <th class="px-0">Check-in</th>
                                <th class="px-0">Check-out</th>
                                <th class="px-0 text-end">Night</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="fw-bold">
                                <td class="px-0">Oct 1, 2022</td>
                                <td class="px-0">Oct 2, 2022</td>
                                <td class="px-0 text-end">
                                    <i class="fa fa-moon"></i> 1
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="bg-light text-dark p-2 mt-3" role="alert">
                            Choose your room
                        </div>

                        <table class="table table-sm table-borderless text-white mb-0 mt-3">
                            <thead>
                            <tr>
                                <th>Rooms</th>
                                <th class="text-end">Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="border-bottom">
                                <td>
                                    <div class="d-flex gap-2">
                                        <div class="text-info">3x</div>

                                        <div>
                                            <div class="fw-bold">Single Room</div>
                                            <div>• RoomOnly1</div>
                                            <div>• 1 Guest</div>
                                        </div>

                                    </div>

                                </td>
                                <td class="text-end">
                                    <span class="fw-bold">$&nbsp;1,500.00</span>
                                </td>
                            </tr>

                            <tr class="border-bottom">
                                <td>
                                    <div class="d-flex gap-2">
                                        <div class="text-info">5x</div>

                                        <div>
                                            <div class="fw-bold">Single Room</div>
                                            <div>• Non Refundable Rate</div>
                                            <div>• 1 Guest</div>
                                        </div>
                                    </div>

                                </td>
                                <td class="text-end">
                                    <span class="fw-bold">$&nbsp;2,500.00</span>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-end" colspan="2">
                                        <span class="fw-bold">
                                            Total:
                                            <span class="fw-bold">$&nbsp;4,000.00</span>
                                        </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button class="btn btn-light text-uppercase fw-bold rounded-0 w-100 search-btn mt-3">
                            Search
                        </button>
                    </div>


                </div>

                <div v-if="reservationVisible" id="accordionRooms" class="accordion mt-lg-5 mt-4">
                    <div v-for="availableRoom in availableRooms" :key="availableRoom.id"
                         class="accordion-item rounded-0">
                        <h2 :id="'accordion-room-heading' + availableRoom.id" class="accordion-header">
                            <button :aria-controls="'accordion-room' + availableRoom.id"
                                    :data-bs-target="'#accordion-room' + availableRoom.id" aria-expanded="false"
                                    class="accordion-button collapsed p-2" data-bs-toggle="collapse"
                                    type="button">
                               <span class="d-flex align-items-start gap-2">
                                    <i class="fa-solid fa-bed"></i>

                                    <span class="d-flex flex-column">
                                        <strong>{{ availableRoom.channelName }}</strong>
                                        <span class="text-danger">
                                            {{ availableRoom.channelRooms }} room(s) left
                                        </span>
                                    </span>
                               </span>
                            </button>
                        </h2>

                        <div :id="'accordion-room' + availableRoom.id"
                             :aria-labelledby="'accordion-room-heading' + availableRoom.id"
                             class="accordion-collapse collapse">
                            <div class="accordion-body border-top fw-normal">
                                <div class="row">
                                    <div class="col-md-4 border-end border-end-0 ps-md-0 pe-md-3 px-0">
                                        <img :src="roomImage(availableRoom)" alt="No image" class="w-100 border">

                                        <p class="mt-1 mb-0">
                                            Prices per room
                                        </p>

                                        <p class="mt-1 mb-0">
                                            <strong>Includes VAT:</strong> 18.00 %
                                        </p>

                                        <hr>

                                        <div class="d-flex align-items-center flex-wrap gap-1 text-success fs-14">
                                            <span>
                                                Bidet &bull;
                                            </span>

                                            <span>
                                                Shower &bull;
                                            </span>

                                            <span>
                                                Oversized bathtub &bull;
                                            </span>

                                            <span>
                                                Soundproofed room &bull;
                                            </span>

                                            <span>
                                                Tables and chairs &bull;
                                            </span>

                                            <span>
                                                Fax machine &bull;
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-8 ps-md-3 pe-md-0 px-0 mt-md-0 mt-3">
                                        <div v-for="availableRate in availableRoom.rates" :key="availableRate.id"
                                             class="border-bottom mb-3">
                                            <p class="mb-0 text-info fw-bold">
                                                {{ availableRate.channelName }}
                                            </p>

                                            <ul class="ps-3 mb-0">
                                                <li v-for="meal in availableRate.meals" :key="meal.Id">
                                                    {{ meal.DisplayName }}
                                                </li>
                                            </ul>

                                            <table
                                                v-if="availableRate.dates && availableRate.dates.length && availableRate.dates[0].occupancies"
                                                class="table table-sm border-top mt-3 fs-14 align-middle">
                                                <thead>
                                                <tr>
                                                    <th>Sleeps</th>
                                                    <th>Price</th>
                                                    <th>Number Of Rooms</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="occupacy in sortOccupancies(availableRate.dates[0].occupancies)"
                                                    :key="occupacy.occupancyId">
                                                    <td>
                                                        <i v-for="personsCount in occupacy.occupancyId"
                                                           :key="personsCount"
                                                           class="fa fa-user"></i>
                                                    </td>
                                                    <td>
                                                        <span class="font-weight-bold">$ {{ occupacy.price }}</span>
                                                    </td>
                                                    <td>
                                                        <select class="form-select form-select-sm rounded-0">
                                                            <option :value="null">0</option>
                                                            <option :value="occupacy.price">1&nbsp;&nbsp;&nbsp;$
                                                                {{ occupacy.price }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <!--                                            <p>-->
                                            <!--                                                <i class="fa fa-info-circle fs-14"></i>-->
                                            <!--                                                The guest will be charged 30% of the total-->
                                            <!--                                                price if they cancel before 2022-09-19. The guest will be charged the-->
                                            <!--                                                first-->
                                            <!--                                                night if they cancel after 2022-09-19.-->
                                            <!--                                            </p>-->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/blueberrynightshotel/img/no_image.png"
import ApiRequest from "@/http/ApiRequest";
import _ from "lodash";

export default {
    name: "HotelBooking",

    data() {
        return {
            NoImage,
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
            checkInDate: null,
            checkOutDate: null,
            availableRooms: [],
            selectedReservation: null,
            availableRates: [],
        }
    },

    computed: {
        searchDisabled() {
            return !this.checkInDate
                || !this.checkOutDate
        },

        reservationVisible() {
            return !this.searchDisabled
                && this.availableRooms.length > 0
        }
    },

    methods: {
        searchForReservation() {
            if (!this.checkInDate || !this.checkOutDate) {
                return
            }

            new ApiRequest('hotel/search-available-rooms', {
                checkInDate: this.checkInDate,
                checkOutDate: this.checkOutDate
            }, response => {
                if (!response.success) {
                    this.availableRooms = []
                    return
                }

                this.availableRooms = response.data.availableRooms
            })
        },

        sortOccupancies(occupancies) {
            return _.sortBy(occupancies, 'occupancyId')
        },

        roomImage(room) {
            if (!room.images || !room.images.length) {
                return this.NoImage
            }

            return room.images[0].medium
        }
    }
}
</script>

<style lang="scss" scoped>
.search-btn {
    transition: .3s;

    &:hover {
        opacity: .85;
    }
}

.col-form-label-lg::after {
    content: '\002A';
    color: #c70e0e;
    margin-left: 5px;
}

.accordion-button:not(.collapsed) {
    color: unset !important;
    background-color: unset !important;
    box-shadow: unset !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

.fs-14 {
    font-size: 14px;
}

.text-info {
    color: #54c5c1 !important;
}

@media screen and (max-width: 760px) {
    .border-end-0 {
        border-right: unset !important;
    }
}
</style>