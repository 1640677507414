<template>
    <nav class="navbar bg-light">
        <div class="container px-lg-0">
            <router-link :to="{ name: 'home' }" class="navbar-brand">
                ALLMENU.GE
            </router-link>

            <button aria-controls="offcanvasNavbar" class="navbar-toggler border-0 p-0"
                    data-bs-target="#offcanvasNavbar"
                    data-bs-toggle="offcanvas" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" class="offcanvas offcanvas-end"
                 tabindex="-1">
                <div class="offcanvas-header">
                    <h5 id="offcanvasNavbarLabel" class="offcanvas-title"></h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas"
                            type="button"></button>
                </div>

                <div class="offcanvas-body">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li class="nav-item">
                            <a class="nav-link" href="#">ქართული კერძები</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">ევროპული კერძები</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">აზიური კერძები</a>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'deseart' }" class="nav-link">
                                დესერტები
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">თევზეული და ზღვის პროდუქტები</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "HeaderLanding"
}
</script>

<style scoped>

</style>