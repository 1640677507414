<template>
    <div>
        <div class="row m-0">
            <div class="col-12 px-3">
                <label class="form-label fs-15 fw-bold" for="name">
                    {{ trans('menu_your_name') }}*
                </label>
                <input id="name" v-model="reservationData.name" class="form-control" type="text">
            </div>

            <div class="col-12 px-3 mt-3">
                <label class="form-label fs-15 fw-bold" for="phone">
                    {{ trans('menu_phone_number') }}*
                </label>
                <input id="phone" v-model="reservationData.phone" class="form-control" type="number">
            </div>

            <div class="col-12 px-3 mt-3">
                <label class="form-label fs-15 fw-bold" for="email">
                    {{ trans('email') }}
                </label>
                <input id="email" v-model="reservationData.email" class="form-control" type="email">
            </div>

            <div class="col-12 px-3 mt-3">
                <label class="form-label fs-15 fw-bold" for="request_or_comment">
                    {{ trans('menu_special_request_or_comment') }}
                </label>
                <textarea id="request_or_comment" v-model="reservationData.comment" class="form-control"
                          rows="3"></textarea>
            </div>

            <div class="col-12 px-3 mt-3">
                <div class="form-check">
                    <input id="flexCheckChecked" v-model="reservationData.agreeWithTerms" checked
                           class="form-check-input" type="checkbox">
                    <label class="form-check-label fs-12" for="flexCheckChecked">
                        {{ trans('menu_agreement') }}
                    </label>
                </div>
            </div>

            <div class="px-3 py-3">
                <button :disabled="!reserveButtonActive || requestProcessing"
                        class="btn btn-lg btn-warning fs-15 fw-bold rounded w-100" @click="reserve">
                    {{ trans('menu_reservation_table') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {t} from "@/lang/core/helpers";
import ApiRequest from "@/http/ApiRequest";
import {mapGetters} from "vuex";

export default {
    name: "TableReservationContacts",

    computed: {
        ...mapGetters([
            'menuCompany',
        ]),

        reservationData: {
            get() {
                return this.$store.getters.reservationData;
            },
            set(value) {
                this.$store.commit('setTableReservationData', value);
            }
        },

        reserveButtonActive() {
            return this.reservationData.agreeWithTerms
                && this.reservationData.reservationDate
                && this.reservationData.personsCount
                && this.reservationData.name
                && this.reservationData.name.trim().length
                && this.reservationData.phone
                && this.reservationData.phone.trim().length;
        },
    },

    data() {
        return {
            requestProcessing: false
        }
    },

    methods: {
        trans(key) {
            return t(key)
        },

        reserve() {
            this.requestProcessing = true;
            new ApiRequest('menu/reservation/requests/create', {
                ...this.reservationData,
                companyId: this.menuCompany.id,
            }, response => {
                this.requestProcessing = false

                if (!response.success) {
                    console.error(response);
                    return;
                }

                let now = new Date();
                now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
                let defaultReservationDate = now.toISOString().slice(0, 16);

                this.reservationData = {
                    reservationDate: defaultReservationDate,
                    personsCount: 1,
                    name: null,
                    email: null,
                    phone: null,
                    comment: null,
                    agreeWithTerms: false,
                }

                this.$store.commit('setTableReservationStep', 1)
            })
        }
    }
}
</script>

<style>
.fs-12 {
    font-size: 12px;
}
</style>
