<template>
    <div class="shadow-sm rounded bg-white p-3 pb-0">
        <div id="nav-tab" class="nav nav-tabs flex-nowrap text-nowrap" role="tablist">
            <button id="basic-info-tab"
                    aria-controls="basic-info"
                    aria-selected="true"
                    class="nav-link active"
                    data-bs-target="#basic-info"
                    data-bs-toggle="tab"
                    role="tab"
                    type="button">
                {{ trans('basic_information') }}
            </button>

            <button v-if="carPart.supported_cars && carPart.supported_cars.length"
                    id="compatible-models-tab"
                    aria-controls="compatible-models"
                    aria-selected="false"
                    class="nav-link"
                    data-bs-target="#compatible-models"
                    data-bs-toggle="tab"
                    role="tab" type="button">
                {{ trans('compatible_models') }}
            </button>

        </div>

        <div v-if="carPart" id="nav-tabContent" class="tab-content">
            <div id="basic-info" aria-labelledby="basic-info-tab" class="tab-pane fade show active" role="tabpanel"
                 tabindex="0">
                <h2 class="fs-15 text-secondary mb-0 mt-3">{{ data_get(carPart, 'name') }} -
                    {{ getSupportedCars(carPart) }}
                </h2>
                <table class="table mb-0 mt-2">
                    <tbody>
                    <tr>
                        <td>{{ trans('original') }}</td>
                        <td>{{ carPart.is_original ? trans('yes') : trans('no') }}</td>
                    </tr>

                    <tr>
                        <td>{{ trans('position') }}</td>
                        <td>წინა</td>
                    </tr>

                    <tr>
                        <td>{{ trans('condition') }}</td>
                        <td>ახალი</td>
                    </tr>

                    <tr>
                        <td>{{ trans('manufacturer_country') }}</td>
                        <td>ტაივანი</td>
                    </tr>

                    <tr class="border-white">
                        <td>{{ trans('manufacturer_code') }}</td>
                        <td>AG4747GV</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div id="compatible-models" aria-labelledby="compatible-models-tab" class="tab-pane fade" role="tabpanel"
                 tabindex="0">
                <ul v-if="carPart.supported_cars && carPart.supported_cars.length" class="py-2 px-0 mb-0">
                    <li v-for="supportedCar in carPart.supported_cars" :key="supportedCar.id">
                        {{ supportedCar.supported_car }}
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
import {data_get} from "@/core/helpers";
import {t} from "@/lang/core/helpers";

export default {
    name: 'ProductDescriptionGP',

    methods: {
        trans(key) {
            return t(key)
        },

        data_get,

        getSupportedCars(part) {
            if (!part.supported_cars || !part.supported_cars.length) {
                return ''
            }

            let supportedCars = ''

            part.supported_cars.forEach((car, index) => {
                supportedCars += `${car.short_supported_car}`

                if (index !== part.supported_cars.length - 1) {
                    supportedCars += ', '
                }
            })

            return supportedCars;
        }
    },

    props: {
        carPart: {
            required: true,
        }
    },
}
</script>

<style scoped>
.nav-tabs .nav-link.active {
    color: #26262a;
    font-weight: 700;
    background-color: unset;
    border-bottom: 3px solid #ffc107 !important;
}

.nav-tabs .nav-link {
    border: unset;
    color: #6f7383;
    font-weight: 700;
}

.nav.nav-tabs {
    overflow-x: auto;
    overflow-y: clip;
}
</style>