<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">

        <div class="container px-0">
            <AutoCategories/>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <AutoFilter/>
        </div>

        <div class="container px-0 mt-lg-5 mt-3">
            <AutoSaleables/>
        </div>

    </div>
</template>

<script>
import AutoCategories from "@/components/websites/auto/parts/Categories.vue";
import AutoFilter from "@/components/websites/auto/parts/Filter.vue";
import AutoSaleables from "@/components/websites/auto/parts/Saleables.vue";

export default {
    name: "AutoCars",
    components: {AutoSaleables, AutoFilter, AutoCategories}
}
</script>

<style scoped>

</style>