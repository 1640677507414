// import Vuex from 'vuex'
// import Vue from 'vue'
import state from './state'
import mutations from './mutators'
import actions from './actions'
import getters from './getters'
import { createStore } from 'vuex'

// Vue.use(Vuex)

// const store = new Vuex.Store({
//     state,
//     mutations,
//     actions,
//     getters,
//     modules: {},
// })

const store = createStore({
    state,
    getters,
    mutations,
    actions
})

// if (module.hot) {
//     module.hot.accept(['./mutators', './actions', './getters'], () => {
//         store.hotUpdate({
//             mutations: require('./mutators').default,
//             actions: require('./actions').default,
//             getters: require('./getters').default,
//         });
//     });
// }

export default store