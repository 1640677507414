<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">{{ trans('favourites') }}</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 mt-3">
            <div class="container px-0">
                <p v-if="favoriteCarParts.length === 0" class="fs-4 text-center px-0 mt-5 mb-0">
                    {{ trans('favourites_empty') }}
                </p>

                <div class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 m-0 favourites_products__row mt-3">
                    <div v-for="product in favoriteCarParts"
                         :key="product.id"
                         class="col px-2 mb-lg-4 mb-3 position-relative">
                        <div class="bg-white p-2 rounded shadow-sm">
                            <router-link :to="{
                                     name: 'view-product',
                                     params: {
                                            id: product.id}
                                     }"
                                         class="d-block fav_product position-relative">
                                <img
                                    :src="getProductImage(product)"
                                    alt="product name"
                                    class="w-100 rounded-top fav__product_img"
                                    height="150"
                                    title="product name">
                            </router-link>

                            <div class="position-absolute top-0 end-0 mx-2 lh-1" style="z-index: 10;">
                                <button :title="trans('remove_from_favourites')"
                                        class="btn border-0 lh-1 remove_from_fav bg-white rounded-0"
                                        type="button" @click="toggleFavorite(product)">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>

                            <div class="mt-2">
                                <router-link :to="{
                                     name: 'view-product',
                                     params: {
                                            id: product.id}
                                     }"
                                             class="fw-bold text-dark mb-0 product__name text-truncate d-block">
                                    {{ getTranslatedPropertyByLocale(product, 'name', locale) }}
                                </router-link>

                                <p class="mb-0 fs-14 text-truncate">
                                    <span v-if="data_get(product, 'mark.name')">{{ data_get(product, 'mark.name') }},</span>
                                    <span v-if="data_get(product, 'model.name')">{{ data_get(product, 'model.name') }},</span>
                                    <span v-if="data_get(product, 'generation.name')">{{ data_get(product, 'generation.name') }},</span>
                                    <span v-if="data_get(product, 'modification.name')">{{ data_get(product, 'modification.name') }}</span>
                                </p>

                                <p class="mt-2 mb-0 fs-14">
                                    {{ product.price_discount || product.price }} ₾
                                </p>

                                <div class="mt-2">
                                    <button v-if="!isInCart(product)"
                                            class="btn btn-outline-warning text-dark btn-sm w-100"
                                            @click="addToCart(product)">
                                        <i class="fa-sharp fa-solid fa-cart-shopping"></i>
                                        {{ trans('add') }}
                                    </button>

                                    <div v-else class="d-flex align-items-center mx-auto border bg-white rounded"
                                         style="width: max-content;">
                                        <div>
                                            <button class="btn btn-sm border-0" type="button"
                                                    @click="decreaseItemCount(product)">
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <input
                                                :value="itemQuantity(product)"
                                                class="form-control text-center p-2 border-start border-end border-0 rounded-0"
                                                style="width: 35px; height: 30px;"
                                                type="tel">
                                        </div>
                                        <div>
                                            <button class="btn btn-sm border-0" type="button"
                                                    @click="increaseItemCount(product)">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image.png"
import {getTranslatedPropertyByLocale, t} from "@/lang/core/helpers";
import {mapGetters} from "vuex";
import ApiRequest from "@/http/ApiRequest";
import product from "../parts/Product.vue";
import {data_get} from "@/core/helpers";
import _ from "lodash";

export default {
    name: 'FavouritesGP',

    computed: {
        product() {
            return product
        },
        ...mapGetters(['favorites', 'locale', 'shoppingCart']),

        favoriteIds() {
            if (!this.favorites || !this.favorites.length) return []

            return this.favorites.map(favorite => favorite.id)
        }
    },

    watch: {
        favoriteIds() {
            this.getFavorites()
        }
    },

    created() {
        this.getFavorites()
    },

    data() {
        return {
            NoImage,
            favoriteCarParts: [],
            companyKey: 'get-parts'
        }
    },

    methods: {
        getTranslatedPropertyByLocale,

        data_get,

        trans(key) {
            return t(key)
        },

        getFavorites() {
            if (!this.favoriteIds.length) return

            new ApiRequest('car-parts/find', {
                key: this.companyKey,
                id: [this.favoriteIds]
            }, response => {
                if (!response.success) {
                    alert(response.message || 'პროდუქტი ვერ მოიძებნა')
                    return
                }

                this.favoriteCarParts = response.data
            })
        },

        getImageBySize(images, size) {
            let filterImage = _.filter(images, {image_type: size})
            return filterImage.length ? filterImage[0].url : null
        },

        getProductImage(part) {
            if (!part.images.length) {
                return NoImage
            }

            return this.getImageBySize(part.images, 'MEDIUM')
        },

        isInCart(part) {
            return this.shoppingCart.find(item => item.id === part.id)
        },

        addToCart(part) {
            this.$store.dispatch('addToCart', part)
        },

        increaseItemCount(product) {
            this.$store.dispatch('increaseItemCount', product)
        },

        decreaseItemCount(product) {
            this.$store.dispatch('decreaseItemCount', product)
        },

        itemQuantity(product) {
            if (!this.shoppingCart || !this.isInCart(product)) {
                return 0
            }

            return this.shoppingCart.find(item => item.id === product.id).quantity
        },

        toggleFavorite(part) {
            this.$store.dispatch('toggleFavorite', part)
        },
    }
}
</script>

<style scoped>
.product__name:hover {
    text-decoration: underline;
}

.remove_from_fav {
    border-top-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.remove_from_fav:hover {
    background: #e3e3e3 !important;
    color: #000;
}

.fav__product_img {
    object-fit: cover;
}

@media screen and (max-width: 760px) {
    .favourites_products__row .col:nth-child(odd) {
        padding-right: 6px !important;
    }

    .favourites_products__row .col:nth-child(even) {
        padding-left: 6px !important;
    }
}
</style>