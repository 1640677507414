<template>
    <div class="container-fluid position-relative px-0">
        <div class="container pt-lg-5 pt-3">
            <div class="d-lg-flex justify-content-center">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="d-lg-flex justify-content-lg-center">
                <div class="mt-5">
                    <img :src="kitchenBg" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HotelKitchen",

    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
            kitchenBg: require("@/assets/websites/blueberrynightshotel/img/kitchen.jpg"),
        }
    }
}
</script>
