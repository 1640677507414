<template>
    <div class="container-fluid mt-lg-5 mt-3 px-0">
        <div class="container p-0">
            <div class="row row-cols-lg-4 row-cols-2 m-0 info">
                <div class="col px-2 mb-lg-0 mb-3">
                    <a class="d-block bg-white shadow-sm p-lg-4 p-2 text-center overflow-hidden h-100"
                       href="#">
                        <div>
                            <img :src="InfoIcon1" alt="Credit icon" class="img-fluid" height="77" loading="lazy"
                                 width="101">
                        </div>

                        <p class="fs-5 fs-sm-14 fw-500 text-uppercase text-dark">кредит</p>

                        <ul class="fs-14 fw-light mt-2 mb-0 text-dark text-start ps-3 d-none d-lg-block">
                            <li style="list-style: outside !important;">

                                От 0 руб. первоначальный взнос
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                От 11% годовых
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                От 6 месяцев до 7 лет
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                До 3 500 000 рублей
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                КАСКО по выбору клиента
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Более 40 кредитных программ!
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Первоначальный взнос — от 0% стоимости авто!
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Срок кредитования — до 7 лет!
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Ответ кредитных специалистов в течение 30 минут!
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Досрочное погашение кредита без штрафа!
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Одобрение 9 из 10 анкет!
                            </li>
                        </ul>
                    </a>
                </div>

                <div class="col px-2 mb-lg-0 mb-3">
                    <a class="d-block bg-white shadow-sm p-lg-4 p-2 text-center overflow-hidden h-100"
                       href="#">
                        <div>
                            <img :src="InfoIcon2" alt="Credit icon" class="img-fluid" height="77" loading="lazy"
                                 width="101">
                        </div>
                        <p class="fs-5 fs-sm-14 fw-500 text-uppercase text-dark">trade-in</p>
                        <ul class="fs-14 fw-light mt-2 mb-0 text-dark text-start ps-3 d-none d-lg-block">
                            <li style="list-style: outside !important;">

                                Прибытие клиента в автосалон
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Проверка документов и идентификаторов
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Тест-драйв: проверка ходовых качеств авто
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                В ходе всей процедуры вы можете отдыхать в комфортном офисе
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Проверка юридической чистоты и пробега автомобиля
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Осмотр автомобиля в техцентре на эстакаде
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Диагностика автомобиля
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Определение стоимости
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Сделка с клиентом
                            </li>
                            <li class="mt-1" style="list-style: outside !important;">

                                Расчет
                            </li>
                        </ul>
                    </a>
                </div>

                <div class="col px-2 mb-0">
                    <a class="d-block bg-white shadow-sm p-lg-4 p-2 text-center overflow-hidden h-100"
                       href="#">
                        <div>
                            <img :src="InfoIcon3" alt="Credit icon" class="img-fluid" height="77" loading="lazy"
                                 width="101">
                        </div>
                        <p class="fs-5 fs-sm-14 fw-500 text-uppercase text-dark">выкуп</p>
                        <p class="fs-14 fw-light mt-2 text-start text-dark d-none d-lg-block">
                            Отличная возможность получения денежных средств в максимально короткие сроки. Если Вам
                            необходимо срочно продать автомобиль, но Вы не желаете этим заниматься самостоятельно или у
                            Вас просто нет на это времени, доверьтесь профессионалам.
                        </p>
                        <p class="fs-14 fw-light mt-2 mb-0 text-start text-dark d-none d-lg-block">
                            Скупка автомобилей с пробегом - это одна из услуг, которую мы предоставляем. В нашей команде
                            трудятся только высококвалифицированные специалисты с большим опытом работы, которые на все
                            задачи реагируют четко и оперативно.
                        </p>
                    </a>
                </div>

                <div class="col px-2 mb-0">
                    <a class="d-block bg-white shadow-sm p-lg-4 p-2 text-center overflow-hidden h-100"
                       href="#">
                        <div>
                            <img :src="InfoIcon4" alt="Credit icon" class="img-fluid" height="77" loading="lazy"
                                 width="101">
                        </div>
                        <p class="fs-5 fs-sm-14 fw-500 text-uppercase text-dark">гарантия</p>
                        <p class="fs-14 fw-light mt-2 text-start text-dark d-none d-lg-block">
                            Покупка подержанного авто у нас — это гарантия того, что вы получите автомобиль без
                            каких-либо технических неисправностей, с комплектом необходимых документов и исчерпывающей
                            информацией о его предыдущих владельцах.
                        </p>
                        <p class="fs-14 fw-light mt-2 mb-0 text-start text-dark mb-0 d-none d-lg-block">
                            Прежде чем совершить сделку, вы всегда можете проверить работоспособность всех систем
                            транспортного средства в специализированном сервисе, а также лично испытать машину на
                            тест-драйве.
                        </p>
                    </a>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import InfoIcon1 from "@/assets/websites/rb-auto/img/info-icon-1.png"
import InfoIcon2 from "@/assets/websites/rb-auto/img/info-icon-2.png"
import InfoIcon3 from "@/assets/websites/rb-auto/img/info-icon-3.png"
import InfoIcon4 from "@/assets/websites/rb-auto/img/info-icon-4.png"

export default {
    name: 'InfoLinks',

    data() {
        return {
            InfoIcon1,
            InfoIcon2,
            InfoIcon3,
            InfoIcon4,
        }
    }
}
</script>

<style scoped>

</style>