<template>
    <div class="container-fluid position-relative px-0">
        <div class="container pt-lg-5 pt-3 px-0">
            <div class="d-lg-flex justify-content-center">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="d-lg-flex justify-content-lg-center">
                <div class="text-uppercase text-white fs-50 fs-sm-25 mt-5">

                    <p class="mb-0 text-center">
                        <a :href="requisite('LOC_GOOGLE_MAP_LINK')"
                           class="text-white text-decoration-none contactsRequisites" target="_blank">
                            7 chovelidze str. tbilisi
                        </a>
                    </p>

                    <div class="mt-2" style="background-color: #F1F3F4">
                        <div v-html="requisite('LOC_GOOGLE_MAP_EMBED')"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {requisite} from "@/core/helpers";
import {mapGetters} from "vuex";

export default {
    name: "HotelLocation",
    computed: {
        ...mapGetters(['requisites'])
    },
    methods: {
        requisite(key) {
            return requisite(key, this.requisites)
        }
    },
    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
        }
    }
}
</script>
