import store from "@/store/_index"
import {data_get} from "@/core/helpers"

export const t = (key, s = null) => {
    const st = store || s
    const translations = st.getters.translations
    const locale = st.getters.locale

    let k = locale + "." + key

    return data_get(translations, k)
}

export const getTranslatedPropertyByLocale = (obj, property, locale = 'ka', fallback = null) => {
    if (!obj || !Object.keys(obj).length || !property) return fallback

    let translations = obj.translations || []
    let translation = translations.find(t => t.locale === locale)

    if (!translation) {
        if (locale !== 'ka') return getTranslatedPropertyByLocale(obj, property, 'ka', fallback)

        return fallback
    }

    if (!translation[property] && locale !== 'ka') return getTranslatedPropertyByLocale(obj, property, 'ka', fallback)

    return translation[property] || fallback
}