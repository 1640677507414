<template>
    <div id="zoomImage" aria-hidden="true" aria-labelledby="zoomImageLabel" class="modal fade zoomImage" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down modal-dialog-scrollable">
            <div class="modal-content border-0 scroll-styles">
                <div class="modal-header border-0 p-2">
                    <h5 class="modal-title">Premiata Golden Goose</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>

                <div class="modal-body p-0">
                    <div class="d-flex flex-column gap-2">
                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>

                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>

                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>

                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>

                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>

                        <div>
                            <img
                                alt="product name"
                                class="w-100 img-fluid"
                                src="https://img.mysilver.ge/cafedaphna/product/large/zMed90uw0F4Cbg5RWzoVUPkF6ShsxoBqdprVAXBU.jpg"
                                title="product name">
                        </div>
                    </div>
                </div>

                <div class="modal-footer justify-content-center border-0 p-0" style="margin-top: -35px; z-index: 10;">
                    <div class="animate__animated animate__fadeInDown animate__infinite">
                        <i class="fa-sharp fa-solid fa-chevron-down text-white fs-5"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaZoomImage"
}
</script>

<style scoped>

.modal-body .carousel-item img {
    height: unset !important;
}

@media screen and (max-width: 992px) {
    .zoomImage .modal-dialog {
        height: 100% !important;
        margin-top: 0 !important;
    }
}
</style>