<template>
    <div>
        <transition name="toast">
            <div v-if="coppiedProductId"
                 class="notification text-bg-dark"
            >
                <span>
                    Articule coppied
                </span>
            </div>
        </transition>

        <div class="d-flex justify-content-between">
            <div class="d-flex align-items-end gap-2">
                <div class="d-flex mb-0 flex-column justify-content-end" style="width: max-content;">
                    <div class="d-flex justify-content-end ms-lg-5 fs-6 mb-0">
                        <span class="text-capitalize fw-light me-1">Price</span>

                        <p class="del-price px-1 d-flex align-items-start mb-0">
                            <span>27 200</span>
                            <span class="fs-12 fw-normal ms-1">$</span>
                        </p>

                    </div>

                    <p class="d-flex align-items-start fw-bold mb-0">
                        <span class="fs-1 lh-1">27 200</span>
                        <span class="fs-4 lh-1 fw-normal ms-1 mt-1">$</span>
                    </p>

                </div>

                <span class="fw-bold text-danger fs-10">Avbl: 30.10.2022</span>
            </div>


            <p class="text-danger fw-bold mb-0 product_id__container" title="Product ID" @click="copyText">
                ART: <span class="product_id" ref="productNumber">4232453</span>
            </p>
        </div>

        <div class="d-flex justify-content-lg-between flex-lg-row flex-column gap-2">
            <div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mb-0 fs-14">Size</p>

                    <button class="btn btn-sm border-0 text-decoration-underline"
                            data-bs-target="#sizesGuideModal"
                            data-bs-toggle="modal">
                        <i class="fa-solid fa-ruler-horizontal"></i>
                        <span class="ms-2">Sizes guide</span>
                    </button>
                </div>

                <div class="d-flex align-items-center flex-lg-wrap gap-1 mt-2 overflow-sm-auto">
                    <div v-for="property in productSize" :key="property.size" class="size-hover position-relative"
                         @mouseenter="property.hover = true"
                         @mouseleave="property.hover = false">

                        <input :id="`size-${property.size}`" class="btn-check" name="size" type="radio">

                        <label :for="`size-${property.size}`"
                               class="btn btn-outline-dark fs-14"
                        >
                            {{ property.size }}
                        </label>

                        <div v-show="property.hover"
                             class="detail-size bg-white shadow text-secondary p-3 position-absolute">
                            <div class="d-flex align-items-center justify-content-between fs-14">
                                <span class="fw-bold">Brand size</span>
                                <span class="fw-bold">{{ property.size }}</span>
                            </div>

                            <div class="d-flex align-items-center justify-content-between fs-14">
                                <span>UK</span>
                                <span class="fw-bold">
                                    {{ property.sizeUK.sizeProperty }}
                                    <span class="fs-12 fw-normal">({{ property.sizeUK.sizeQuantity }})</span>
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-between fs-14">
                                <span>US</span>
                                <span class="fw-bold">
                                    {{ property.sizeUS.sizeProperty }}
                                    <span class="fs-12 fw-normal">({{ property.sizeUS.sizeQuantity }})</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-lg-0 mt-3">
                <p class="mb-0 fs-14">Color</p>

                <div class="row row-cols-lg-5 row-cols-auto gap-lg-0 gap-3 m-0 mt-lg-2 py-lg-0 py-2 overflow-sm-auto">
                    <div v-for="color in productColor"
                         :key="color.size"
                         class="col px-0 color-hover position-relative"
                         @mouseenter="color.hover = true"
                         @mouseleave="color.hover = false"
                    >
                        <input :id="`product-color-${color.title}`" class="btn-check" name="product-colors"
                               type="radio">

                        <label :for="`product-color-${color.title}`" :style="`background-color: ${color.colorCode};`"
                               :title="color.title" class="color-check"></label>

                        <div v-show="color.hover"
                             class="detail-color bg-white shadow text-secondary p-3 position-absolute">
                            <div class="d-flex align-items-center gap-2">
                                <span class="text-capitalize">{{ color.title }}</span>
                                <span class="fs-12 fw-normal">({{ color.quantity }})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div :class="['d-flex align-items-center gap-3 mt-3', {
            'fixed-mobile' : $route.name === 'product'
        }]">
            <div class="d-flex align-items-center border position-relative bg-white quantity">
                <div>
                    <input v-model="quantity" class="form-control border-0" type="tel"/>
                    <span class="position-absolute fs-12 bg-white px-1" style="top: -10px; left: 5px;">Quantity</span>
                </div>

                <div class="d-flex flex-column border-start">
                    <button class="btn border-0 fs-10 quantity-action border-bottom" @click="increaseQuantity">
                        <i class="fa-solid fa-plus"></i>
                    </button>

                    <button class="btn border-0 fs-10 quantity-action" @click="decreaseQuantity">
                        <i class="fa-solid fa-minus"></i>
                    </button>
                </div>
            </div>

            <div class="flex-lg-grow-1">
                <button
                    class="btn btn-lg btn-dark w-100 text-uppercase d-flex align-items-center justify-content-center fs-sm-16 addBasketViewProduct">
                    Add to cart
                </button>
            </div>

            <div>
                <button class="btn btn-lg btn-dark w-100 addFavouriteViewProduct" title="Add to favourite">
                    <i class="fa-regular fa-heart"></i>
                </button>
            </div>
        </div>

        <div
            class="alert alert-info bg-white border border-danger alert-dismissible fade show rounded-0 position-relative mt-3 mb-0"
            role="alert">
            <p class="mb-0 fw-light fm-sans fs-14 fw-bold">
                European size indicated. View Size Chart Every order is shipped with a free fitting and
                partial redemption service!
            </p>
            <button aria-label="Close" class="btn-close position-absolute p-1 bg-none"
                    data-bs-dismiss="alert"
                    type="button">
                <i class="fa-solid fa-xmark text-danger"></i>
            </button>
        </div>

        <div class="alert alert-success rounded-0 mt-3 mb-0">
            <div class="d-flex flex-md-row flex-column justify-content-md-between text-uppercase">
                <p class="fw-light mb-0">
                    Shop SOHO, Liberty Sq., Tbilisi <br>
                    Sizes: 36, 38, 38.5, 40, 41
                </p>

                <span class="fw-bold text-uppercase mt-lg-0 mt-2">
                    IN STOCK
                </span>
            </div>
        </div>

        <div class="alert alert-danger rounded-0 mt-3 mb-0">
            <div class="fw-bold text-uppercase text-center">
                product not available
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaProductDescription",

    data() {
        return {
            coppiedProductId: false,

            productSize: [
                {
                    size: 32,
                    hover: false,
                    sizeUK: {sizeProperty: 11.5, sizeQuantity: 3},
                    sizeUS: {sizeProperty: 12.5, sizeQuantity: 8}
                },
                {
                    size: 36,
                    hover: false,
                    sizeUK: {sizeProperty: 8.5, sizeQuantity: 4},
                    sizeUS: {sizeProperty: 9.5, sizeQuantity: 12}
                },
                {
                    size: 38,
                    hover: false,
                    sizeUK: {sizeProperty: 15.5, sizeQuantity: 30},
                    sizeUS: {sizeProperty: 16.5, sizeQuantity: 10}
                },
                {
                    size: 40,
                    hover: false,
                    sizeUK: {sizeProperty: 17.5, sizeQuantity: 5},
                    sizeUS: {sizeProperty: 18.5, sizeQuantity: 3}
                },
                {
                    size: 42,
                    hover: false,
                    sizeUK: {sizeProperty: 14.5, sizeQuantity: 10},
                    sizeUS: {sizeProperty: 15.5, sizeQuantity: 1}
                },
                {
                    size: 46,
                    hover: false,
                    sizeUK: {sizeProperty: 18.5, sizeQuantity: 3},
                    sizeUS: {sizeProperty: 19.5, sizeQuantity: 9}
                }
            ],

            productColor: [
                {title: 'orange', hover: false, colorCode: '#FBCA64', quantity: 13},
                {title: 'maroon', hover: false, colorCode: '#8E2C46 ', quantity: 5},
                {title: 'wheat', hover: false, colorCode: '#FFF7F3 ', quantity: 9},
                {title: 'black', hover: false, colorCode: '#252225 ', quantity: 10},
                {title: 'brown', hover: false, colorCode: '#E0BC9C ', quantity: 20},
                {title: 'grey', hover: false, colorCode: '#A8A6A6 ', quantity: 4},
                {title: 'green', hover: false, colorCode: '#0f8d00 ', quantity: 2},
            ],

            quantity: 0
        }
    },

    methods: {
        increaseQuantity() {
            this.quantity++
        },

        decreaseQuantity() {
            if (this.quantity > 0) {
                this.quantity--
            }

        },

        copyText() {
            this.coppiedProductId = true;

            const spanText = this.$el.querySelector('span.product_id').textContent;

            const textareaElement = document.createElement('textarea');
            textareaElement.value = spanText;
            document.body.appendChild(textareaElement);

            textareaElement.select();
            document.execCommand('copy');

            document.body.removeChild(textareaElement);
        },

        hideNotification() {
            setTimeout(()=> {
                this.coppiedProductId = false
            }, 3000)
        }
    },

    watch: {
        coppiedProductId() {
            if (this.coppiedProductId) {
                this.hideNotification()
            }
        }
    }
}
</script>

<style lang="scss">
@for $i from 20 through 1 {
    .size-hover .detail-size:nth-child(#{$i}) {
        z-index: 100 - $i;
    }
}

</style>

<style>
.toast-enter {
    transform: translateX(120px);
    opacity: 0;
}
.toast-enter-active {
    transition: all 0.6s ease;
}
.toast-enter-to {
    opacity: 1;
}
.toast-leave {
    height: 50px;
    opacity: 1;
}
.toast-leave-active {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
}
.toast-leave-to {
    height: 0;
    transform: translateX(120px);
    opacity: 0;
}
.toast-move {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
}
.notification {
    position: fixed;
    top: 140px;
    right: 20px;
    border-radius: 3px;
    padding: 12px;
    z-index: 1;
}

.product_id__container {
    user-select: all;
}
.detail-color {
    z-index: 1;
    top: -56px;
}

.detail-size {
    top: -95px;
    width: 300px;
    transition: .2s;
}


.del-price {
    position: relative;
    width: max-content;
}

.del-price::after {
    content: "";
    width: 100%;
    background: #E86363;
    top: 48%;
    transform: translateY(-50%) rotateZ(6deg);
    height: 2px;
    position: absolute;
    left: 0;
}

.color-check {
    border: 1px solid rgba(0, 0, 0, .15);
    height: 20px;
    transition: .1s;
    width: 20px;
    cursor: pointer;
}

.btn-check:checked + .color-check {
    border: 2px solid #fff;
    outline: 2px solid #111;
}

.addBasketViewProduct, .addFavouriteViewProduct {
    height: 57px;
}

@media screen and (min-width: 992px) {
    .quantity {
        width: 160px;
    }
}

@media screen and (max-width: 991px) {
    .addBasketViewProduct, .addFavouriteViewProduct {
        height: 46px;
    }

    .quantity-action {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fixed-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 12px;
        z-index: 21;
        background-color: rgba(255, 255, 255, 0.85);
        width: 100%;
        justify-content: space-between;
    }

    .quantity {
        width: 95px;
    }

    .detail-size,
    .detail-color {
        display: none;
    }
}
</style>