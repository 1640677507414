<template>
    <div class="container-fluid mt-lg-5 mt-3 call_request_bg px-0">
        <div class="container p-0 py-lg-5 py-3">
            <div>
                <p class="text-white text-uppercase fs-4 fs-sm-16 fw-normal mb-2 px-2">заказать звонок</p>
                <div class="row m-0">
                    <div class="col-lg-4 px-2 mt-lg-3 d-flex justify-content-lg-start justify-content-center">
                        <div class="input-group input-group-lg mt-3">
                            <input class="form-control border-0" placeholder="Имя" type="text">
                        </div>
                    </div>
                    <div class="col-lg-4 px-2 mt-3 d-flex justify-content-lg-start justify-content-center">
                        <div class="input-group input-group-lg mt-lg-3">
                            <input class="form-control border-0" placeholder="+7 (___) ___-__-__" type="text">
                        </div>
                    </div>
                    <div class="col-lg-4 px-2 mt-3 d-flex justify-content-lg-start justify-content-center">
                        <div class="input-group input-group-lg mt-lg-3">
                            <div
                                class="btn btn-danger d-flex justify-content-center align-items-center w-100 text-uppercase fs-sm-14 py-lg-2">
                                заказать звонок
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CallRequestLine',

    data() {
        return {}
    }
}
</script>

<style scoped>
.call_request_bg {
    background-image: url('@/assets/websites/rb-auto/img/call-request-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
</style>