<template>
    <div class="bg-dark mt-3 p-lg-3 p-2">
        <p class="fs-6 text-center text-uppercase text-white">выкупим ваш автомобиль</p>
        <div class="d-flex align-items-center mt-4">
            <img :src="TradeInIcon1" alt="" height="39" loading="lazy" width="38">
            <p class="text-white ms-2 lh-sm mb-0">Гарантируем юридическую чистоту</p>
        </div>
        <div class="d-flex align-items-center mt-3">
            <img :src="TradeInIcon2" alt="" height="39" loading="lazy" width="38">
            <p class="text-white ms-2 lh-sm mb-0">Бесплатная диагностика</p>
        </div>
        <div class="d-flex align-items-center mt-3">
            <img :src="TradeInIcon3" alt="" height="39" loading="lazy" width="38">
            <p class="text-white ms-2 lh-sm mb-0">Бесплатно оформим все документы</p>
        </div>
        <div class="d-flex align-items-center mt-3">
            <img :src="TradeInIcon4" alt="" height="39" loading="lazy" width="38">
            <p class="text-white ms-2 lh-sm mb-0">Деньги сразу</p>
        </div>
        <a class="btn btn-outline-danger w-100 mt-4"
           href="#">
            Оценить мой авто
        </a>
    </div>
</template>

<script>
import TradeInIcon1 from "@/assets/websites/rb-auto/img/tradein-banner-icon1.png"
import TradeInIcon2 from "@/assets/websites/rb-auto/img/tradein-banner-icon2.png"
import TradeInIcon3 from "@/assets/websites/rb-auto/img/tradein-banner-icon3.png"
import TradeInIcon4 from "@/assets/websites/rb-auto/img/tradein-banner-icon4.png"

export default {
    name: 'TradeInRbAuto',

    data() {
        return {
            TradeInIcon1,
            TradeInIcon2,
            TradeInIcon3,
            TradeInIcon4
        }
    }
}
</script>


<style scoped>

</style>