<template>
    <div class="container-fluid px-0">
        <div class="container pt-lg-5 pt-3">

            <div class="d-lg-flex justify-content-center">
                <div class="text-center ms-lg-0 ms-2">
                    <router-link :to="{ name:'home' }">
                        <img :src="logo" alt="logo" class="logo w-100">
                    </router-link>
                </div>
            </div>

            <div class="col-lg-4 mx-auto">

                <p class="mb-0 text-white fs-4 mt-lg-5 mt-3">
                    The newly opened cosy and stylish Blueberry Nights hotel, located in downtown, right in the
                    centre of our well-known Restaurant Lolita, offering delicious breakfast and other meals with an
                    open bar and grill, and a famous meeting place for artists, locals and visitors of Tbilisi.
                </p>

                <p class="mb-0 text-white fs-4">
                    Spacious and sun-lit rooms with views of authentic Tbilisi yards and urban landscapes, offer
                    movie theaters (equipped with hi definition projectors) at a single click of the remote,
                    featuring carefully selected movies along with the modern day streaming services stocked with
                    all kinds of content stretching from films to sports, news and famous TV shows.
                </p>

                <p class="mb-0 text-white fs-4">
                    Then you have the best selection of records of different genres, selected by a local artist for
                    you to enjoy on vinyl players with hi quality sound in every room.
                </p>
            </div>
        </div>


    </div>

</template>

<script>
export default {
    name: "HotelAbout",

    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
        }
    }
}
</script>
