<template>
    <div class="row m-0 shadow bg-white rounded overflow-hidden mb-3 placeholder-glow">
        <div class="col-lg-3 px-0" style="height: 190px;">
            <div class="placeholder w-100 h-100"></div>
        </div>

        <div class="col-lg-7 mt-lg-0 mt-3 p-2">
            <div class="d-flex flex-column justify-content-between h-100">
                <div>
                    <span class="placeholder col-8 d-block"></span>
                    <span class="placeholder col-1 d-block mt-3"></span>
                </div>

                <p class="fs-14 fw-light mt-3 mb-0">
                    <span class="placeholder col-4"></span>
                </p>
            </div>
        </div>

        <div class="col-lg-2 p-2">
            <div class="d-flex flex-lg-column justify-content-between h-100">
                <div class="d-flex align-items-center justify-content-lg-end gap-3">
                    <p class="col-lg-3 col-12 placeholder mb-0"></p>

                    <div class="col-lg-5 col-12 placeholder"></div>
                </div>

                <div class="d-flex justify-content-lg-end gap-2">
                    <div class="col-lg-3 col-12 placeholder"></div>

                    <div class="col-lg-3 col-12 placeholder"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LoaderProduct'
}
</script>
