<template>
    <div>
        <TableReservation/>

        <MenuControl/>

        <OffCanvas/>

        <div class="container px-0 mt-lg-3">
            <div class="row m-0 px-lg-3">
                <left-side/>

                <div class="col-lg-6 px-lg-4 px-0 pb-lg-5">
                    <div class="row m-0 product-card">
                        <div :class="getProductImage(product) ? 'col-12 p-0 product-image' : 'd-none'">
                            <div v-if="getProductImage(product)"
                                 class="position-relative productImageContainer rounded overflow-hidden">
                                <div
                                    :title="trans('menu_close')"
                                    class="position-absolute top-0 end-0 px-0 d-flex m-2 rounded-circle close-view-product cursor-pointer"
                                    style="z-index: 10;" @click="$router.go(-1)">
                                    <span class="fs-14 text-white">&#10761;</span>
                                    <!--                                <i class="fa-solid fa-xmark fs-14 text-white"></i>-->
                                </div>
                                <img :id="'product-image-'+product.id" :alt="getProductName(product)"
                                     :src="getProductImage(product)" class="w-100 rounded productImage"
                                     itemprop="image" loading="lazy"/>
                                <p :content="product.price_discount || product.price"
                                   :title="product.price_discount || product.price + ' ' + menuCompanyCurrency"
                                   class="m-2 position-absolute top-0 badge rounded fs-14 shadow d-none productPrice"
                                   itemprop="price">
                                    {{ product.price_discount || product.price }}
                                    <span class="ms-1"
                                          content="GEL"
                                          itemprop="priceCurrency">{{ menuCompanyCurrency }}</span>
                                </p>
                                <div
                                    class="mb-0 position-absolute bottom-0 text-white py-lg-3 py-2 px-lg-3 px-2 fs-5 fw-semibold w-100 d-none productTitle">
                                    <p class="mb-0 lh-1">
                                    <span>{{
                                            getProductName(product)
                                        }}</span>
                                    </p>
                                </div>
                                <div class="position-absolute opacity-0 lh-1 loop">
                                    <i class="fa-solid fa-magnifying-glass-plus text-white fs-6"></i>
                                </div>

                                <div
                                    class="position-absolute bottom-0 end-0 d-flex cardShare me-2 d-none rounded-circle">
                                    <div class="dropdown d-flex align-items-center">
                                        <button aria-expanded="false"
                                                class="btn p-0 dropdown-toggle share-btn d-flex align-items-center"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <i class="fa-solid fa-share fs-12 text-white"></i>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li @click="stopPropagation($event)">
                                                <a class="dropdown-item d-flex align-items-center gap-2"
                                                   href="#">
                                                    <img :src="Facebook" alt="Facebook" title="Facebook" width="20">
                                                    <span>Facebook</span>
                                                </a>
                                            </li>

                                            <li class="mt-2">
                                                <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                                    <img :src="Instagram" alt="Instagram" title="Instagram" width="20">
                                                    <span>Instagram</span>
                                                </a>
                                            </li>

                                            <li class="mt-2">
                                                <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                                    <img :src="Twiter" alt="Twiter" title="Twiter" width="20">
                                                    <span>Twitter</span>
                                                </a>
                                            </li>

                                            <li class="mt-2" @click="stopPropagation($event)">
                                                <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                                                   target="_blank">
                                                    <img :src="Telegram" alt="Telegram" title="Telegram" width="20">
                                                    <span>Telegram</span>
                                                </a>
                                            </li>

                                            <li class="mt-2" @click="stopPropagation($event)">
                                                <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                                                   target="_blank">
                                                    <img :src="Whatsapp" alt="Whatsapp" title="Whatsapp" width="20">
                                                    <span>WhatsApp</span>
                                                </a>
                                            </li>

                                            <li class="mt-2" @click="stopPropagation($event)">
                                                <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                                                   target="_blank">
                                                    <img :src="Viber" alt="Viber" title="Viber" width="20">
                                                    <span>Viber</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 p-lg-0">

                            <p v-if="getProductComment(product)" class="mb-0 card-text fw-bold mt-1"
                               itemprop="description" v-html="getProductComment(product)"></p>

                            <div class="d-flex align-items-start mt-2 d-none">
                                <div class="d-flex align-items-center">
                                    <button v-if="product.counter >= 2"
                                            :title="trans('menu_subtract')"
                                            class="alert alert-warning rounded fs-18 mb-0 p-0 add-btn-plus"
                                            @click="basketAction(product, false)">
                                        <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">&minus;</span>
                                    </button>

                                    <button v-if="product.counter === 1"
                                            :title="trans('menu_remove')"
                                            class="alert alert-danger rounded fs-18 mb-0 p-0 add-btn-plus"
                                            data-bs-target="#removeProduct" data-bs-toggle="modal"
                                            @click="removeProductFromBasketRequest(product)">
                                        <i class="fa-solid fa-xmark text-danger"></i>
                                    </button>

                                    <span
                                        :class="product.counter === 0 ? 'lh-1 fs-5 text-danger mx-1' : 'lh-1 fs-5 text-warning mx-2'">{{
                                            product.counter
                                        }}</span>

                                    <button
                                        :class="product.counter < 1  ? 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus ms-n' : 'alert alert-warning rounded fs-14 mb-0 p-0 add-btn-plus'"
                                        :title="trans('menu_add')" @click="basketAction(product, true)">
                                      <span class="d-flex align-items-center justify-content-center lh-1 fw-semibold">
                                          <i class="fa-solid fa-plus"></i>
                                      </span>
                                    </button>
                                </div>

                                <div class="d-flex align-items-center flex-wrap gap-2 ms-2 product-card-badges">
                                    <div v-if="dg(product, 'meta_data.sale_quantity') || dg(product, 'unit')"
                                         class="badge rounded text-bg-light fs-10 d-flex align-items-center gap-1">
                                        <div v-if="product.bottle_type">
                                            <img v-if="product.bottle_type === 2" :src="BottleBeer" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 1" :src="Cup" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 7" :src="Cup2" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 3" :src="Shot" alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 4" :src="DraftBeer"
                                                 alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 6" :src="BottleWine"
                                                 alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">

                                            <img v-if="product.bottle_type === 5" :src="WineGlass"
                                                 alt=""
                                                 class="d-flex align-items-center" height="16" loading="lazy">
                                        </div>
                                        <span>
                                      {{ product.sale_quantity }} {{ getProductUnitShortName(product) }}
                                    </span>

                                    </div>

                                    <span v-if="dg(product, 'meta_data.popular_product')"
                                          class="badge rounded text-bg-light fs-10">
                                        {{ trans('menu_popular') }}
                                    </span>

                                    <span v-if="dg(product, 'meta_data.vegetarian_product')"
                                          class="badge rounded text-bg-light vegetarian-badge fs-10">
                                        {{ trans('menu_vegetarian') }}

                                    </span>

                                    <span v-if="dg(product, 'meta_data.lenten_product')"
                                          class="badge rounded text-bg-light fs-10">
                                        {{ trans('menu_lenten') }}
                                    </span>

                                    <span v-if="dg(product, 'meta_data.new_product') "
                                          :title="trans('menu_new')"
                                          class="badge rounded text-bg-danger fs-10 badge-new-nopic">
                                        NEW
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--                    <p class="mt-5 mb-0 fs-4 text-center">-->
                    <!--                        {{ trans('menu_recommended_dishes') }}-->
                    <!--                    </p>-->

                    <!--                    <div class="row m-0 recomendedProductRow hide-scroll">-->
                    <!--                        <div class="col-lg-5 col-8 mt-3 recomendedProduct">-->
                    <!--                            <div class="border rounded">-->
                    <!--                                <div class="position-relative">-->
                    <!--                                    <img :src="ProductPic" class="w-100 rounded-top" alt="">-->

                    <!--                                    <p class="m-2 position-absolute top-0 badge rounded fs-6 shadow productPrice">-->
                    <!--                                        20 ₾-->
                    <!--                                    </p>-->

                    <!--                                    <div :class="recomendedProductCounter >= 1 ? 'position-absolute start-50 bottom-0 translate-middle-x' : 'position-absolute end-0 bottom-0'">-->
                    <!--                                        <div :class="recomendedProductCounter >= 1 ? 'd-flex align-items-center gap-3 px-2 mb-1 counter-flex rounded h-30' : 'mb-1 me-1'">-->
                    <!--                                            <button v-if="recomendedProductCounter >= 1" class="btn p-0 fs-6 recomendedProductBtn" @click="recomendedProductCounter&#45;&#45;">-->
                    <!--                                                <i class="fa-solid fa-minus text-white"></i>-->
                    <!--                                            </button>-->

                    <!--                                            <span v-if="recomendedProductCounter >= 1" class="text-white">-->
                    <!--                                                {{ recomendedProductCounter }}-->
                    <!--                                            </span>-->

                    <!--                                            <button :class="recomendedProductCounter >= 1 ? 'btn p-0 fs-6 text-white recomendedProductBtn' : 'alert alert-warning rounded p-0 m-0 add-btn-plus'" @click="recomendedProductCounter++">-->
                    <!--                                                <i class="fa-solid fa-plus"></i>-->
                    <!--                                            </button>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->

                    <!--                                <div class="p-lg-3 p-2">-->
                    <!--                                    <p class="mb-0 lh-1 recomendedProductTitle">-->
                    <!--                                        კიტრის და პომიდვრის სალათი თხილის პასტით და სულგუნის ჩიფსით-->
                    <!--                                    </p>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="col-lg-5 col-8 mt-3 recomendedProduct">-->
                    <!--                            <div class="border rounded">-->
                    <!--                                <div class="position-relative">-->
                    <!--                                    <img :src="ProductPic" class="w-100 rounded-top" alt="">-->

                    <!--                                    <p class="m-2 position-absolute top-0 badge rounded fs-6 shadow productPrice">-->
                    <!--                                        20 ₾-->
                    <!--                                    </p>-->

                    <!--                                    <div :class="recomendedProductCounter >= 1 ? 'position-absolute start-50 bottom-0 translate-middle-x' : 'position-absolute end-0 bottom-0'">-->
                    <!--                                        <div :class="recomendedProductCounter >= 1 ? 'd-flex align-items-center gap-3 px-2 mb-1 counter-flex rounded h-30' : 'mb-1 me-1'">-->
                    <!--                                            <button v-if="recomendedProductCounter >= 1" class="btn p-0 fs-6 recomendedProductBtn" @click="recomendedProductCounter&#45;&#45;">-->
                    <!--                                                <i class="fa-solid fa-minus text-white"></i>-->
                    <!--                                            </button>-->

                    <!--                                            <span v-if="recomendedProductCounter >= 1" class="text-white">-->
                    <!--                                                {{ recomendedProductCounter }}-->
                    <!--                                            </span>-->

                    <!--                                            <button :class="recomendedProductCounter >= 1 ? 'btn p-0 fs-6 text-white recomendedProductBtn' : 'alert alert-warning rounded p-0 m-0 add-btn-plus'" @click="recomendedProductCounter++">-->
                    <!--                                                <i class="fa-solid fa-plus"></i>-->
                    <!--                                            </button>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->

                    <!--                                <div class="p-lg-3 p-2">-->
                    <!--                                    <p class="mb-0 lh-1 recomendedProductTitle">-->
                    <!--                                        კიტრის და პომიდვრის სალათი-->
                    <!--                                    </p>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                </div>

                <div class="col-lg-3 mt-lg-0 mt-3 mb-lg-0 mb-5">
                    <div class="sticky-lg-top" style="top: 70px;">
                        <menu-information/>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex"
import ApiRequest from "@/http/ApiRequest"
import _ from "lodash"
import {data_get} from "@/core/helpers"
import {t} from "@/lang/core/helpers"
import MenuInformation from "@/components/websites/allmenu/parts/Information";
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Twiter from "@/assets/websites/allmenu/img/twiter-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import BottleBeer from "@/assets/websites/allmenu/img/bottle_beer.svg"
import BottleWine from "@/assets/websites/allmenu/img/bottle_wine.svg"
import Cup from "@/assets/websites/allmenu/img/cup.svg"
import Cup2 from "@/assets/websites/allmenu/img/cup_2.svg"
import DraftBeer from "@/assets/websites/allmenu/img/draft_beer.svg"
import Shot from "@/assets/websites/allmenu/img/shot.svg"
import WineGlass from "@/assets/websites/allmenu/img/wineglass.svg"
import LeftSide from "@/components/websites/allmenu/parts/LeftSide";
import MenuControl from "@/components/websites/allmenu/parts/MenuControl";
import ProductPic from "@/assets/websites/allmenu/img/test-product-pic.jpg"
import OffCanvas from "@/components/websites/allmenu/parts/OffCanvas";
import TableReservation from "@/components/websites/allmenu/parts/TableReservation";

export default {
    name: "ViewProduct",
    components: {TableReservation, OffCanvas, MenuControl, LeftSide, MenuInformation},
    data() {
        const recomendedProductCounter = 0;

        return {
            recomendedProductCounter,
            productId: this.$route.params.productId.substr(0, this.$route.params.productId.indexOf('-')),
            product: null,
            Facebook,
            Instagram,
            Twiter,
            Whatsapp,
            Viber,
            Telegram,
            Messenger,
            BottleBeer,
            BottleWine,
            Cup,
            Cup2,
            DraftBeer,
            Shot,
            WineGlass,
            ProductPic
        }
    },

    computed: {
        ...mapGetters([
            'locale',
            'menuCompany',
            'menuCompanyCurrency'
        ])
    },

    watch: {
        menuCompany: {
            handler: function () {
                this.getProduct(this.menuCompany.id, this.productId)
            },
            deep: true,
        }
    },

    created() {
        if (this.menuCompany) {
            this.getProduct(this.menuCompany.id, this.productId)
        }
    },

    methods: {
        trans(key) {
            return t(key)
        },

        getProduct(companyId, productId) {
            new ApiRequest('menu/company/product/get', {
                companyId: companyId,
                productId: productId
            }, data => {
                this.product = data.data
                this.$store.commit('setMenuSelectedProduct', this.product)
            })
        },

        getProductName(product) {
            let translation = _.filter(product.translations, {locale: this.locale})
            return translation.length ? translation[0].name : product.name
        },

        getProductUnitShortName(product) {
            if (!product.unit) {
                return null
            }

            let translation = _.filter(product.unit.translations, {locale: this.locale})
            return translation.length ? translation[0].short_name : null
        },

        getImageBySize(images, size) {
            let filterImage = _.filter(images, {image_type: size})
            return filterImage.length ? filterImage[0].url : null
        },

        getProductImage(product) {
            return this.getImageBySize(product.images, 'MEDIUM')
        },

        getProductComment(product) {
            let translation = _.filter(product.translations, {locale: this.locale})

            return translation.length ? translation[0].comment : product.comment
        },

        categoryName(category) {
            let translation = _.filter(category.translations, {locale: this.locale})

            return translation.length ? translation[0].name : category.name
        },

        stopPropagation(e) {
            e.stopPropagation()
        },

        basketAction(product, condition) {
            if (condition) {
                product.counter++
            } else {
                product.counter--
            }

            this.$store.commit('setProductInBasket', product)
        },

        removeProductFromBasketRequest(product) {
            this.$store.commit('setDeleteFromBasket', product.id)
        },

        async removeProductFromBasket() {
            let productId = this.deleteFromBasket

            this.$store.commit('removeProductInBasket', productId)

            this.$store.commit('resetProductCounter', productId)
        },

        dg(key, value) {
            return data_get(key, value)
        }
    }
}
</script>

<style scoped>
.recomendedProductRow {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
}

.recomendedProductRow .recomendedProduct {
    scroll-snap-align: center;
}

.counter-flex {
    background-color: rgb(255 163 54 / 90%);;
}

.h-30 {
    height: 30px;
}

.recomendedProductBtn:hover, .recomendedProductBtn {
    border-color: transparent;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recomendedProductTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 32px;
}

.vegetarian-badge {
    background-color: #C1E4CB !important;
}

.add-btn-plus {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trush-btn, .trush-btn:hover, .trush-btn:focus {
    border: unset;
}

.trush-btn {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-btn::after {
    display: none;
}

.share-btn:hover, .share-btn:focus-within {
    border: unset !important;
}

@media screen and (max-width: 992px){
    header {
        display: none !important;
    }
}
</style>
