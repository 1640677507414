<template>
    <div class="container-fluid mt-lg-5 mt-3 credit_request_bg px-0">
        <div class="container p-0 py-lg-5 py-3">
            <div>
                <p class="text-white text-uppercase fs-4 fs-sm-16 fw-normal mb-2 px-2">заявка на авто кредит</p>
                <div class="row m-0">
                    <div class="col-lg-4 px-2 mt-lg-3">
                        <div class="position-relative credit_request_title overflow-hidden">
                            <label
                                class="border border-end-0 border-white p-lg-3 p-2 text-white text-uppercase fs-sm-14"
                                for="credit-request-line-name">30+ програм кредитования</label>
                        </div>
                        <div class="input-group input-group-lg mt-3">
                            <input id="credit-request-line-name" class="form-control border" placeholder="Имя"
                                   type="text">
                        </div>
                    </div>

                    <div class="col-lg-4 px-2 mt-3">
                        <div class="position-relative credit_request_title overflow-hidden">
                            <label
                                class="border border-end-0 border-white p-lg-3 p-2 text-white text-uppercase fs-sm-14"
                                for="credit-request-line-number">автокредит с господдержкой</label>
                        </div>
                        <div class="input-group input-group-lg mt-3">
                            <input id="credit-request-line-number" class="form-control border"
                                   placeholder="+7 (___) ___-__-__"
                                   type="text">
                        </div>
                    </div>

                    <div class="col-lg-4 px-2 mt-3">
                        <div class="position-relative credit_request_title overflow-hidden">
                            <label
                                class="border border-end-0 border-white p-lg-3 p-2 text-white text-uppercase fs-sm-14">20
                                банков партнеров</label>
                        </div>
                        <div class="input-group input-group-lg mt-3">
                            <div class="btn btn-danger w-100 text-uppercase fs-sm-14">Подать заявку</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreditRequestLine',

    data() {
        return {}
    }
}
</script>

<style scoped>
.credit_request_bg {
    background-image: url('@/assets/websites/rb-auto/img/credit-request-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.credit_request_title label {
    width: 90%;
}

.credit_request_title::after {
    content: "";
    display: inline-block;
    height: 43px;
    transform: rotate(45deg);
    width: 42px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    position: absolute;
    top: 0;
    margin-left: -21px;
    margin-top: 8px;
}

@media screen and (max-width: 991px) {
    .credit_request_title::after {
        height: 30px;
        width: 30px;
        margin-left: -16px;
        margin-top: 5px;
    }
}

</style>