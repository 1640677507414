<template>
    <div>
        <div class="container-fluid px-0">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-4">
                        <div class="sticky-top p-lg-4 pb-lg-5 bg-white bg-sm-0 shadow-lg shadow-sm-0 rounded"
                             style="top: 68px;">
                            <p class="mb-0 fs-2 fw-bold rounded text-capitalize">контакты</p>
                            <div id="call-request-line-form" class="call-request-2">

                                <input class="form-control mt-3"
                                       placeholder="Имя"
                                       type="text">

                                <div class="btn btn-dark px-4 mt-3 w-100 text-uppercase py-2">
                                    заказать звонок
                                </div>

                            </div>

                            <hr class="my-4">

                            <p class="mb-0 fw-bold">Наши контакты для связи</p>

                            <div class="d-flex flex-column ps-lg-3">
                                <a class="d-flex text-dark fs-14 mt-lg-0 pt-3"
                                   href="tel:4546546546">
                                    <i class="fas fa-phone-alt fs-6 mt-1"></i>
                                    <p class="d-flex flex-column mb-0 ms-2">
                                <span>
                                    4546546546
                                </span>
                                    </p>
                                </a>

                                <div class="d-flex text-dark mb-0 mt-lg-0 pt-3">
                                    <i class="fas fa-map-marker-alt fs-6 mt-1"></i>
                                    <p class="mb-0 ms-2">
                                        Address
                                    </p>
                                </div>

                                <div class="d-flex text-dark mb-0 mt-lg-0 pt-3">
                                    <i class="fas fa-clock fs-6 mt-1"></i>
                                    <p class="mb-0 ms-2">
                                        Ежедневно с 09:00 до 20:00
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 mt-lg-0 mt-3">
                        <nav aria-label="breadcrumb"
                             class="overflow-auto mt-lg-2 mt-4 d-none d-lg-block"
                             style="--bs-breadcrumb-divider: url(&quot;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&quot;);">
                            <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                                <li class="breadcrumb-item d-flex align-items-center">
                                    <a class="text-dark text-nowrap"
                                       href="#">
                                        Главная
                                    </a>
                                </li>
                                <li class="breadcrumb-item d-flex align-items-center active">
                                    <a class="text-dark text-nowrap text-capitalize"
                                       href="#">
                                        контакты
                                    </a>
                                </li>

                            </ol>
                        </nav>
                        <div class="p-lg-4 p-2 border rounded bg-light mt-lg-0">
                            <h1 class="mb-lg-4 mb-2">О нашем автосалоне</h1>
                            <p style="opacity: 0.8;">
                                Коллектив автосалона накопил большой опыт по качественному и быстрому
                                обслуживанию клиентов и их автомобилей, мы прикладываем все усилия для того, чтобы
                                закрепить свой успех на рынке, и постоянно улучшать обслуживание наших клиентов.
                                <br><br>
                                Посетив наш автосалон по адресу м. Каширское, Каширское шоссе дом 41, Вы увидите
                                большое количество автомобилей самого широкого спектра классов, начиная от
                                автомобилей «бизнес» и среднего классов, семейные универсалы и минивэны,
                                внедорожников от ведущих мировых производителей, так и автомобилей класса «VIP».
                                Разобраться во всём этом разнообразии автомобилей Вам помогут наши менеджеры, а
                                индивидуальный подход и дружественная атмосфера – мы надеемся, сделают Вашу покупку,
                                ещё более приятной.
                            </p>
                            <hr class="my-4">
                            <p class="mb-0" style="opacity: 0.8;">
                                В настоящее время покупка в кредит, стала неотъемлемой и довольно удобной формой
                                оплаты, поэтому для удобства наших клиентов оформление кредита можно оперативно
                                решить
                                <br><br>
                                В нашем автосалоне очень развита кредитная линия, с нами работают более 20
                                проверенных банков-партнеров и страховые эксперты, которые застрахуют ваше авто б\у
                                на месте. Специалисты банка быстро подберут Вам кредитную программу с первоначальным
                                взносом 0%. Для принятия решения или же просто для отдыха, у нас вы найдете зону
                                комфорта, в которой можно выпить чашечку кофе или перекусить. Предоставляем также
                                отличную услугу TRADE-IN, если вам надоела ваша старая машина, и вы готовы
                                приобрести новую, вы сможете это сделать у нас, просто обменяв машины расчёт по
                                системе Traid-in (зачёт Вашего старого автомобиля на новый).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid px-0 mt-5">
            <iframe allowfullscreen=""
                    height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d23819.10901860567!2d44.7621418!3d41.7337097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sge!4v1680099738623!5m2!1sru!2sge"
                    style="border:0;"
                    width="100%"></iframe>
        </div>
    </div>

</template>

<script>
export default {
    name: "AutoContacts"
}
</script>

<style scoped>

</style>