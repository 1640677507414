<template>
    <div class="d-lg-none">
        <div id="reviewsCanvas" aria-labelledby="reviewsCanvasLabel" class="offcanvas offcanvas-end border-0"
             tabindex="-1">
            <div class="offcanvas-header p-2">
                <h5 id="reviwsCanvasLabel" class="offcanvas-title">Reviews (25)</h5>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
            </div>

            <div class="offcanvas-body p-2 pt-0">
                <ModaReviwsContent/>
            </div>
        </div>
    </div>
</template>

<script>
import ModaReviwsContent from "@/components/websites/moda/parts/view-product/ReviewsContent.vue";

export default {
    name: "ModaReviewsCanvas",

    components: {
        ModaReviwsContent
    }
}
</script>

<style scoped>

</style>