<template>
    <div class="position-relative top-50 translate-middle-y">
        <div :style="indexBg" class="index-bg w-100">
            <div class="position-absolute top-50 start-50 translate-middle">
                <div>
                    <img :src="logo" alt="logo" class="img-fluid indexLogo">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',

    data() {
        return {
            logo: require("@/assets/websites/blueberrynightshotel/img/logo.png"),
            indexBg: {
                backgroundImage: `url(${require('@/assets/websites/blueberrynightshotel/img/main-bg.jpg')})`
            }
        }
    }

}
</script>

<style scoped>
@media screen and (max-width: 992px) {
    .index-bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .indexLogo {
        width: 300px !important;
        height: unset !important;
        max-width: unset !important;
    }
}
</style>