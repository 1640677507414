<template>
    <div>
        <div class="container-fluid mt-lg-5 mt-3">
            <div class="container px-lg-0 px-1">
                <h1 class="fs-2 mb-0">
                    Выкуп
                </h1>

                <nav aria-label="breadcrumb" class="overflow-auto mt-2">
                    <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                        <li class="breadcrumb-item d-flex align-items-center">
                            <a class="text-dark text-nowrap"
                               href="#">
                                Главная
                            </a>
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center active">
                            <a class="text-dark text-nowrap"
                               href="#">
                                Услуги
                            </a>
                        </li>
                        <li class="breadcrumb-item d-flex align-items-center text-nowrap active">
                            Выкуп
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid">
            <div class="container px-lg-0 px-1">
                <div class="row m-0 justify-content-between">

                    <div class="col-lg-4 px-0">
                        <div action="#" class="bg-white rounded-3 shadow-lg p-lg-4 p-0">
                            <h3 class="fs-18 fs-sm-16">Ваш автомобиль</h3>

                            <select
                                class="form-select form-select-md bg-light border-0 rounded mt-3 py-2 text-secondary">
                                <option value="">Марка</option>
                            </select>

                            <select
                                class="form-select form-select-md bg-light border-0 rounded mt-3 py-2 text-secondary">
                                <option value="">Модель</option>
                            </select>

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Пробег тыс. км" type="number">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Год выпуска" type="number">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Желаемая цена" type="number">

                            <h3 class="mt-4 mb-3">Персональные данные</h3>

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary"
                                placeholder="Имя" type="text">

                            <input
                                class="form-control form-control-md bg-light border-0 rounded mt-3 py-2 text-secondary client-phone credit-phone-number-mask buy-out-number input_validate"
                                placeholder="Номер телефона" required type="tel">

                            <div class="form-check mt-3">
                                <input id="flexCheckChecked" checked="" class="form-check-input" type="checkbox"
                                       value="">
                                <label class="form-check-label fs-14 fs-sm-12" for="flexCheckChecked">
                                    Даю согласие на обработку персональных данных
                                </label>
                            </div>

                            <button class="btn rounded btn-warning text-uppercase w-100 py-2 mt-3" type="submit">
                                отправить заявку
                            </button>

                        </div>
                    </div>

                    <div class="col-lg-7 px-0 mt-lg-0 mt-3">
                        <div>
                            <img alt=""
                                 class="d-none d-lg-block img-fluid" loading="lazy"
                                 src="https://makler-motors.ru/img/websites/maklermotors/boy-out-bg.png">
                        </div>

                        <div class="alert border border-warning bg-white rounded alert-dismissible fade show p-lg-3 p-2"
                             role="alert">
                            <p class="mb-0">
                                Эта заявка ни к чему Вас не обязывает.
                                Наши специалисты свяжутся в Вами и назовут сумму, которою автосалон может предложить за
                                Ваш автомобиль.
                            </p>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoBuyOut"
}
</script>

<style scoped>

</style>