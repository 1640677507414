<template>
    <div class="index__bg">
        <SliderGP/>

        <CategoriesGP/>

        <div class="container-fluid px-0 mt-lg-5 mt-3">
            <div class="container px-lg-2 px-0">
                <div class="row m-0 my-3">
                    <div class="col-lg-3 px-2">
                        <FilterGP/>
                    </div>

                    <div class="col-lg-9 px-0 mt-lg-0 mt-3">
                        <ProductPG/>
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
import SliderGP from "@/components/websites/get-parts/parts/Slider.vue";
import CategoriesGP from "@/components/websites/get-parts/parts/Categories.vue";
import FilterGP from "@/components/websites/get-parts/parts/Filter.vue";
import ProductPG from "@/components/websites/get-parts/parts/Product.vue";
import {t} from "@/lang/core/helpers";

export default {
    name: 'IndexGP',

    components: {
        ProductPG,
        FilterGP,
        CategoriesGP,
        SliderGP
    },

    methods: {
        trans(key) {
            return t(key)
        }
    },

    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
    .index__bg {
        background-image: url('@/assets/websites/get-parts/img/bg-opacity2.png');
        background-attachment: fixed;
        background-repeat: repeat;
        background-size: 200px;
    }
    //.index__bg {
    //    position: relative;
    //    z-index: 0;
    //}
    //
    //.index__bg::before,
    //.index__bg::after {
    //    content: "";
    //    position: absolute;
    //    top: 0;
    //    bottom: 0;
    //    width: 50%;
    //    background-image: url('@/assets/websites/get-parts/img/bg-opacity2.png');
    //    background-repeat: repeat-y;
    //    background-size: 187px;
    //}
    //
    //.index__bg::before {
    //    left: 0;
    //    background-position: left;
    //}
    //
    //.index__bg::after {
    //    right: 0;
    //    background-position: right;
    //}
}

</style>