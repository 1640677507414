<template>
    <div>
        <div class="d-none d-md-flex justify-content-between align-items-center p-2 bg-white shadow-sm mb-3">
            <div class="d-flex align-items-center">
                <button class="btn p-0">
                    <img :src="SortIcon" alt="" height="18"
                         loading="lazy" style="filter: brightness(0); transition: .3s;" width="18">
                </button>
                <select class="form-select border-0 bg-transparent">
                    <option value="1">По умолчанию</option>
                    <option value="2">Последние поступления</option>
                    <option value="3">Дата добавления старые</option>
                    <option value="4">Сначала дешевые</option>
                    <option value="5">Сначала дорогие</option>
                    <option value="6">По алфавиту A-Z</option>
                    <option value="7">По алфавиту Z-A</option>
                </select>
            </div>

            <div class="d-flex align-items-center">
                <button class="btn d-flex pe-0 grid-view" @click="gridView()">
                    <img :src="GridIcon" alt="" height="18"
                         loading="lazy" style="filter: brightness(0); transition: .3s;" width="18">
                </button>

                <button class="btn d-flex pe-0 list-view active" @click="listView()">
                    <img :src="ListIcon" alt="" height="18"
                         loading="lazy" style="filter: brightness(0); transition: .3s;" width="18">
                </button>
            </div>
        </div>

        <div class="row m-0">
            <div v-for="car in 10" :key="car" class="col-12 mb-3 px-0 saleable_card">
                <div class="row shadow-sm bg-white m-0">
                    <div class="col-lg-3 p-0 card_pic">
                        <router-link :to="{ name:'view-car' }" class="d-block h-100">
                            <div class="d-flex d-lg-block overflow-auto shadow position-relative h-100">
                                <img
                                    alt="car name"
                                    class="w-100 h-100 img-fluid car__img"
                                    loading="lazy"
                                    src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg"
                                    style="object-fit: cover;"
                                >
                                <div class="d-lg-none">
                                    <span
                                        class="bg-dark text-white h-100 d-flex flex-column align-items-center justify-content-center"
                                        style="width: 300px;">
                                        <i class="bi bi-images me-2 fs-2"></i>
                                        <span class="fs-14">Посмотреть еще ...</span>
                                    </span>
                                </div>
                                <ul class="w-100 h-100 position-absolute top-0 start-0 row card-image-ul d-none d-lg-flex">
                                    <li class="card-img-radio col p-2 m-0">
                                        <div
                                            class="border-bottom border-4 border-light h-100 w-100"></div>
                                    </li>
                                    <li class="card-img-radio col p-2 m-0">
                                        <div
                                            class="border-bottom border-4 border-light h-100 w-100"></div>
                                    </li>
                                    <li class="card-img-radio col p-2 m-0">
                                        <div
                                            class="border-bottom border-4 border-light h-100 w-100"></div>
                                    </li>
                                </ul>
                            </div>
                        </router-link>
                    </div>

                    <div class="col-lg-6 p-0 card_info">
                        <div class="d-flex flex-column justify-content-between p-2 h-100">
                            <div>
                                <router-link :to="{ name:'view-car' }" c
                                             class="fs-5 fs-md-16 text-lg-start text-center text-dark">
                                    <span class="fw-bold me-1">Ford</span>
                                    <span>Focus</span>
                                </router-link>

                                <div class="opacity-50 text-truncate">
                                                    <span class="fw-light fs-14">
                                                        2008 г.
                                                    </span>
                                    <span
                                        class="fw-light border-start border-end border-secondary text-center ps-2 pe-1 ms-1 me-2 fs-14">
                                                        137 218 км
                                                    </span>
                                    <span class="fw-light fs-14">
                                                       II Рестайлинг 1.8 MT (125 л.с.)
                                                    </span>
                                </div>
                            </div>

                            <div class="opacity-50">
                                <div
                                    class="d-flex align-items-center justify-content-lg-start justify-content-between gap-2 mt-lg-0 mt-3">
                                <span
                                    class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                    title="transmission type">
                                    <img :src="KorobkaIcon"
                                         alt="Transmission" class="property-icon" height="15" loading="lazy"
                                         title="Коробка передач" width="14">
                                    Механика
                                </span>

                                    <span
                                        class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                        title="body type">
                                    <img :src="KuzovIcon"
                                         alt="Modification" class="property-icon" height="15" loading="lazy"
                                         title="Тип кузова" width="15">
                                        Седан
                                </span>

                                    <span
                                        class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                        title="car color">
                                    <img :src="ColorIcon"
                                         alt="Color" class="property-icon" height="15" loading="lazy" title="Цвет"
                                         width="14">
                                    Черный
                                </span>
                                </div>

                                <div
                                    class="d-flex align-items-center justify-content-lg-start justify-content-between mt-2 gap-2">
                                <span
                                    class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                    title="wheel">
                                    <img :src="RuleIcon"
                                         alt="Wheel" class="property-icon" height="15" loading="lazy" title="Руль"
                                         width="14">
                                    Левый
                                </span>

                                    <span
                                        class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                        title="drive wheel">
                                    <img :src="PrivodIcon"
                                         alt="Drive Wheel" class="property-icon" height="15" loading="lazy"
                                         title="Тип привода" width="14">
                                   Передний
                                </span>

                                    <span
                                        class="bg-light fw-light p-1 border border-light fs-14 fs-sm-12 text-nowrap text-truncate d-inline-block"
                                        title="engine">
                                    <img :src="DvigatelIcon"
                                         alt="Engine" class="property-icon me-1" height="15" loading="lazy"
                                         title="Тип двигателя" width="14">
                                   Бензин
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 p-0 border-start card_price">
                        <div class="d-flex flex-column justify-content-between h-100 p-2">
                            <div>
                                <p class="fs-14 fw-light mb-2 text-nowrap text-truncate opacity-75 vin_code">
                                    <i class="fa fa-check-circle me-1 text-success"></i>
                                    VIN проверен
                                </p>

                                <div class="d-none d-lg-block">

                                    <div class="card__discount_prices">
                                        <p class="fs-14 fw-light mb-2 opacity-75">
                                            <del>
                                                469 000 руб.
                                            </del>
                                        </p>
                                        <p class="fs-18 text-dark fw-bold mb-2">
                                            350 000 руб.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="d-flex align-items-center">
                                    <button class="btn bg-light py-1">
                                        <i class="fas fa-heart fs-14 text-secondary"></i>
                                    </button>

                                    <button
                                        class="btn bg-light py-1 ms-2 w-100 d-none d-lg-block"
                                        data-bs-target="#tradeinmodal"
                                        data-bs-toggle="modal">
                                        Trade In
                                    </button>

                                    <div class="bg-light py-1 px-2 ms-2 w-100 d-lg-none">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <p class="text-dark fw-bold mb-0 text-end">
                                                350 000 руб.
                                            </p>
                                            <p class="fs-14 fw-light mb-0">
                                                <del>
                                                    469 000 руб.
                                                </del>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2">
                                    <button class="btn btn-danger fs-14 fs-md-12 fs-sm-14 text-uppercase w-100"
                                            data-bs-target="#creditmodal"
                                            data-bs-toggle="modal">
                                        купить в кредит
                                        <span class="d-block text-center text-lowercase fs-12 fw-light">
                                                                от 4 613 руб. / мес.
                                                            </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CreditRequestPopup/>

        <TradeInPopup/>
    </div>
</template>

<script>
import SortIcon from "@/assets/websites/rb-auto/img/sort-icon.svg"
import ListIcon from "@/assets/websites/rb-auto/img/list-icon.svg"
import GridIcon from "@/assets/websites/rb-auto/img/grid-icon.svg"
import ColorIcon from "@/assets/websites/rb-auto/img/color-icon.png"
import DvigatelIcon from "@/assets/websites/rb-auto/img/dvigatel-icon.png"
import KorobkaIcon from "@/assets/websites/rb-auto/img/korobka-icon.png"
import KuzovIcon from "@/assets/websites/rb-auto/img/kuzov-icon.png"
import PrivodIcon from "@/assets/websites/rb-auto/img/privod-icon.png"
import RuleIcon from "@/assets/websites/rb-auto/img/rule-icon.png"
import CreditRequestPopup from "@/components/websites/rb-auto/parts/popups-and-blocks/CreditRequestPopup.vue";
import TradeInPopup from "@/components/websites/rb-auto/parts/popups-and-blocks/TradeInPopup.vue";

export default {
    name: 'SaleableRbAuto',
    components: {TradeInPopup, CreditRequestPopup},

    methods: {
        gridView() {
            const saleableCards = document.querySelectorAll('.saleable_card');

            saleableCards.forEach(node => node.classList.add('saleable_card__grid_view'));

            document.querySelector('.grid-view').classList.add('active');
            document.querySelector('.list-view').classList.remove('active');
        },

        listView() {
            const saleableCards = document.querySelectorAll('.saleable_card');

            saleableCards.forEach(node => node.classList.remove('saleable_card__grid_view'));

            document.querySelector('.list-view').classList.add('active');
            document.querySelector('.grid-view').classList.remove('active');
        }

    },

    data() {
        return {
            SortIcon,
            ListIcon,
            GridIcon,
            ColorIcon,
            DvigatelIcon,
            KorobkaIcon,
            KuzovIcon,
            PrivodIcon,
            RuleIcon
        }
    }
}
</script>

<style scoped>
.saleable_card__grid_view {
    width: 33.3333333333% !important;
    padding-inline: 12px !important;
}

.saleable_card__grid_view .card_pic,
.saleable_card__grid_view .card_info,
.saleable_card__grid_view .card_price {
    width: 100% !important;
}

.saleable_card__grid_view .card_price {
    border-left: unset !important;
}

.saleable_card__grid_view .card__discount_prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saleable_card__grid_view:nth-child(3n+1) {
    padding-left: 0 !important;
}

.saleable_card__grid_view:nth-child(3n+3) {
    padding-right: 0 !important;
}

.grid-view.active img {
    filter: none !important;
}

.list-view.active img {
    filter: none !important;
}

@media screen and (min-width: 992px) {
    .car__img {
        height: 201px !important;
    }
}

@media screen and (max-width: 991px) {
    .car__img {
        max-width: 300px;
    }
}
</style>