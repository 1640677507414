<template>
    <div>
        <div>
            <div id="offcanvasContacts" aria-labelledby="offcanvasContactsLabel"
                 class="offcanvas offcanvas-end border-start-0"
                 tabindex="-1">
                <div class="offcanvas-header align-items-start">
                    <h5 id="offcanvasContactsLabel" class="offcanvas-title d-flex flex-column">
                        <span class="lh-1">{{ companyName }}</span>
                        <span class="fs-14 text-secondary">{{ companyObjectType }}</span>
                    </h5>
                    <button :title="trans('menu_close')" aria-label="Close" class="btn-close"
                            data-bs-dismiss="offcanvas"
                            type="button"></button>
                </div>
                <div class="offcanvas-body d-flex flex-column pt-0">
                    <div>
                        <div class="mt-2 pb-2 border-bottom">
                            <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15">
                                {{ trans('menu_about') }}
                            </button>
                        </div>

                        <div class="mt-2 pb-2 border-bottom">
                            <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15">
                                {{ trans('menu_contacts') }}
                            </button>
                        </div>

                        <div class="mt-2 pb-2 border-bottom">
                            <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15">
                                {{ trans('menu_menu') }}
                            </button>
                        </div>

                        <div class="mt-2 pb-2 border-bottom">
                            <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15">
                                {{ trans('menu_reviews') }}
                            </button>
                        </div>

                        <div class="mt-2 pb-2 border-bottom">
                            <button class="dropdown-item d-flex align-items-center gap-2 fw-bold fs-15"
                                    data-bs-target="#menuControl" data-bs-toggle="modal">
                                {{ trans('menu_menu_control') }}
                            </button>
                        </div>
                    </div>


                    <div class="mt-3 flex-grow-1">
                        <menu-information/>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {t} from "@/lang/core/helpers";
import MenuInformation from "@/components/websites/allmenu/parts/Information";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
    name: "OffCanvas",

    components: {
        MenuInformation
    },

    computed: {
        ...mapGetters(['menuCompany', 'menuCompanyDefaultLocale']),

        locale() {
            return window.localStorage.getItem('locale') 
                || this.menuCompanyDefaultLocale 
                || 'ka'
        },

        companyName() {
            if (!this.menuCompany) {
                return null
            }

            let translation = _.filter(this.menuCompany.translations, {locale: this.locale})
            let translatedName = null

            if (!translation || !translation.length) {
                let locales = (this.availableLocales || []).filter(locale => locale !== this.locale)

                if (locales && locales.length) {
                    locales.forEach(l => {
                        translation = _.filter(this.menuCompany.translations, {locale: l})

                        if (translation && translation.length) {
                            translatedName = translation[0].name
                            return
                        }
                    })
                }

                return translatedName
            }

            return translation[0].name
        },

        companyObjectType() {
            if (!this.menuCompany) {
                return null
            }

            let translatedCompany = _.filter(this.menuCompany.translations, {locale: this.locale})

            if (!translatedCompany || !translatedCompany.length) {
                return this.menuCompany.translations[0].object_type
            }

            return translatedCompany[0].object_type
        },
    },

    methods: {
        trans(key) {
            return t(key)
        },

        changeLocale(locale) {
            if (!this.companyAvailableLocales.includes(locale)) {
                return
            }

            let oldLocale = window.localStorage.getItem('locale') || 'ka'
            let path = this.$route.fullPath.replace('/' + oldLocale, '/' + locale)

            window.localStorage.setItem('locale', locale)
            this.$store.commit('setLocale', locale)

            this.$router.push(path)

            if (this.$route.meta.title.includes('##COMPANY_NAME##')) {
                let localeCompany = _.filter(this.menuCompany.translations, {locale: locale})

                if (!localeCompany.length) {
                    document.title = this.$route.meta.title.replace('##COMPANY_NAME##', this.menuCompany.translations[0].name)
                    return
                }

                let companyName = _.filter(this.menuCompany.translations, {locale: locale})[0].name

                document.title = this.$route.meta.title.replace('##COMPANY_NAME##', companyName)
            }
        },
    }
}
</script>