<template>
    <div class="container-fluid px-0 mt-2">
        <div class="container p-2">
            <div class="row row-cols-lg-6 row-cols-md-4 row-cols-3 mt-lg-4 banks">
                <div class="col mb-lg-4 mb-3 ps-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank1" alt="Альфа Банк" class="w-50 img-fluid" loading="lazy" title="Альфа Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank2" alt="OTП Банк" class="w-50 img-fluid" loading="lazy" title="OTП Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank3" alt="Промсвязьбанк" class="w-50 img-fluid" loading="lazy"
                             title="Промсвязьбанк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank4" alt="ВТБ Банк" class="w-25 img-fluid" loading="lazy" title="ВТБ Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank5" alt="Быстро Банк" class="w-50 img-fluid" loading="lazy" title="Быстро Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3 pe-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank6" alt="Совком Банк" class="w-50 img-fluid" loading="lazy" title="Совком Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3 ps-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank7" alt="Банк Зенит" class="w-25 img-fluid" loading="lazy" title="Банк Зенит">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank8" alt="Экспобанк" class="w-50 img-fluid" loading="lazy" title="Экспобанк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank9" alt="ЮниКредит" class="w-50 img-fluid" loading="lazy" title="ЮниКредит">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank10" alt="УРАЛСИБ" class="w-50 img-fluid" loading="lazy" title="УРАЛСИБ">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank11" alt="ПримСоцБанк" class="w-50 img-fluid" loading="lazy" title="ПримСоцБанк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3 pe-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank12" alt="Ренессанс Кредит" class="w-50 img-fluid" loading="lazy"
                             title="Ренессанс Кредит">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3 ps-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank13" alt="Эксперт Банк" class="w-50 img-fluid" loading="lazy"
                             title="Эксперт Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank14" alt="ЛокоБанк" class="w-25 img-fluid" loading="lazy" title="ЛокоБанк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank15" alt="БАЛТИНВЕСТБАНК" class="w-50 img-fluid" loading="lazy"
                             title="БАЛТИНВЕСТБАНК">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank16" alt="Плюс Банк" class="w-50 img-fluid" loading="lazy" title="Плюс Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank17" alt="Заубер Банк" class="w-50 img-fluid" loading="lazy" title="Заубер Банк">
                    </div>
                </div>
                <div class="col mb-lg-4 mb-3 pe-lg-0">
                    <div class="border d-flex align-items-center justify-content-center bg-white" style="height: 80px">
                        <img :src="Bank18" alt="Сетелем" class="w-50 img-fluid" loading="lazy" title="Сетелем">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Bank1 from "@/assets/websites/rb-auto/img/banks/bank-1.png"
import Bank2 from "@/assets/websites/rb-auto/img/banks/bank-2.png"
import Bank3 from "@/assets/websites/rb-auto/img/banks/bank-3.png"
import Bank4 from "@/assets/websites/rb-auto/img/banks/bank-4.png"
import Bank5 from "@/assets/websites/rb-auto/img/banks/bank-5.png"
import Bank6 from "@/assets/websites/rb-auto/img/banks/bank-6.png"
import Bank7 from "@/assets/websites/rb-auto/img/banks/bank-7.png"
import Bank8 from "@/assets/websites/rb-auto/img/banks/bank-8.png"
import Bank9 from "@/assets/websites/rb-auto/img/banks/bank-9.png"
import Bank10 from "@/assets/websites/rb-auto/img/banks/bank-10.png"
import Bank11 from "@/assets/websites/rb-auto/img/banks/bank-11.png"
import Bank12 from "@/assets/websites/rb-auto/img/banks/bank-12.png"
import Bank13 from "@/assets/websites/rb-auto/img/banks/bank-13.png"
import Bank14 from "@/assets/websites/rb-auto/img/banks/bank-14.png"
import Bank15 from "@/assets/websites/rb-auto/img/banks/bank-15.png"
import Bank16 from "@/assets/websites/rb-auto/img/banks/bank-16.png"
import Bank17 from "@/assets/websites/rb-auto/img/banks/bank-17.png"
import Bank18 from "@/assets/websites/rb-auto/img/banks/bank-18.png"

export default {
    name: 'PartnersRbAuto',

    data() {
        return {
            Bank1,
            Bank2,
            Bank3,
            Bank4,
            Bank5,
            Bank6,
            Bank7,
            Bank8,
            Bank9,
            Bank10,
            Bank11,
            Bank12,
            Bank13,
            Bank14,
            Bank15,
            Bank16,
            Bank17,
            Bank18,
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
    .banks .col {
        padding-right: 4px;
    }

    .banks .col:nth-child(3n+3) {
        padding-right: 12px;
    }

    .banks .col .border {
        height: 50px !important;
    }
}
</style>