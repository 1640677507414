<template>
    <div class="row m-0">
        <template v-if="!carPartsPromotionCarPartsLoaded">
            <div v-for="carLoader in 3"
                 :key="carLoader"
                 aria-hidden="true"
                 class="col-lg-12 col-md-6 px-2">
                <LoaderProduct/>
            </div>
        </template>

        <template v-else>
            <div v-if="carPartsPromotionCarParts.length === 0">
                <p class="fs-4 mb-0 text-danger text-center">
                    {{ trans('not_found') }}!
                </p>
            </div>

            <div v-for="part in carPartsPromotionCarParts" :key="part.id" class="col-lg-12 col-md-6 px-2">
                <div class="row m-0 shadow bg-white rounded mb-3 product">
                    <div class="col-lg-3 px-0">
                        <router-link v-if="part.images && part.images.length"
                                     :to="{
                                     name: 'view-product',
                                     params: {
                                            id: part.id}
                                     }"
                                     class="position-relative d-block product__img">

                            <img
                                :src="getProductImage(part)"
                                alt=""
                                class="w-100 rounded-start pic__rounded_mob"
                                height="190"
                                style="object-fit: cover;">
                            <div
                                class="position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex justify-content-center align-items-center text-center product__img__hover">
                                {{ trans('view') }}
                            </div>
                        </router-link>

                        <router-link v-else
                                     :to="{
                                     name: 'view-product',
                                     params: {
                                            id: part.id}
                                     }"
                                     class="d-block">

                            <img :src="NoImage" alt="" class="w-100 rounded-start" height="190"
                                 style="object-fit: cover;">

                        </router-link>
                    </div>

                    <div class="col-lg-7 mt-lg-0 mt-3 p-2">
                        <div class="d-flex flex-column justify-content-between h-100">
                            <div>
                                <router-link :to="{
                                        name: 'view-product',
                                        params: {
                                            id: part.id
                                        }
                                    }"
                                             class="d-flex flex-lg-row flex-column align-items-lg-center text-dark text-truncate product__name">
                                    <p class="fw-600 mb-0">{{ getTranslatedPropertyByLocale(part, 'name', locale) }}</p>

                                    <template v-if="part.supported_cars && part.supported_cars.length">
                                        <span class="d-none d-lg-inline mx-1">-</span>

                                        <p class="fs-sm-15 mt-lg-0 mt-1 mb-0 text-truncate">
                                            {{ getSupportedCars(part) }}
                                        </p>
                                    </template>
                                </router-link>

                                <div class="d-flex gap-2 mt-3">
                                    <div>
                                        <span v-if="part.condition === 'NEW'" class="badge bg-info">
                                            {{ trans('new') }}
                                        </span>

                                        <span v-else class="badge bg-warning">{{ trans('secondary') }}</span>
                                    </div>

                                    <div
                                        v-if="part.categories && part.categories.length"
                                        class="d-flex align-items-center gap-2"
                                    >
                                        <span v-for="category in part.categories" :key="category.id"
                                              class="badge bg-success">
                                            {{ getTranslatedPropertyByLocale(category, 'name', locale) }}
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <a v-if="requisites.LOC_GOOGLE_MAP_LINK"
                               :href="requisites.LOC_GOOGLE_MAP_LINK"
                               class="d-block text-dark fs-14 fw-light mt-3 product__address"
                               target="_blank">
                                {{ requisites.LOC_CITY && locale ? requisites.LOC_CITY[locale] : '' }},
                                {{ requisites.LOC_ADDRESS && locale ? requisites.LOC_ADDRESS[locale] : '' }}
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-2 p-2">
                        <div class="d-flex flex-lg-column justify-content-between h-100">
                            <div class="d-flex align-items-center justify-content-lg-end gap-2">
                                <p v-if="selectedCurrency === 'lari_product'" id="lari_price"
                                   :class="part.price_discount !== null ? 'd-flex flex-column align-items-lg-end fw-600 mb-0 product__price' :  'fw-600 mb-0 product__price text-lg-end'">
                                    <span :class="part.price_discount !== null ? 'lh-1' : 'fs-5'">{{ part.price_discount || part.price }}</span>

                                    <del v-if="part.price_discount !== null" class="text-danger fs-15 lh-1">
                                        {{ part.price }}
                                    </del>
                                </p>

                                <p v-if="selectedCurrency === 'dollar_product'" id="dollar_price"
                                   :class="part.price_discount !== null ? 'd-flex flex-column align-items-lg-end fw-600 mb-0 product__price' :  'fw-600 mb-0 product__price text-lg-end'">
                                    <span :class="part.price_discount !== null ? 'lh-1' : 'fs-5'">{{ calculateUsdPrice(part.price_discount || part.price) }}</span>

                                    <del v-if="part.price_discount !== null" class="text-danger fs-15 lh-1">
                                       {{ calculateUsdPrice(part.price) }}
                                    </del>
                                </p>

                                <div class="btn-group bg-white">
                                    <input :id="`lari_product-${part.id}`"
                                           v-model="selectedCurrency"
                                           :name="`currency_product-${part.id}`"
                                           autocomplete="off"
                                           checked
                                           class="btn-check"
                                           type="radio"
                                           value="lari_product">
                                    <label :for="`lari_product-${part.id}`"
                                           class="btn btn-sm btn-outline-warning">
                                        ₾
                                    </label>

                                    <input :id="`dollar_product-${part.id}`"
                                           v-model="selectedCurrency"
                                           :name="`currency_product-${part.id}`"
                                           autocomplete="off"
                                           class="btn-check"
                                           type="radio"
                                           value="dollar_product">
                                    <label :for="`dollar_product-${part.id}`"
                                           class="btn btn-sm btn-outline-warning">
                                        $
                                    </label>
                                </div>

                                <div class="btn-group d-none">
                                    <input id="lari_product" autocomplete="off" checked class="btn-check"
                                           name="currency_product" type="radio">
                                    <label class="btn btn-sm btn-outline-warning" for="lari_product">₾</label>

                                    <input id="dollar_product" autocomplete="off" class="btn-check"
                                           name="currency_product"
                                           type="radio">
                                    <label class="btn btn-sm btn-outline-warning" for="dollar_product">$</label>
                                </div>
                            </div>

                            <div class="d-flex justify-content-lg-end gap-2">
                                <button
                                    v-if="!isInCart(part)"
                                    class="btn btn-light btn-sm d-flex align-items-center justify-content-center rounded-circle"
                                    style="width: 31px; height: 31px;"
                                    type="button" @click="addToCart(part)">
                                    <i class="fa-sharp fa-solid fa-cart-shopping"></i>
                                </button>

                                <div v-else class="d-flex align-items-center border bg-white rounded"
                                     style="width: max-content;">
                                    <div>
                                        <button class="btn btn-sm border-0" type="button"
                                                @click="decreaseItemCount(part)">
                                            <i class="fa-solid fa-minus"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <input
                                            :value="itemQuantity(part)"
                                            class="form-control text-center p-2 border-start border-end border-0 rounded-0"
                                            style="width: 35px; height: 31px;"
                                            type="tel">
                                    </div>
                                    <div>
                                        <button class="btn btn-sm border-0" type="button"
                                                @click="increaseItemCount(part)">
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <button
                                    class="btn btn-light btn-sm d-flex align-items-center justify-content-center rounded-circle"
                                    style="width: 31px; height: 31px;"
                                    type="button" @click="toggleFavorite(part)">
                                    <i :class="!isInFavorites(part) ? 'fa-regular fa-heart' : 'fa-solid fa-heart'"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image.png"
import {mapGetters} from "vuex";
import {data_get, round_number} from "@/core/helpers";
import _ from 'lodash'
import LoaderProduct from "@/components/websites/get-parts/parts/loaders/LoaderProduct.vue";
import {getTranslatedPropertyByLocale, t} from "@/lang/core/helpers";

export default {
    name: 'ProductPG',
    components: {LoaderProduct},

    computed: {
        ...mapGetters([
            'carPartsPromotionCarParts',
            'carPartsPromotionCarPartsLoaded',
            'locale',
            'requisites',
            'shoppingCart',
            'favorites',
            'exchangeRates'
        ])
    },

    methods: {
        getTranslatedPropertyByLocale,
        trans(key) {
            return t(key)
        },

        getImageBySize(images, size) {
            let filterImage = _.filter(images, {image_type: size})
            return filterImage.length ? filterImage[0].url : null
        },

        getProductImage(part) {
            if (!part.images.length) {
                return null
            }

            return this.getImageBySize(part.images, 'MEDIUM')
        },

        addToCart(part) {
            this.$store.dispatch('addToCart', part)
        },

        isInCart(part) {
            return this.shoppingCart.find(item => item.id === part.id)
        },

        increaseItemCount(product) {
            this.$store.dispatch('increaseItemCount', product)
        },

        decreaseItemCount(product) {
            this.$store.dispatch('decreaseItemCount', product)
        },

        itemQuantity(product) {
            if (!this.shoppingCart || !this.isInCart(product)) {
                return 0
            }

            return this.shoppingCart.find(item => item.id === product.id).quantity
        },

        toggleFavorite(part) {
            this.$store.dispatch('toggleFavorite', part)
        },

        isInFavorites(part) {
            return this.favorites.find(item => item.id === part.id)
        },

        calculateUsdPrice(price) {
            if (!this.exchangeRates || !Object.keys(this.exchangeRates).length || !this.exchangeRates['USD']) {
                return price
            }

            return round_number(price * this.exchangeRates['USD'])
        },

        getSupportedCars(part) {
            if (!part.supported_cars || !part.supported_cars.length) {
                return ''
            }

            let supportedCars = ''

            part.supported_cars.forEach((car, index) => {
                supportedCars += `${car.short_supported_car}`

                if (index !== part.supported_cars.length - 1) {
                    supportedCars += ', '
                }
            })

            return supportedCars;
        }
    },


    data() {
        return {
            NoImage,
            data_get,
            selectedCurrency: 'lari_product'
        }
    }
}
</script>

<style scoped>
.product {
    transition: .3s;
}

.product:hover {
    background-color: #f1f1f1 !important;
}

.product__name,
.product__address {
    transition: .3s;
}

.product__name:hover,
.product__address:hover {
    text-decoration: underline;
}

.product__img .product__img__hover {
    opacity: 0;
    transition: background-color .3s
}

.product__img:hover .product__img__hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.72);
    color: #fff;
    font-size: 24px;
    font-weight: 800;
}

.product__price {
    width: 65px;
}

@media screen and (max-width: 992px) {
    .pic__rounded_mob {
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
        border-bottom-left-radius: 0 !important;
    }
}
</style>