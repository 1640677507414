<template>
    <div>
        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container py-lg-2 p-0 d-flex justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent d-flex align-items-center mb-0 px-lg-0 px-2">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'home'}" class="text-dark fs-sm-14">
                                Главная
                            </router-link>
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14 active">Избранное</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 px-0">
            <div class="container px-2">
                <div class="row">
                    <div v-for="n in 5" :key="n" class="col-12 mb-3">
                        <div class="row border shadow-sm">
                            <div class="col-lg-3 pe-lg-2 px-0 position-relative">
                                <div class="position-absolute remove_favorite"
                                     style="cursor: pointer; right: 16px; top: 5px;">
                                    <i class="fas fa-heart text-light fs-12 p-1 border border-dark bg-dark rounded-circle blockIconPropertie removeNote"></i>
                                </div>

                                <router-link :to="{name: 'view-car'}" class="favorite_car_img">
                                    <img alt=""
                                         class="w-100 h-100 img-fluid"
                                         loading="Lazy"
                                         src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg"
                                         title="">
                                </router-link>
                            </div>

                            <ul class="col-lg-6 list-group list-group-flush d-flex justify-content-between pe-lg-2 px-0">
                                <li>
                                    <router-link :to="{name: 'view-car'}"
                                                 class="text-dark text-center fw-bold fs-5 fs-sm-16 d-md-none">
                                    <span class="card-title text-truncate d-inline-block fs-5 col-12 mb-0">
                                        Ford Focus
                                    </span>
                                    </router-link>
                                </li>

                                <li class="list-group-item text-secondary d-flex justify-content-between bg-transparent px-lg-0 px-2 fs-sm-15">
                                    Пробег:
                                    <strong class="text-dark">165 376 км</strong>
                                </li>
                                <li class="list-group-item text-secondary d-flex justify-content-between bg-transparent px-lg-0 px-2 fs-sm-15">
                                    Год выпуска:
                                    <strong class="text-dark">2007 г.</strong>
                                </li>
                                <li class="list-group-item text-secondary d-flex justify-content-between bg-transparent px-lg-0 px-2 fs-sm-15">
                                    КПП:
                                    <strong class="text-dark">Автоматическая</strong>
                                </li>
                                <li class="list-group-item text-secondary d-flex justify-content-between bg-transparent px-lg-0 px-2 fs-sm-15">
                                    Двигатель:
                                    <strong class="text-dark">Бензин инжектор</strong>
                                </li>
                                <li class="list-group-item text-secondary d-flex justify-content-between bg-transparent px-lg-0 px-2 fs-sm-15">
                                    Объем и л.с.:
                                    <strong class="text-dark">2.0 см³ / 147 л.с.</strong>
                                </li>
                            </ul>

                            <div
                                class="col-lg-3 border-start favorite_border_start d-flex flex-column justify-content-between">
                                <router-link :to="{name: 'view-car'}"
                                             class="text-dark fw-bold fs-5 fs-sm-16 d-none d-md-block">
                                <span class="card-title text-truncate d-inline-block fs-5">
                                    Ford Focus
                                </span>
                                </router-link>
                                <p class="fs-5 fs-md-16 d-flex justify-content-between mb-0">

                                    <del class="pr-2">769 000 руб.</del>

                                    <b class="pr-2">450 000 руб.</b>

                                </p>
                                <p class="text-end mb-0">
                                    от 9 613 ₽/мес
                                </p>

                                <div class="d-flex flex-column justify-content-between mt-lg-3 mt-2 pb-2">
                                    <button class="btn btn-outline-danger w-100 fs-sm-14 text-uppercase mb-2"
                                            data-bs-target="#creditmodal"
                                            data-bs-toggle="modal">
                                        получить предложение
                                    </button>

                                    <router-link :to="{name: 'credit'}"
                                                 class="btn btn-danger text-truncate text-uppercase fs-sm-14">
                                        купить в кредит
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CallRequestLine/>

        <CreditRequestPopup/>

    </div>
</template>

<script>

import CreditRequestPopup from "@/components/websites/rb-auto/parts/popups-and-blocks/CreditRequestPopup.vue";
import CallRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CallRequestLine.vue";

export default {
    name: 'FavouritesRbAuto',
    components: {CallRequestLine, CreditRequestPopup},
}
</script>

<style scoped>

</style>