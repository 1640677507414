<template>
    <div class="container px-lg-0">
        <div class="d-flex justify-content-between">
                    <span>
                        &copy; Allmenu.Ge
                    </span>

            <a class="text-decoration-none" href="tel:tel:+995 599 995 995">+995 599 995 995</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterLanding"
}
</script>



<style scoped>

</style>