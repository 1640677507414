<template>
    <div>
        <div class="container-fluid mt-3 d-lg-none px-0">
            <div class="container p-lg-2 p-0">
                <BodyTypeFilter/>
            </div>
        </div>

        <PriceRange/>

        <div class="container-fluid mt-3 px-0">
            <div class="container p-lg-2 p-0">
                <div class="row flex-wrap-reverse m-0">
                    <div class="col-lg-9 px-0">
                        <h1 class="fs-2 mb-3 px-lg-0 px-2">Автомобили с пробегом</h1>
                        <SaleableRbAuto/>
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-3 ps-lg-3 px-0">
                        <div class="sticky-lg-top" style="top: 20px;">
                            <FilterRbAuto/>

                            <div class="d-none d-lg-block">
                                <TradeInRbAuto/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container p-0 d-lg-none">
                <TradeInRbAuto/>
            </div>

            <CreditRequestLine/>

            <InfoLinks/>

            <CallRequestLine/>
        </div>
    </div>
</template>

<script>
import FilterRbAuto from "@/components/websites/rb-auto/parts/Filter.vue";
import TradeInRbAuto from "@/components/websites/rb-auto/parts/TradeInBanner.vue";
import BodyTypeFilter from "@/components/websites/rb-auto/parts/BodyTypeFilter.vue";
import CreditRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CreditRequestLine.vue";
import InfoLinks from "@/components/websites/rb-auto/parts/InfoLinks.vue";
import PriceRange from "@/components/websites/rb-auto/parts/PriceRange.vue";
import CallRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CallRequestLine.vue";
import SaleableRbAuto from "@/components/websites/rb-auto/parts/Saleable.vue";

export default {
    name: 'CarsRbAuto',
    components: {
        SaleableRbAuto,
        CallRequestLine,
        PriceRange,
        InfoLinks,
        CreditRequestLine,
        BodyTypeFilter,
        TradeInRbAuto,
        FilterRbAuto
    }
}
</script>

<style scoped>

</style>