<template>
    <div class="car__part_sames">
        <carousel v-if="carParts && carParts.length"
                  :items="5"
                  :margin="16"
                  :responsive="{
                      250:{items: 1.5, center: true, loop: true},
                      670:{items: 3},
                      992:{items:5}}"
                  class="mt-3">

            <div v-for="carPart in carParts" :key="carPart.id" class="same-product shadow rounded bg-white">
                <div>
                    <img v-if="getFirstImage(carPart.images || []).url" :src="getFirstImage(carPart.images || []).url"
                         alt="" class="w-100 rounded-top" height="234" style="object-fit: cover;">
                    <img v-else :src="NoImage" alt="" class="w-100 rounded-top" height="234" style="object-fit: cover;">
                </div>

                <div class="px-2">
                    <p class="fw-600 mt-2 mb-0">
                        {{ data_get(carPart, 'name') }}
                    </p>

                    <p class="text-truncate mt-1 mb-0">
                        {{ data_get(carPart, 'mark.name') }},
                        {{ data_get(carPart, 'model.name') }}, {{ data_get(carPart, 'generation.name') }},
                        {{ data_get(carPart, 'modification.name') }}
                    </p>

                    <div class="d-flex align-items-center justify-content-between gap-3 mt-2">
                        <p v-if="selectedCurrencySameProduct === 'lari_same_product'" class="fs-15 fw-600 mb-0">
                            {{ carPart.price_discount || carPart.price }}</p>

                        <p v-if="selectedCurrencySameProduct === 'dollar_same_product'" class="fs-15 fw-600 mb-0">
                            {{ calculateUsdPrice(carPart.price_discount || carPart.price) }}
                        </p>

                        <div class="btn-group">
                            <input :id="`lari_same_product-${carPart.id}`"
                                   v-model="selectedCurrencySameProduct"
                                   :name="`currency_same_product-${carPart.id}`"
                                   autocomplete="off"
                                   class="btn-check"
                                   type="radio"
                                   value="lari_same_product">
                            <label :for="`lari_same_product-${carPart.id}`"
                                   class="btn btn-sm btn-outline-warning">₾</label>

                            <input :id="`dollar_same_product-${carPart.id}`"
                                   v-model="selectedCurrencySameProduct"
                                   :name="`currency_same_product-${carPart.id}`"
                                   autocomplete="off"
                                   class="btn-check"
                                   type="radio"
                                   value="dollar_same_product">
                            <label :for="`dollar_same_product-${carPart.id}`"
                                   class="btn btn-sm btn-outline-warning">$</label>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-2">
                        <div>
                            <span v-if="carPart && carPart.condition === 'NEW'" class="badge bg-info">
                                {{ trans('new') }}
                            </span>

                            <span v-else class="badge bg-warning">
                                {{ trans('secondary') }}
                            </span>
                        </div>

                        <button
                            class="btn btn-light btn-sm d-flex align-items-center justify-content-center rounded-circle"
                            style="width: 40px; height: 40px;"
                            type="button"
                            @click="toggleFavorite(carPart)">
                            <i :class="!isInFavorites(carPart) ? 'fa-regular fa-heart' : 'fa-solid fa-heart'"></i>
                        </button>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image.png"
import carousel from "vue-owl-carousel"
import {data_get, round_number} from "@/core/helpers";
import _ from "lodash";
import {t} from "@/lang/core/helpers";
import {mapGetters} from "vuex";

export default {
    name: 'SameProductGP',

    computed: {
        ...mapGetters([
            'favorites',
            'exchangeRates'
        ])
    },

    components: {
        carousel
    },

    props: {
        carParts: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            NoImage,
            selectedCurrencySameProduct: 'lari_same_product'
        }
    },

    methods: {
        trans(key) {
            return t(key)
        },

        data_get,

        getImagesBySize(images, size) {
            return _.filter(images, {image_type: size}) || []
        },

        getFirstImage(images, size) {
            return _.first(this.getImagesBySize(images, size)) || {}
        },

        toggleFavorite(part) {
            this.$store.dispatch('toggleFavorite', part)
        },

        isInFavorites(part) {
            return this.favorites.find(item => item.id === part.id)
        },

        calculateUsdPrice(price) {
            if (!this.exchangeRates || !Object.keys(this.exchangeRates).length || !this.exchangeRates['USD']) {
                return price
            }

            return round_number(price * this.exchangeRates['USD'])
            // return Math.round(round_number(price * this.exchangeRates['USD']))
        }
    }
}
</script>


<style>

.car__part_sames .owl-theme .owl-nav {
    margin-top: 0 !important;
}

.car__part_sames .owl-prev, .owl-next {
    position: relative;
    background: unset !important;
}

.car__part_sames .owl-prev::after, .owl-next::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D6D6D6;
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-weight: 900;
    border-radius: 50%;
    transition: .3s;
}

.car__part_sames .owl-prev::after {
    content: '\f053';
}

.car__part_sames .owl-next::after {
    content: '\f054';
}

.car__part_sames .owl-prev:hover::after, .owl-next:hover::after {
    background: #869791 !important;
}

.car__part_sames .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    opacity: 0;
    transition: .3s;

}

.car__part_sames .owl-carousel:hover .owl-nav {
    opacity: 1;
}

.car__part_sames .owl-stage {
    padding: 12px 0;
}

@media screen and (max-width: 992px) {
    .car__part_sames .owl-prev::after, .car__part_sames .owl-next::after {
        width: 40px;
        height: 40px;
    }

    .car__part_sames .owl-nav {
        opacity: 1;
    }
}
</style>