<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">
        <div class="container px-lg-0">
            <div class="d-flex flex-md-row flex-column align-items-md-start gap-lg-4 gap-3">
                <div class="d-flex gap-1">
                    <p class="fs-15 fw-semibold mb-0">
                        Do you have a discount code?
                    </p>

                    <button aria-controls="collapsePromoCode" aria-expanded="false"
                            class="btn p-0 border-0 text-decoration-underline fs-15 fw-semibold"
                            data-bs-target="#collapsePromoCode" data-bs-toggle="collapse"
                            type="button">
                        Enter the code
                    </button>
                </div>

                <div id="collapsePromoCode" class="collapse">
                    <div class="card card-body d-flex flex-row align-items-center gap-2 rounded-0">
                        <input class="form-control form-control-sm" placeholder="Coupon code" type="text">

                        <button class="btn btn-dark btn-sm text-nowrap">
                            Use the coupon
                        </button>
                    </div>
                </div>

            </div>

            <div class="row justify-content-between m-0 mt-lg-5 mt-4">
                <div class="col-lg-6 ps-lg-0 pe-lg-5 px-0">
                    <h1 class="fs-3">
                        Information and address
                    </h1>

                    <ModaOrderInformation/>
                </div>

                <div class="col-lg-5 ps-lg-5 pe-lg-0 px-0 mt-lg-0 mt-4">

                    <ModaOrderCheck/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModaOrderCheck from "@/components/websites/moda/parts/OrderCheck.vue";
import ModaOrderInformation from "@/components/websites/moda/parts/OrderInformation.vue";

export default {
    name: "OrderFinalyStep",

    components: {
        ModaOrderInformation,
        ModaOrderCheck
    }
}
</script>

<style scoped>
#collapsePromoCode .card-body {
    border-style: dashed !important;
    border-width: 2px;
}


@media screen and (min-width: 1200px) {
    .order-left-side {
        min-width: 350px;
    }
}
</style>