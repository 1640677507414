<template>
    <div class="bg-white shadow-sm mb-3 p-3">
        <p class="fs-5 fs-sm-18 text-center mb-0">Экспресс заявка на кредит</p>

        <div class="input-form position-relative mt-3">
            <label class="input-title nameGet">Имя</label>
            <input class="form-control position-relative" type="text">
        </div>

        <div class="input-form position-relative mt-3">
            <label class="input-title">Адрес</label>
            <input class="form-control position-relative" type="text">
        </div>

        <div class="input-form position-relative mt-3">
            <label class="input-title">Телефон</label>
            <input class="form-control" placeholder="+7(___)___-__-__" type="tel">
        </div>

        <button class="btn btn-danger shadow-sm w-100 mt-3">
            Отправить
        </button>
    </div>
</template>

<script>
export default {
    name: 'ExpressCreditRbAuto',

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>