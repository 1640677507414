<template>
    <div class="container-fluid px-0 pt-lg-5 pt-3 bg-light" style="margin-bottom: -48px;">
        <div class="container px-lg-0">
            <div class="d-flex flex-lg-row flex-column align-items-start privacy">
                <div id="v-pills-tab" aria-orientation="vertical"
                     class="nav flex-column nav-pills text-nowrap me-lg-100"
                     role="tablist">
                    <button id="v-pills-home-tab" aria-controls="v-pills-home"
                            aria-selected="true" class="nav-link text-dark text-start px-0 pt-0 active"
                            data-bs-target="#v-pills-home" data-bs-toggle="pill"
                            role="tab" type="button">
                        Contact us
                    </button>

                    <button id="v-pills-profile-tab" aria-controls="v-pills-profile" aria-selected="false"
                            class="nav-link text-dark text-start px-0" data-bs-target="#v-pills-profile"
                            data-bs-toggle="pill" role="tab"
                            type="button">
                        Terms & Conditions
                    </button>

                    <button id="v-pills-messages-tab" aria-controls="v-pills-messages" aria-selected="false"
                            class="nav-link text-dark text-start px-0" data-bs-target="#v-pills-messages"
                            data-bs-toggle="pill" role="tab"
                            type="button">
                        Messages
                    </button>

                    <button id="v-pills-settings-tab" aria-controls="v-pills-settings" aria-selected="false"
                            class="nav-link text-dark text-start px-0" data-bs-target="#v-pills-settings"
                            data-bs-toggle="pill" role="tab"
                            type="button">
                        Settings
                    </button>
                </div>

                <div id="v-pills-tabContent" class="tab-content mt-lg-0 mt-3">
                    <div id="v-pills-home" aria-labelledby="v-pills-home-tab" class="tab-pane fade show active"
                         role="tabpanel" tabindex="0">
                        <p class="mb-0 fw-bold">Contact us</p>

                        <div class="row m-0">
                            <div class="col-md-4 ps-md-0 pe-md-2 px-0">
                                <div class="bg-white shadow-sm p-md-3 p-2 h-100">
                                    <p class="mb-0">
                                        Costumer service
                                    </p>

                                    <p class="mt-2 fs-14 mb-0">
                                        We're available Monday to Friday, 08:00 - 21:00 EST
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-4 px-md-2 px-0 mt-md-0 mt-3">
                                <div class="bg-white shadow-sm p-md-3 p-2 h-100">
                                    <p class="mb-0">
                                        FAQs
                                    </p>

                                    <p class="mt-2 fs-14 mb-0">
                                        Find the answer you need in our FAQs
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-4 pe-md-0 ps-md-2 px-0 mt-md-0 mt-3">
                                <div class="bg-white shadow-sm p-md-3 p-2 h-100">
                                    <p class="mb-0">
                                        We'll be in touch soon
                                    </p>

                                    <p class="mt-2 fs-14 mb-0">
                                        We aim to respond within 24 hours, but during busy periods like holidays, sales
                                        and special campaigns, we may take a little longer getting back to you. Want to
                                        get in touch? Scroll down for more details.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="accordionExample" class="accordion accordion-flush mt-3 accordionStyles">
                            <div class="accordion-item">
                                <h2 id="headingOne" class="accordion-header">
                                    <button aria-controls="collapseOne" aria-expanded="true" class="accordion-button"
                                            data-bs-target="#collapseOne" data-bs-toggle="collapse"
                                            type="button">
                                        How to cancel an order or start a return
                                    </button>
                                </h2>
                                <div id="collapseOne" aria-labelledby="headingOne"
                                     class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by
                                        default, until the collapse plugin adds the appropriate classes that we use to
                                        style each element. These classes control the overall appearance, as well as the
                                        showing and hiding via CSS transitions. You can modify any of this with custom
                                        CSS or overriding our default variables. It's also worth noting that just about
                                        any HTML can go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingTwo" class="accordion-header">
                                    <button aria-controls="collapseTwo" aria-expanded="false"
                                            class="accordion-button collapsed"
                                            data-bs-target="#collapseTwo" data-bs-toggle="collapse"
                                            type="button">
                                        Email our Customer Service team
                                    </button>
                                </h2>
                                <div id="collapseTwo" aria-labelledby="headingTwo" class="accordion-collapse collapse"
                                     data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by
                                        default, until the collapse plugin adds the appropriate classes that we use to
                                        style each element. These classes control the overall appearance, as well as the
                                        showing and hiding via CSS transitions. You can modify any of this with custom
                                        CSS or overriding our default variables. It's also worth noting that just about
                                        any HTML can go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button aria-controls="collapseThree" aria-expanded="false"
                                            class="accordion-button collapsed"
                                            data-bs-target="#collapseThree" data-bs-toggle="collapse"
                                            type="button">
                                        General contacts
                                    </button>
                                </h2>
                                <div id="collapseThree" aria-labelledby="headingThree"
                                     class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by
                                        default, until the collapse plugin adds the appropriate classes that we use to
                                        style each element. These classes control the overall appearance, as well as the
                                        showing and hiding via CSS transitions. You can modify any of this with custom
                                        CSS or overriding our default variables. It's also worth noting that just about
                                        any HTML can go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="v-pills-profile" aria-labelledby="v-pills-profile-tab" class="tab-pane fade"
                         role="tabpanel" tabindex="0">
                        <p class="fw-bold mb-0">
                            Terms & conditions
                        </p>

                        <div class="bg-white p-lg-3 p-2 mt-3">
                            <p>
                                TERMS & CONDITIONS
                            </p>

                            <p>
                                These terms and conditions (together with the information and policies contained in the
                                "Customer Service" pages on the website and any other documents referred in these terms
                                and conditions) ("Terms and Conditions") set out the legal terms that apply to your use
                                of our website http://www.farfetch.com, any of its sub-domains and any other websites
                                operated by us or on our behalf and any mobile device application or desktop application
                                developed by us or on our behalf (together, the "Websites" and “Website” being a
                                reference to any one of them) and the other services that we provide (the "Services").
                            </p>

                            <p>
                                Please read these Terms and Conditions carefully and make sure that you understand them
                                before using the Services. Please note that by using the Services, you agree to be bound
                                by these Terms and Conditions. If you do not accept these Terms and Conditions, you will
                                not be able to use the Services and you should leave the Website immediately. If you
                                continue to use the Website or if you order products, we will take this as your
                                acceptance of these Terms and Conditions.
                            </p>

                            <p>
                                1) Understanding these Terms and Conditions
                            </p>

                            <p>
                                When certain words and phrases are used in these Terms and Conditions, they have
                                specific meanings (these are known as 'defined terms'). You can identify these defined
                                terms because they start with capital letters (even if they are not at the start of a
                                sentence). Where a defined term is used, it has the meaning given to it in the section
                                of the Terms and Conditions where it was defined (you can find these meanings by looking
                                at the sentence where the defined term is included in brackets and speech marks).
                            </p>

                            <p class="mb-0">
                                We have used headings to help you understand these Terms and Conditions and to easily
                                locate information. These Terms and Conditions are only available in the English
                                language. We will not file copies of the contracts between us and you relating to our
                                supply of the Services, or between you and the Partners relating to the sale of the
                                products, so we recommend that you print or save a copy of these Terms and Conditions
                                for your records (but please note that we may amend these Terms and Conditions from time
                                to time so please check the Website regularly, and each time you use the Services to
                                order products, to ensure you understand the legal terms which apply at that time).
                            </p>
                        </div>
                    </div>

                    <div id="v-pills-messages" aria-labelledby="v-pills-messages-tab" class="tab-pane fade"
                         role="tabpanel" tabindex="0">
                        123
                    </div>

                    <div id="v-pills-settings" aria-labelledby="v-pills-settings-tab" class="tab-pane fade"
                         role="tabpanel" tabindex="0">
                        456
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModaPrivacy"
}
</script>

<style scoped>
.privacy .nav-pills .nav-link.active, .privacy .nav-pills .show > .nav-link {
    background: unset;
    color: #000;
    font-weight: 600;
}

@media screen and (min-width: 992px) {
    .me-lg-100 {
        margin-right: 100px;
    }
}
</style>