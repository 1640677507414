<template>
    <div class="row row-cols-lg-5 row-cols-md-3 row-cols-2 m-0">
        <div class="col mb-lg-3 mb-2 px-2 loader__card">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 loader__card">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 loader__card d-none d-md-block">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 loader__card d-none d-lg-block">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>

        <div class="col mb-lg-3 mb-2 px-2 loader__card d-none d-lg-block">
            <div aria-hidden="true" class="card shadow placeholder-glow h-100">
                <span class="placeholder col-12 h-100 rounded"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderMarkAndParts'
}
</script>

<style scoped>
@media screen and (min-width: 1400px) {
    .loader__card {
        height: 194px;
    }
}

@media screen and (max-width: 1399px) {
    .loader__card {
        height: 180px;
    }
}

@media screen and (max-width: 1200px) {
    .loader__card {
        height: 164px;
    }
}

@media screen and (max-width: 991px) {
    .loader__card {
        height: 152px;
    }
}


@media screen and (max-width: 760px) {
    .loader__card {
        height: 131px;
    }
}
</style>