<template>
    <div>
        <div class="container-fluid bg-danger d-none d-lg-block px-0">
            <div class="container d-flex align-items-center justify-content-around py-4">
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    До 3 500 000 &#8381;
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                по двум документам
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    до 7 лет!
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                срок кредита
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    0 &#8381;
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                первый взнос
            </span>
                </p>
                <p class="fs-5 text-white fw-bold text-uppercase text-center mb-0">
                    Без КАСКО!
                    <span class="d-block text-uppercase fs-14 fw-normal lh-1">
                решаете сами
            </span>
                </p>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container p-0 d-flex justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent d-flex align-items-center mb-0 px-lg-0 px-2">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }" class="text-dark fs-sm-14">Главная</router-link>
                        </li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14">Услуги</li>
                        <li aria-current="page" class="breadcrumb-item fs-sm-14 active">Кредит</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid mt-lg-3 mt-2 px-0">
            <div class="container p-lg-3 p-2 shadow-sm bg-white">
                <h1 class="fs-5 fs-sm-16 text-uppercase mb-2 text-lg-start text-center">оформление кредита</h1>
                <div class="row m-0">
                    <div class="col-lg-5 px-0">
                        <div class="row m-0">
                            <div class="col-12 px-0 text-center">
                                <img alt="" class="mt-3 no__car_pic"
                                     height="230"
                                     loading="lazy" src="https://autopark22.ru/img/websites/r-cars/no-car.png">
                            </div>

                            <div class="col-lg-6 px-0 text-center">
                                <img
                                    alt=""
                                    class="w-100 img-fluid" loading="Lazy"
                                    src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg">
                            </div>


                            <div class="col-lg-6 px-0 ps-lg-3">
                                <div class="d-flex flex-column justify-content-between mt-lg-0 mt-3 h-100">
                                    <h1 class="fs-5 text-lg-start text-center mb-0">
                                        <span class="fw-bold me-1">Ford</span>
                                        <span>Focus</span>
                                    </h1>

                                    <div class="opacity-50 text-truncate text-lg-start text-center">
                                    <span class="fw-light fs-14">
                                       2008 г.
                                    </span>

                                        <span
                                            class="fw-light border-start border-end border-secondary text-center fs-14 px-2 mx-2">
                                        137 218 км
                                    </span>

                                        <span class="fw-light fs-14">
                                        II Рестайлинг 1.8 MT (125 л.с.)
                                    </span>
                                    </div>

                                    <div class="mt-lg-5 mt-3 text-lg-start text-center">
                                    <span class="text-danger fs-5 fs-sm-16 fw-bold">
                                        469 000 руб.

                                        <del class="text-dark opacity-50 ms-2 fs-6 fs-sm-14">
                                           350 000 руб.
                                        </del>
                                    </span>
                                    </div>

                                    <p class="fs-14 opacity-50 mt-2 mb-0 text-lg-start text-center">
                                        В кредит от 4 613 руб. / мес.
                                    </p>
                                </div>
                            </div>


                            <div class="col-12 px-0 mt-lg-5 mt-3 d-none d-md-block">
                                <p class="text-danger fw-bold fs-18 fs-sm-16 text-lg-start text-center text-uppercase mb-2">
                                    Пример расчета платежей по Авто Рассрочке
                                </p>
                                <table class="table table-bordered table-hover mb-0">
                                    <tbody>
                                    <tr>
                                        <th>Стоимость автомобиля</th>
                                        <td>1 200 000 &#8381;</td>
                                    </tr>
                                    <tr>
                                        <th>Первоначальный взнос</th>
                                        <td>600 000 &#8381;</td>
                                    </tr>
                                    <tr>
                                        <th>Сумма рассрочки</th>
                                        <td>600 000 &#8381;</td>
                                    </tr>
                                    <tr>
                                        <th>Срок рассрочки</th>
                                        <td>12 месяцев</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 ps-lg-3 px-0 mt-lg-0 mt-3">
                        <div class="row m-0">
                            <div class="col-lg-6 px-lg-2 px-0">
                                <select
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2">
                                    <option value="1">Выберите марку</option>

                                    <option value="2">1</option>
                                </select>
                            </div>

                            <div class="col-lg-6 px-lg-2 px-0 mt-lg-0 mt-3">
                                <select
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2">
                                    <option value="1">Выберите модель</option>

                                    <option value="2">1</option>
                                </select>
                            </div>
                        </div>

                        <div class="row m-0 mt-lg-5 mt-4">
                            <p class="text-uppercase fs-18 fs-sm-16 text-secondary fw-bold mb-2 px-lg-2 px-0">ваши
                                персональные данные</p>
                            <div class="col-lg-6 px-lg-2 px-0">
                                <input
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                                    placeholder="Имя" type="text">
                            </div>

                            <div class="col-lg-6 px-lg-2 px-0 mt-lg-0 mt-3">
                                <input
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                                    placeholder="Адресс" type="text">
                            </div>

                            <div class="col-lg-6 px-lg-2 px-0 mt-3">
                                <input
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                                    placeholder="+7(___)___-__-__" type="tel">
                            </div>

                        </div>

                        <div class="row m-0 mt-lg-5 mt-4">
                            <p class="text-uppercase fs-18 fs-sm-16 text-secondary fw-bold mb-2 px-lg-2 px-0">условия
                                кредита</p>
                            <div class="col-lg-6 px-lg-2 px-0">
                                <select
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2">
                                    <option value="1">Срок кредита (месяцев)</option>

                                    <option value="2">1</option>
                                </select>
                            </div>

                            <div class="col-lg-6 px-lg-2 px-0 mt-lg-0 mt-3">
                                <input
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                                    placeholder="Сумма кредита" type="number">
                            </div>

                            <div class="col-12 px-lg-2 px-0 mt-3">
                                <input
                                    class="form-select form-select-lg bg-light border-0 border-bottom border-danger fs-6 fs-sm-14 py-lg-3 py-2"
                                    placeholder="Первоначальный взнос" type="number">
                            </div>
                        </div>

                        <div class="col-12 px-lg-2 px-0 mt-3">
                            <div class="form-check">
                                <input id="personalInfo" checked
                                       class="form-check-input" type="checkbox">
                                <label class="form-check-label fs-sm-14" for="personalInfo">
                                    Даю согласие на обработку персональных данных
                                </label>
                            </div>

                            <div class="form-check">
                                <input id="citizen" checked
                                       class="form-check-input" type="checkbox">
                                <label class="form-check-label fs-sm-14" for="citizen">
                                    Подтвержданю, что являюсь гражданином РФ
                                </label>
                            </div>

                            <div class="mt-3">
                                <button
                                    class="btn btn-danger text-uppercase fs-18 fs-sm-16 py-lg-3 py-2 col-lg-6 col-12">
                                    Отправить заявку
                                    <i class="fas fa-angle-right fs-18 ms-4"></i>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <PartnersRbAuto/>

        <CallRequestLine/>
    </div>
</template>

<script>
import CallRequestLine from "@/components/websites/rb-auto/parts/popups-and-blocks/CallRequestLine.vue";
import PartnersRbAuto from "@/components/websites/rb-auto/parts/Partners.vue";

export default {
    name: 'CreditRbAuto',
    components: {PartnersRbAuto, CallRequestLine},

    data() {
        return {}
    }
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
    .no__car_pic {
        max-width: 100% !important;
        height: auto !important;
    }
}

</style>