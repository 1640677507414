<template>
    <div>
        <div class="container-fluid px-0 mt-3">
            <div class="container px-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb fs-14 mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{name:'home'}">{{ trans('home') }}</router-link>
                        </li>

                        <li aria-current="page" class="breadcrumb-item active">
                            {{ trans('cart') }}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="container-fluid px-0 my-3">
            <div class="container-fluid px-0">
                <div class="container px-0">

                    <p v-if="shoppingCart.length === 0" class="fs-4 text-center px-0 mt-5 mb-0">
                        {{ trans('cart_empty') }}
                    </p>

                    <div v-else class="row m-0">
                        <div class="col-lg-8 px-lg-2 px-0">
                            <div class="bg-white rounded rounded-sm-0 shadow-sm">
                                <div class="row m-0 fs-13 fw-bold py-2 border-bottom d-none d-lg-flex">
                                    <div class="col-2 px-2" title="სურათი">
                                        {{ trans('image') }}
                                    </div>

                                    <div class="col-3 px-2" title="პროდუქტი">
                                        {{ trans('product') }}
                                    </div>

                                    <div class="col-2 px-2" title="რაოდენობა">
                                        {{ trans('quantity') }}
                                    </div>

                                    <div class="col-2 px-2 text-end" title="ფასი">
                                        {{ trans('price') }}
                                    </div>

                                    <div class="col-1 px-2 text-truncate text-end" title="ფასდაკლება">
                                        {{ trans('sale') }}
                                    </div>

                                    <div class="col-2 px-2 text-center" title="ჯამი">
                                        {{ trans('total') }}
                                    </div>
                                </div>

                                <div v-for="product in shoppingCart" :key="product.id"
                                     class="row m-0 border-bottom py-2 cart__item">
                                    <div class="col-md-2 col-4 px-2 mb-md-0 mb-2">
                                        <router-link :to="{
                                                 name: 'view-product',
                                                 params: {
                                                        id: product.id}
                                                 }">
                                            <img v-if="product.image" :src="product.image" alt="" height="75"
                                                 width="100">

                                            <img v-else :src="NoImage" alt="" height="75" width="100">
                                        </router-link>

                                    </div>


                                    <div class="col-md-3 col-8 px-2 d-flex align-items-center mb-md-0 mb-2">
                                        <router-link :to="{
                                                 name: 'view-product',
                                                 params: {
                                                        id: product.id}
                                                 }"
                                                     class="text-dark link">
                                            {{ product.name }}

                                        </router-link>

                                    </div>

                                    <div class="col-md-2 col-3 px-2 d-flex align-items-center">
                                        <div class="d-flex align-items-center border bg-white rounded"
                                             style="width: max-content;">
                                            <div>
                                                <button class="btn btn-sm border-0" type="button"
                                                        @click="decreaseItemCount(product)">
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                            </div>
                                            <div>
                                                <input
                                                    :value="product.quantity"
                                                    class="form-control text-center p-2 border-start border-end border-0 rounded-0"
                                                    style="width: 35px; height: 30px;"
                                                    type="tel">
                                            </div>
                                            <div>
                                                <button class="btn btn-sm border-0" type="button"
                                                        @click="increaseItemCount(product)">
                                                    <i class="fa-solid fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2 col-3 px-2 d-flex align-items-center justify-content-end">
                                        {{ product.price }} ₾
                                    </div>

                                    <div class="col-md-1 col-2 px-2 d-flex align-items-center justify-content-end">

                                    </div>

                                    <div class="col-md-2 col-4 px-2 d-flex align-items-center justify-content-end">
                                        <span class="me-3">{{ product.price * product.quantity }} ₾</span>
                                        <button :title="trans('remove_from_cart')" class="btn btn-sm btn-outline-danger"
                                                @click="removeFromCart(product)">
                                            <i class="fa-solid fa-xmark"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 px-lg-2 px-0 mt-lg-0 mt-3">
                            <div class="bg-white rounded rounded-sm-0 shadow-sm p-3">
                                <h1 class="fs-3 text-center mb-0">{{ trans('send_order') }}</h1>

                                <div class="form-floating mt-3">
                                    <input id="cart_name" class="form-control" placeholder="სახელი" type="text">
                                    <label class="text-secondary" for="cart_name">{{ trans('first_name') }}</label>
                                </div>


                                <div class="form-floating mt-3">
                                    <input id="cart_tel" class="form-control" placeholder="ტელეფონი" type="number">
                                    <label class="text-secondary" for="cart_tel">{{ trans('phone') }}</label>
                                </div>

                                <div class="form-floating mt-3">
                                    <textarea id="cart_comment" :placeholder="trans('message')"
                                              class="form-control"></textarea>
                                    <label class="text-secondary" for="cart_comment">{{ trans('message') }}</label>
                                </div>

                                <div class="mt-3">
                                    <button class="btn btn-lg btn-warning w-100" type="button">
                                        {{ trans('send') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoImage from "@/assets/websites/get-parts/img/no-image.png"
import {t} from "@/lang/core/helpers";
import {mapGetters} from "vuex";

export default {
    name: 'CartGP',

    data() {
        return {
            NoImage
        }
    },

    methods: {
        trans(key) {
            return t(key)
        },

        removeFromCart(product) {
            this.$store.dispatch('removeFromCart', product)
        },

        increaseItemCount(product) {
            this.$store.dispatch('increaseItemCount', product)
        },

        decreaseItemCount(product) {
            this.$store.dispatch('decreaseItemCount', product)
        },
    },

    computed: {
        ...mapGetters(['shoppingCart'])
    },
}
</script>

<style scoped>
.cart__item:last-child {
    border-bottom: unset !important;
}

.cart__item {
    transition: .3s;
}

.cart__item:hover {
    background-color: #f1f1f1;
}

textarea {
    min-height: 110px !important;
}

</style>
