<template>
    <div>
        <div class="bg-white">
            <h1 class="fs-3 fs-sm-16 text-truncate text-truncate bg-light p-lg-3 p-1 py-2 mb-lg-0">
                <span>Ford</span>
                <span class="text-secondary mx-1">Focus</span>
                <span class="fs-6 text-secondary">1.8 см³ 125 л.с</span>
            </h1>

            <div class="d-flex flex-md-row flex-column justify-content-between p-lg-3 p-2 mt-lg-0 mt-2">
                <div class="d-flex flex-md-column flex-row justify-content-between">
                    <p class="fs-5 fs-sm-16 opacity-75 text-nowrap mb-0">
                        Специальная цена
                    </p>


                    <p class="fs-4 fs-sm-16 mb-0 d-flex align-items-md-end flex-md-row flex-column">
                    <span class="fs-5">
                        350 000 руб.
                    </span>

                        <del class="fs-14 ms-3 opacity-50 text-md-start text-end">
                            469 000 руб.
                        </del>
                    </p>
                </div>

                <div class="d-flex flex-md-column flex-row mt-lg-0 mt-2">
                    <div class="col-md-12 col-6 p-0">
                        <router-link :to="{ name: 'credit' }" class="btn btn-danger w-100 fs-sm-14">
                            Купить в кредит
                        </router-link>
                    </div>
                    <div class="col-md-12 col-6 p-0 mt-md-2">
                        <button class="btn btn-outline-danger border-danger text-danger w-100 fs-sm-14"
                                data-bs-target="#tradeinmodal" data-bs-toggle="modal">
                            Trade In
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-none d-md-block">
            <div class="row row-cols-2 mt-lg-3">
                <div class="col">
                    <div class="bg-white py-2">
                        <div class="row align-items-center">
                            <div class="col-4 border-end text-center">
                                <img :src="DvigatelIcon" alt="" class="img-fluid" height="41" loading="lazy" width="41">
                            </div>
                            <div class="col-8 text-center">
                                <span>Бензин инжектор </span>
                                <span class="mt-2">1.8 см³ 125 л.с.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="bg-white py-2">
                        <div class="row align-items-center">
                            <div class="col-4 border-end text-center">
                                <img :src="KuzovIcon" alt="" class="img-fluid" height="48" loading="lazy" width="48">
                            </div>
                            <div class="col-8 text-center">
                                Седан
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mt-3">
                    <div class="bg-white py-2">
                        <div class="row align-items-center">
                            <div class="col-4 border-end text-center">
                                <img :src="PrivodIcon" alt="" class="img-fluid" height="41" loading="lazy" width="41">
                            </div>
                            <div class="col-8 text-center">
                                Передний
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col mt-3">
                    <div class="bg-white py-2">
                        <div class="row align-items-center">
                            <div class="col-4 border-end text-center">
                                <img :src="KorobkaIcon" alt="" class="img-fluid" height="41" loading="lazy" width="41">
                            </div>
                            <div class="col-8 text-center">
                                Механическая
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DvigatelIcon from "@/assets/websites/rb-auto/img/dvigatel1-icon.png"
import KuzovIcon from "@//assets/websites/rb-auto/img/kuzov1-icon.png"
import PrivodIcon from "@//assets/websites/rb-auto/img/privod1-icon.png"
import KorobkaIcon from "@//assets/websites/rb-auto/img/korobka1-icon.png"

export default {
    name: 'CarInfo',

    data() {
        return {
            DvigatelIcon,
            KuzovIcon,
            PrivodIcon,
            KorobkaIcon
        }
    }
}
</script>

<style scoped>

</style>