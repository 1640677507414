<template>
    <div id="creditmodal" aria-hidden="true" aria-labelledby="creditmodal" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-white">
                <div class="modal-header border-bottom-0 shadow-sm" style="background: #f1f1f1;">
                    <h5 id="exampleModalLabel" class="modal-title fs-14 text-uppercase">Выбранный автомобиль</h5>
                    <button aria-label="Close" class="btn-close pt-0" data-bs-dismiss="modal" type="button">&#10006;
                    </button>
                </div>

                <div class="modal-body p-3">
                    <div class="row m-0 pb-3 p-0 border-bottom">
                        <div class="col-lg-4 p-0 text-center">
                            <img alt=""
                                 class="w-100 h-100 img-fluid" loading="lazy"
                                 src="https://img.mysilver.ge/autopark77/car/medium/P70JemHegRaB6xjKzrh2a6qPFNO7JNs6zS0ndo4u.jpg">
                        </div>
                        <div class="col-lg-8 mt-lg-0 mt-3 ps-lg-3 ps-0">
                            <div class="d-flex flex-column justify-content-between">
                                <div>
                                    <p class="fs-4 fs-sm-18">
                                        Ford
                                        <span class="text-secondary">Santa Fe</span>
                                    </p>
                                    <div class="opacity-50 text-truncate">

                                        <span class="fw-light fs-14">
                                            2008 г.
                                        </span>

                                        <span
                                            class="fw-light border-start border-end border-secondary text-center ps-2 pe-1 ms-1 me-2 fs-14">
                                            137 218 км
                                        </span>

                                        <span class="fw-light fs-14">
                                           II Рестайлинг 1.8 MT (125 л.с.)
                                        </span>
                                    </div>
                                </div>

                                <span class="opacity-75">в кредит от 4 613 ₽ / мес.</span>
                            </div>
                        </div>
                    </div>

                    <div class="row px-0 p-0 m-0 pt-3 bp-3">
                        <div class="input-form position-relative p-0">
                            <label class="input-title" for="name">Имя</label>
                            <input id="name" class="form-control position-relative" type="text">
                        </div>

                        <div class="input-form position-relative p-0 mt-3">
                            <label class="input-title" for="number">Телефон</label>
                            <input id="number" class="form-control position-relative" placeholder="+7 (___) ___-__-__"
                                   type="text">
                        </div>

                        <div class="form-check mt-3">
                            <input id="flexCheckChecked" checked class="form-check-input" type="checkbox" value="">
                            <label class="form-check-label fs-14 fs-sm-12" for="flexCheckChecked">
                                Даю согласие на обработку персональных данных
                            </label>
                        </div>
                        <div class="btn btn-danger text-light w-100 text-uppercase mt-lg-3 mt-2">
                            Отправить заявку
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreditRequestPopup',

    data() {
        return {}
    }
}

</script>

<style scoped>

</style>