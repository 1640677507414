<template>
    <div class="container-fluid px-0 mt-lg-5 mt-3">

        <ModaSizesGuidePopup/>

        <ModaSizesGuideClothPopup/>

        <ModaZoomImage/>

        <ModaReviewsCanvas/>

        <ModaShopSimilar/>

        <ModaReviewsPicsPopup/>

        <div class="container px-lg-0">
            <div class="d-flex align-items-lg-center justify-content-between">
                <h1 class="fw-bold fs-sm-22 mb-0">
                    Premiata
                    <span class="fw-light text-capitalize">golden goose</span>
                </h1>

                <div class="dropdown share" style="z-index: 1001;">
                    <button aria-expanded="false"
                            class="btn btn-dark position-relative share__button dropdown-toggle"
                            data-bs-toggle="dropdown"
                            type="button">
                        <i class="fa-solid fa-share fs-12"></i>
                        <span class="toolkit share_toolkit d-none d-lg-block">Share</span>
                    </button>

                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item d-flex align-items-center gap-2"
                               href="#">
                                <img :src="Facebook" alt="Facebook" title="Facebook" width="20">
                                <span>Facebook</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                <img :src="Instagram" alt="Instagram" title="Instagram" width="20">
                                <span>Instagram</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#">
                                <img :src="Twiter" alt="Twiter" title="Twiter" width="20">
                                <span>Twitter</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Telegram" alt="Telegram" title="Telegram" width="20">
                                <span>Telegram</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Whatsapp" alt="Whatsapp" title="Whatsapp" width="20">
                                <span>WhatsApp</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <img :src="Viber" alt="Viber" title="Viber" width="20">
                                <span>Viber</span>
                            </a>
                        </li>

                        <li class="mt-2">
                            <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                               target="_blank">
                                <i class="fa fa-link"></i>
                                <span>Copy link</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <nav aria-label="breadcrumb"
                 class="col-lg-auto my-2 overflow-auto">
                <ol class="breadcrumb d-flex flex-nowrap align-items-center bg-transparent">
                    <li class="breadcrumb-item d-flex align-items-center gap-2">
                        <button class="btn btn-dark p-0 d-flex align-items-center justify-content-center"
                                style="width: 20px; height: 20px;"
                                title="Go back" type="button" @click="$router.go(-1)">
                            <i class="fa-solid fa-chevron-left fs-14"></i>
                        </button>

                        <router-link :to="{ name: 'home' }" class="text-dark fs-14 text-capitalize text-nowrap">
                            Home
                        </router-link>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a aria-expanded="false"
                           class="text-dark fs-14 text-capitalize text-nowrap dropdown-toggle" data-bs-toggle="dropdown"
                           href="#">
                            Shoes
                        </a>

                        <ul class="dropdown-menu" style="z-index: 1003;">
                            <li>
                                <a class="dropdown-item" href="#">20% Off</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">New in</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shop by</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Brands</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Clothing</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shoes</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Bags</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Accessories</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Jewelry</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Pre-owned</a>
                            </li>
                        </ul>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a aria-expanded="false"
                           class="text-dark fs-14 text-capitalize text-nowrap dropdown-toggle" data-bs-toggle="dropdown"
                           href="#">
                            Margiella
                        </a>

                        <ul class="dropdown-menu" style="z-index: 1003;">
                            <li>
                                <a class="dropdown-item" href="#">20% Off</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">New in</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shop by</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Brands</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Clothing</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Shoes</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Bags</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Accessories</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Jewelry</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">Pre-owned</a>
                            </li>
                        </ul>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a class="text-dark fs-14 text-capitalize text-nowrap"
                           href="#">
                            Premiata
                        </a>
                    </li>

                    <li class="breadcrumb-item d-flex align-items-center active">
                        <a class="text-dark fs-14 text-capitalize text-nowrap"
                           href="#">
                            12-24547-65
                        </a>
                    </li>
                </ol>
            </nav>
        </div>

        <div class="container px-lg-0 mt-lg-5 mt-3">
            <div class="row m-0">
                <div class="col-lg-6 ps-lg-0 pe-lg-3 px-0" style="height: max-content;">
                    <ViewProductImagesSlide/>
                </div>

                <div class="col-lg-6 ps-lg-3 pe-lg-0 px-0 mt-lg-0 mt-3 product-info sticky-lg-top">
                    <ModaProductDescription/>
                </div>

                <div class="col-lg-6 ps-lg-0 pe-lg-3 px-0 mt-lg-5 mt-3">
                    <ViewProductCharacteristics/>
                </div>

            </div>
        </div>


        <div class="container px-0 mt-lg-5 mt-3">
            <h2 class="fw-semibold mb-0 px-3">
                You may like
            </h2>

            <ModaOffers/>
        </div>

        <div class="container-fluid bg-light py-5">
            <div class="container px-lg-0">
                <div class="row m-0">
                    <div class="col-lg-2 px-0">
                        <div class="text-lg-start text-center">
                            <img :src="Fila" alt="" class="opacity-50 w-50">
                        </div>

                        <ul class="list-style-none ps-0 mt-lg-5 mt-3 mb-0">
                            <li class="mt-3">
                                <a class="d-block text-secondary fw-light text-decoration-none" href="#">
                                    Women shoes <span class="text-danger fs-14">(104)</span>
                                </a>
                            </li>

                            <li class="mt-3">
                                <a class="d-block text-secondary fw-light text-decoration-none" href="#">
                                    Man shoes <span class="text-danger fs-14">(104)</span>
                                </a>
                            </li>

                            <li class="mt-3">
                                <a class="d-block text-secondary fw-light text-decoration-none" href="#">
                                    Child shoes <span class="text-danger fs-14">(104)</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-10 ps-lg-3 pe-lg-0 px-0 mt-lg-0 mt-3">
                        <p class="fs-18">
                            FILA <a href="https://www.fila.com/" class="fs-15 ms-1 fw-light">www.fila.com</a>
                        </p>

                        <p class="fs-15 text-secondary fw-light">
                            Fila is a world famous Italian sportswear and footwear brand with over a century of history.
                            The
                            company made history through collaborations with international sports stars such as Bjorn
                            Borg
                            and Reinhold Messner.
                        </p>

                        <p class="fs-15 text-secondary fw-light mb-0">
                            Active advertising in the 90s made Fila branded products a real heritage of street fashion.
                            Today, in their collections, designers use iconic models of the brand, created over 100
                            years.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid px-0">
            <img :src="BgPic3" alt="" class="w-100 img-fluid">
        </div>

        <div class="container px-lg-0 mt-lg-5 mt-3">
            <div class="d-flex align-items-center justify-content-lg-start justify-content-between gap-2">
                <p aria-controls="reviewsCanvas" class="fs-18 fw-bold text-uppercase mb-0"
                   data-bs-target="#reviewsCanvas"
                   data-bs-toggle="offcanvas">
                    reviews (25)
                </p>

                <div
                    class="d-flex align-items-center justify-content-lg-start justify-content-between gap-1 flex-grow-1 sm-reverse">
                    <div
                        aria-controls="reviewsCanvas"
                        class="d-flex align-items-center justify-content-lg-start justify-content-end flex-grow-1 gap-1 fs-15"
                        data-bs-target="#reviewsCanvas" data-bs-toggle="offcanvas">
                        <i class="fa-solid fa-star text-warning"></i>
                        <span class="lh-1">4.8</span>
                        <i class="fa-solid fa-chevron-right d-lg-none fs-12"></i>
                    </div>

                    <button aria-controls="writeReviews" aria-expanded="false"
                            class="btn btn-sm btn-dark ms-lg-auto text-uppercase write-reviews-btn"
                            data-bs-target="#writeReviews" data-bs-toggle="collapse"
                            type="button">
                        <i class="fa-solid fa-plus d-lg-none"></i>
                        <span class="d-none d-lg-block">Add review</span>
                    </button>
                </div>

            </div>

            <div id="writeReviews" class="collapse mt-3">
                <div class="card card-body border-dark rounded-0">
                    <textarea class="form-control border-0 p-0" rows="3"></textarea>
                </div>

                <button class="btn btn-outline-dark btn-sm text-uppercase mt-3" type="submit">
                    leave a comment
                </button>
            </div>

            <div class="d-none d-lg-block mt-3">
                <ModaReviewsContent/>
            </div>

        </div>
    </div>

</template>

<script>
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Twiter from "@/assets/websites/allmenu/img/twiter-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import BgPic3 from "@/assets/websites/moda/img/banner2.png"
import Fila from "@/assets/websites/moda/img/fila.svg"
import ModaOffers from "@/components/websites/moda/parts/view-product/Offers.vue";
import ModaSizesGuidePopup from "@/components/websites/moda/parts/dialogs-and-offcanvas/SizesGuidePopup.vue";
import ModaZoomImage from "@/components/websites/moda/parts/dialogs-and-offcanvas/ZoomImage.vue";
import ModaReviewsContent from "@/components/websites/moda/parts/view-product/ReviewsContent.vue";
import ModaReviewsCanvas from "@/components/websites/moda/parts/view-product/ReviewsCanvas.vue";
import ModaShopSimilar from "@/components/websites/moda/parts/view-product/ShopSimilar.vue";
import ModaReviewsPicsPopup from "@/components/websites/moda/parts/view-product/ReviewsPicsPopup.vue";
import ModaSizesGuideClothPopup from "@/components/websites/moda/parts/dialogs-and-offcanvas/SizesGuideClothPopup.vue";
import ModaProductDescription from "@/components/websites/moda/parts/view-product/ProductDescription.vue";
import ViewProductImagesSlide from "@/components/websites/moda/parts/view-product/ViewProductImagesSlide.vue";
import ViewProductCharacteristics from "@/components/websites/moda/parts/view-product/ViewProductCharacteristics.vue";


export default {
    name: "ModaViewProduct",

    components: {
        ViewProductCharacteristics,
        ViewProductImagesSlide,
        ModaProductDescription,
        ModaSizesGuideClothPopup,
        ModaReviewsPicsPopup,
        ModaShopSimilar,
        ModaReviewsCanvas,
        ModaReviewsContent,
        ModaZoomImage,
        ModaSizesGuidePopup,
        ModaOffers,
    },

    data() {
        return {
            Facebook,
            Instagram,
            Telegram,
            Twiter,
            Whatsapp,
            Viber,
            Messenger,
            BgPic3,
            Fila
        }
    }
}
</script>

<style scoped>
.share__button {
    background-color: rgba(1, 1, 1, 0.35) !important;
    border-color: transparent !important;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    transition: background-color .3s;
}

.share_toolkit {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #a6a6a6;
    border-radius: 30px;
    padding: 3px 14px;
    font-size: 14px;
    transition: background-color .3s;
}

.share_toolkit::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #a6a6a6;
    top: -3px;
    left: 50%;
    transform: translateX(-50%) rotatez(45deg);
}

.share__button:hover,
.share__button:hover .share_toolkit,
.share__button:hover .share_toolkit::after {
    background-color: #000 !important;
}

.product-info {
    top: 130px !important;
    z-index: 1000 !important;
    height: max-content;
}

.dropdown-toggle::after {
    display: none;
}

@media screen and (max-width: 991px) {
    .sm-reverse {
        flex-direction: row-reverse;
    }

    .write-reviews-btn {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>