import {HttpMethod} from "@/core/enums"
import {localStorageGet} from "@/core/localStorage"

export default class ApiRequest {
    constructor(url, body, resolve = null, reject = null, method = HttpMethod.POST, headers = {'Content-Type': 'application/json'}) {
        this.baseUrl = process.env.VUE_APP_API_URL

        if (headers['Content-Language'] === undefined) {
            headers['Content-Language'] = localStorageGet('locale') || 'ka'
        }

        this.url = this.baseUrl ? this.baseUrl + url : url
        this.method = method
        this.headers = headers
        this.body = body
        this.resolve = resolve !== null ? resolve : data => {
            if (!data.success) {
                // window.toastr.error(data.message || 'Something went wrong')
            }
        }
        this.reject = reject !== null ? reject : () => {
        }

        this.sendRequest()
    }

    static get inProgress() {
        return ApiRequest.progressCount > 0
    }

    static get inProgressCount() {
        return ApiRequest.progressCount
    }

    static set inProgressCount(value) {
        ApiRequest.progressCount = value
        ApiRequest.inProgressCountChange()
    }

    increaseProgressCount() {
        if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
            ApiRequest.inProgressCount++
        }
    }

    decreaseProgressCount() {
        if (!ApiRequest.hiddenRequestsForProgressCount.includes(this.url)) {
            ApiRequest.inProgressCount--
        }
    }

    sendRequest() {
        this.increaseProgressCount()
        fetch(this.url, {
            method: this.method,
            headers: this.headers,
            body: JSON.stringify(this.body),
        }).then(response => response.json().then(data => {
            this.resolve(data)
        })).catch(error => {
            console.error(error)
            this.reject(error)
        }).finally(() => {
            this.decreaseProgressCount()

            if (ApiRequest.requestQueue.length) {
                let request = ApiRequest.requestQueue.shift()
                request.sendRequest()
            }
        })
    }
}

ApiRequest.progressCount = 0
ApiRequest.inProgressCountChange = () => {
}
ApiRequest.hiddenRequestsForProgressCount = []
ApiRequest.hiddenRequestsForPinTokenValidation = []
ApiRequest.requestQueue = []