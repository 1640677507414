<template>
    <div>
        <carousel :items="5"
                  :margin="16"
                  :responsive="{250:{items:1}, 670:{items:3}, 992:{items:5}}"
                  class="mt-lg-5 mt-3">
            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/7CbYv1sCWuSDPCSBqQRJWQwa6BY7bQt25oY1X2Sj.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/OP6dYRkJn0ENyqGkXlQdRfnrtMizmycbq0XH0wmv.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/P1ugssD2pTcKCaiEW9ja4yn1tOtCNXi0Qamb0Hqy.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name:'view-product' }"
                             class="d-block text-dark text-decoration-none">
                    <div class="offers-product">
                        <div>
                            <img
                                alt=""
                                class="w-100 rounded" loading="lazy"
                                src="https://img.mysilver.ge/TEST/product/medium/84x1mknLA2t3eboMYZMhWEojZ3iYYAVsOKBko6fF.jpg">
                        </div>

                        <div class="mt-2">
                            <p class="text-lg-start text-center text-uppercase mb-0 fs-14">adidas SNEAKERS Ultra 4D</p>

                            <div
                                class="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 mt-lg-3 mt-2">
                                <span class="fw-bold">283 $</span>
                                <span class="del-price">360 $</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

        </carousel>
    </div>

</template>

<script>
import Product1 from "@/assets/websites/moda/img/product1.png"
import Product2 from "@/assets/websites/moda/img/product2.png"
import Product3 from "@/assets/websites/moda/img/product3.png"
import OffersProduct1 from "@/assets/websites/moda/img/offers-product1.png"
import OffersProduct2 from "@/assets/websites/moda/img/offers-product2.png"
import OffersProduct3 from "@/assets/websites/moda/img/offers-product3.png"
import carousel from "vue-owl-carousel"

export default {
    name: "ModaOffers",

    components: {
        carousel
    },

    data() {
        return {
            Product1,
            Product2,
            Product3,
            OffersProduct1,
            OffersProduct2,
            OffersProduct3,
        }
    },

}
</script>

<style>
.del-price {
    position: relative;
    width: max-content;
}

.del-price::after {
    content: "";
    width: 100%;
    background: #E86363;
    top: 48%;
    transform: translateY(-50%) rotateZ(6deg);
    height: 2px;
    position: absolute;
    left: 0;
}

.owl-theme .owl-nav {
    margin-top: 0 !important;
}

.owl-prev, .owl-next {
    position: relative;
    background: unset !important;
}

.owl-prev::after, .owl-next::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D6D6D6;
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-weight: 900;
    border-radius: 50%;
    transition: .3s;
}

.owl-prev::after {
    content: '\f053';
}

.owl-next::after {
    content: '\f054';
}

.owl-prev:hover::after, .owl-next:hover::after {
    background: #869791 !important;
}

.owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    opacity: 0;
    transition: .3s;

}

.owl-carousel:hover .owl-nav {
    opacity: 1;
}

@media screen and (max-width: 992px) {
    .owl-prev::after, .owl-next::after {
        width: 40px;
        height: 40px;
    }

    .owl-nav {
        opacity: 1;
    }
}
</style>