<template>
    <div>
        <nav class="sign-in-tab">
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
                <button id="nav-home-tab" aria-controls="nav-home"
                        aria-selected="true" class="nav-link text-uppercase text-dark border-0 ps-0 active"
                        data-bs-target="#nav-home" data-bs-toggle="tab"
                        role="tab" type="button">
                    Sign in
                </button>

                <button id="nav-register-tab" aria-controls="nav-register" aria-selected="false"
                        class="nav-link text-uppercase text-dark border-0" data-bs-target="#nav-register"
                        data-bs-toggle="tab" role="tab"
                        type="button">
                    I'm new here
                </button>
            </div>
        </nav>

        <div id="nav-tabContent" class="tab-content mt-3">
            <div id="nav-home" aria-labelledby="nav-home-tab" class="tab-pane fade show active" role="tabpanel"
                 tabindex="0">
                <form>
                    <div>
                        <label class="form-label" for="email">Email address</label>
                        <input id="email" autocomplete="off" class="form-control border-dark rounded-0" type="email">
                    </div>

                    <div class="mt-2">
                        <label class="form-label" for="password">Password</label>
                        <input id="password" autocomplete="off" class="form-control border-dark rounded-0"
                               type="password">
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-3">
                        <div class="form-check mb-0">
                            <input id="saved" checked class="form-check-input" type="checkbox" value="">
                            <label class="form-check-label fs-14" for="saved">
                                Keep me signed in
                            </label>
                        </div>

                        <button class="btn p-0 border-0 text-dark fs-14" data-bs-target="#recoveryPassword"
                                data-bs-toggle="modal"
                                type="button">Forgot your password?
                        </button>
                    </div>

                    <div class="mt-3">
                        <button class="btn btn-dark w-100" type="submit">
                            Sign in
                        </button>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-3 gap-3">
                        <span>OR CONTINUE WITH</span>

                        <ModaContinueWith/>
                    </div>

                </form>
            </div>

            <div id="nav-register" aria-labelledby="nav-register-tab" class="tab-pane fade" role="tabpanel"
                 tabindex="0">
                <form>
                    <div>
                        <label class="form-label" for="name">Name</label>
                        <input id="name" autocomplete="off" class="form-control border-dark rounded-0" type="text">
                    </div>

                    <div class="mt-2">
                        <label class="form-label" for="email-reg">Email address</label>
                        <input id="email-reg" autocomplete="off" class="form-control border-dark rounded-0"
                               type="email">
                    </div>

                    <div class="mt-2">
                        <label class="form-label" for="password-reg">Password</label>
                        <input id="password-reg" autocomplete="off" class="form-control border-dark rounded-0"
                               type="password">
                    </div>

                    <div class="mt-2">
                        <label class="form-label" for="repeat-password-reg">Repeat password</label>
                        <input id="repeat-password-reg" autocomplete="off" class="form-control border-dark rounded-0"
                               type="password">
                    </div>

                    <div class="form-check mt-3 mb-0">
                        <input id="saved-reg" checked class="form-check-input" type="checkbox" value="">
                        <label class="form-check-label fs-14" for="saved-reg">
                            By registering, you agree with our Terms & Conditions and Privacy and Cookie Policy.
                        </label>
                    </div>

                    <div class="mt-3">
                        <button class="btn btn-dark w-100" type="submit">
                            Register
                        </button>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-3 gap-3">
                        <span>OR CONTINUE WITH</span>

                        <ModaContinueWith/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ModaContinueWith from "@/components/websites/moda/parts/dialogs-and-offcanvas/ContinueWith.vue";

export default {
    name: "ModaSignInPopupBody",
    components: {ModaContinueWith}
}
</script>

<style scoped>
.sign-in-tab .nav-tabs .nav-link.active, .sign-in-tab .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #000 !important;
    color: #000;
}

@media screen and (min-width: 992px) {
    #profileModal .modal-dialog {
        max-width: 400px !important;
    }
}
</style>