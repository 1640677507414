<template>
	<div class="shadow bg-white rounded sticky-lg-top p-3" style="top: 12px">
		<div>
			<select
				v-model="filter.category"
				:disabled="
					!carPartsGeneratedCategories || !carPartsGeneratedCategories.length
				"
				class="form-select"
			>
				<option :value="null">{{ trans("category") }}</option>
				<option
					v-for="category in carPartsGeneratedCategories"
					:key="category.id"
					:value="category.id"
				>
					{{ category.name }}
				</option>
			</select>
		</div>

		<div class="mt-lg-4 mt-3">
			<select v-model="filter.mark" class="form-select" @change="loadModels">
				<option :value="null">{{ trans("manufacturer") }}</option>
				<option v-for="mark in carPartsMarks" :key="mark.id" :value="mark.id">
					{{ mark.name }}
				</option>
			</select>
		</div>

		<div class="mt-lg-4 mt-3">
			<select
				v-model="filter.model"
				:disabled="!filter.mark"
				class="form-select"
				@change="loadYears"
			>
				<option :value="null">{{ trans("model") }}</option>
				<option v-for="model in models" :key="model.id" :value="model.id">
					{{ model.name }}
				</option>
			</select>
		</div>

		<div class="mt-lg-4 mt-3">
			<select
				v-model="filter.year"
				:disabled="!filter.mark || !filter.model"
				class="form-select"
			>
				<option :value="null">{{ trans("year") }}</option>
				<option v-for="year in years" :key="year" :value="year">
					{{ year }}
				</option>
			</select>
		</div>

		<div class="mt-lg-4 mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<label class="form-label d-block mb-0" for="min__price">{{
					trans("price")
				}}</label>

				<div class="btn-group">
					<input
						id="lari"
						v-model="filter.currency"
						autocomplete="off"
						checked
						class="btn-check"
						name="currency"
						type="radio"
						value="GEL"
					/>
					<label class="btn btn-sm btn-outline-warning" for="lari">₾</label>

					<input
						id="dollar"
						v-model="filter.currency"
						autocomplete="off"
						class="btn-check"
						name="currency"
						type="radio"
						value="USD"
					/>
					<label class="btn btn-sm btn-outline-warning" for="dollar">$</label>
				</div>
			</div>

			<div class="input-group mt-2">
				<input
					id="min__price"
					v-model="filter.minPrice"
					:placeholder="trans('from')"
					class="form-control"
					type="number"
				/>
				<span class="input-group-text">-</span>
				<input
					id="max__price"
					v-model="filter.maxPrice"
					:placeholder="trans('to')"
					class="form-control"
					type="number"
				/>
			</div>
		</div>

		<div class="mt-lg-4 mt-3">
			<label class="form-label d-block" for="condition">{{
				trans("condition")
			}}</label>

			<div class="btn-group gap-3 flex-wrap">
				<input
					id="all"
					autocomplete="off"
					checked
					class="btn-check"
					name="condition"
					type="radio"
					v-model="filter.condition"
				/>
				<label
					class="btn btn-sm btn-outline-warning text-dark rounded"
					for="all"
					>{{ trans("all") }}</label
				>

				<input
					id="new"
					value="NEW"
					autocomplete="off"
					class="btn-check"
					name="condition"
					type="radio"
					v-model="filter.condition"
				/>
				<label
					class="btn btn-sm btn-outline-warning text-dark rounded"
					for="new"
					>{{ trans("new") }}</label
				>

				<input
					id="used"
					value="SECONDARY"
					autocomplete="off"
					class="btn-check"
					name="condition"
					type="radio"
					v-model="filter.condition"
				/>
				<label
					class="btn btn-sm btn-outline-warning text-dark rounded"
					for="used"
					>{{ trans("secondary") }}</label
				>
			</div>
		</div>

		<div class="d-flex gap-3 justify-content-between mt-lg-4 mt-3">
			<button
				:title="trans('find')"
				class="btn btn-warning flex-grow-1"
				type="button"
				@click="filterCarParts"
			>
				<i class="fa-solid fa-magnifying-glass"></i>
				{{ trans("find") }}
			</button>

			<button
				:title="trans('clear')"
				class="btn btn-outline-dark"
				type="button"
				@click="resetFilters"
			>
				<i class="fa-solid fa-trash"></i>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiRequest from "@/http/ApiRequest";
import { t } from "@/lang/core/helpers";

export default {
	name: "FilterGP",

	data() {
		return {
			filter: {
				category: null,
				mark: null,
				model: null,
				year: null,
				minPrice: null,
				maxPrice: null,
				city: null,
				condition: null,
				currency: "GEL",
			},
			models: [],
			years: [],
			companyKey: "get-parts",
		};
	},

	computed: {
		...mapGetters(["carPartsGeneratedCategories", "carPartsMarks"]),
	},

	methods: {
		trans(key) {
			return t(key);
		},

		loadModels() {
			this.filter.model = null;
			this.filter.year = null;
			this.models = [];

			new ApiRequest(
				"car-parts/models",
				{
					key: this.companyKey,
					mark: this.filter.mark,
				},
				(data) => {
					if (!data.success) {
						alert(
							data.message ||
								"Something went wrong while getting company categories"
						);
						return;
					}

					this.models = data.data;
				}
			);
		},

		loadYears() {
			this.filter.year = null;
			this.years = [];

			new ApiRequest(
				"car-parts/years",
				{
					key: this.companyKey,
					mark: this.filter.mark,
					model: this.filter.model,
				},
				(data) => {
					if (!data.success) {
						alert(
							data.message ||
								"Something went wrong while getting company categories"
						);
						return;
					}

					this.years = data.data;
				}
			);
		},

		filterCarParts() {
			this.$store.commit("setCarPartPromotionCarPartsLoaded", false);

			new ApiRequest(
				"car-parts/filter",
				{
					key: this.companyKey,
					filter: this.filter,
				},
				(data) => {
					if (!data.success) {
						alert(
							data.message || "Something went wrong while filtering car parts"
						);
						return;
					}

					this.$store.commit("setCarPartPromotionCarPartsLoaded", true);
					this.$store.commit("setCarPartsPromotionCarParts", data.data);
				}
			);
		},

		getPromotionCarParts() {
			this.$store.commit("setCarPartPromotionCarPartsLoaded", false);

			new ApiRequest(
				"car-parts/promotions",
				{
					key: this.companyKey,
				},
				(data) => {
					if (!data.success) {
						alert(
							data.message ||
								"Something went wrong while getting promotion car parts"
						);
						return;
					}

					this.$store.commit("setCarPartPromotionCarPartsLoaded", true);
					this.$store.commit("setCarPartsPromotionCarParts", data.data);
				}
			);
		},

		resetFilters() {
			this.filter = {
				category: null,
				mark: null,
				model: null,
				year: null,
				minPrice: null,
				maxPrice: null,
				city: null,
				condition: null,
				currency: "GEL",
			};

			this.getPromotionCarParts();
		},
	},
};
</script>
