// import Vue from 'vue'
// import VueRouter from 'vue-router'
import Index from "@/components/websites/blueberrynightshotel/Index"
import Contacts from "@/components/websites/blueberrynightshotel/Contacts"
import About from "@/components/websites/blueberrynightshotel/About"
import Rooms from "@/components/websites/blueberrynightshotel/Rooms"
import Kitchen from "@/components/websites/blueberrynightshotel/Kitchen"
import Location from "@/components/websites/blueberrynightshotel/Location"
import Booking from "@/components/websites/blueberrynightshotel/Booking"
import Favicon from "@/assets/websites/blueberrynightshotel/img/favicon.png"
import { createRouter, createWebHistory } from 'vue-router'
// Vue.use(VueRouter)

const favIcon = Favicon

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },
    {
        path: '/rooms',
        name: 'rooms',
        component: Rooms,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },
    {
        path: '/kitchen',
        name: 'kitchen',
        component: Kitchen,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },
    {
        path: '/location',
        name: 'location',
        component: Location,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    },

    {
        path: '/booking',
        name: 'booking',
        component: Booking,
        meta: {
            title: 'BLUEBERRY NIGHTS',
            favicon: favIcon
        }
    }
].map(route => ({
    ...route,
    meta: {
        ...route.meta,
        translationKey: 'blueberrynightshotel'
    }
}))

// const router = new VueRouter({
//     mode: 'history',
//     routes
// })

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
