<template>
    <div id="menuControl" aria-hidden="true" aria-labelledby="menuControlLabel" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body pt-0">
                    <p class="mb-0 fs-4 text-center">Auto-Click.Pro</p>

                    <form action="#" class="mt-2" method="#">
                        <div class="mx-3">
                            <input :placeholder="trans('menu_user')" class="form-control" type="text">
                        </div>

                        <div class="mx-3 mt-3">
                            <input :placeholder="trans('menu_password')" class="form-control" type="password">

                        </div>

                        <button class="btn btn-warning mt-3 w-100" type="submit">{{ trans('menu_login') }}</button>
                    </form>

                    <div class="mt-5 text-center">
                        <a class="d-block text-dark text-decoration-none" href="#">{{
                                trans('menu_forgot_password')
                            }}</a>
                        <a class="d-block text-dark text-decoration-none mt-3" href="#">{{ trans('menu_register') }}</a>
                    </div>

                    <div class="d-flex align-items-center justify-content-center mt-5">
                        <i class="fa-solid fa-phone me-1"></i>
                        <a class="d-block text-dark text-decoration-none" href="tel:+995599995995">+995 599 995 995</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {t} from "@/lang/core/helpers";

export default {
    name: "MenuControl",

    methods: {
        trans(key) {
            return t(key)
        },
    }
}
</script>

<style scoped>
.modal-dialog {
    max-width: 400px;
}
</style>