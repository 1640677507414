<template>
    <div class="characteristics">
        <p class="fw-semibold">
            Characteristics
        </p>

        <div class="border p-lg-3 p-2">
            <div class="d-flex justify-content-between">
                <span class="d-block lh-1 text-secondary">Базовый цвет</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Мультиколор</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Бренд</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">ARA (Вся обувь)</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Пол</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Женщины</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Утеплитель</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Утепленная</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Тип каблука</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Плоский</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Основной материал</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Кожа (натуральная кожа)</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Материал верха</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Кожа/Замша</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Материал подкладки</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Флис</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Материал подошвы</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">ТПР</span>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <span class="d-block lh-1 text-secondary">Производитель</span>
                <span class="border-2 border-bottom flex-grow-1 mx-2" style="border-bottom-style: dotted !important;"></span>
                <span class="d-block lh-1">Shoes AG Postfach</span>
            </div>
        </div>

        <div id="accordionFlushExample" class="accordion accordion-flush mt-lg-5 mt-3 accordionStyles">
            <div class="accordion-item">
                <h2 id="flush-headingOne" class="accordion-header">
                    <button aria-controls="flush-collapseOne"
                            aria-expanded="false"
                            class="accordion-button collapsed fs-5 px-0 fs-sm-16 text-uppercase"
                            data-bs-target="#flush-collapseOne"
                            data-bs-toggle="collapse" type="button">
                        guarantees
                    </button>
                </h2>
                <div id="flush-collapseOne" aria-labelledby="flush-headingOne"
                     class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <p class="mb-0 fw-semibold">
                            Our guarantees
                        </p>

                        <ul class="list-style-none fs-15 mb-0">
                            <li>
                                Single shipping cost
                            </li>

                            <li>
                                14 days free return of goods (does not apply to custom items and masks)
                            </li>

                            <li>
                                Reply to any inquiry within 1 business day
                            </li>

                            <li>
                                Carbon neutral shipping for all parcels
                            </li>
                        </ul>

                        <p class="mb-0 fw-semibold mt-3">
                            Want to know more?
                        </p>

                        <ul class="list-style-none fs-15 mb-0">
                            <li>
                                <button class="btn link-primary border-0 p-0" type="button">
                                    Orders and delivery
                                </button>
                            </li>

                            <li>
                                <button class="btn link-primary border-0 p-0" type="button">
                                    Return
                                </button>
                            </li>

                            <li>
                                <button class="btn link-primary border-0 p-0" type="button">
                                    Taxes and duties
                                </button>
                            </li>

                            <li>
                                <button class="btn link-primary border-0 p-0" type="button">
                                    Carbon neutral shipping
                                </button>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 id="flush-headingTwo" class="accordion-header">
                    <button aria-controls="flush-collapseTwo"
                            aria-expanded="false"
                            class="accordion-button collapsed fs-5 px-0 fs-sm-16 text-uppercase"
                            data-bs-target="#flush-collapseTwo"
                            data-bs-toggle="collapse" type="button">
                        size and fit
                    </button>
                </h2>
                <div id="flush-collapseTwo" aria-labelledby="flush-headingTwo"
                     class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        2
                    </div>
                </div>
            </div>
            <div class="accordion-item border-bottom">
                <h2 id="flush-headingThree" class="accordion-header">
                    <button aria-controls="flush-collapseThree"
                            aria-expanded="false"
                            class="accordion-button collapsed fs-5 px-0 fs-sm-16 text-uppercase"
                            data-bs-target="#flush-collapseThree"
                            data-bs-toggle="collapse" type="button">
                        description
                    </button>
                </h2>
                <div id="flush-collapseThree" aria-labelledby="flush-headingThree"
                     class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        3
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ViewProductDescription"
}
</script>

<style scoped>



@media screen and (min-width: 992px) {
    .characteristics {
        margin-top: -80px;
    }
}

</style>