<template>
    <div>
        <header class="container-fluid px-0 sticky-top">
            <HeaderLanding/>
        </header>

        <main class="container-fluid px-0 my-lg-5 my-3 pb-lg-5 pb-3">
            <div class="container px-lg-0">
                <div class="card rounded border-0 overflow-hidden  mt-lg-5 mt-3 catering">
                    <div class="card-body p-0 position-relative">
                        <img :src="Pic1" alt="" class="w-100 img-fluid">

                        <p class="mb-0 position-absolute start-50 top-50 translate-middle text-white text-center fs-3">
                            კვების ობიექტები საქართველოში
                        </p>
                    </div>
                </div>

                <div class="card rounded border-0 overflow-hidden  mt-lg-5 mt-3 dish-recipes">
                    <div class="card-body p-0 position-relative">
                        <img :src="Pic2" alt="" class="w-100 img-fluid">

                        <p class="mb-0 position-absolute start-50 top-50 translate-middle text-white text-center fs-3">
                            კერძების რეცექპტები
                        </p>
                    </div>
                </div>

                <div class="card rounded border-0 overflow-hidden  mt-lg-5 mt-3 cocktails-recipes">
                    <div class="card-body p-0 position-relative">
                        <img :src="Pic1" alt="" class="w-100 img-fluid">

                        <p class="mb-0 position-absolute start-50 top-50 translate-middle text-white text-center fs-3">
                            კოქტეილების რეცეპტები
                        </p>
                    </div>
                </div>

                <div class="card rounded border-0 overflow-hidden  mt-lg-5 mt-3 delivery">
                    <div class="card-body p-0 position-relative">
                        <img :src="Pic2" alt="" class="w-100 img-fluid">

                        <p class="mb-0 position-absolute start-50 top-50 translate-middle text-white text-center fs-3">
                            მიწოდების სერვისი
                        </p>
                    </div>
                </div>

                <div class="row m-0 mt-lg-5 mt-3 food-row">
                    <div class="col-md-4 col-6 ps-0 pe-2">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="GeoFood" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        ქართული კერძრბი
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-6 px-md-2 ps-2 pe-0">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="EuropeFood" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        ევროპული კერძრბი
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-6 ps-md-2 ps-0 pe-md-0 pe-2 mt-lg-0 mt-3">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="AsianFood" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        აზიური კერძრბი
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-6 ps-md-0 pe-md-2 ps-2 pe-0 mt-3">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="Deserts" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        დესერტები
                                    </p>
                                </router-link>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-6 px-md-2 ps-0 pe-2 mt-3">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="Fish" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        თევზეული და ზღვის პროდუქტები
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-6 ps-2 pe-0 mt-3">
                        <div class="card overflow-hidden border-0">
                            <div class="card-body p-0 position-relative">
                                <router-link :to="{ name: 'deseart' }" class="d-block">
                                    <img :src="OtherFood" alt="" class="w-100 img-fluid">

                                    <p class="mb-0 position-absolute start-50 top-50 translate-middle text-nowrap-lg text-white fs-3 text-md-start text-center">
                                        სხვა დანარჩენი
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card overflow-hidden border-0 mt-lg-5 mt-3">
                    <div class="card-body p-0 map-index">
                        <iframe
                            allowfullscreen=""
                            height="100%" loading="lazy"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.956952747897!2d44.799894815355515!3d41.69986498452824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40440d7c8a1be847%3A0xdb4c8a3b81aae94f!2z4YOZ4YOQ4YOk4YOUIOGDk-GDkOGDpOGDnOGDkA!5e0!3m2!1ska!2sru!4v1634644365969!5m2!1ska!2sru"
                            style="border:0;" width="100%"></iframe>
                    </div>
                </div>

                <ul class="mt-lg-5 mt-3 list-links ps-0">
                    <li>
                        <a class="text-decoration-none" href="#">ახალი ამბები</a>
                    </li>

                    <li class="mt-2">
                        <a class="text-decoration-none" href="#">სამზარეულოს ინვენტარი</a>
                    </li>

                    <li class="mt-2">
                        <a class="text-decoration-none" href="#">ბარის ინვენტარი</a>
                    </li>

                    <li class="mt-2">
                        <a class="text-decoration-none" href="#">მიმოხილვები</a>
                    </li>

                    <li class="mt-2">
                        <a class="text-decoration-none" href="#">სასარგებლო ბმულები</a>
                    </li>
                </ul>
            </div>
        </main>

        <footer class="container-fluid px-0">
            <FooterLanding/>
        </footer>
    </div>
</template>

<script>
import Pic1 from "@/assets/websites/allmenu/img/pic-1.png"
import Pic2 from "@/assets/websites/allmenu/img/pic-2.png"
import GeoFood from "@/assets/websites/allmenu/img/geo-food.jpg"
import EuropeFood from "@/assets/websites/allmenu/img/eu-food.jpg"
import AsianFood from "@/assets/websites/allmenu/img/as-food.jpg"
import Deserts from "@/assets/websites/allmenu/img/deserts.jpg"
import Fish from "@/assets/websites/allmenu/img/fish-pic.jpg"
import OtherFood from "@/assets/websites/allmenu/img/other-food.jpg"
import HeaderLanding from "@/components/websites/allmenu/landing/landing-parts/HeaderLanding.vue";
import FooterLanding from "@/components/websites/allmenu/landing/landing-parts/FooterLanding.vue";

export default {
    name: "WebsitesIndex",

    components: {
        FooterLanding,
        HeaderLanding
    },

    data() {
        return {
            Pic1,
            Pic2,
            GeoFood,
            EuropeFood,
            AsianFood,
            Deserts,
            Fish,
            OtherFood
        }
    }
}
</script>

<style scoped>
.list-links li {
    list-style: none;
}

.list-links li a:hover {
    color: #7c66df;
    text-decoration: underline !important;
}


.card img {
    filter: brightness(.5);
}


.card .card-body {
    transition: .3s;
}

.card:hover .card-body {
    transform: scale(1.15);
}

@media screen and (min-width: 992px) {
    .text-nowrap-lg {
        white-space: nowrap !important
    }

    .card img {
        height: 250px;
    }

    .map-index {
        height: 250px;
    }
}

@media screen and (max-width: 992px) {
    .food-row img {
        height: 105px;
    }
}
</style>