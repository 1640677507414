<template>
    <div class="container-fluid intro-bg px-0 d-none d-lg-block">
        <div class="container index-intro py-5 px-0">
            <div class="row row-cols-3 m-0">
                <a class="col px-5"
                   href="#">
                    <div
                        class="services_links border border-white d-flex align-items-center justify-content-center px-0 py-4">
                        <div class="row">
                            <div class="col-3">
                                <img :src="CreditSmallIcon" alt="Credit icon" class="img-fluid" height="60"
                                     loading="lazy"
                                     width="80">
                            </div>
                            <div class="col-9 text-uppercase px-0">
                                <span class="text-white">автокредитование</span>
                                <span class="text-light fw-light fs-12 d-block">все регионы от 3.2%</span>
                            </div>
                        </div>
                    </div>
                </a>
                <a class="col px-5"
                   href="#">
                    <div
                        class="services_links border border-white d-flex align-items-center justify-content-center px-0 py-4">
                        <div class="row">
                            <div class="col-3">
                                <img :src="TradeInSmallIcon" alt="Credit icon" class="img-fluid" height="60"
                                     loading="lazy"
                                     width="80">
                            </div>
                            <div class="col-9 text-uppercase px-0">
                                <span class="text-white">TRADE-IN</span>
                                <span class="text-light fw-light fs-12 d-block">обмен на выгодных условиях</span>
                            </div>
                        </div>
                    </div>
                </a>
                <a class="col px-5"
                   href="#">
                    <div
                        class="services_links border border-white d-flex align-items-center justify-content-center px-0 py-4">
                        <div class="row">
                            <div class="col-3">
                                <img :src="BuyOutSmallIcon" alt="Credit icon" class="img-fluid" height="60"
                                     loading="lazy"
                                     width="70">
                            </div>
                            <div class="col-9 text-uppercase px-0">
                                <span class="text-white">Выкуп</span>
                                <span class="text-light fw-light fs-12 d-block">выкуп на выгодных условиях</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CreditSmallIcon from "@/assets/websites/rb-auto/img/credit-small-icon1.png"
import TradeInSmallIcon from "@/assets/websites/rb-auto/img/tradein-small-icon1.svg"
import BuyOutSmallIcon from "@/assets/websites/rb-auto/img/vikup-small-icon1.svg";

export default {
    name: 'IntroSlideRbAuto',

    data() {
        return {
            CreditSmallIcon,
            TradeInSmallIcon,
            BuyOutSmallIcon,
        }
    }
}

</script>

<style>
.intro-bg {
    background-image: url('@/assets/websites/rb-auto/img/intro-pic1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
//background-attachment: fixed;
}

.services_links {
    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(100px);
    height: 109px;
    transition: .5s;
}

.services_links:hover {
    backdrop-filter: brightness(.5) !important;
}
</style>