document.addEventListener("DOMContentLoaded", function () {

    let lastScrollTop = 0;

    const headerTransformation = () => {
        let windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
        windowScrollTop = windowScrollTop + 115

        let header = document.getElementById('header')
        let mainCategoryWrapper = document.getElementById('main-categories-wrapper')
        let stickyMobile = document.getElementById('sticky-mobile')
        let innerHeaderActivity = document.getElementById('inner-header')

        if (windowScrollTop >= header.clientHeight) {
            mainCategoryWrapper.classList.add('category-fixed-top');
            stickyMobile.setAttribute("style", "top: 41px")

            return
        }

        if (windowScrollTop <= 220) {
            innerHeaderActivity.classList.remove('inner-header-activity')
        }

        mainCategoryWrapper.classList.remove('category-fixed-top')
    }

    const upScrollActivities = () => {
        let windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
        windowScrollTop = windowScrollTop + 100


        if (windowScrollTop >= 320) {
            let stickyMobile = document.getElementById('sticky-mobile')
            let mainCategoryWrapper = document.getElementById('main-categories-wrapper')
            let innerHeaderActivity = document.getElementById('inner-header')

            if (mainCategoryWrapper.classList.contains('category-fixed-top')) {
                if (!mainCategoryWrapper.classList.contains('upScrollActivity')) {
                    mainCategoryWrapper.classList.add('upScrollActivity')
                    innerHeaderActivity.classList.add('headerIndex')
                }

                stickyMobile.setAttribute("style", "top: 80px")

                if (!innerHeaderActivity.classList.contains('inner-header-activity')) {
                    innerHeaderActivity.classList.add('inner-header-activity')
                }
            }
        }
    }

    const slideActiveClass = (activeClass) => {
        let categoryBlocks = document.getElementsByClassName('un-category-block')
        let mainCategoryScroller = document.getElementById('main-category-scroller')

        let screenMiddleX = window.innerWidth / 2

        let totalWidth = 0

        if (activeClass > 1) {
            let activeClassWidth = document.getElementById('main-category-' + categoryBlocks[activeClass].getAttribute('parentId')).clientWidth / 2

            for (let i = 0; i < activeClass; i++) {
                totalWidth += document.getElementById('main-category-' + categoryBlocks[i].getAttribute('parentId')).clientWidth
            }

            screenMiddleX = screenMiddleX - activeClassWidth

            totalWidth = totalWidth - screenMiddleX

            mainCategoryScroller.scrollLeft = totalWidth + 15
        } else {
            mainCategoryScroller.scrollLeft = totalWidth
        }
    }

    const activeParentCategory = (activeClass) => {
        let categoryBlocks = document.getElementsByClassName('un-category-block')

        for (let i = 0; i < categoryBlocks.length; i++) {
            if (i === activeClass) {
                document.getElementById('main-category-' + categoryBlocks[i].getAttribute('parentId')).classList.add('active_nav_btn')
                document.getElementById('parent-x-category-' + categoryBlocks[i].getAttribute('parentId')).classList.add('active')
                document.getElementById('x-parent-' + categoryBlocks[i].getAttribute('parentId')).classList.remove('d-none')
                document.getElementById('x-parent-' + categoryBlocks[i].getAttribute('parentId')).classList.add('d-block')
                slideActiveClass(i)
            } else {
                let mainCategory = document.getElementById('main-category-' + categoryBlocks[i].getAttribute('parentId'))
                let parentCategory = document.getElementById('parent-x-category-' + categoryBlocks[i].getAttribute('parentId'))
                let xParentCategory = document.getElementById('x-parent-' + categoryBlocks[i].getAttribute('parentId'))

                if (mainCategory) {
                    document.getElementById('main-category-' + categoryBlocks[i].getAttribute('parentId')).classList.remove('active_nav_btn')
                }

                if (parentCategory) {
                    document.getElementById('parent-x-category-' + categoryBlocks[i].getAttribute('parentId')).classList.remove('active')
                }

                if (xParentCategory) {
                    document.getElementById('x-parent-' + categoryBlocks[i].getAttribute('parentId')).classList.remove('d-block')
                    document.getElementById('x-parent-' + categoryBlocks[i].getAttribute('parentId')).classList.add('d-none')
                }
            }
        }
    }

    const parentCategories = (windowScrollTop) => {
        let categoryBlocks = document.getElementsByClassName('un-category-block')

        let activeClass = 0

        for (let i = 0; i < categoryBlocks.length; i++) {
            let categoryOffset = categoryBlocks[i].offsetTop;
            let categoryHeight = categoryBlocks[i].scrollHeight;

            let categoryOffsetX = parseFloat(categoryOffset) + parseFloat(categoryHeight)

            categoryOffsetX = categoryOffsetX + 100

            if ((windowScrollTop === categoryOffset) || (windowScrollTop > categoryOffset && windowScrollTop < categoryOffsetX)) {
                activeClass = i
            }
        }

        activeParentCategory(activeClass)
    }

    const slideChildActiveClass = (activeClass) => {
        let categoryBlocks = document.getElementsByClassName('un-children-category-block')
        let childCategoryScroller = document.getElementsByClassName('children-categories-box')

        for (let i = 0; i < childCategoryScroller.length; i++) {
            if (childCategoryScroller[i].parentElement.classList.contains('d-block')) {
                childCategoryScroller = childCategoryScroller[i]
            }
        }

        let screenMiddleX = window.innerWidth / 2

        let totalWidth = 0

        if (activeClass > 1) {
            let activeClassWidth = document.getElementById('parent-' + categoryBlocks[activeClass].getAttribute('childid')).clientWidth / 2

            for (let i = 0; i < activeClass; i++) {
                totalWidth += document.getElementById('parent-' + categoryBlocks[i].getAttribute('childid')).parentElement.clientWidth
            }

            screenMiddleX = screenMiddleX - activeClassWidth

            totalWidth = totalWidth - screenMiddleX

            childCategoryScroller.scrollLeft = totalWidth
        } else {
            childCategoryScroller.scrollLeft = totalWidth
        }
    }

    const activeChildCategory = (activeClass) => {
        let childrenCategoryBlock = document.getElementsByClassName('un-children-category-block')

        for (let i = 0; i < childrenCategoryBlock.length; i++) {
            if (i === activeClass) {
                document.getElementById('parent-' + childrenCategoryBlock[i].getAttribute('childId')).classList.add('active')
                slideChildActiveClass(i)
            } else {
                document.getElementById('parent-' + childrenCategoryBlock[i].getAttribute('childId')).classList.remove('active')
            }
        }
    }

    const childrenCategories = (windowScrollTop) => {
        let childrenCategoryBlock = document.getElementsByClassName('un-children-category-block')

        let activeClass = null

        for (let i = 0; i < childrenCategoryBlock.length; i++) {
            let categoryOffset = childrenCategoryBlock[i].offsetTop;
            let categoryHeight = childrenCategoryBlock[i].scrollHeight;

            let categoryOffsetX = parseFloat(categoryOffset) + parseFloat(categoryHeight)

            categoryOffsetX = categoryOffsetX + 100

            if ((windowScrollTop === categoryOffset) || (windowScrollTop > categoryOffset && windowScrollTop < categoryOffsetX)) {
                activeClass = i
            }
        }

        activeChildCategory(activeClass)
    }


    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    window.addEventListener("scroll", function () {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        let windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        let scrollTopButton = document.getElementById('scrollTop')

        if (!scrollTopButton) {
            return false
        }

        scrollTopButton.style.opacity = 0

        if (windowScrollTop > 250) {
            scrollTopButton.style.opacity = 1
        }

        let mainCategoryWrapper = document.getElementById('main-categories-wrapper')
        let innerHeaderActivity = document.getElementById('inner-header')
        let stickyMobile = document.getElementById('sticky-mobile')

        innerHeaderActivity.classList.remove('headerIndex')
        mainCategoryWrapper.classList.remove('upScrollActivity')
        innerHeaderActivity.classList.add('inner-header-activity')
        stickyMobile.classList.add('upScrollActivitySticky')
        if (st > lastScrollTop) {
            parentCategories(windowScrollTop)
            childrenCategories(windowScrollTop)
            headerTransformation()
        } else {
            parentCategories(windowScrollTop)
            childrenCategories(windowScrollTop)
            headerTransformation()
            upScrollActivities()
        }

        lastScrollTop = st;
    }, false);
});
