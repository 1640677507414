<template>
    <div>
        <TableReservation/>

        <MenuControl/>

        <OffCanvas/>

        <main>
            <div v-if="webBg.url" class="container-fluid px-0 containerMobHeight">
                <div class="container px-0">
                    <div
                        class="mx-lg-3 d-none d-md-block position-relative rounded overflow-hidden category-soft-wrapper"
                        style="height: 300px;">
                        <div
                            :style="typeof webBg.meta.x !== 'undefined' && typeof webBg.meta.y !== 'undefined' && webBg.meta.x && webBg.meta.y ? 'background-image: url('+webBg.url+');height: 100%; zoom: '+webBg.meta.zoom+'; background-repeat: no-repeat; background-position: '+webBg.meta.x+'px '+webBg.meta.y+'px; zoom: '+webBg.meta.zoom+'' : 'background-image: url('+webBg.url+');zoom: '+webBg.meta.zoom+';height: 100%;background-repeat: no-repeat;'"
                            :title="companyName"
                            class="d-none d-md-block position-relative">
                            <p class="mb-0 position-absolute bottom-0 text-white p-3 fs-3 fw-semibold webBgTitle">
                                {{ getCompanyAddress() }}
                            </p>
                        </div>
                    </div>
                    <div class="d-md-none mobBg">
                        <img :alt="companyName" :src="mobBg" :title="companyName" class="w-100 rounded" loading="lazy">
                    </div>
                    <div class="mobBgOverlay w-100 position-absolute top-0 d-md-none"></div>
                </div>
            </div>

            <div class="container px-0 mt-lg-3">
                <div class="row m-0 px-lg-3">
                    <left-side/>

                    <div class="col-lg-6 px-lg-4 px-0 pb-lg-5">
                        <div>
                            <p class="mb-0 fs-3 fw-bold">
                                <category-products
                                    :categories="menuCompanyCategoriesAndProducts"/>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 mt-lg-0 mt-3 mb-lg-0 mb-5">
                        <div class="sticky-lg-top" style="top: 70px;">
                            <menu-information/>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <a id="scrollTop" :title="trans('menu_up')"
           class="cursor-pointer d-flex align-items-center justify-content-center text-decoration-none border border-warning rounded-top p-2 arrow-up"
           style="opacity: 0" @click="scrollToTop()">
            <i class="fa-solid fa-chevron-up text-warning"></i>
        </a>
    </div>
</template>

<script>
import {t} from '@/lang/core/helpers'
import {mapGetters} from "vuex"
import _ from "lodash"
import CategoryProducts from "@/components/websites/allmenu/parts/CategoryProducts"
import langEN from "@/assets/websites/allmenu/img/flags/en.png"
import langRU from "@/assets/websites/allmenu/img/flags/ru.png"
import langKA from "@/assets/websites/allmenu/img/flags/ka.png"
import TableReservation from "@/components/websites/allmenu/parts/TableReservation"
import AppleMap from "@/assets/websites/allmenu/img/apple-map.png"
import GoogleMap from "@/assets/websites/allmenu/img/google-map.png"
import YandexMap from "@/assets/websites/allmenu/img/yandex-map.png"
import TwoGis from "@/assets/websites/allmenu/img/2gis-map.png"
import WikiMapia from "@/assets/websites/allmenu/img/wikimapia.png"
import OpenStreet from "@/assets/websites/allmenu/img/openstreet-map.png"
import BoltTaxi from "@/assets/websites/allmenu/img/bolt-taxi.png"
import YandexTaxi from "@/assets/websites/allmenu/img/yandex-taxi.png"
import MaximTaxi from "@/assets/websites/allmenu/img/maxim-taxi.png"
import Glovo from "@/assets/websites/allmenu/img/glovo-icon.png"
import Wolt from "@/assets/websites/allmenu/img/wolt-icon.png"
import Elvis from "@/assets/websites/allmenu/img/elvis-icon.png"
import BoltFood from "@/assets/websites/allmenu/img/bolt-food-icon.png"
import Facebook from "@/assets/websites/allmenu/img/fb-icon.png"
import Instagram from "@/assets/websites/allmenu/img/instagram-icon.png"
import Telegram from "@/assets/websites/allmenu/img/telegram-icon.png"
import Twiter from "@/assets/websites/allmenu/img/twiter-icon.png"
import Whatsapp from "@/assets/websites/allmenu/img/app_whatsapp.png"
import Viber from "@/assets/websites/allmenu/img/app_viber.png"
import Messenger from "@/assets/websites/allmenu/img/messenger-icon.png"
import MenuInformation from "@/components/websites/allmenu/parts/Information";
import LeftSide from "@/components/websites/allmenu/parts/LeftSide";
import MenuControl from "@/components/websites/allmenu/parts/MenuControl";
import OffCanvas from "@/components/websites/allmenu/parts/OffCanvas";

export default {
    name: "RestaurantCategories",
    components: {OffCanvas, MenuControl, LeftSide, MenuInformation, TableReservation, CategoryProducts},
    computed: {
        ...mapGetters([
            'menuCompany',
            'locale',
            'menuCompanyCategoriesAndProducts',
            'menuCompanyRequisites',
            'availableLocales',
            'menuCompanyDefaultLocale',
        ]),

        companyName() {
            if (!this.menuCompany) {
                return null
            }

            let translation = _.filter(this.menuCompany.translations, {locale: this.locale})
            let translatedName = null

            if (!translation || !translation.length) {
                let locales = (this.availableLocales || []).filter(locale => locale !== this.locale)

                if (locales && locales.length) {
                    locales.forEach(l => {
                        translation = _.filter(this.menuCompany.translations, {locale: l})

                        if (translation && translation.length) {
                            translatedName = translation[0].name
                            return
                        }
                    })
                }

                return translatedName
            }

            return translation[0].name
        },

        companyObjectType() {
            if (!this.menuCompany) {
                return null
            }

            let translatedCompany = _.filter(this.menuCompany.translations, {locale: this.locale})

            if (!translatedCompany || !translatedCompany.length) {
                return this.menuCompany.translations[0].object_type
            }

            return translatedCompany[0].object_type
        },

        companyId() {
            if (!this.menuCompany) {
                return null
            }

            return this.menuCompany.id
        },

        companyAvailableLocales() {
            return this.availableLocales.map(availableLocale => {
                return availableLocale.toLowerCase()
            })
        },

        webBg() {
            if (!this.menuCompany) {
                return null
            }

            let assets = _.filter(this.menuCompany.assets, {image_type: 'COVER'})

            return {
                url: assets.length
                    ? assets[0].url + '?id=' + Math.floor(Math.random() * (100000 - 1 + 1) + 1)
                    : null,
                meta: assets.length
                    ? assets[0].meta
                        ? assets[0].meta
                        : {
                            x: null,
                            y: null,
                            zoom: 1
                        }
                    : {
                        x: null,
                        y: null,
                        zoom: 1
                    },
            }
        },

        mobBg() {
            if (!this.menuCompany) {
                return null
            }

            let assets = _.filter(this.menuCompany.assets, {image_type: 'COVER'})

            return assets.length ? assets[0].url + '?id=' + Math.floor(Math.random() * (100000 - 1 + 1) + 1) : null
        },

        localizedMap() {
            if (!this.menuCompanyRequisites['LOC_GOOGLE_MAP_EMBED']) {
                return null
            }

            let map = this.menuCompanyRequisites['LOC_GOOGLE_MAP_EMBED']
            let mapLocale = "1s" + this.locale.toLowerCase()

            switch (this.locale.toLowerCase()) {
                case 'ka':
                    map = map.replace('1sru', mapLocale)
                    map = map.replace('1sen', mapLocale)
                    break
                case 'ru':
                    map = map.replace('1sen', mapLocale)
                    map = map.replace('1ska', mapLocale)
                    break
                case 'en':
                    map = map.replace('1ska', mapLocale)
                    map = map.replace('1sru', mapLocale)
                    break
                default:
                    break
            }

            return map
        },

        wikimapia() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://wikimapia.org/#lang=en&lat=" + this.menuCompanyRequisites['COORDINATE_XX'] + "&lon=" + this.menuCompanyRequisites['COORDINATE_YY'] + "&z=17&m=w"
        },

        openStreet() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://www.openstreetmap.org/search?whereami=1&query=" + this.menuCompanyRequisites['COORDINATE_XX'] + "%2C" + this.menuCompanyRequisites['COORDINATE_YY'] + "#map=19/" + this.menuCompanyRequisites['COORDINATE_XX'] + "/" + this.menuCompanyRequisites['COORDINATE_YY']
        },

        twogis() {
            if (!this.menuCompanyRequisites['COORDINATE_XX'] || !this.menuCompanyRequisites['COORDINATE_YY']) {
                return null
            }

            return "https://2gis.ru/?m=" + this.menuCompanyRequisites['COORDINATE_YY'] + "%2C" + this.menuCompanyRequisites['COORDINATE_XX'] + "%2F14.52"
        }
    },

    data() {
        return {
            BoltTaxi,
            YandexTaxi,
            MaximTaxi,
            AppleMap,
            GoogleMap,
            YandexMap,
            TwoGis,
            WikiMapia,
            OpenStreet,
            Glovo,
            Wolt,
            Elvis,
            BoltFood,
            Facebook,
            Instagram,
            Telegram,
            Twiter,
            Whatsapp,
            Viber,
            Messenger,
            treeData: [],
            popupBg: {
                backgroundImage: `url(${require('@/assets/websites/allmenu/img/popup-bg.jpeg')})`
            },
            localeIcons: {
                en: langEN,
                ru: langRU,
                ka: langKA
            }
        }
    },

    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        trans(key) {
            return t(key)
        },

        openMap() {
            if (!this.menuCompanyRequisites['LOC_GOOGLE_MAP_LINK']) {
                return
            }

            window.open(this.menuCompanyRequisites['LOC_GOOGLE_MAP_LINK'], '_blank')
        },

        getCategoryName(category) {
            let translation = _.filter(category.translations, {locale: this.locale})

            return translation[0].name
        },

        pushRoute(category) {
            this.$router.push({
                name: 'menu-category',
                params: {
                    locale: this.locale,
                    companyKey: this.menuCompany.key,
                    slug: category.slug
                }
            })

            let categoryContent = document.getElementById('category-' + category.id) || document.getElementById('children-category-' + category.id)

            window.scrollTo({
                top: categoryContent.offsetTop,
                behavior: 'smooth'
            })
        },

        openBlankUrl(url) {
            window.open(url, '_blank')
        },

        changeLocale(locale) {
            if (!this.companyAvailableLocales.includes(locale)) {
                return
            }

            let oldLocale = window.localStorage.getItem('locale') || 'ka'
            let path = this.$route.fullPath.replace('/' + oldLocale, '/' + locale)

            window.localStorage.setItem('locale', locale)
            this.$store.commit('setLocale', locale)

            this.$router.push(path)

            if (this.$route.meta.title.includes('##COMPANY_NAME##')) {
                let localeCompany = _.filter(this.menuCompany.translations, {locale: locale})

                if (!localeCompany.length) {
                    document.title = this.$route.meta.title.replace('##COMPANY_NAME##', this.menuCompany.translations[0].name)
                    return
                }

                let companyName = _.filter(this.menuCompany.translations, {locale: locale})[0].name

                document.title = this.$route.meta.title.replace('##COMPANY_NAME##', companyName)
            }
        },

        getCompanyAddress() {
            let locale = this.locale || 'ka'
            let locAddress = this.menuCompanyRequisites['LOC_ADDRESS'] || []
            let locCity = this.menuCompanyRequisites['LOC_CITY'] || []
            let locRegion = this.menuCompanyRequisites['LOC_REGION'] || []

            return [
                locRegion[locale] || null,
                locCity[locale] || null,
                locAddress[locale] || null
            ].filter(Boolean).join(', ')
        },

        basketButton() {
            let basketButton = document.getElementById('basketButton')
            let basketButtonicon = document.getElementById('basketButtonIcon')

            if (basketButton.getAttribute('opened') === 'true') {
                basketButton.setAttribute('opened', false)
                basketButtonicon.classList.add('fa-chevron-right')
                basketButtonicon.classList.remove('fa-xmark')
            } else {
                basketButton.setAttribute('opened', true)
                basketButtonicon.classList.remove('fa-chevron-right')
                basketButtonicon.classList.add('fa-xmark')
            }
        }
    }
}
</script>

<style>

.map iframe {
    width: 100% !important;
    height: unset !important;
}

.basketTotalBg {
    background: #f2f2f2;
}

.category-fixed-top {
    position: fixed !important;
    top: 0 !important;
    background-color: #f8f9fa;
    left: 0;
    right: 0;
}

@media screen and (min-width: 992px) {

    .category-checker-duck {
        border: unset !important;
    }

}

@media screen and (max-width: 992px) {
    .category-fixed-top .parent-category {
        color: #000 !important;
    }

    .category-fixed-top .parent-category.active_nav_btn {
        color: #FFA336 !important;
    }

    .stickyMobile {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 10;
        border-bottom: 1px solid var(--bs-border-color-translucent);
    }

    .containerMobHeight {
        height: 124px;
        overflow: hidden;
    }

    .mobBg {
        transform: translate3d(0, -30%, 0);
    }

    .mobBg img {
        border-radius: 0 !important;
    }

    .mobBgOverlay {
        background-color: rgba(0, 0, 0, .2);
        height: 124px;
    }
}

</style>
