<template>
    <div class="row m-0">
        <div v-for="n in 8" :key="n" class="col-lg-3 col-md-6">
            <div class="border">
                <router-link :to="{ name:'view-car' }" class="d-block text-decoration-none">
                    <img :src="CarPic" alt="" class="w-100 img-fluid">
                </router-link>

                <router-link :to="{ name:'view-car' }" class="d-block text-decoration-none">
                    Volkswagen Carabelle
                </router-link>

                <hr>

                <div class="d-flex flex-wrap align-items-center gap-2">
                    <span>Дизель</span>
                    <span>136 кс</span>
                    <span>2011 г</span>
                    <span>Автомат</span>
                    <span>Коричневый</span>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between">
                    <span class="d-block">1 775 000 руб</span>
                    <del class="d-block">1 975 000 руб</del>
                </div>

                <div>
                    от 22 188 руб. / мес
                </div>

                <router-link :to="{name: 'credit'}" class="text-decoration-none d-flex justify-content-center">
                    купить в кредит
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
import CarPic from "@/assets/websites/auto/img/car-pic.jpg"

export default {
    name: "AutoSaleables",

    data() {
        return {
            CarPic
        }
    }
}
</script>

<style scoped>

</style>