<template>
    <div class="row flex-nowrap overflow-auto">
        <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="d-flex align-items-center bg-light p-3 gap-3">
                <i class="fa-solid fa-cart-shopping"></i>

                <p class="fw-normal m-0">
                    Your order is in preparation
                </p>
            </div>
            <div aria-label="Success example" aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress"
                 role="progressbar">
                <div class="progress-bar bg-secondary" style="width: 60%"></div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="d-flex align-items-center bg-light p-3 gap-3">
                <i class="fa-solid fa-cart-shopping"></i>

                <p class="fw-normal m-0">
                    Your order is in preparation
                </p>
            </div>
            <div aria-label="Success example" aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress"
                 role="progressbar">
                <div class="progress-bar bg-secondary" style="width: 20%"></div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-10">
            <div class="d-flex align-items-center bg-light p-3 gap-3">
                <i class="fa-solid fa-cart-shopping"></i>

                <p class="fw-normal m-0">
                    Your order is in preparation
                </p>
            </div>
            <div aria-label="Success example" aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress"
                 role="progressbar">
                <div class="progress-bar bg-secondary" style="width: 80%"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PreparationOrder"
}
</script>

<style>
.preparation-order {
    top: 200px;
}

.progress {
    height: 4px;
}

@media screen and (max-width: 991px) {
    .preparation-order {
        top: -95px;
    }
}
</style>