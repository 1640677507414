<template>
    <div class="shadow-sm mt-3">
        <div class="bg-white p-lg-3 p-2 border-bottom">
            <h5 class="p-0 m-0 text-uppercase fs-sm-16">характеристики</h5>
        </div>

        <div id="accordionPanelsStayOpenExample" class="accordion accordion-flush">
            <div class="accordion-item">
                <h2 id="flush-headingOne" class="accordion-header">
                    <button aria-controls="flush-collapseOne"
                            aria-expanded="false"
                            class="accordion-button collapsed bg-white text-uppercase fs-15 fs-sm-14 p-lg-3 p-2"
                            data-bs-target="#flush-collapseOne"
                            data-bs-toggle="collapse" type="button">
                        КОМПЛЕКТАЦИЯ
                    </button>
                </h2>

                <div id="flush-collapseOne" aria-labelledby="flush-headingOne" class="accordion-collapse collapse"
                     data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body bg-white p-0">
                        <div class="row m-0">
                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Обзор</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Противотуманные фары
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Автоматический корректор фар
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Омыватель фар
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Система адаптивного освещения
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Система управления дальним светом
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Датчик дождя
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Датчик света
                                </p>
                            </div>

                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Элементы экстерьера</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Аэрография
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Декоративные молдинги
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Рейлинги на крыше
                                </p>
                            </div>

                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Прочее</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Рейлинги на крыше
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Рейлинги на крыше
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Рейлинги на крыше
                                </p>

                            </div>

                            <div class="col-lg-6  mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Мультимедиа</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    AUX
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Bluetooth
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    USB
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Мультимедиа система для задних пассажиров
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Навигационная система
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Голосовое управление
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Android Auto
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Apple CarPlay
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Яндекс.Авто
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Беспроводная зарядка для смартфона
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Розетка 12V
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Розетка 220V
                                </p>
                            </div>

                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Салон</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Память положения
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Спортивные передние сидения
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Сиденья с массажем
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Обогрев рулевого колеса
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Отделка кожей рулевого колеса
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Отделка кожей рычага КПП
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Люк
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Панорамная крыша / лобовое стекло
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Отделка потолка чёрной тканью
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Передний центральный подлокотник
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Третий задний подголовник
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Третий ряд сидений
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Складывающееся заднее сидение
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Функция складывания спинки сиденья пассажира
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Складной столик на спинках передних сидений
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Тонированные стекла
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Солнцезащитные шторки в задних дверях
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Солнцезащитная шторка на заднем стекле
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Декоративная подсветка салона
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Декоративные накладки на педали
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Накладки на пороги
                                </p>

                            </div>

                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Комфорт</strong>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Система доступа без ключа
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Дистанционный запуск двигателя
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Запуск двигателя с кнопки
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Система «старт-стоп»
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Бортовой компьютер
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Электронная приборная панель
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Проекционный дисплей
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Программируемый предпусковой отопитель
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Электропривод зеркал
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Электроскладывание зеркал
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Электропривод крышки багажника
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Открытие багажника без помощи рук
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Мультифункциональное рулевое колесо
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Подрулевые лепестки переключения передач
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Охлаждаемый перчаточный ящик
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Регулируемый педальный узел
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Доводчик дверей
                                </p>

                                <p class="py-2"><i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Прикуриватель и пепельница
                                </p>
                            </div>

                            <div class="col-lg-6 mt-3 fs-sm-14">
                                <strong class="fs-sm-16">Безопасность</strong>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Центральный замок
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Антиблокировочная система (ABS)
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Система стабилизации (ESP)
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Датчик давления в шинах
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Блокировка замков задних дверей
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    ЭРА-ГЛОНАСС
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Бронированный кузов
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Иммобилайзер
                                </p>

                                <p class="py-2">
                                    <i class="fas fa-check fs-12 me-2 text-danger"></i>
                                    Датчик проникновения в салон (датчик объема)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <h2 id="flush-headingThree" class="accordion-header">
                <button aria-controls="flush-collapseThree"
                        aria-expanded="false"
                        class="accordion-button collapsed bg-white text-uppercase fs-15 fs-sm-14 p-lg-3 p-2"
                        data-bs-target="#flush-collapseThree"
                        data-bs-toggle="collapse" type="button">
                    Проверка по VIN-номеру
                </button>
            </h2>
            <div id="flush-collapseThree" aria-labelledby="flush-headingThree" class="accordion-collapse collapse"
                 data-bs-parent="#accordionFlushExample">
                <div class="accordion-body bg-white p-0">
                    <div class="row m-0">
                        <div class="col-lg-6">
                            <strong class="fs-sm-16">Технические характеристики</strong>
                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Марка и модель:
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Год выпуска:
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Цвет:
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Объём:
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Мощность двигателя л.с.

                            </p>

                            <strong class="fs-sm-16">Участие в ДТП</strong><br/>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Не обнаружены
                            </p>

                        </div>

                        <div class="col-lg-6">
                            <strong class="fs-sm-16">Юридическая чистота</strong>
                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-danger me-2"></i>
                                Сведения о нахождении в залоге: не обнаружены
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-secondary me-2"></i>
                                Сведения о нахождении в розыске: не обнаружены
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-secondary me-2"></i>
                                Ограничения на регистрационные действия: не обнаружены
                            </p>


                            <strong class="fs-sm-16">Владельцы по ПТС</strong>
                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-secondary me-2"></i>
                                Количество владельцев: владелец(а)
                            </p>

                            <p class="py-2">
                                <i class="fas fa-check fs-12 text-secondary me-2"></i>
                                Сведения об использовании в такси: не обнаружены
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarCharacteristics',

    data() {
        return {}
    }
}
</script>

<style scoped>

</style>